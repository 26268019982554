// @ts-strict-ignore
import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { floatMath, FormatNumber } from 'phoenix/util';
import { Flex } from 'components/Flex';
import './index.module.css';
import { CustomNumberInputProps } from 'components/TradeTicket/Shared/TradeFormComponents';

const DollarAdornment = (
    <InputAdornment className='custom-dollar-adornment' position='start' style={{ marginLeft: 10 }}>
        $
    </InputAdornment>
);

export const CustomNumberInput = React.forwardRef((props: CustomNumberInputProps, ref: React.MutableRefObject<any>) => {
    const {
        arrows = null,
        decimalPlaces = 0,
        disabled,
        error,
        helperText,
        initialValue,
        label,
        min = 0,
        max,
        maxIsInfinite,
        onDollarValueChange,
        showDollarSign,
        step = 1,
        styles
    } = props;

    const [values, setValues] = useState<{ inputNumber?: number; display: string }>({ display: '' });
    const [cursor, setCursor] = useState(null);

    useEffect(() => {
        if (!ref?.current) return;
        const input = ref.current;
        if (input) input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, initialValue]);

    useEffect(() => {
        if (initialValue !== values?.inputNumber) {
            const value = typeof initialValue === 'number' || !isNaN(Number(initialValue)) ? initialValue : null;
            updateValue(value, value !== null ? value.toString() : '');
        }
    }, [decimalPlaces, initialValue]);

    const updateValue = (number: number, originalInput: string, selectionStart?: number) => {
        if ([null, undefined].includes(originalInput)) return;
        if (originalInput !== '.' && (isNaN(number) || originalInput === '')) {
            setValues({ display: '' });
            return;
        }
        const source = originalInput === '.' ? '0.' : originalInput;
        const formatted = source?.replace(/[^\d.]/g, '');
        const hasDecimal = formatted.indexOf('.') !== -1;
        const [integerPart, fractionalPart] = formatted.split('.');
        const fractionalPartFormatted = fractionalPart?.slice(0, decimalPlaces);
        const integerPartFormatted = integerPart?.replace(/[^\d]/g, ''); // parseInt() assumes commas are decimal points
        const almost = props?.decimalPlaces
            ? `${integerPart ? FormatNumber.toCommas(parseInt(integerPartFormatted)) : ''}${hasDecimal ? `.${fractionalPartFormatted}` : ''}`
            : FormatNumber.toCommas(parseInt(integerPartFormatted));
        const final = number >= max && maxIsInfinite ? `+${almost}` : almost;

        const prevMatch = [...values?.display?.matchAll(/,/g)];
        const prevCommas = prevMatch?.length;
        const newMatch = [...final.matchAll(/,/g)];
        const newCommas = newMatch?.length;

        // If adding or subtracting a new comma from a position < current cursor position, add or subtract the diff
        // So the cursor appears to stay in the same place within the value
        let newCursor = selectionStart || source?.length;
        if (prevCommas !== newCommas) {
            const diff = newCommas - prevCommas;
            if (newMatch.length && selectionStart > newMatch?.[0]?.index) {
                newCursor += diff;
            }
        }

        setCursor(newCursor);
        setValues({ inputNumber: number, display: final });
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        switch (event?.key) {
            case 'ArrowUp':
                return handleIncrement();
            case 'ArrowDown':
                return handleDecrement();
        }
    };

    const handleChange = (e) => {
        const { selectionStart, value: input } = e?.target || {};
        let number = input && parseFloat(parseFloat(input.replace(/[^\d.]/g, '')).toFixed(decimalPlaces));
        if (isNaN(number) || number < min) number = min;
        if (number > max) number = max;
        if (onDollarValueChange) onDollarValueChange(number || 0);

        updateValue(number, input, selectionStart);
    };

    const handleIncrement = () => {
        const number = parseFloat((values?.display || '')?.replace(/[^\d.]/g, '')) || 0;
        const newNumber = floatMath(number, step, (v, s) => v + s) || 0;
        if (onDollarValueChange) onDollarValueChange(newNumber || 0);
        updateValue(newNumber, `${FormatNumber.toLocaleDecimal({ decimalPlaces, value: newNumber })}`);
    };

    const handleDecrement = () => {
        const number = parseFloat((values?.display || '')?.replace(/[^\d.]/g, '')) || 0;
        if (number === min) return;
        const newNumber = floatMath(number, step, (v, s) => v - s) || 0;
        if (onDollarValueChange) onDollarValueChange(newNumber || 0);
        updateValue(newNumber, `${FormatNumber.toLocaleDecimal({ decimalPlaces, value: newNumber })}`);
    };

    const className = [{ 'custom-number-input': true }, { arrows }, { disabled }]
        .filter((x) => Object.values(x)[0])
        .map((x) => Object.keys(x)[0])
        .join(' ');

    // Reformat on blur
    const onBlur = (e) => {
        if (props.onBlur) props.onBlur(e.target.value);
        const formatted = FormatNumber.toLocaleDecimal({ decimalPlaces, value: values?.inputNumber, signDisplay: false });
        if (formatted !== values?.display && values?.display !== '') setValues({ ...values, display: formatted });
    };

    return (
        <TextField
            inputRef={ref}
            fullWidth
            placeholder={decimalPlaces ? `0.${new Array(decimalPlaces).fill('0').join('')}` : '0'}
            variant='outlined'
            {...{
                className,
                disabled,
                error,
                InputProps: {
                    endAdornment: arrows && (
                        <Flex column justify='space-around'>
                            <button title='Increment' onClick={handleIncrement}>
                                <KeyboardArrowUp />
                            </button>
                            <button title='Decrement' onClick={handleDecrement}>
                                <KeyboardArrowDown />
                            </button>
                        </Flex>
                    ),
                    onChange: (e) => handleChange(e),
                    onKeyDown: (e) => handleKeyDown(e),
                    startAdornment: showDollarSign ? DollarAdornment : undefined
                },
                helperText,
                label,
                onBlur,
                styles,
                value: values?.display
            }}
        />
    );
});
