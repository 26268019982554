import { Typography } from '@mui/material';
import React from 'react';
import { TradeTicketSection } from '../Shared/TradeTicketSection';

export const TradeMessageHeader = React.memo((props: { title: string }) => {

    return (
        <TradeTicketSection style={{ padding: '6px 20px', minHeight: 65, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Typography style={{ fontSize: 20, marginRight: 18 }} variant='h5'>{ props.title }</Typography>
        </TradeTicketSection>
    )

})