// @ts-strict-ignore
import React from 'react'
import { GetConfig } from "../../phoenix/constants";
import { SortData } from "../TableComponents/SortableSnexTableHeaderCell";
import { FilterableSecurity } from "./FilterableSecurity";

type S = (a: FilterableSecurity, b: FilterableSecurity) => number

export const SortSecurities = (securities: FilterableSecurity[], sort: SortData) => {

    const sorter: S = (() => {
        switch (sort?.field) { 
            case 'symbol': return ((a, b) => a.symbol > b.symbol ? 1 : -1) as S
            case 'name': return ((a, b) => a.name > b.name ? 1: -1) as S
            case 'price': return ((a, b) => a.price - b.price) as S
            case 'priceChange': return ((a, b) => a.priceChange - b.priceChange) as S
            case 'percentChange': return ((a, b) => a.percentChange - b.percentChange) as S
            case 'marketCap': return ((a,b) => a.marketCap - b.marketCap) as S
            default: return ((a, b) => 0) as S
        }
    })()

    const directionCoeff = sort?.direction === 'desc' ? -1 : 1;

    if(securities && securities.length > 0) securities = securities?.sort((a, b) => sorter(a, b) * directionCoeff).map(b => b)
    return securities
}