// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Flex, LoadingBlock } from 'components';
import { DisclosureFooter } from 'components/DisclosureFooter/DisclosureFooter';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { ContractListSkeletonRow, OrderDirection } from 'screens/OptionsScreen';
import { useParams } from 'react-router-dom';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { SafeFormat } from 'phoenix/util';
import TimeSpreadContractListRow from './TimeSpreadContractListRow';
import 'screens/OptionsScreen/OptionContractList/OptionContractList.scss';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { useDispatch } from 'react-redux';
import { GetFuturesTimeSpreadsAction } from 'phoenix/redux/actions';

export type TimeSpreadContractListProps = {
    orderDirection?: OrderDirection;
};

// F:ZC FTS +U23,-Z23 => ZCU23-ZCZ23
export function getTimeSpreadLabel(symbol: string): string {
    const fsym = new FuturesSymbol(symbol);

    const frontMonthSymbol = new FuturesSymbol(fsym.nearMonthContract);
    const frontYear = SafeFormat(frontMonthSymbol.date, 'MMM yyyy');

    const farMonthSymbol = new FuturesSymbol(fsym.farMonthContract);
    const farYear = SafeFormat(farMonthSymbol.date, 'MMM yyyy');

    return `${frontYear} - ${farYear}`;
}

export default (props: TimeSpreadContractListProps) => {
    const dispatch = useDispatch();
    const { orderDirection = 'desc' } = props;
    const { frontMonthSymbol } = useParams<{ symbol: string; frontMonthSymbol: string }>();
    const contracts = useSnexStore((s) => s.securities.bySymbol[frontMonthSymbol]?.timeSpreads);
    const loading = !contracts || contracts?.pristine || contracts?.loading;
    const sorted = contracts?.data?.sort((a, b) => {
        const aSym = new FuturesSymbol(a.symbol);
        const bSym = new FuturesSymbol(b.symbol);

        const aFarSym = new FuturesSymbol(aSym.farMonthContract);
        const bFarSym = new FuturesSymbol(bSym.farMonthContract);

        return aFarSym.date.getTime() - bFarSym.date.getTime();
    });
    const orderedContracts = orderDirection === 'desc' ? sorted : sorted.reverse();

    useEffect(() => {
        if ((!contracts || contracts.pristine) && frontMonthSymbol) dispatch(GetFuturesTimeSpreadsAction(frontMonthSymbol));
    }, [contracts, dispatch, frontMonthSymbol]);

    if (loading || !contracts?.data?.length) return <LoadingBlock height={300} loading={loading} noDataText='No contracts available' />;

    return (
        <Flex column style={{ width: '100%' }}>
            <div className='contract-list-inner-container'>
                {orderedContracts.map((c, i) => (
                    <LazyLoadedContractListRow key={i} meta={c} />
                ))}
            </div>
            <DisclosureFooter securityPricing ordersAndActivity clientExtras />
        </Flex>
    );
};

const LazyLoadedContractListRow = React.memo(({ meta }: { meta: SecurityMetadata }) => {
    const [ref, visible] = useInView({ trackVisibility: true, delay: 400 });
    const [expanded, setExpanded] = useState(false);

    return (
        <div ref={ref}>
            {visible || expanded ? (
                <TimeSpreadContractListRow {...{ expanded, meta, setExpanded }} />
            ) : (
                <ContractListSkeletonRow label={getTimeSpreadLabel(meta.symbol)} />
            )}
        </div>
    );
});
