import { ReduxAction } from '../../models';

export class ActionNames {
    BaseName: string;
    Loading: string;
    Success: string;
    Failure: string;

    constructor(prefix: string) {
        const s = (suffix: string) => `${prefix}${suffix}`;
        this.BaseName = prefix;
        this.Loading = s('_LOADING');
        this.Success = s('_SUCCESS');
        this.Failure = s('_FAILURE');
    }

    matches(action: string): 'loading' | 'success' | 'failure' | false {
        switch (action) {
            case this.Loading:
                return 'loading';
            case this.Success:
                return 'success';
            case this.Failure:
                return 'failure';
            default:
                return false;
        }
    }

    static IsLoading(action: string): boolean {
        return /_LOADING$/.test(action);
    }

    static IsSuccess(action: string): boolean {
        return /_SUCCESS$/.test(action);
    }

    static IsFailure(action: string): boolean {
        return /_FAILURE$/.test(action);
    }
}

export class StreamingActionNames {
    Subscribing: string;
    Subscribed: string;
    Update: string;
    Unsubscribed: string;
    Terminated: string;

    constructor(prefix: string) {
        const s = (suffix: string) => `${prefix}${suffix}`;
        this.Subscribing = s('_SUBSCRIBING');
        this.Subscribed = s('_SUBSCRIBED');
        this.Update = s('_UPDATE');
        this.Unsubscribed = s('_UNSUBSCRIBED');
        this.Terminated = s('_TERMINATED');
    }
}

export const GroupNameChecker = (actionNames: ({ [key: string]: ActionNames & StreamingActionNames & any } | ActionNames | StreamingActionNames | string)[]) => {
    const pickStringLeaves = (o: object): string[] => {
        return Object.values(o).flatMap((value) => {
            switch (typeof value) {
                case 'string':
                    return [value];
                case 'object':
                    return pickStringLeaves(value);
                default:
                    return [];
            }
        });
    };
    const names = actionNames.flatMap((n) => {
        switch (typeof n) {
            case 'object':
                return pickStringLeaves(n);
            case 'string':
                return [n];
            default:
                return null;
        }
    });
    const nameSet = new Set(names);

    return (action: ReduxAction) => nameSet.has(action.type);
};

export const Actions = {
    // General
    Errors: {
        ReportApiError: 'CAUGHT_API_ERROR',
        Clear: 'CLEAR_ERRORS'
    },

    ClearStore: 'CLEAR_STORE',
    DebugNoOp: new ActionNames('DEBUG_NOOP'),
    NoAction: 'NO_ACTION',

    // Login
    Login: new ActionNames('LOGIN'),
    Logout: new ActionNames('LOGOUT'),
    JwtLogin: 'JWT_LOGIN',
    GetTokenFromCode: new ActionNames('MOBILE_CODE_XCHANGE'),
    RefreshToken: new ActionNames('REFRESH_TOKEN'),
    CheckLogin: new ActionNames('LOGIN_VERIFY'),
    SetLoginExpired: 'SET_LOGIN_EXPIRED',
    UnauthError: new ActionNames('DEBUG_TRIGGER_UNAUTH_ERROR'),

    Admin: {
        GetSearchedUser: new ActionNames('GET_SEARCHED_USR'),
        UpdateSearchedUser: new ActionNames('UPDATE_SEARCHED_USR'),
        GetFullSystemStatus: new ActionNames('ADMIN_GET_FULL_SYS_STATUS'),
        BypassEnrollment: new ActionNames('ADMIN_BYPASS_ENROLLMENT'),
        DemoteUserToFree: new ActionNames('ADMIN_DEMOTE_USER_TO_FREE'),
        GetSymbologyOverrides: new ActionNames('ADMIN_GET_SYMBOLOGY_OVERRIDES'),
        UpsertSymbologyOverride: new ActionNames('ADMIN_UPSERT_SYMBOLOGY_OVERRIDE'),
        DeleteSymbologyOverride: new ActionNames('ADMIN_DELETE_SYMBOLOGY_OVERRIDES')
    },

    Alerts: {
        GetFields: new ActionNames('GET_ALERT_FIELDS'),
        GetRules: new ActionNames('GET_ALERT_RULES'),
        CreateRule: new ActionNames('CREATE_RULE'),
        UpdateRule: new ActionNames('UPDATE_RULE'),
        ToggleRule: new ActionNames('TOGGLE_RULE'),
        DeleteRule: new ActionNames('DELETE_RULE')
    },

    Acats: {
        GetDeliveringFirms: new ActionNames('ACATS_GET_FIRMS'),
        Submit: new ActionNames('ACATS_SUBMIT'),
        ResetSubmit: 'ACATS_SUBMIT_RESET'
    },

    // Funding
    Funding: {
        Plaid: {
            GetToken: new ActionNames('FUN_GET_TOKEN'),
            GetAuthorization: new ActionNames('FUN_GET_AUTHORIZATION'),
            UpdateAuthorization: new ActionNames('FUN_UPDATE_AUTHORIZATION'),
            GetVerificationStatus: new ActionNames('FUN_GET_VERIFICATION_STATUS')
        },
        LinkedAccounts: {
            GetForAccount: new ActionNames('FUN_GET_LINKED_ACCT'),
            GetAll: new ActionNames('FUN_GET_LINKED_ALL'),
            Add: new ActionNames('FUN_ADD_LINKED'),
            Void: new ActionNames('FUN_VOID_LINKED'),
            Balance: new ActionNames('FUN_BANK_BALANCE'),
            Limits: new ActionNames('FUN_MGMT_LIMITS')
        },
        Banks: {
            GetTop: new ActionNames('FUN_TOP_BANKS'),
            Search: new ActionNames('FUN_SEARCH_BANKS')
        },
        Transfer: {
            Deposit: new ActionNames('FUN_DEPOSIT'),
            Withdraw: new ActionNames('FUN_WITHDRAW'),
            Limits: new ActionNames('GET_LIMITS')
        },
        FundingSources: {
            GetAll: new ActionNames('FUN_SRC_GET_ALL'),
            Add: new ActionNames('FUN_SRC_ADD'),
            Void: new ActionNames('FUN_SRC_VOID')
        },
        Ains: {
            GetVoids: new ActionNames('FUN_GET_VOIDS'),
            GetDepositFailures: new ActionNames('FUN_GET_DEPOSIT_FAILURES'),
            GetWithdrawFailures: new ActionNames('FUN_GET_WITHDRAW_FAILURES'),
            ClearVoidsAndDepositFailures: new ActionNames('FUN_CLEAR_VOIDS_AND_DEPOSIT_FAILURES'),
        }
    },

    // Positions
    Positions: {
        Closed: new ActionNames('GET_CLOSED_POSITIONS'),
        GetAll: new ActionNames('GET_POSITIONS'),
        GetAllUnpriced: new ActionNames('GET_POSITIONS_INDEX'),
        LiveUpdates: new StreamingActionNames('STREAM_POSITIONS'),
        GetRealizedPnl: new ActionNames('GET_REALIZED_PNL')
    },

    Messages: {
        Search: new ActionNames('MSG_SEARCH'),
        GetOne: new ActionNames('MSG_GET_ONE'),
        CountUnread: new ActionNames('MSG_UNREAD_COUNT'),
        GetUnreadStats: new ActionNames('MSG_UNREAD_STATS'),
        MarkRead: new ActionNames('MSG_MARK_READ'),
        Generate: new ActionNames('MSG_GENERATE'),
        GeneratePush: new ActionNames('MSG_GENERATE_PUSH'),
        Broadcast: new ActionNames('MSG_BROADCAST'),
        LiveStream: new StreamingActionNames('MSG_STREAM'),
        Delete: new ActionNames('MSG_DELETE'),
        DeleteAll: new ActionNames('MSG_DELETE_ALL'),
        emitToast: 'EMIT_TOAST',
        EmitMessage: 'EMIT_TOAST',
        GetClientControls: new ActionNames('MSG_CLIENT_CONTROLS'),
        UpdateClientControls: new ActionNames('MSG_CLIENT_CONTROLS_UPDATE'),
        GetSystemControls: new ActionNames('MSG_SYSTEM_CONTROLS'),
        GetAdminControls: new ActionNames('MSG_ADMIN_CONTROLS'),
        UpdateAdminControls: new ActionNames('MSG_ADMIN_CONTROLS_UPDATE'),
        VerifyPhone: new ActionNames('MSG_CONFIRM_PHONE')
    },

    Market: {
        GetHolidays: new ActionNames('MKT_HOLIDAYS_GET'),
        UpsertHoliday: new ActionNames('MKT_HOLIDAY_UPSERT'),
        DeleteHoliday: new ActionNames('MKT_HOLIDAY_DELETE'),
        GetOpenStatus: new ActionNames('MKT_STATUS_GET')
    },

     // Transactions
     Transactions: {
        SearchResults: new ActionNames('SEARCH_TRANSACTIONS'),
        SearchResultsV2: new ActionNames('SEARCH_TRANSACTIONS_V2')
    },

    // Accounts
    Accounts: {
        GetAll: new ActionNames('ALL_ACCOUNTS'),
        GetProjectedIncome: new ActionNames('ALL_INCOME_PROJECTION'),
        GetProjectedIncomeV2: new ActionNames('ALL_INCOME_PROJECTION_V2'),
        UpdateNickname: new ActionNames('ACCT_NICKNAME_UPDATE'),
        GetMaxNumberOfFuturesAccounts: new ActionNames('GET_MAX_NUMBER_OF_FUTURES_ACCOUNTS'),
        GetFuturesPendingAccounts: new ActionNames('GET_FUTURES_PENDING_ACCOUNTS')
    },

    AccountApplicationStatus: {
        GetByAccountNumber: new ActionNames('ACCT_APPLICATION_STATUS')
    },

    AccountOpenings: {
        GetAll: new ActionNames('ACCT_OPENINGS')
    },

    AccountCharts: {
        GetAggregate: new ActionNames('ACCT_CHART_AGG'),
        GetOne: new ActionNames('ACCT_CHART'),
        GetMany: new ActionNames('ACCT_CHART_MULTI'),
        ResetCharts: 'RESET_ACCOUNT_CHARTS',
        LiveUpdates: new StreamingActionNames('LIVE_ACCT_CHART'),
        AggregateLiveUpdates: new StreamingActionNames('LIVE_CHART_AGG')
    },

    // Account Summaries
    AccountSummaries: {
        GetAggregate: new ActionNames('ACCT_SUM_AGG'),
        GetBatch: new ActionNames('ACCT_SUM_BATCH'),
        GetBuyingPower: new ActionNames('ACCT_MANY_BUYING_POWER')
    },

    Device: {
        Register: new ActionNames('DEV_REGISTER'),
        Unregister: new ActionNames('DEV_UNREGISTER')
    },

    Documents: {
        GetTypes: new ActionNames('DOC_TYPES'),
        GetTypesV2: new ActionNames('DOC_TYPES_V2'),
        Search: new ActionNames('DOC_SEARCH'),
        SearchV2: new ActionNames('DOC_SEARCH_V2'),
        Download: new ActionNames('DOC_DOWNLOAD'),
        GetPaperlessPrefs: new ActionNames('DOC_PAPERLESS_PREFS_FETCH'),
        UpdatePaperlessPrefs: new ActionNames('DOC_PAPERLESS_PREFS_UPDATE'),
        GoCompletelyPaperless: new ActionNames('DOC_PAPERLESS_PREFS_SET_ALL')
    },

    Orders: {
        Search: new ActionNames('SEARCH_ORDERS'),
        SearchV2: new ActionNames('SEARCH_ORDERS_V2'),
        SearchV3: new ActionNames('SEARCH_ORDERS_V3'),
        GetOrders: new ActionNames('GET_ORDERS'),
        GetOpenOrders: new ActionNames('GET_OPEN_ORDERS'),
        GetSingleOrder: new ActionNames('GET_ONE_ORDER'),
        GetSingleOrderV3: new ActionNames('GET_ONE_ORDER_V3'),
        GetForSymbol: new ActionNames('GET_SYM_ORDERS'),
        GetForAccount: new ActionNames('GET_ACCT_ORDERS'),
        LiveStatusUpdate: new StreamingActionNames('LIVE_ORD_STATUS_UPDATE')
    },

    // Taxlots
    Taxlots: {
        GetAggregate: new ActionNames('TAXLOTS_AGG'),
        GetOpen: new ActionNames('TAXLOTS_OPEN'),
        GetClosed: new ActionNames('TAXLOTS_CLOSED')
    },

    // Balances
    AccountBalances: {
        getBalances: new ActionNames('ACCOUNT_BALANCES')
    },

    Trading: {
        Submit: new ActionNames('SUBMIT_TRADE'),
        Verify: new ActionNames('VERIFY_TRADE'),
        ResetVerify: 'VERIFY_TRADE_RESET',
        Edit: new ActionNames('EDIT_TRADE'),
        Cancel: new ActionNames('CANCEL_TRADE'),
        GetLoanRate: new ActionNames('GET_LOAN_RATE'),
        RemoveOrder: 'REMOVE_ORDER'
    },

    System: {
        GetLanguages: new ActionNames('GET_LANGS'),
        GetTimeZones: new ActionNames('GET_TZ'),
        GetStatus: new ActionNames('GET_SYS_STATUS'),
        GetTermsDocument: new ActionNames('GET_TERMS_DOC'),
        SetTitleSecurity: 'SET_TITLE_SEC'
    },

    Options: {
        GetMonths: new ActionNames('OPT_MONTHS'),
        GetChain: new ActionNames('OPT_CHAIN'),
        GetSingleQuote: new ActionNames('OPT_SINGLE_QUOTE'),
        GetChart: new ActionNames('OPT_CHART'),
        GetContractSize: new ActionNames('OPT_SIZE'),
        StreamQuotes: new StreamingActionNames('LIVE_OPT_CHAIN'),
        StreamFuturesQuotes: new StreamingActionNames('LIVE_OPT_CHAIN_FUTURES')
    },

    // News
    News: {
        ForMarket: new ActionNames('NEWS_MARKET'),
        ForPortfolio: new ActionNames('NEWS_PORTFOLIO'),
        ForAccount: new ActionNames('NEWS_ACCT'),
        ForSymbol: new ActionNames('NEWS_SYM'),
        Streaming: new StreamingActionNames('NEWS_STREAM'),
        ShowStreamed: 'NEWS_SHOW_STREAMED'
    },

    Subscription: {
        Subscribe: new ActionNames('PREMIUM_SUBSCRIBE'),
        GetMyInfo: new ActionNames('GET_MY_SUB_INFO'),
        SetMyTier: new ActionNames('SET_MY_SUB_TIER'),
        GetStripeCheckoutUrl: new ActionNames('GET_STRIPE_CHECKOUT_URL'),
        GetStripePortalUrl: new ActionNames('GET_STRIPE_PORTAL_URL'),
        Cancel: new ActionNames('CANCEL_SUB'),
        Uncancel: new ActionNames('UNCANCEL_SUB'),
        UpdatePaymentAccount: new ActionNames('SUB_UPDATE_PAYMENT_ACCOUNT')
    },

    // Securities & Market Data
    Securities: {
        SetSelectedSymbol: 'SET_SELECTED_SYMBOL',
        GetRiskFreeRate: new ActionNames('RISK_FREE_RATE'),
        Logos: new ActionNames('GET_SEC_LOGOS'), // OBSOLETE! Please use GetLogo isntead
        Chart: {
            Get: new ActionNames('GET_SEC_CHART'),
            Reset: 'CLEAR_SECURITY-CHARTS',
            GetAllRanges: new ActionNames('GET_ALL_CHART_RANGES'),
            GetCustom: new ActionNames('GET_CUSTOM_CHART'),
            LiveUpdates: new StreamingActionNames('STREAM_SEC_CHART')
        },
        Dom: {
            LiveUpdates: new StreamingActionNames('STREAM_DOM')
        },
        GetMultiSecurityChartQuoteBatch: new ActionNames('GET_MULTI_SEC_CHART_QUOTE_BATCH'),
        GetMultiSecurityChartBatch: new ActionNames('GET_MULTI_SECURITY_CHART_BATCH'),
        GetMultiSecurityQuoteBatch: new ActionNames('GET_MULTI_SECURITY_QUOTE_BATCH'),
        Search: new ActionNames('GET_SEARCH_RESULT'),
        Quote: {
            Get: new ActionNames('GET_SEC_QUOTE'),
            SpreadLiveUpdates: new StreamingActionNames('STREAM_FUTURES_SPREAD')
        },
        GetMetadata: new ActionNames('GET_SEC_META'),
        GetCompanyInfo: new ActionNames('GET_COMPANY_INFO'),
        GetCompanyStats: new ActionNames('GET_COMPANY_ADV_STATS'),
        GetCompanyKeyStats: new ActionNames('GET_COMPANY_KEY_STATS'),
        GetCryptoStats: new ActionNames('GET_CRYPTO_STATS'),
        GetCryptoStatsForSymbol: new ActionNames('GET_CRYPTO_STATS_FOR_SYMBOL'),
        GetEarnings: new ActionNames('GET_EARNINGS'),
        GetEarningsEstimates: new ActionNames('GET_EARNINGS_EST'),
        GetDividends: new ActionNames('GET_DIVIDENDS'),
        GetRatings: new ActionNames('GET_RATINGS'),
        GetQuote: new ActionNames('GET_QUOTE'),
        GetNews: new ActionNames('GET_SECURITY_NEWS'),
        GetLogo: new ActionNames('GET_SEC_LOGO'),
        SetLogo: new ActionNames('SET_SEC_LOGO'),
        EarningsCalls: {
            Index: new ActionNames('GET_CALL_INDEX'),
            Next: new ActionNames('GET_NEXT_CALL'),
            Details: new ActionNames('GET_CALL_DETAILS')
        },
        Ownership: {
            GetInsiders: new ActionNames('GET_OWN_INSIDERS'),
            GetInstitutions: new ActionNames('GET_OWN_INSTS'),
            GetFunds: new ActionNames('GET_OWN_FUNDS')
        },
        GetPriceTarget: new ActionNames('GET_PRICE_TARGET'),
        GetTopMovers: new ActionNames('GET_TOP_MOVERS'),
        GetTopGainers: new ActionNames('GET_TOP_GAINERS'),
        GetTopLosers: new ActionNames('GET_TOP_LOSERS'),
        GetSectorPerformance: new ActionNames('GET_SECTOR_PERF'),
        GetRevenueEstimates: new ActionNames('GET_REV_EST'),
        GetStockSplits: new ActionNames('GET_SPLITS'),
        GetRelatedSymbols: new ActionNames('GET_SEC_RELATED'),
        GetRelatedMonths: new ActionNames('GET_SEC_RELATED_MONTHS'),
        GetFuturesContracts: new ActionNames('GET_FUTURES_CONTRACTS'),
        GetFuturesTimeSpreads: new ActionNames('GET_FUTURES_TIME_SPREADS'),
        GetFrontMonthTopMovers: new ActionNames('GET_FUTURES_MOVERS'),
        GetUpcomingEarnings: new ActionNames('GET_UPCOMING_EARNINGS')
    },

    Funds: {
        GetHoldings: new ActionNames('GET_FUND_HOLDINGS'),
        GetProfile: new ActionNames('GET_FUND_PROFILE'),
        GetSectorExposure: new ActionNames('GET_FUND_SECTOR_EXPOSURE'),
        GetCountryExposure: new ActionNames('GET_FUND_COUNTRY_EXPOSURE'),
        GetReinvestmentPreferences: new ActionNames('GET_FUND_REINVEST_PREFS'),
        SetReinvestmentPreferences: new ActionNames('SET_FUND_REINVEST_PREFS')
    },

    Sectors: {
        GetEtfSecuritiesBySector: new ActionNames('GET_ETF_SECURITIES_BY_SECTOR'),
        ResetEtfSecuritiesBySector: 'RESET_ETF_SECURITIES_BY_SECTOR'
    },

    // Watchlists
    Watchlists: {
        GetAll: new ActionNames('GET_WATCHLISTS'),
        Create: new ActionNames('CREATE_WATCHLIST'),
        Edit: new ActionNames('EDIT_WATCHLIST'),
        AddSymbols: new ActionNames('ADD_TO_WATCHLIST'),
        DeleteSymbols: new ActionNames('DELETE_FROM_WATCHLIST'),
        Delete: new ActionNames('DELETE_WATCHLIST'),
        ReorderSymbols: new ActionNames('WLIST_ITEM_REORDER'), // For API action
        ReorderSymbolsBatch: 'WLIST_ITEM_REORDER_BATCH', // For immediate UI response
        ReorderWatchlists: new ActionNames('WLIST_REORDER'),
        ReorderWatchlistsBatch: 'WLIST_REORDER_BATCH',
        ReorderWatchlistsSuccess: 'WLIST_REORDER_SUCCESS',
        MoveItemBetweenLists: 'WLIST_CHANGE_LISTS', // For immediate UI response too
        Reset: 'RESET_WATCHLIST_OPERATION'
    },

    WatchlistChart: {
        Get: new ActionNames('GET_WATCHLIST_CHART')
    },

    FinancialStatements: {
        BalanceSheet: new ActionNames('GET_BAL_SHEET'),
        CashFlow: new ActionNames('GET_CASH_FLOW'),
        Income: new ActionNames('GET_INCOME_STMT')
    },

    Users: {
        UpdateAppReviewStatus: new ActionNames('UPDATE_APP_REVIEW_STATUS'),
        DeleteMyImage: new ActionNames('USR_DELETE_PROFILE_PIC'),
        GetMyFeatures: new ActionNames('USR_GET_ME_FEATURES'),
        GetMyInfo: new ActionNames('USR_GET_ME'),
        GetMyPrefs: new ActionNames('USR_GET_ME_PREFS'),
        GetMyTaxInfo: new ActionNames('USR_GET_ME_TAX_INFO'),
        GetMyToken: new ActionNames('USR_GET_ME_TOKEN'),
        GetUserInfo: new ActionNames('USR_GET'),
        ResetMyProgress: new ActionNames('USR_RESET_ME_PROG'),
        UpdateMyImage: new ActionNames('USR_UPDATE_PROFILE_PIC'),
        UpdateMyInfo: new ActionNames('USR_UPDATE_ME'),
        UpdateMyOneClickTrading: new ActionNames('USR_UPDATE_ONECLICK'),
        UpdateMyTradingViewTicker: new ActionNames('USR_UPDATE_TRADINGVIEWTICKER'),
        UpdateMyPhone: new ActionNames('USR_UPDATE_ME_PHONE'),
        UpdateMyPrefs: new ActionNames('USR_UPDATE_ME_PREFS'),
        UpdateMyProgress: new ActionNames('USR_UPDATE_ME_PROG'),
        UpdateMyTaxInfo: new ActionNames('USR_UPDATE_ME_TAX_INFO'),
        UpdateMyTheme: new ActionNames('USR_UPDATE_THEME'),
        UpdateFuturesStrikes: new ActionNames('USR_UPDATE_FUTURES_STRIKES'),
        UpdateStrikes: new ActionNames('USR_UPDATE_STRIKES'),
        GetOneTimeToken: new ActionNames('USR_GET_OTT'),
        SetLanguageAnonymous: 'SET_LANG_ANON',
        GetHasAcceptedWebTos: new ActionNames('USR_GET_HAS_ACCEPTED_WEB_TOS'),
        ReportHasAcceptedWebTos: new ActionNames('USR_HAS_ACCEPTED_WEB_TOS')
    },

    Miniplayer: {
        Show: 'MP_SHOW',
        Hide: 'MP_HIDE',
        SetMedia: 'MP_SET',
        Resume: 'MP_RESUME',
        Pause: 'MP_STOP',
        Mute: 'MP_MUTE',
        Unmute: 'MP_UNMUTE',
        Close: 'MP_CLOSE',
        Loading: 'MP_LOADING',
        Size: 'MP_SIZE'
    },

    Relay: {
        Update: 'RELAY_UPDATE'
    },

    FeatureFlags: {
        GetAll: new ActionNames('FEATURES_GET_ALL')
    },

    // NOTE! For these to work, the name MUST start with `MOBILE_SCREEN`. Check RootReducer.tsx for why
    MobileScreens: {
        LoadLandingScreen: new ActionNames('MOBILE_SCREEN_LANDING_SCREEN'),
        LoadAppStartup: new ActionNames('MOBILE_SCREEN_APP_STARTUP'),
        LoadAppStartupPartTwo: new ActionNames('MOBILE_SCREEN_APP_STARTUP_P2'),
        LoadSummaryScreen: new ActionNames('MOBILE_SCREEN_LOAD_SUMMARY_SCREEN'),
        LoadSummaryScreenPartTwo: new ActionNames('MOBILE_SCREEN_LOAD_SUMMARY_SCREEN_PT_2'),
        LoadSecurityScreen: new ActionNames('MOBILE_SCREEN_LOAD_SECURITY_SCREEN'),
        LoadSecurityScreenPartTwo: new ActionNames('MOBILE_SCREEN_LOAD_SECURITY_SCREEN_PT_2'),
        LoadSecurityScreenForFuture: new ActionNames('MOBILE_SCREEN_LOAD_SECURITY_SCREEN_FUTURE'),
        LoadBrowseScreen: new ActionNames('MOBILE_SCREEN_LOAD_BROWSE_SCREEN'),
        LoadSectorScreen: new ActionNames('MOBILE_SCREEN_LOAD_SECTOR_SCREEN'),
        LoadProfileScreen: new ActionNames('MOBILE_SCREEN_PROFILE_SCREEN'),
        LoadEquityDeepAnalysisScreen: new ActionNames('MOBILE_SCREEN_EQUITY_DEEP_ANALYSIS_SCREEN'),
        Watchlists: {
            LoadIndex: new ActionNames('MOBILE_SCREEN_WATCHLIST_INDEX'),
            LoadDetails: new ActionNames('MOBILE_SCREEN_WATCHLIST_DETAILS')
        }
    },

    FuturesOnboarding: {
        GetFuturesOnboarding: new ActionNames('GET_FUTURES_ONBOARDING'),
        SubmitFuturesOnboarding: new ActionNames('SUBMIT_FUTURES_ONBOARDING'),
        UpdateFuturesOnboarding: new ActionNames('UPDATE_FUTURES_ONBOARDING')
    },

    Startup: {
        Authorize: new ActionNames('GET_STARTUP_AUTHORIZATION')
    },

    MediantSSO: {
        GetUrl: new ActionNames('GET_MEDIANTSSO_URL')
    },

    Whitelabel: {
        GetSettings: new ActionNames('GET_WHITELABEL_SETTINGS')
    },

    AuthorizationSettings: {
        GetSettings: new ActionNames('GET_AUTHORIZATION_SETTINGS')
    },

    Weather: {
        GetDaily: new ActionNames('GET_WEATHER_DAILY'),
        GetHourly: new ActionNames('GET_WEATHER_HOURLY'),
        GetCurrent: new ActionNames('GET_WEATHER_CURRENT'),
        GetRadarToken: new ActionNames('GET_RADAR_TOKEN'),
        GetVideos: new ActionNames('GET_WEATHER_VIDEOS'),
    }
};
