// @ts-strict-ignore
import { AssetFamily, AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { create } from 'zustand';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { getAccountAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { pullValuesFromUrl } from 'hooks/UseQueryParams';
import { AssetClass as LegacyAssetClass, QualifiedId } from 'phoenix/util/QualifiedId';

type AccountSelectByComponent = 'positionsScreenTradeTicket';

export const GetAssetClassFromAccountNumber = (accountNumber?: string): LegacyAssetClass => {
    if (!accountNumber) return 'equities';
    return QualifiedId.Class(accountNumber);
};
type AccountSelectionStore = {
    selectedAccount?: string;
    selectedAccountByAssetClass?: { [assetClass: string]: string };
    selectedAccountByComponent?: { [key in AccountSelectByComponent]: string };
    getSelectedAccountByAssetClass: (assetClass: AssetClass) => string;
    setSelectedAccountByAssetClass: (assetClass: AssetClass, accountNumber: string) => void;
    getSelectedAccountByComponent: (component: AccountSelectByComponent) => string;
    setSelectedAccountByComponent: ({ accountNumber, component }: { accountNumber: string; component: AccountSelectByComponent }) => void;
    setSelectedAccount: (accountNumber: string) => void;
};
const UseAccountSelectionStore = create<AccountSelectionStore>((set, get) => {
    const { selectedAccount } = pullValuesFromUrl();
    const assetFamily = GetAssetClassFromAccountNumber(selectedAccount);
    const defaults = {
        ...(selectedAccount ? { selectedAccount } : {}),
        selectedAccountByAssetClass: {
            futures: null,
            equities: null,
            crypto: null
        },
        selectedAccountByComponent: {
            positionsScreenTradeTicket: null
        }
    };

    if (selectedAccount) defaults.selectedAccountByAssetClass[assetFamily] = selectedAccount;

    return {
        ...defaults,
        setSelectedAccount: (accountNumber?: string) => {
            set((s) => ({ ...s, selectedAccount: accountNumber }));
        },
        getSelectedAccountByAssetClass: (assetClass: AssetClass) => get().selectedAccountByAssetClass[assetClass.family],
        setSelectedAccountByAssetClass: (assetClass: AssetClass, accountNumber?: string) =>
            set((s) => ({ ...s, selectedAccountByAssetClass: { ...s.selectedAccountByAssetClass, [assetClass.family]: accountNumber } })),
        getSelectedAccountByComponent: (component: AccountSelectByComponent) => get().selectedAccountByComponent[component],
        setSelectedAccountByComponent: ({ accountNumber, component }: { accountNumber?: string; component: AccountSelectByComponent }) => {
            set((s) => ({ ...s, selectedAccountByComponent: { ...s.selectedAccountByComponent, [component]: accountNumber } }));
        }
    };
});

const assetFamilyToAssetClass = (af: AssetFamily): AssetClass => {
    switch (af) {
        case 'cryptos':
            return CryptosAssetClass;
        case 'futures':
            return FuturesAssetClass;
        case 'equities':
        default:
            return EquitiesAssetClass;
    }
};

export const useSelectedAccountByAssetFamily = (assetFamily: AssetFamily): string =>
    UseAccountSelectionStore((s) => s).getSelectedAccountByAssetClass(assetFamilyToAssetClass(assetFamily));

export const UseSelectedAccount = (): [string, (accountNumber: string) => void, (assetClass: AssetClass) => string] => {
    const store = UseAccountSelectionStore;
    return [store((s) => s).selectedAccount, SetSelectedAccount, GetSelectedAccountByAssetClass];
};

export const useSelectedAccountByComponent = (component: AccountSelectByComponent): [string, (accountNumber?: string) => void] => {
    const store = UseAccountSelectionStore((s) => s);
    return [store.selectedAccountByComponent[component], (accountNumber: string) => store.setSelectedAccountByComponent({ accountNumber, component })];
};

export const GetSelectedAccount = (): string => UseAccountSelectionStore.getState().selectedAccount;
export const SetSelectedAccount = (accountNumber?: string): void => {
    const store = UseAccountSelectionStore;

    const prevState = store.getState();
    const selectedAccountByAssetClass =
        accountNumber === 'all'
            ? prevState.selectedAccountByAssetClass
            : { ...prevState.selectedAccountByAssetClass, [getAccountAssetClass(accountNumber).family]: accountNumber };

    const newState = {
        ...prevState,
        selectedAccount: accountNumber,
        selectedAccountByAssetClass
    };
    store.setState(newState);
};

export const GetSelectedAccountByAssetClass = (assetClass: AssetClass): string => UseAccountSelectionStore.getState()?.getSelectedAccountByAssetClass(assetClass);

export const GetSelectedAccountNonReactive = (): string => UseAccountSelectionStore.getState()?.selectedAccount;

// Selected account for an order can be isolated from the globally selected account in particular cases (currently only on the positions screen)
export const useSelectedAccountForOrderRequest = (assetClass: AssetClass): string => {
    const store = UseAccountSelectionStore((s) => s);
    return location.pathname === '/positions'
        ? store?.selectedAccountByComponent.positionsScreenTradeTicket
        : GetSelectedAccountByAssetClass(assetClass);
};


export const GetSelectedAccountForOrderRequest = (assetClass: AssetClass): string => {
    return location.pathname === '/positions'
        ? UseAccountSelectionStore.getState()?.selectedAccountByComponent.positionsScreenTradeTicket
        : GetSelectedAccountByAssetClass(assetClass);
};
