// @ts-strict-ignore
import Axios from 'axios'
import { SnexAxios } from 'phoenix/stores/AxiosHelpers'
import { Dispatch } from 'redux'
import { Ts } from '../../assets/lang/T'
import { Urls } from '../../constants'
import { DocumentTypeNames, DocumentTypesStaticList, DocumentTypeV2Ids } from '../../constants/DocumentTypes'
import { GlobalState } from '../GlobalState'
import { SnexDocumentType } from '../models'
import { PaperlessElectionType } from '../models/Documents/PaperlessPref'
import { MakeDocumentName, MakeDocumentNameV2, SnexDocument, SnexDocumentV2 } from '../models/Documents/SnexDocument'
import { Actions } from './Constants'
import { ReduxApiGet, ReduxApiPut } from './Helpers'
import { GetUserTokenAction } from './UserActions'

type DocumentFiltersBase = {
    documentTypeId: string,
    documentId: string,
    startDocumentDate: Date | string,
    endDocumentDate: string,
    subNo: string,
    branchNo: string,
    repNo: string,
    cusip: string,
    firstName: string,
    lastName: string,
    reference: string,
    referenceId: string,
    symbol: string,
    taxId: string,
    tradeDate: string,
    displayName: string,
    page: number,
    size: number,
    sortBy: string,
    sortMode: 'Ascend' | 'Descend',
    maxSize: number
}

type DocumentFiltersV1Type = DocumentFiltersBase & {
    accountNo: string
}

type DocumentFiltersV2Type = DocumentFiltersBase & {
    accountNumbers: string
    maxSize: number
    allAccounts: boolean
}

export type DocumentFilters = Partial<DocumentFiltersV1Type>
export type DocumentFiltersV2 = Partial<DocumentFiltersV2Type>

export const GetDocumentTypesActionV2 = (assetFamily?:string) => ReduxApiGet(Urls.documents.getTypesV2(assetFamily), Actions.Documents.GetTypesV2)
    .withMutex()
    .withSubject(assetFamily)
    .onSuccess((res: string[]) => {
        const text = Ts(s => s.documentsScreen.docTypes)

        const types = {
            accountStatements: {
                id: DocumentTypeV2Ids.accountStatements,
                name: text.accountStatements
            },
            accountNotices: {
                id: DocumentTypeV2Ids.accountNotices,
                name: text.accountNotices
            },
            checks: {
                id: DocumentTypeV2Ids.checks,
                name: text.checks
            },
            tradeConfirmations: {
                id: DocumentTypeV2Ids.tradeConfirmations,
                name: text.tradeConfirmations
            },
            marginRequirements: {
                id: DocumentTypeV2Ids.marginRequirements,
                name: text.marginRequirements
            },
            ifsNewAccts: {
                id: DocumentTypeV2Ids.ifsNewAccts,
                name: text.ifsNewAccts
            },
            intlPublicForms: {
                id: DocumentTypeV2Ids.intlPublicForms,
                name: text.intlPublicForms
            },
            taxDocuments: {
                id: DocumentTypeV2Ids.taxDocuments,
                name: text.taxDocuments
            },
            detailedVariationAnalysis: {
                id: DocumentTypeV2Ids.detailedVariationAnalysis,
                name: text.detailedVariationAnalysis
            },
            moneyOptionReport: {
                id: DocumentTypeV2Ids.moneyOptionReport,
                name: text.moneyOptionReport
            },
            MemoStatement: {
                id: DocumentTypeV2Ids.memoStatement,
                name: text.memoStatement
            },
            monthlyDiscretionaryAccounts: {
                id: DocumentTypeV2Ids.monthlyDiscretionaryAccounts,
                name: text.monthlyDiscretionaryAccounts
            },
            MonthlyStatementExcel: {
                id: DocumentTypeV2Ids.monthlyStatementExcel,
                name: text.monthlyStatementExcel
            },
            annualStatement: {
                id: DocumentTypeV2Ids.annualStatement,
                name: text.annualStatement
            },
            quarterlyStatement: {
                id: DocumentTypeV2Ids.quarterlyStatement,
                name: text.quaterlyStatement
            },
            monthlyStatement: {
                id: DocumentTypeV2Ids.monthlyStatement,
                name: text.monthlyStatement
            },
            dailyStatement: {
                id: DocumentTypeV2Ids.dailyStatement,
                name: text.dailyStatement
            },
            fullyPaidEarnings: {
                id: DocumentTypeV2Ids.fullyPaidEarnings,
                name: text.fullyPaidEarnings
            },
            fullyPaidLendingConfirms: {
                id: DocumentTypeV2Ids.fullyPaidLendingConfirms,
                name: text.fullyPaidLendingConfirms
            },
            fullyPaidLendingPositions: {
                id: DocumentTypeV2Ids.fullyPaidLendingPositions,
                name: text.fullyPaidLendingPositions
            }
        }

        // Only return type, if id was returned from endpoint.
        const keysToRemove = Object.entries(types)
            .filter(o => !res.includes(o[1].id))
            .map(o => o[0])

        keysToRemove.forEach(k => delete types[k])

        return types
    }).run();

export const SearchDocumentsActionV2 = (documentTypeId: string, filters?: DocumentFiltersV2, append?: boolean) => {
    const query: any = Object.entries(filters || {}).filter(e => !!e[1]).reduce((final, next) => ({ ...final, [next[0]]: next[1] }), {})
    query.sortBy = filters?.sortBy || 'documentDate'
    query.sortMode = filters?.sortMode || 'Descend'
    query.accountNumbers = filters?.accountNumbers
    query.allAccounts = filters?.allAccounts

    return ReduxApiGet(Urls.documents.searchV2(documentTypeId, query), Actions.Documents.SearchV2)
        .showToasts()
        .withLoading()
        .onSuccess((documents: SnexDocumentV2[]) => documents.map(d => ({
            ...d,
            type: documentTypeId,
            name: MakeDocumentNameV2(d),
            fileName: documentTypeId === DocumentTypeV2Ids.monthlyStatementExcel ? d.fileName?.replace('.xlsx', '.zip') : d.fileName
        })))
        .withPassthrough({ passthrough: { append } })
        .run()
}

export const DownloadDocumentActionV2 = (document: SnexDocumentV2) => {
    return async (dispatch: Dispatch, getState: () => GlobalState) => {
        const existing = getState().documents.download.data
        const useCached = existing?.documentId === document.id
        if (useCached) {
            dispatch({ type: Actions.Documents.Download.Success, data: existing })
            return existing
        }

        dispatch({ type: Actions.Documents.Download.Loading, data: { documentId: document.id, blob: null } })
        try {
            // TODO -- Switch to GetJwt() from the new generalized JWT storage
            // @ts-ignore
            const token: MyToken = await dispatch(GetUserTokenAction())
            const config = { headers: { Authorization: `Bearer ${token.accessToken}` } }
            const metadataV2 = document

            // Note: Axios was messing this up, which is why I'm using fetch instead. Also one of the reasons we can't use ReduxApiGet
            const url = Urls.documents.downloadV2(document.documentType, [document.id], document.source)
            const response = await fetch(url, config)

            if (!response.ok) { throw { errorCode: response.status } }

            const blob = await response.blob()
            const payload = { documentTypeId: document.documentType, documentId: document.id, blob, metadataV2 }
            dispatch({ type: Actions.Documents.Download.Success, data: payload })
            return payload
        } catch (e) {
            dispatch({ type: Actions.Documents.Download.Failure, error: e, errorDisplay: 'toast' })
            dispatch({ type: Actions.Errors.ReportApiError, error: e, errorDisplay: 'toast' })
            return { ...e, failed: true }
        }
    }
}

export const GetDocumentTypesAction = () => ReduxApiGet(Urls.documents.getTypes(), Actions.Documents.GetTypes)
    .withMutex()
    .onSuccess((res: SnexDocumentType[]) => {
        const returnedIds = new Set(res.map(r => r.id))
        const extras = DocumentTypesStaticList.filter(t => !returnedIds.has(t.id))
        const result = [...res, ...extras].sort((a, b) => b.name > a.name ? 1 : -1)
            .map(t => ({ ...t, name: DocumentTypeNames[t.name] || t.name }))
        return result
    })
    .useStored(s => s.documents.types.data?.length ? s.documents.types.data : null).run()

export const SearchDocumentsAction = (documentType: string, filters?: DocumentFilters, append?: boolean) => {
    const query: any = Object.entries(filters || {}).filter(e => !!e[1]).reduce((final, next) => ({ ...final, [next[0]]: next[1] }), {})
    query.sortBy = filters?.sortBy || 'documentDate'
    query.sortMode = filters?.sortMode || 'Descend'
    query.acctNo = filters?.accountNo || 'all'
    return ReduxApiGet(Urls.documents.search(documentType, query), Actions.Documents.Search)
        .showToasts()
        .withLoading()
        .onSuccess((documents: SnexDocument[]) => documents.map(d => ({
            ...d,
            type: documentType,
            name: MakeDocumentName(d, documentType)
        })))
        .withPassthrough({ passthrough: { append } })
        .run()
}

export const DownloadMultipleDocumentsAction = (documentTypeId: string, documentIds: string[]) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: Actions.Documents.Download.Loading, data: { documentTypeId, documentId: 'multiple', blob: null } })
        try {
            // @ts-ignore
            const token: MyToken = await dispatch(GetUserTokenAction())

            // Note: Axios was messing this up, which is why I'm using fetch instead. Also one of the reasons we can't use ReduxApiGet
            const response = await fetch(Urls.documents.download(documentTypeId, documentIds), { headers: { Authorization: `Bearer ${token.accessToken}` } })
            const blob = await response.blob()
            const payload = { documentTypeId, documentId: 'multiple', blob, metadata: null }
            dispatch({ type: Actions.Documents.Download.Success, data: payload })
            return payload
        } catch (e) {
            dispatch({ type: Actions.Documents.Download.Failure, error: e, errorDisplay: 'toast' })
            dispatch({ type: Actions.Errors.ReportApiError, error: e, errorDisplay: 'toast' })
            return { ...e, failed: true }
        }
    }
}

export const DownloadDocumentAction = (documentTypeId: string, documentId: string) => {
    return async (dispatch: Dispatch, getState: () => GlobalState) => {
        const existing = getState().documents.download.data
        const useCached = existing?.documentId === documentId && existing?.documentTypeId === documentTypeId
        if (useCached) {
            dispatch({ type: Actions.Documents.Download.Success, data: existing })
            return existing
        }

        dispatch({ type: Actions.Documents.Download.Loading, data: { documentTypeId, documentId, blob: null } })
        try {
            // @ts-ignore
            const token: MyToken = await dispatch(GetUserTokenAction())
            const config = { headers: { Authorization: `Bearer ${token.accessToken}` } }

            const metadataResponse = await SnexAxios.ApiGet<SnexDocument[]>(Urls.documents.getOne(documentTypeId, documentId), config).run()
            const metadata = metadataResponse

            // Note: Axios was messing this up, which is why I'm using fetch instead. Also one of the reasons we can't use ReduxApiGet
            const response = await fetch(Urls.documents.download(documentTypeId, [documentId]), config)
            const blob = await response.blob()
            const payload = { documentTypeId, documentId, blob, metadata }
            dispatch({ type: Actions.Documents.Download.Success, data: payload })
            return payload
        } catch (e) {
            dispatch({ type: Actions.Documents.Download.Failure, error: e, errorDisplay: 'toast' })
            dispatch({ type: Actions.Errors.ReportApiError, error: e, errorDisplay: 'toast' })
            return { ...e, failed: true }
        }
    }
}

export const GetPaperlessPrefsAction = () => ReduxApiGet(Urls.documents.getPaperlessPrefs(), Actions.Documents.GetPaperlessPrefs)
    .withMutex().run()

export const UpdatePaperlessPrefsAction = (documentType: string, accountNumber: string | 'all', election: PaperlessElectionType) =>
    ReduxApiPut(Urls.documents.updatePaperlessPrefs(documentType, accountNumber, election), Actions.Documents.UpdatePaperlessPrefs)
        .withSubject({ documentType, accountNumber, election })
        .withLoading()
        .run()

export const GoCompletelyPaperlessAction = (election: PaperlessElectionType) =>
    ReduxApiPut(Urls.documents.completelyPaperless(election), Actions.Documents.GoCompletelyPaperless)
        .withSubject({ election })
        .withLoading()
        .run()
