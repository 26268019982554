import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import {
    DeleteAllMessagesAction,
    DeleteMessagesAction,
    GenerateMessagesAction,
    GeneratePushMessageAction,
    GetAllOpenOrdersAction,
    GetMessageAction,
    GetSingleOrderV3Action,
    GetSystemMessageDeliveryControlsAction,
    MarkAsReadAction,
    OrderSearchFiltersV3,
    SearchMessagesAction,
    SearchOrdersV3Action
} from 'phoenix/redux/actions';
import { OrderIdentifierType } from 'phoenix/redux/models';
import { PaginationOffsets } from 'phoenix/redux/models/Paging/Page';
import { useMessagesStore } from 'phoenix/stores/MessagesStore';
import { useOrdersStore } from 'phoenix/stores/OrdersStore';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const useOrdersFunctions = () => {
    const {
        searchResults,
        searchResultsLoading,
        searchResultsPristine,
        openOrders: zusOpenOrders,
        openOrdersLoading: zusOpenOrdersLoading,
        openOrdersPristine: zusOpenOrdersPristine,
        nextOffsets: zusNextOffsets,
        noMoreResults: zusNoMoreResults,
        searchOrders: zusSearchOrders,
        getSingleOrder: zusGetSingleOrder,
        getAllOpenOrders: zusGetAllOpenOrders
    } = useOrdersStore();
    const dispatch = useDispatch();
    const useZustand = useFeatureFlag(FeatureFlags.Web.OrdersStore);
    const reduxSearchResults = useSnexStore((s) => s.orders.searchResults);
    const reduxOpenOrders = useSnexStore((s) => s.orders.openOrders);
    const reduxNextOffsets = useSnexStore((s) => s.orders.nextOffsets);
    const reduxNoMoreResults = useSnexStore((s) => s.orders.noMoreResults);

    const [orders, ordersLoading, ordersPristine] = useMemo(() => {
        if (useZustand) {
            return [searchResults, searchResultsLoading, searchResultsPristine];
        } else {
            return [reduxSearchResults.data, reduxSearchResults.loading, reduxSearchResults.pristine];
        }
    }, [reduxSearchResults.data, reduxSearchResults.loading, reduxSearchResults.pristine, searchResults, searchResultsLoading, searchResultsPristine, useZustand]);

    const [openOrders, openOrdersLoading, openOrdersPristine] = useMemo(() => {
        if (useZustand) {
            return [zusOpenOrders, zusOpenOrdersLoading, zusOpenOrdersPristine];
        } else {
            return [reduxOpenOrders.data, reduxOpenOrders.loading, reduxOpenOrders.pristine];
        }
    }, [reduxOpenOrders.data, reduxOpenOrders.loading, reduxOpenOrders.pristine, useZustand, zusOpenOrders, zusOpenOrdersLoading, zusOpenOrdersPristine]);

    const nextOffsets = useMemo(() => (useZustand ? zusNextOffsets : reduxNextOffsets.data), [reduxNextOffsets.data, useZustand, zusNextOffsets]);

    const noMoreResults = useMemo(() => (useZustand ? zusNoMoreResults : reduxNoMoreResults), [reduxNoMoreResults, useZustand, zusNoMoreResults]);

    const searchOrders = useCallback(
        async (filters: OrderSearchFiltersV3, append?: boolean, offsets?: PaginationOffsets) => {
            if (useZustand) {
                return await zusSearchOrders(filters, append, offsets);
            } else {
                return dispatch(SearchOrdersV3Action(filters, append, offsets));
            }
        },
        [dispatch, useZustand, zusSearchOrders]
    );

    const getAllOpenOrders = useCallback(
        async (options?: { page?: number; size?: number }) => {
            if (useZustand) {
                return await zusGetAllOpenOrders(options);
            } else {
                return () => dispatch(GetAllOpenOrdersAction(options));
            }
        },
        [dispatch, useZustand, zusGetAllOpenOrders]
    );

    const getSingleOrder = useCallback(
        async (orderIdentifier: string, orderIdentifierType: OrderIdentifierType, useStored?: boolean) => {
            if (useZustand) {
                return await zusGetSingleOrder(orderIdentifier, orderIdentifierType, useStored);
            } else {
                return () => dispatch(GetSingleOrderV3Action(orderIdentifier, orderIdentifierType, useStored));
            }
        },
        [dispatch, useZustand, zusGetSingleOrder]
    );

    return {
        orders,
        ordersLoading,
        ordersPristine,
        openOrders,
        openOrdersLoading,
        openOrdersPristine,
        nextOffsets,
        noMoreResults,
        searchOrders,
        getAllOpenOrders,
        getSingleOrder
    };
};

export const useMessagesFunctions = (messageId?: string) => {
    const {
        messages: zusMessages,
        messagesLoading: zusMessagesLoading,
        messageLoading: zusMessageLoading,
        latestMessage: zusLatestMessage,
        unread,
        systemControls: zusSystemControls,
        loadMessages: zusLoadMessages,
        getMessage: zusGetMessage,
        generateMessage: zusGenerateMessage,
        markAsRead: zusMarkAsRead,
        deleteAllMessages: zusDeleteAllMessages,
        deleteMessages: zusDeleteMessages,
        getSystemMessageControls: zusGetSystemMessageControls
    } = useMessagesStore();
    const dispatch = useDispatch();
    const useZustand = useFeatureFlag(FeatureFlags.Web.ZustandLiveStreaming);
    const reduxMessages = useSnexStore((s) => s.messages.all);
    const reduxMessage = useSnexStore((s) => s.messages.byId[messageId || '']);
    const reduxLatestMessage = useSnexStore((s) => s.messages.latest);
    const reduxUnreadCount = useSnexStore((s) => s.messages.unreadCount);
    const reduxSystemControls = useSnexStore((s) => s.messages.systemControls);

    const [messages, messagesLoading] = useMemo(() => {
        if (useZustand) {
            return [zusMessages, zusMessagesLoading];
        } else {
            return [reduxMessages.data, reduxMessages.loading];
        }
    }, [reduxMessages.data, reduxMessages.loading, useZustand, zusMessages, zusMessagesLoading]);

    const unreadCount = useMemo(() => {
        if (useZustand) {
            return unread;
        } else {
            return reduxUnreadCount.data;
        }
    }, [unread, reduxUnreadCount.data, useZustand]);

    const latestMessage = useMemo(() => (useZustand ? zusLatestMessage : reduxLatestMessage), [zusLatestMessage, reduxLatestMessage, useZustand]);

    const systemControls = useMemo(() => (useZustand ? zusSystemControls : reduxSystemControls.data), [zusSystemControls, reduxSystemControls.data, useZustand]);

    const [messageById, messageByIdLoading] = useMemo(() => {
        if (!messageId) return [undefined, false];
        if (useZustand) {
            return [zusMessages.find((m) => m.id === messageId), zusMessageLoading[messageId || '']];
        } else {
            return [reduxMessage?.data, reduxMessage?.loading];
        }
    }, [messageId, useZustand, zusMessages, zusMessageLoading, reduxMessage?.data, reduxMessage?.loading]);

    const loadMessages = useCallback(
        async (page?: number, pageSize?: number) => {
            if (useZustand) {
                return await zusLoadMessages(page, pageSize);
            } else {
                return () => dispatch(SearchMessagesAction({ page, size: pageSize }));
            }
        },
        [dispatch, useZustand, zusLoadMessages]
    );

    const getMessage = useCallback(
        async (messageId: string) => {
            if (useZustand) {
                return await zusGetMessage(messageId);
            } else {
                return () => dispatch(GetMessageAction(messageId));
            }
        },
        [dispatch, useZustand, zusGetMessage]
    );

    const generateMessage = useCallback(
        async (reset = false) => {
            if (useZustand) {
                return await zusGenerateMessage(reset);
            } else {
                return () => {
                    dispatch(GenerateMessagesAction());
                    if (reset) dispatch(SearchMessagesAction({ page: 1, size: 10 }));
                };
            }
        },
        [dispatch, useZustand, zusGenerateMessage]
    );

    const generatePushMessage = useCallback(
        async (reset = false) => {
            if (useZustand) {
                return await zusGenerateMessage(reset);
            } else {
                return () => {
                    dispatch(GenerateMessagesAction());
                    if (reset) dispatch(SearchMessagesAction({ page: 1, size: 10 }));
                };
            }
        },
        [dispatch, useZustand, zusGenerateMessage]
    );

    const markAsRead = useCallback(
        async (messageId: string) => {
            if (useZustand) {
                return await zusMarkAsRead(messageId);
            } else {
                return () => dispatch(MarkAsReadAction(messageId));
            }
        },
        [dispatch, useZustand, zusMarkAsRead]
    );

    const deleteMessages = useCallback(
        async (messageIds: string[]) => {
            if (useZustand) {
                return await zusDeleteMessages(messageIds);
            } else {
                return () => dispatch(DeleteMessagesAction(messageIds));
            }
        },
        [dispatch, useZustand, zusDeleteMessages]
    );

    const deleteAllMessages = useCallback(async () => {
        if (useZustand) {
            return await zusDeleteAllMessages();
        } else {
            return () => dispatch(DeleteAllMessagesAction());
        }
    }, [dispatch, useZustand, zusDeleteAllMessages]);

    const getSystemMessageControls = useCallback(async () => {
        if (useZustand) {
            return await zusGetSystemMessageControls();
        } else {
            return () => dispatch(GetSystemMessageDeliveryControlsAction());
        }
    }, [dispatch, useZustand, zusGetSystemMessageControls]);

    return {
        messages,
        messagesLoading,
        messageById,
        messageByIdLoading,
        unreadCount,
        latestMessage,
        systemControls,
        loadMessages,
        getMessage,
        generateMessage,
        generatePushMessage,
        markAsRead,
        deleteMessages,
        deleteAllMessages,
        getSystemMessageControls
    };
};
