import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { SetShowPremiumUpgradeModal } from 'hooks/UseShowPremiumUpgradeModal';
import { PremiumProducts, ProductCode, TierLevel } from 'phoenix/constants/PremiumProducts';
import { useTier } from 'phoenix/hooks/UseTier';
import { GetAllFeatureFlagsAction } from 'phoenix/redux/actions/FeatureFlagActions';
import { GetMySubscriptionInfoAction } from 'phoenix/redux/actions/SubscriptionActions';
import { GetUseLimitedAccess } from 'phoenix/util';
import { ResolveEnvironment } from 'phoenix/constants';

interface PremiumContentClickableProps {
    children: any;
    onClick: () => void;
    hide?: 'never' | 'if-paid-and-disallowed';
    products?: ProductCode[];
    tiers?: TierLevel[];
}

export const PremiumContentClickable = TelemetryProvider((props: PremiumContentClickableProps) => {
    const dispatch = useDispatch();
    const { hide, products, tiers } = props;
    const { tierPrefix, product, tierLoading } = useTier();

    const allowed = useMemo(() => {
        const productAllowed = !products || products.some((p) => p === product);
        const tierAllowed = (tiers || ['trial', 'preferred']).some((t) => t === tierPrefix);
        return productAllowed && tierAllowed;
    }, [tierPrefix, product, products, tiers]);

    const show = useMemo(() => {
        if (GetUseLimitedAccess() || ResolveEnvironment() === 'beta') return false;
        if (tierLoading) return true;
        if (tierPrefix === 'free' || hide === 'never') return true;
        else if ((hide || 'if-paid-and-disallowed') === 'if-paid-and-disallowed') return allowed;
        else return true;
    }, [tierLoading, tierPrefix, hide, allowed]);

    useEffect(() => {
        dispatch(GetAllFeatureFlagsAction());
        dispatch(GetMySubscriptionInfoAction());
    }, []);

    const handleClick = useCallback(() => {
        if (allowed) props.onClick();
        else SetShowPremiumUpgradeModal(PremiumProducts.premium.code);
    }, [allowed, props.onClick]);

    // Not sure if this was meant to be hidden, was unexpected in prod.
    // if (!show) return null;

    return show ? (
        <div style={{ cursor: 'pointer', opacity: tierLoading ? 0.4 : 1 }} onClick={handleClick} onKeyDown={(e) => e.key === 'Enter' && handleClick()}>
            {props.children}
        </div>
    ) : null;
}, TelemetryCategories.premium);
