import { Urls } from '../../constants'
import { NoActionThunk } from '../../constants/NoAction'
import { ReduxAction } from '../../models'
import { SymbolUrlChunk, UniqueBy } from '../../util'
import { Actions } from './Constants'
import { ReduxApiGet, ReduxApiPut } from './Helpers'

export const GetSecurityLogoAction = (symbol: string) => (symbol?.indexOf(' ') || 0) !== -1
    ? NoActionThunk
    : ReduxApiGet(symbol, Actions.Securities.GetLogo)
        .withBatching('logos', symbols => {
            return SymbolUrlChunk(symbols).map(s => Urls.securities.core.getLogoBatch(UniqueBy(symbols, x => x)))
        }, 500)
        .useStored(s => s.logos.bySymbol[symbol]?.data)
        .withSubject(symbol)
        .run()

export const SetReduxSecurityLogoAction = (symbol: string, logo: string) => <ReduxAction>({
    type: Actions.Securities.GetLogo.Success,
    subject: [symbol],
    data: [{ symbol, logo }],
    passthrough: { subject: [symbol] }
})

export const SetSecurityLogoAction = (symbol: string, logoUrl: string) => ReduxApiPut(Urls.securities.core.setLogo(symbol), Actions.Securities.SetLogo, { logoUrl })
    .showToasts().withLoading().withAsyncOperationIndicator()
    .withSubject({ symbol, logoUrl }).run()
