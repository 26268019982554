// @ts-strict-ignore
import { CulledCollapse } from 'components/CulledCollapse/CulledCollapse';
import { Flex } from 'components/Flex';
import { ListHeader } from 'components/ListHeader';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import React, { useEffect, useMemo, useRef } from 'react';
import { InFrameVideoPlayer } from '../inFrameVideoPlayer/inFrameVideoPlayer';
import { useOneProNetworkStore } from 'phoenix/stores/OneProNetworkStore/OneProNetworkStore';
import { SnexButton } from 'components/SnexButton';
import { useText } from 'phoenix/hooks/UseText';
import { Routes } from 'util/Routes';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { OneProNetworkLogo } from 'components/OneProNetwork/assets/oneProNetworkLogo';
import { Typography } from '@mui/material';
import { GetPostCard, SkeletonPostCard } from '../postsSection/postsSection';
import { useColors } from 'hooks/UseColors';
import { MediaPost } from '../deskAlerts/deskAlerts';
import { QuoteCards } from '../QuoteCard/quoteCard';
import { useShowOneProNetwork } from 'components/OneProNetwork/useShowOnePro';

export const OneProNetworkSidebar = ({ showFull }: { showFull?: boolean }): JSX.Element => {
    const showOneProNetwork = useShowOneProNetwork()
    const [isCollapsedFull, setIsCollapsedFull] = useLocalStorage(`collapsed-oneProNetwork`, false);
    const { data, highLevel, mainScreen } = useOneProNetworkStore();

    useEffect(() => {
        if (highLevel.pristine && !highLevel.loading) highLevel.load(true);
    }, [highLevel]);
    useEffect(() => {
        if (showFull && mainScreen.pristine && !mainScreen.loading) mainScreen.load(true);
    }, [mainScreen, showFull]);

    const featuredPost = useMemo(() => data?.featuredPosts.filter((p) => p.media.type === 'video')[0], [data?.featuredPosts]);
    const text = useText((t) => t.oneProNetwork);

    /* using set to get distinct values only */
    const posts = useMemo(() => data?.featuredPosts?.slice(1, 2), [data?.featuredPosts]);

    const colors = useColors();

    const wrapperRef = useRef<HTMLDivElement>(null);

    if (!showOneProNetwork) return null;

    return (
        <>
            <ListHeader title={<OneProNetworkLogo height={16} />} collapsable isCollapsed={isCollapsedFull} onCollapseExpand={setIsCollapsedFull} />
            <CulledCollapse eventTag={`One Pro Network Summary`} in={!isCollapsedFull}>
                <Flex className='one-pro-network' ref={wrapperRef} column fullWidth center style={{ padding: '16px' }}>
                    <InFrameVideoPlayer containerRef={wrapperRef} loading={highLevel?.loading} post={featuredPost} />
                    <div style={{ marginTop: 6, width: '100%', overflow: 'hidden' }}>
                        <QuoteCards id={'sidebarTicker'} symbols={data?.featuredPosts[0]?.relatedSecurities.map((s) => s.qsi)} scroll={data?.featuredPosts[0]?.relatedSecurities?.length > 2}/>
                    </div>
                    {showFull && (
                        <>
                            <Flex column fullWidth>
                                <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 24, marginBottom: 12 }}>Latest Desk Notes</Typography>
                                <MediaPost type={'desk'} post={data?.deskAlerts[0]} />
                            </Flex>
                            <Flex row fullWidth>
                                <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 24, marginBottom: 12 }}>{text.latestPosts}</Typography>
                            </Flex>
                            {posts.map((p, pidx) => (
                                <div key={`post-${pidx}`} style={{ marginBottom: 12 }}>
                                    {mainScreen.loading ? <SkeletonPostCard maxWidth={'418px'} /> : <GetPostCard maxWidth={'418px'} {...{ post: p, colors }} />}
                                </div>
                            ))}
                        </>
                    )}
                    <SnexButton flavor={'submit'} style={{ padding: 8, marginTop: 8, width: '100%' }} route={Routes.oneProNetwork()}>
                        <span style={{ fontWeight: 400 }}>{text.discoverMore} </span>
                        <span style={{ fontWeight: 800 }}>{text.insights} </span>
                        <span style={{ fontWeight: 400 }}>{'>'}</span>
                    </SnexButton>
                </Flex>
            </CulledCollapse>
        </>
    );
};
