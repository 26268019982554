// @ts-strict-ignore
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { AssetTradeability } from 'phoenix/constants/AssetTradeability';
import { AssetClass, StandardPriceToQuantityFormula, StandardQuantityToPriceFormula } from 'phoenix/models/AssetClasses/AssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { AccountSummary, MyInfo, OptionSymbol } from 'phoenix/redux/models';
import { AggregateBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { ChartRanges } from 'phoenix/constants';
import { Ts } from 'phoenix/assets/lang/T';
import { OrderTypes } from 'phoenix/constants/Trade';
import { FormatNumber } from 'phoenix/util';
import { formatOptionExpiration, formatOptionSymbolName } from 'phoenix/models/AssetClasses/utils';

export const FuturesOptionsAssetClass: AssetClass = {
    // Typing
    type: 'futures-option',
    family: 'futures',
    derivative: 'option',
    derivatives: [],
    algoliaTypes: ['unknown'],

    // Trading + Tradeability
    tradeability: AssetTradeability?.['futures-option'],
    accountCanTradeThis: FuturesAssetClass?.accountCanTradeThis,
    userAllowedToTradeThis: FuturesAssetClass?.userAllowedToTradeThis,
    /** @deprecated Please use defaultTradePrice */
    defaultLimitPriceToLatestPrice: true,
    defaultOrderQuantity: 1,
    defaultOrderType: () => OrderTypes.limit,
    defaultTradePrice: {
        market: 'bid-ask',
        limit: 'bid-ask',
        stop: 'bid-ask',
        stoplimit: 'bid-ask'
    },
    optionsUseToOpenToClose: false,
    negativePriceAllowed: false,

    // Features
    hasDeepAnalysis: false,
    canCreateAlerts: false,
    hasOptions: false,
    hasMultiLeg: false,
    canWatchlist: true,
    hideTradeImpact: true,
    tradePriceShortcutButtonsVariant: 'tick',
    hasDerivatives: (() => FuturesOptionsAssetClass?.derivatives.length > 1),

    // Charting
    neverShowChartPulse: false,
    hasAdvancedChart: true,
    advancedChartConfiguration: {
        supported_resolutions: ['1', '10', '30', '1D', '1W']
    },
    chartVariants: ['line', 'candles'],
    chartRanges: () => {
        const text = Ts((s) => s.misc.ranges);

        return [
            { label: text.oneDay, value: ChartRanges.oneDay },
            { label: text.fiveDay, value: ChartRanges.fiveDays },
            { label: text.oneMonth, value: ChartRanges.oneMonth },
            { label: text.threeMonths, value: ChartRanges.threeMonths },
            { label: text.oneYear, value: ChartRanges.oneYear },
            { label: text.fiveYear, value: ChartRanges.fiveYears }
        ];
    },
    accountChartRanges: FuturesAssetClass?.accountChartRanges,
    securityChartRanges: ['1d', '5d', '1m', '3m', '1y', '5y'],

    formatOrderQuantity: (o) => {
        const { showDecimals = true, decimalPlaces = 2 } = o?.quantityQualifier === 'Shares' ? { showDecimals: true, decimalPlaces: 8 } : {};
        let qty = FormatNumber.removeDecimalsIfZero(FormatNumber.toCommas(o.orderQuantity, showDecimals, decimalPlaces))

        if (o.quantityQualifier === 'EvenDollar') {
            qty = `${FormatNumber.toMoneyOpt2(o?.cost)}`;
        }

        return qty
    },

    // Formatting + Codec
    getPriceFormatInfo: FuturesAssetClass?.getPriceFormatInfo,
    formatPrice: FuturesAssetClass?.formatPrice,
    formatQuantity: FuturesAssetClass?.formatQuantity,
    unit: 'contract',
    idMatches: (id: string) => OptionSymbol.IsOptionSymbol(id) && FuturesSymbol.IsFuturesSymbol(id),
    getSymbolName: (s, lang, variant, meta) => {
        const optSym = new OptionSymbol(s)
        const strike = meta ? FuturesOptionsAssetClass?.formatPrice(optSym.strike, meta) : FormatNumber.toMoneyOpt2(optSym.strike)
        return formatOptionSymbolName(optSym, lang, strike, variant);
    },
    getSymbolNameLines: (s, lang, meta) => {
        const first = FuturesOptionsAssetClass?.getSymbolName(s, lang, 'short', meta)
        const exp = formatOptionExpiration(s);
        return [ first, exp ]
    },
    getBaseSymbol: s => new FuturesSymbol(new OptionSymbol(s)?.underlyingSymbolWithPrefix).baseContract,
    getConcreteSymbol: s => new OptionSymbol(s)?.underlyingSymbolWithPrefix,

    // Colors
   /** @deprecated Please do not use this value. It does not use theming Use useAssetClassColors hook */
    primaryColor: FuturesAssetClass?.primaryColor,
    positiveChangeColor: 'rgb(2,179,52)',
    negativeChangeColor: 'rgb(178,41,46)',
    showColorHalo: true,

    // Pricing
    getQuantityPrice: StandardQuantityToPriceFormula,
    getPriceForQuantity: StandardQuantityToPriceFormula,
    getQuantityForPrice: StandardPriceToQuantityFormula,
    getTodaysEarningsLabel: (l) => l.positionsScreen.todaysGainLoss,
    getTotalEarningsLabel: (l) => l.positionsScreen.openPnL,

    // Account Data (N/A)
    getBuyingPowerFigures: (power: AggregateBuyingPower, lang: Snex1LanguagePack) => [],
    getAccountSummaryFigures: (summary: AccountSummary, lang: Snex1LanguagePack) => [],
    getNumberOfStrikes: (user: MyInfo) => user.strikes,

    // Documents
    documentDeliveryOptions: 'electronic-only',

    showShortPositionIcon: false,
    showStopPriceShortcutButtons: true
};
