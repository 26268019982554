// @ts-strict-ignore
import { Typography, Skeleton } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { Routes } from 'util/Routes';
import { CircularLogo, Flex } from 'components';
import { GetClientAccountsAction, GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { ChangeColor, FormatDaysFromNow, FormatNumber } from 'phoenix/util';
import { useText } from 'phoenix/hooks/UseText';
import { DeeperViewTitle } from 'components/ComponentWithAnimatedBackArrow';
import { useAppWindowSizeVariable } from 'hooks/UseWindowSize';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { OptionSymbol } from 'phoenix/redux/models/Options/OptionSymbol';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { T } from 'phoenix/assets/lang/T';
import { LastQuoteUpdateDisplay } from 'components/LastQuoteUpdateDisplay/LastQuoteUpdateDisplay';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { XS } from 'phoenix/xstream/XS';
import { SecurityValues } from 'components/SecurityValues/SecurityValues';
import { useSecurityChartStore } from 'store/SecurityChart';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import style from './style.module.scss';
import { useShowAdditionalStats } from 'components/SecurityValues/SecurityValuesViewModel';
import { ExpiresLabel } from 'components/ExpiresLabel/ExpiresLabel';
import { AddToListIcon } from 'components/SecurityChartSection/AddToListIcon';

export const OptionContractHeaderSection = ({ symbol, onAddToListClick }: { symbol: string; onAddToListClick: () => void }): ReactElement => {
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();
    const [subscription, setSubscription] = useState<string | null>(null);
    const [optSubscription, setOptSubscription] = useState<string | null>(null);
    const text = useText((s) => s.optionContractScreen);
    const optSym = new OptionSymbol(symbol);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const underlyingSymbol = isFuture ? optSym.underlyingSymbolWithPrefix : optSym?.underlyingSymbol;
    const latestUnderlyingQuote = XS.Quotes.use(underlyingSymbol);
    const underlying = isFuture ? optSym.underlyingSymbolWithPrefix : optSym.underlyingSymbol;
    const symbolName = useText((t) => t.general.symbolName(symbol));
    const { changeValue, changePercentValue } = useSecurityChartStore((s) => ({ changeValue: s.changeValue, changePercentValue: s.changePercentValue }));
    const accentColor = ChangeColor(changePercentValue || changeValue);
    const latestPrice = latestUnderlyingQuote?.price;
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const { strikePrice = optSym.strike } = meta || {};
    const metaUnderlying = useSnexStore((s) => s.securities.bySymbol[underlyingSymbol]?.metadata?.data);
    const { formatPrice, getPriceFormatInfo } = useAssetClass(symbol);
    const { decimalPlaces } = getPriceFormatInfo(meta);
    const { formatPrice: formatPriceUnderlying } = useAssetClass(underlyingSymbol);
    const formatValue = (value: number) => formatPrice(value, meta);
    const formatUnderlying = (value: number) => formatPriceUnderlying(value, metaUnderlying);
    const showAdditionalStats = useShowAdditionalStats();

    const titleSectionSizes = useAppWindowSizeVariable({
        tablet: { logo: 30, title: 24 },
        sm: { logo: 30, title: 24 },
        def: { logo: 55, title: 32 }
    });

    const valueWrapperMargin = useAppWindowSizeVariable({
        tablet: { paddingTop: 10, paddingLeft: 40 },
        sm: { paddingTop: 10, paddingLeft: 40 },
        def: { paddingTop: 0, paddingLeft: 15 }
    });

    const hideQuoteBadgeWhileScrubbing = useAppWindowSizeVariable({
        sm: true,
        md: true,
        lg: false,
        tablet: true
    });

    useEffect(() => {
        dispatch(GetClientAccountsAction());
    }, [dispatch]);

    const hasFuturesAccount = !!useSelector((s: GlobalState) => s.accounts.all)?.data?.find((a) => FuturesSymbol.IsFuturesSymbol(a.accountNumber));

    const subscribe = async () => {
        if (!subscription) {
            const sub: string = underlyingSymbol ? await xdispatch(XS.Quotes.start(underlyingSymbol, LiveDataNamespaces.OptionContractScreen)) : null;
            if (sub) setSubscription(sub);
        }

        if (!optSubscription) {
            const sub: string = underlyingSymbol ? await xdispatch(XS.OptionQuotes.start([symbol], LiveDataNamespaces.OptionContractScreen)) : null;
            if (sub) setOptSubscription(sub);
        }
    };

    const unsubscribe = () => {
        if (subscription) {
            XS.stop(subscription);
            XS.stop(optSubscription);
            setSubscription(null);
            setOptSubscription(null);
        }
    };

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(underlyingSymbol));
        if (!subscription) subscribe();
        return unsubscribe;
    }, [symbol, hasFuturesAccount]);

    const hideQuoteBadge = hideQuoteBadgeWhileScrubbing && showAdditionalStats;

    return (
        <Flex column align='flex-start' style={{ '--accentColor': accentColor } as React.CSSProperties}>
            <Flex>
                <DeeperViewTitle route={Routes.optionChain(underlying)} withBackArrow={!meta?.isAdjusted}>
                    <CircularLogo altText={symbolName} size={titleSectionSizes?.logo} symbol={underlying} />
                    <Typography style={{ fontSize: titleSectionSizes?.title, marginLeft: 15 }} variant='h3'>
                        {QualifiedId.Format(optSym.underlyingSymbol)} {FormatNumber.toMoneyOpt2(strikePrice, { decimalPlaces, hideCurrencySymbol: isFuture })}{' '}
                        {T((t) => t.general.options.putCallWord(optSym.putCall))}
                    </Typography>
                    <Flex row justify='space-between' align='flex-start' style={{ gap: 16 }}>
                        <Flex column style={{ marginLeft: 10, paddingLeft: 10, borderLeft: 'solid 1px #e3e3e3' }}>
                            <Typography variant='h6'>{text.expiresOnDate(optSym.expDate)}</Typography>
                            <Typography variant='body1'>{FormatDaysFromNow(optSym.expDate)}</Typography>
                        </Flex>
                        <ExpiresLabel expiryDate={optSym.expDate} style={{ fontSize: 15 }} />
                    </Flex>
                </DeeperViewTitle>
                <span style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                    <AddToListIcon securityId={symbol} onClick={onAddToListClick} />
                </span>
            </Flex>
            <Flex align='center' justify='space-between' style={{ boxSizing: 'border-box', width: '100%', ...valueWrapperMargin }}>
                <Flex column align='flex-start' justify='center'>
                    <SecurityValues symbol={symbol} formatValue={formatValue} />
                </Flex>
                {!hideQuoteBadge && (
                    <QuoteBadge
                        label={text.symbolLastQuote(QualifiedId.Format(optSym?.underlyingSymbol))}
                        loading={latestUnderlyingQuote?.loading}
                        value={formatUnderlying(latestPrice)}
                    />
                )}
            </Flex>
            <LastQuoteUpdateDisplay debounceMs={50 * 1000} prefix='Price as of' qsi={symbol} style={{ marginTop: 10, color: 'rgba(150,150,150,0.8)', fontSize: 11 }} />
        </Flex>
    );
};

export const QuoteBadge = ({ label, loading, value }: { label: string; loading: boolean; value: string }): ReactElement =>
    loading ? (
        <Skeleton animation='wave' width={100} />
    ) : (
        <Flex align='center' className={style.streamingVolumeWrapper} justify='flex-end'>
            <Typography style={{ marginRight: 7, fontWeight: 500 }} variant='h6'>
                {label}
            </Typography>
            <Typography variant='h6'>{value}</Typography>
        </Flex>
    );
