// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../..';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { OptionSymbol, WatchlistSecurity } from 'phoenix/redux/models';
import { CaptionLabel, DescriptionLabel, SymbolLabel } from '../Shared/Labels';
import { SecurityCellChart } from '../Shared/SecurityCellChart';
import { SecurityCellLogo } from '../Shared/SecurityCellLogo';
import { SecurityNameSection } from '../Shared/SecurityNameSection';
import style from '../style.module.scss';
import { SecurityValue } from './SecurityValue';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { useInView } from 'react-intersection-observer';
import { useText } from 'phoenix/hooks/UseText';
import { FormatNumber } from 'phoenix/util';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { T } from 'phoenix/assets/lang/T';
import { ExpiresLabel } from 'components/ExpiresLabel/ExpiresLabel';

export interface OptionWatchlistCellProps {
    getCached?: boolean;
    onDelete?: (watchlistId?: any) => void;
    security?: WatchlistSecurity;
    streamingNamespace?: string;
    symbol: string;
    visible?: boolean;
}

export const OptionWatchlistCell = React.memo((props: OptionWatchlistCellProps) => {
    const text = useText((s) => s.portfolioScreen.sidebar);
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();

    const { streamingNamespace, symbol } = props;

    const { ref, inView } = useInView({ trackVisibility: true, threshold: 0, delay: 400 });
    const [subscription, setSubscription] = useState<string>(null);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const optSym = new OptionSymbol(symbol);
    const fsym = isFuture && new FuturesSymbol(optSym.underlyingSymbol);

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
    }, [symbol]);

    useEffect(() => {
        const run = async () => {
            if (inView && !subscription) {
                const ticket: string = await xdispatch(XS.Quotes.start(symbol, streamingNamespace));
                setSubscription(ticket);
            } else if (!inView) {
                XS.stop(subscription);
                setSubscription(null);
            } else {
                await dispatch(GetSecurityQuoteAction(symbol));

                if (!subscription) {
                    const ticket: string = await xdispatch(XS.Quotes.start(symbol, streamingNamespace));
                    setSubscription(ticket);
                }
            }
        };

        run();
        return () => {
            XS.stop(subscription);
        };
    }, [inView]);

    const [latestChartPercChange, setLatestChartPercChange] = useState(null);
    const [latestChartPrice, setLatestChartPrice] = useState(null);

    const onCrosshairUpdate = useCallback((value?: any, isScrubbing?: boolean) => {
        setLatestChartPercChange(value[symbol]?.chartPercChange);
        setLatestChartPrice(typeof value[symbol] === 'number' ? value[symbol] : value[symbol]?.value);
    }, []);

    const percentChange = props.security?.percentChange || latestChartPercChange;
    
    return (
        <>
            <SecurityCellLogo symbol={isFuture ? fsym.baseContract : optSym.underlyingSymbol} />
            <Flex ref={ref} column className={style.cellContent}>
                <Flex className={style.mainRow}>
                    <SecurityNameSection>
                        <SymbolLabel>{`${optSym.underlyingSymbol} - ${T((t) => t.general.options.putCallWord(optSym.putCall))}`}</SymbolLabel>
                        <CaptionLabel>
                            {text.strike}: {FormatNumber.toMoney(optSym.strike, null, isFuture ? 'never' : 'auto')}
                        </CaptionLabel>
                        <DescriptionLabel>{text.expires(optSym.expDate)}</DescriptionLabel>
                    </SecurityNameSection>
                    <SecurityCellChart withQuoteOverride symbol={symbol} onCrosshairUpdate={onCrosshairUpdate} />
                    <Flex column>
                        <SecurityValue withQuoteOverride chartPercChange={percentChange} chartPrice={latestChartPrice} symbol={symbol} />
                        <ExpiresLabel expiryDate={optSym.expDate} />
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
});
