import { CryptoSymbol } from 'phoenix/redux/models/Crypto/CryptoSymbol';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { useMemo, useState } from 'react';
import { OptionSymbol, TradeableSecurityType } from '../redux/models';
import { GetSecurityType, GetSecurityTypeFromMetadata } from '../util';
import { useAsyncEffect } from './UseAsyncEffect';
import { useSnexStore } from './UseSnexStore';

/** @deprecated Please use `useSecurityTypeFromStore`; this old implementation makes an API call */
export const useSecurityType = (qsi: string): TradeableSecurityType | 'loading' => {
    const [type, setType] = useState<TradeableSecurityType | 'loading'>('loading');
    useAsyncEffect(async () => {
        setType(await GetSecurityType(qsi));
    }, [qsi]);
    return type;
};

export const useSecurityTypeFromStore = (qsi: string): TradeableSecurityType | 'loading' => {
    const metadata = useSnexStore((s) => s.securities.bySymbol[qsi]?.metadata);
    const loading = !metadata || metadata.loading || metadata.pristine;
    return useMemo(() => {
        if (!qsi) return 'loading';
        if (FuturesSymbol.IsFuturesSymbol(qsi) && OptionSymbol.IsOptionSymbol(qsi)) return 'futures-option'
        if (FuturesSymbol.IsTimeSpreadSymbol(qsi)) return 'futures-time-spread';
        if (FuturesSymbol.IsFuturesSymbol(qsi)) return 'future';
        if (OptionSymbol.IsOptionSymbol(qsi)) return 'option';
        if (CryptoSymbol.IsCryptoSymbol(qsi)) return 'crypto';
        return loading ? 'loading' : GetSecurityTypeFromMetadata(metadata?.data);
    }, [qsi, loading, metadata?.data]);
};
