// @ts-strict-ignore
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { Flex } from 'components/Flex';
import { CustomNumberInputProps } from 'components/TradeTicket/Shared/TradeFormComponents';
import { floatMath, parseFractional, toFractional2 } from 'phoenix/util';
import React, { useCallback, useEffect, useState } from 'react';
import './index.module.css';

const DollarAdornment = (
    <InputAdornment className='custom-dollar-adornment' position='start' style={{ marginLeft: 10 }}>
        $
    </InputAdornment>
);

export const FractionalNumberInput = React.forwardRef((props: CustomNumberInputProps, ref: React.MutableRefObject<HTMLInputElement>) => {
    const { arrows = null, disabled, error, formatOptions, helperText, initialValue, label, onBlur, onValueChange, showDollarSign, styles } = props;

    const [isFormatted, setIsFormatted] = useState<boolean>(false);
    const { allowNegative, fractionalParts, fractionalGranularity, tickSize: step = 1 } = formatOptions || {};
    const initialDisplayValue = typeof initialValue === 'number' ? toFractional2(initialValue, step, fractionalParts, { granularity: fractionalGranularity }) : undefined;
    const [displayValue, setDisplayValue] = useState<string>(initialDisplayValue);
    // Use to determine whether to display initial values or stateful ones
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const effectiveDisplay = userChanged ? displayValue : initialDisplayValue;

    const updateValue = useCallback(
        (input: string) => {
            setUserChanged(true);
            setIsFormatted(false);
            setDisplayValue(input);

            const decimal = parseFractional(input, formatOptions);

            // Parsing and reformatting handles things like:
            // converting 1 33/32 to 2 1/32 (fraction with a numerator > denominator)
            // Removing non-numeric characters, etc
            const fractional = toFractional2(decimal, step, fractionalParts, { granularity: fractionalGranularity });

            // parse again since reformatting can fundamentally change the value
            const reparsed = parseFractional(fractional, { fractionalGranularity, fractionalParts, showDecimals: true });

            if (onValueChange) onValueChange(reparsed);
        },
        [formatOptions, fractionalGranularity, fractionalParts, onValueChange, step]
    );

    const handleKeyDown = (event: React.KeyboardEvent) => {
        switch (event?.key) {
            case 'ArrowUp':
                return handleIncrement();
            case 'ArrowDown':
                return handleDecrement();
        }
    };

    const handleFormatting = useCallback(
        (value: string) => {
            if (isFormatted) return;

            const decimal = parseFractional(value, { allowNegative, fractionalGranularity, fractionalParts, showDecimals: true });

            // Parsing and reformatting handles things like:
            // converting 1 33/32 to 2 1/32 (fraction with a numerator > denominator)
            // Removing non-numeric characters, etc
            const fractional = toFractional2(decimal, step, fractionalParts, { granularity: fractionalGranularity });

            // parse again since reformatting can fundamentally change the value
            const reparsed = parseFractional(fractional, { allowNegative, fractionalGranularity, fractionalParts, showDecimals: true });

            if (onValueChange && value !== effectiveDisplay) {
                onValueChange(reparsed);
            }

            // Prevent returning this default value into the input
            // TODO: Add defaultValue param to toFractional2
            const actual = fractional === '---.--' ? '' : fractional;

            setIsFormatted(true);
            setDisplayValue(actual);
        },
        [allowNegative, effectiveDisplay, fractionalGranularity, fractionalParts, isFormatted, onValueChange, step]
    );

    const handleIncrement = () => {
        const decimal = parseFractional(effectiveDisplay, { fractionalGranularity, fractionalParts });
        const newNumber = floatMath(decimal, step, (v, s) => v + s) || 0;

        if (onValueChange) onValueChange(newNumber || 0);
        updateValue(toFractional2(newNumber, step, fractionalParts, { granularity: fractionalGranularity }));
    };

    const handleDecrement = () => {
        const decimal = parseFractional(effectiveDisplay, { fractionalGranularity, fractionalParts });

        let newNumber = floatMath(decimal, step, (v, s) => v - s) || 0;

        if (!allowNegative && newNumber <= 0) newNumber = 0;

        if (onValueChange) onValueChange(newNumber || 0);
        updateValue(toFractional2(newNumber, step, fractionalParts, { granularity: fractionalGranularity }));
    };

    const className = [{ 'custom-number-input': true }, { arrows }, { disabled }]
        .filter((x) => Object.values(x)[0])
        .map((x) => Object.keys(x)[0])
        .join(' ');

    useEffect(() => {
        const timer = setTimeout(() => handleFormatting(effectiveDisplay), 1200);
        return () => clearTimeout(timer);
    }, [effectiveDisplay, handleFormatting]);

    return (
        <TextField
            ref={ref}
            fullWidth
            placeholder={toFractional2(0, step, fractionalParts, { granularity: fractionalGranularity })}
            variant='outlined'
            {...{
                className,
                disabled,
                error,
                InputProps: {
                    endAdornment: arrows && (
                        <Flex column justify='space-around'>
                            <button title='Increment' onClick={handleIncrement}>
                                <KeyboardArrowUp />
                            </button>
                            <button title='Decrement' onClick={handleDecrement}>
                                <KeyboardArrowDown />
                            </button>
                        </Flex>
                    ),
                    onChange: (e) => updateValue(e?.target?.value),
                    onKeyDown: (e) => handleKeyDown(e),
                    startAdornment: showDollarSign ? DollarAdornment : undefined
                },
                helperText,
                label,
                onBlur: (e) => props?.onBlur && onBlur(e.target.value),
                styles,
                value: effectiveDisplay
            }}
        />
    );
});
