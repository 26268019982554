import { FromEnglish, Ts } from 'phoenix/assets/lang/T';
import { OptionSymbol } from '../redux/models';
import { SecurityMetadata } from '../redux/models/Securities/SecurityMetadata';
import { FormatString } from '../util/FormatString';
import { QualifiedId } from '../util/QualifiedId';
import { IsOffshoreMutualFundByMetadata } from 'phoenix/util';

export type SecurityType =
    | 'Cash & Equivalents'
    | 'Cryptos'
    | 'Equities'
    | 'Fixed Income'
    | 'Futures'
    | 'Futures Options'
    | 'Mutual Funds'
    | 'Options'
    | 'Rights and Warrants';

// Define a type for the keys of SecurityTypes
type SecurityTypeKeys = 
    | 'mutualFunds'
    | 'equities'
    | 'options'
    | 'cash'
    | 'cs'
    | 'oef'
    | 'cef'
    | 'ad'
    | 'et'
    | 'ps'
    | 'rt'
    | 'struct'
    | 'ut'
    | 'wi'
    | 'wt'
    | ''
    | 'Futures'
    | 'FuturesOptions';

export const SecurityTypes: Record<SecurityTypeKeys, SecurityType | string> = {
    mutualFunds: 'Mutual Funds',
    equities: 'Equities',
    options: 'Options',
    cash: 'Cash & Equivalents',
    cs: 'Common Stock',
    oef: 'Mutual Fund',
    cef: 'Mutual Fund',
    ad: 'ADR',
    et: 'ETF',
    ps: 'Preferred Stock',
    rt: 'Right',
    struct: 'Structured Product',
    ut: 'Unit',
    wi: 'When Issued',
    wt: 'Warrant',
    '': 'Miscellaneous',
    Futures: 'Futures',
    FuturesOptions: 'Futures Options'
};

export const AlgoliaTypesThatCannotLookAtDeepAnalysis = new Set(['et', 'crypto', 'future', 'oef', 'of']);
export const AlgoliaTypesThatCannotLookAtOptions = new Set(['crypto', 'oef', 'of']);

// These are also from IEX
export const ViewableSecurityTypes = ['cs', 'oef', 'cef', 'ad'];

export const TranslatePositionType = (typeFromApi: string): string => {
    return FromEnglish(
        (s) => s.portfolioScreen.portfolioBreakdown,
        Object.entries(formattedSecurityTypeLabels).find((e) => e[0] === typeFromApi)?.[1] || typeFromApi,
        'value'
    );
};

// https://dev.azure.com/intlanalytics/Vulcan/_git/SecurityMasterService?path=/src/SecurityMasterService.Sdk/Models/Enumerations.cs&version=GBmaster&line=27&lineEnd=28&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents
/** @deprecated For web, these values were originally designed to be human-readable in the times before we supported multiple languages. The values from this function are not suitable to code against. */
// Mobile currently uses to display the type of security in its title block.
export const GetSecTypeNameFromMetadata = (meta: SecurityMetadata) => {
    const text = Ts((s) => s.assetClass);

    if (!meta || !meta?.securityType) return text.miscellaneous;

    const isOffshore = IsOffshoreMutualFundByMetadata(meta)
    
    const sort = QualifiedId.Class(meta.symbol);
    if (sort === 'crypto') return text.cryptocurrency;
    else if (sort === 'futures') return (meta.securityType === 'option') ? text.futuresOptions : text.future;
    else if (OptionSymbol.IsOptionSymbol(meta.symbol)) return text.equitiesOption;
    else if (isOffshore) return text.offShoreMutualFund

    // prettier-ignore
    switch (meta.securityType) {
        case 'OtcOption': return text.otcOption
        case 'MutualFundClosed': return text.etf
        case 'MutualFundOpen': return text.mutualFund
        case 'OilGasReit': return text.oilGasReit
        case 'EscrowReceiptsLetterGuaranty': return text.escrowReceiptsLetterGuaranty
        case 'Treasuries': return text.treasury
        case 'MunicipleBonds': return text.municipleBond
        case 'CorporateUit': return text.corporateUit
        case 'MunicipalUit': return text.municipalUit
        case 'Units': return text.unit
        case 'PreciousMetals': return text.preciousMetals
        case 'PreferredStock':
        case 'ListedOption':
        case 'CommonStock': return text.commonStock
        case 'Currency':
        case 'Warrant':
        case 'Future':
        case 'Index':
        case 'Annuity': return text.annuity
        case 'Unknown':
        case 'Miscellaneous':
        default: return SecurityTypes[meta.securityType as SecurityTypeKeys] || meta.securityType
    }
};

export const HumanizeSecurityCode = (code: string, name: string) => {
    // prettier-ignore
    switch (code) {
        case 'C': return 'Stock'
        case 'M': return 'Mutual Fund'
        case 'L': return 'Real Estate / Oil + Gas'
        case 'W': return 'Warrant'
        case null: return (!name || name === 'Unknown') ? 'Miscellaneous' : FormatString.ChangeCamelCaseToSentenceCase(name)
        default: return `${FormatString.ChangeCamelCaseToSentenceCase(name)} (${code})`
    }
};

export const ProductTypes = {
    mutualFunds: 'Mutual Funds',
    uits: 'UITs',
    cashAndEquivalents: 'Cash & Equivalents',
    cryptos: 'Cryptos',
    fixedIncome: 'Fixed Income',
    equities: 'Equities',
    rightsAndWarrents: 'Rights and Warrants',
    options: 'Options',
    futures: 'Futures',
    metals: 'Precious Metals'
};

export const SideBarExcludedPositionTypes = new Set(['Rights and Warrants', 'Cash & Equivalents']);

export const formattedSecurityTypeLabels: { [key: string]: string } = {
    [SecurityTypes.equities]: 'Stocks'
};