// @ts-strict-ignore
import React, { ReactNode } from 'react';
import './Card.module.css';

interface CardProps {
    id?: string;
    children: ReactNode;
    // CLASSNAMES:
    // "collapsed" - removes background color and box-shadow
    // "animate-press" - reduces box-shadow when element is pressed
    className?: string;
}

const CardComponent = (props: CardProps) => {
    return <div className={`card${props.className ? ` ${props.className}` : ''}`}>{props.children}</div>;
};

export const Card = React.memo(CardComponent);
