// @ts-strict-ignore
import _ from "lodash"
import { T } from "phoenix/assets/lang/T"
import { AddToast } from "react-toast-notifications"

interface OnboardingDataContactInfo {
    name?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    street?: string,
    street2?: string,
    city?: string,
    state?: string,
    country?: string,
    zip?: string,
}
interface OnboardingDataPersonalInfo {
    ssn?: string,
    country?: string,
    dob?: string,
    // governmentId?: string,
}

interface OnboardingDataAccountInfo {
    sourceOfFunds?: string,
    intendedInitialDeposit?: number,
    expectedMonthlyTrading?: number
}

interface OnboardingDataEmploymentInfo {
    status?: string,
    name?: string,
    years?: string,
    jobTitle?: string,
}

interface OnboardingDataTradingExperience {
    commoditiesYears?: string,
    securitiesYears?: string,
    otcYears?: string,
    professionalTrader?: boolean,
}

interface OnboardingDataAffiliationsInfo {
    politicalOrGovernment?: boolean,
    employeeOfStoneX?: boolean,
    employeeName?: string,
    employeeRelationship? : string,
    regulatoryMember?: boolean,
    regulatoryBody?: string,
    registrationNumber? : string,
}

interface OnboardingDataRegulatoryInfo {
    registrationExemption?: boolean,
    bankruptcy?: boolean,
    disputeOrArbitration? : boolean,
    unsatisfiedDebitBalance?: boolean,
}

interface OnboardingDataFinancialInfo {
    annualRevenue? : string,
    netWorth?: string,
    workingCapital?: string
}

interface OnboardingResult {
    onboardingData?: OnboardingData,
    isSuccess?: boolean,
    onboardingRequest?: object,
    onboardingResponse?: OnboardingResponse
}

interface OnboardingResponse {
    applicationId?: string,
    accountId?: string,
    errorMessage?: string
}
export interface OnboardingData {
    contactInfo?: OnboardingDataContactInfo,
    personalInfo?: OnboardingDataPersonalInfo,
    accountInfo?: OnboardingDataAccountInfo,
    employmentInfo?: OnboardingDataEmploymentInfo,
    tradingExperience?: OnboardingDataTradingExperience,
    affiliationsInfo?: OnboardingDataAffiliationsInfo,
    regulatoryInfo?: OnboardingDataRegulatoryInfo,
    financialInfo?: OnboardingDataFinancialInfo,
    isReviewed?: boolean,
    acceptAgreements?: boolean,
    acceptArbitration?: boolean,
    acceptPrivacyStatements?: boolean,
    acceptRiskDisclosureStatements?: boolean,
    acceptFuturesCustomerAgreements?: boolean,
    acceptPlatformUserAgreement?: boolean,
    acceptAchAgreement?: boolean
}

export interface DocumentLink {
    text: string;
    url?: string;
    subText?: string[];
}

export type OnboardingStep = {
    label: string,
    isComplete: (...args: any[]) => boolean,
    isEnabled: (...args: any[]) => boolean,
    isCurrent: (...args: any[]) => boolean,
    onClickGoToScreenId: string
}

export type OnboardingButton = {
    label: string,
    onClick: (addToast?: AddToast, ...args: any[]) => void,
    isEnabled?: (...args: any[]) => boolean,
    isPrimary?: boolean,
    isLoading?: (...args: any[]) => boolean
}

export type OnboardingInputError = {
    key: string;
    error: string;
}

export type OnboardingScreen = {
    id: string,
    validate: (...args: any[]) => OnboardingInputError[],
    buttons: OnboardingButton[],
    title?: string,
    subtitles?: string[],
    body?: JSX.Element | JSX.Element[]
}

export type OnboardingViewModel = {
    steps: OnboardingStep[],
    screens: OnboardingScreen[],
    headerText?: string,
    stepsDisabled?: (...args: any[]) => boolean,
    handleLogoClick?: () => void,
}

export const NoValidation = () => [] as OnboardingInputError[]

export const ValidateRequired = <T,>(data: T, key: string, altText?: string) : OnboardingInputError => {
    const value =  _.get(data, key)?.toString().trim()
    const hasValue = !!value
    if(!hasValue) return {key, error: altText || T(t => t.webRegistration.errors.required)}
}