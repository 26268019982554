import { Flex } from 'components';
import { CandleLineToggleControls } from 'components/CandleLineToggleControls/CandleLineToggleControls';
import { RangeSelector } from 'components/Charting/RangeSelectors';
import { CrosshairUpdateObject, SecurityChartWrapper } from 'components/Charting/SecurityChartWrapper';
import { setSecurityValues, setSecurityValuesOverride, setShowAdditionalStats, setStartingValue } from 'components/SecurityValues/SecurityValuesViewModel';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { ChartMinuteOffsetsByRange, ChartRange, ChartRanges } from 'phoenix/constants';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { XstreamUpdateChartFrequencyInMinutesAction } from 'phoenix/xstream/actions/XstreamChartActions';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

export const OptionChartSection = ({ symbol }: { symbol: string }): ReactElement => {
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();
    const [range, setRange] = useState<ChartRange>('1d');
    const [appWindowSize] = useAppWindowSize();
    const [seriesType, setSeriesType] = useState<'candle' | 'line'>('line');
    const [showVolume, setShowVolume] = useState(true);
    const [latestChangePercent, setLatestChangePercent] = useState<number | undefined>(undefined);
    const quote = XS.OptionQuotes.use(symbol);
    const apiQuote = useSnexStore((s) => s.options.quotesByOsi[symbol]);
    const chartData = useSnexStore((s) => s.securityChart.bySymbol[symbol]?.[range]?.data);
    const startingValue = range === '1d' ? apiQuote?.data?.previousClose || chartData?.[chartData?.length - 1]?.close : chartData?.[chartData?.length - 1]?.close;

    // Assuming all OptionContractScreens using this component are equities
    const excludeChartRanges = () => {
        const assetChartRanges = EquitiesAssetClass.chartRanges().map((range) => range.value);
        return new Set(
            Object.values(ChartRanges)
                .filter((range) => !assetChartRanges.includes(range))
                .map((range) => range as string)
        );
    };

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
        xdispatch(XstreamUpdateChartFrequencyInMinutesAction(ChartMinuteOffsetsByRange[range]));
    }, [dispatch, range, symbol, xdispatch]);

    useEffect(() => {
        xdispatch(XS.OptionQuotes.start([symbol], LiveDataNamespaces.SecurityScreenOptions));
        return () => XS.stopNs(LiveDataNamespaces.SecurityScreenOptions);
    }, [symbol, xdispatch]);

    const chartDimensions = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return { width: 560, height: 320 };
            case WindowSizes.regular:
            case WindowSizes.small:
                return { width: 670, height: 350 };
            case WindowSizes.large:
            default:
                return { width: 1000, height: 350 };
        }
    }, [appWindowSize]);

    const handleCrosshairUpdate = useCallback(
        (update: Record<string, CrosshairUpdateObject>, isScrubbing?: boolean) => {
            // (type: 'line' | 'candle' | 'area', update: Record<string, CrosshairUpdateObject>, isScrubbing = false) => {
            const updateObj: CrosshairUpdateObject = update;

            if (!isScrubbing) {
                setSecurityValuesOverride(false);
                setSecurityValues({ ...quote });
                if (startingValue) setStartingValue(startingValue);
                setLatestChangePercent(quote?.changePercent);
                setShowAdditionalStats(false);
                return;
            } else {
                setSecurityValuesOverride(true);
                setSecurityValues({ ...updateObj, price: updateObj.value, change: updateObj?.chartValChange, changePercent: updateObj?.chartPercChange });
                if (startingValue) setStartingValue(startingValue);
                setLatestChangePercent(updateObj?.chartPercChange);
            }

            if (seriesType === 'line') {
                setShowAdditionalStats(false);
            } else if (
                seriesType === 'candle' &&
                typeof update !== 'number' &&
                !isNaN(updateObj?.open as number) &&
                !isNaN(updateObj?.close as number) &&
                !isNaN(updateObj?.high as number) &&
                !isNaN(updateObj?.low as number)
            ) {
                setShowAdditionalStats(true);
            }
        },
        [seriesType, quote, startingValue]
    );

    const updateRange = useCallback(
        (newRange: ChartRange) => {
            setRange(newRange);
            xdispatch(XstreamUpdateChartFrequencyInMinutesAction(ChartMinuteOffsetsByRange[newRange]));
        },
        [xdispatch]
    );

    return (
        <Flex column key={`option-contract-${appWindowSize}`}>
            <div style={{ width: '100%', minHeight: 350 }}>
                <SecurityChartWrapper
                    bypassBatching
                    canScrub
                    chartResolution='hi'
                    containerId='lalala'
                    crosshairType='vertical'
                    height={chartDimensions.height}
                    onCrosshairUpdate={handleCrosshairUpdate}
                    percentChangeOverride={range === '1d' ? latestChangePercent : undefined}
                    range={range}
                    seriesType={seriesType}
                    showNoDataLabel
                    showOpenLine
                    showVolume={showVolume}
                    symbols={[symbol]}
                    width={chartDimensions.width}
                />
            </div>
            <Flex row align='flex-start' style={{ marginTop: 10 }}>
                <div>
                    <RangeSelector
                        colorSymbol={symbol}
                        selectedRange={range}
                        setRange={updateRange}
                        valueChange={latestChangePercent}
                        excludeRanges={excludeChartRanges()}
                    />
                </div>
                <CandleLineToggleControls onDisplayTypeChange={setSeriesType} onToggleVolume={setShowVolume} />
            </Flex>
        </Flex>
    );
};
