// @ts-strict-ignore
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { Ts } from 'phoenix/assets/lang/T';
import { ChartRanges } from 'phoenix/constants';
import { AssetTradeability } from 'phoenix/constants/AssetTradeability';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { CalculatedAccountValuation } from 'phoenix/hooks/UseAccountValuation';
import { AssetClass, StandardPriceToQuantityFormula, StandardQuantityToPriceFormula } from 'phoenix/models/AssetClasses/AssetClass';
import { Account } from 'phoenix/redux/models';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { FormatNumber } from 'phoenix/util/FormatNumber';
import { QualifiedId } from 'phoenix/util/QualifiedId';

export const CryptosAssetClass: AssetClass = {
    // Typing
    type: 'crypto',
    family: 'cryptos',
    derivative: 'base',
    derivatives: [],
    algoliaTypes: ['crypto'],

    // Trading + Tradeability
    tradeability: AssetTradeability?.crypto,
    accountCanTradeThis: (account: Account) => /^X:/.test(account.accountNumber),
    userAllowedToTradeThis: () => true,
    getQuantityPrice: StandardQuantityToPriceFormula,
    getPriceForQuantity: StandardQuantityToPriceFormula,
    getQuantityForPrice: (usdAmount, quote, opts) => StandardPriceToQuantityFormula(usdAmount, quote, { ...opts, precision: 8 }),
    defaultTradePrice: { limit: 'bid-ask', stop: 'bid-ask', stoplimit: 'bid-ask' },
    defaultOrderType: () => 'market',
    hideTimeInForceUi: true,

    // Features
    flags: {
        mobile: {
            view: FeatureFlags.Mobile.ViewCrypto,
            trade: FeatureFlags.Mobile.TradeCrypto
        },
        web: {
            view: FeatureFlags.Web.ViewCrypto,
            trade: FeatureFlags.Web.TradeCrypto
        }
    },
    hasDeepAnalysis: false,
    canCreateAlerts: false,
    hasOptions: false,
    canWatchlist: true,
    tradePriceShortcutButtonsVariant: 'percent',
    hasMultiLeg: false,
    hasDerivatives: () => CryptosAssetClass.derivatives.length > 1,

    // Charting
    neverShowChartPulse: false,
    hasAdvancedChart: true,
    advancedChartConfiguration: {
        supported_resolutions: ['1', '10', '30', '1D', '1W']
    },
    chartVariants: ['line', 'candles'],
    chartRanges: () => {
        const text = Ts((s) => s.misc.ranges);

        return [
            { label: text.twentyFourHours, value: ChartRanges.twentyFourHours },
            { label: text.oneWeek, value: ChartRanges.oneWeek },
            { label: text.oneMonth, value: ChartRanges.oneMonth },
            { label: text.sixMonths, value: ChartRanges.sixMonths },
            { label: text.oneYear, value: ChartRanges.oneYear }
        ];
    },
    accountChartRanges: ['24h', '1w', '1m', '3m', '1y', '5y'],
    securityChartRanges: ['24h', '1w', '1m', '3m', '1y', '5y'],

    formatOrderQuantity: (o) => {
        const cost = o.quantityQualifier === 'Shares' ? o.cost : o.orderQuantity;
        let quantity: any = o.quantityQualifier === 'Shares' ? o.orderQuantity : o.cost;

        if (o.quantityQualifier === 'EvenDollar' && !o.cost) {
            quantity = FormatNumber.toMoneyOpt2(cost);
        }
        return quantity;
    },

    // Formatting + Codec
    getPriceFormatInfo: () => {
        return {
            decimalPlaces: 2,
            tickSize: 0.01,
            moneyFormatOptions: {
                hideCurrencySymbol: false,
                maxDecimalPlaces: 2,
                min: 0,
                minDecimalPlaces: 2,
                showDecimals: true,
                tickSize: 0.01
            }
        };
    },
    formatPrice: (price: number) => FormatNumber.toMoneyOpt2(price, CryptosAssetClass.getPriceFormatInfo().moneyFormatOptions),

    getQuantityFormatOptions: () => {
        return {
            maxDecimalPlaces: 8,
            minDecimalPlaces: 0,
            hideCurrencySymbol: true,
            tickSize: 0.00000001,
            showDecimals: true
        };
    },
    formatQuantity: (quantity: number, meta: SecurityMetadata) => FormatNumber.toMoneyOpt2(quantity, CryptosAssetClass.getQuantityFormatOptions(meta)),
    getSymbolName: (s) => QualifiedId.RemovePrefix(s),
    getSymbolNameLines: (s, lang) => [CryptosAssetClass.getSymbolName(s, lang)],
    getBaseSymbol: (s) => s,
    getConcreteSymbol: (s) => s,

    unit: 'share',
    idMatches: () => undefined,

    // Colors
    /** @deprecated Please do not use this value. It does not use theming Use useAssetClassColors hook */
    primaryColor: '#883FF6',
    positiveChangeColor: 'rgb(2,179,52)',
    negativeChangeColor: '#AB2323',

    // TODO -- Account Data
    getBuyingPowerFigures: () => [],
    getAccountSummaryFigures: () => [],
    getAccountGlanceFigures: (val: CalculatedAccountValuation, lang: Snex1LanguagePack) => {
        const l = lang.portfolioScreen.glance;
        return [
            { id: 'value', label: l.accountValue, value: val?.value },
            { id: 'cash', label: l.cash, value: val?.summary?.totalCashCurrent },
            { id: 'todayPnl', label: l.todaysGainLoss, value: val?.change },
            { id: 'buying-power', label: l.buyingPower, value: val?.buyingPower?.buyingPower }
        ].map((i) => ({ ...i, value: FormatNumber.toMoneyOpt2(i.value) }));
    },

    // Documents
    documentDeliveryOptions: 'mail-electronic',

    showShortPositionIcon: false,
    showStopPriceShortcutButtons: true
};
