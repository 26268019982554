import { useSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { OptionsTradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { ApiPosition, OptionsOpenClose } from 'phoenix/redux/models';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { GetOptionsOpenClose } from 'phoenix/util/OptionsHelpers';

export default function useOptionsOpenClose(): { openClose: OptionsOpenClose; leg2OpenClose: OptionsOpenClose } {
    const { leg2Symbol = '', leg2TradeAction = 'Buy', symbol = '', tradeAction = 'Buy' } = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const ac = useAssetClass(symbol);
    const selectedAccountNumber = useSelectedAccountForOrderRequest(ac);
    const { positions = [] as ApiPosition[] } = usePositionsStore();

    const openClose = GetOptionsOpenClose({ positions, selectedAccountNumber, symbol, tradeAction });
    const leg2OpenClose = GetOptionsOpenClose({ positions, selectedAccountNumber, symbol: leg2Symbol, tradeAction: leg2TradeAction });

    return { openClose, leg2OpenClose };
}
