// @ts-strict-ignore
import { useLocalStorage } from './UseLocalStorage';
import { MyProgress } from 'phoenix/redux/models/Users/MyProgress'
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useEffect } from 'react';

export const useProgress = (): [MyProgress, (value: MyProgress) => void] => {
    const userInfo = useSnexStore(s => s.user.myInfo)    
    const [ progress, setProgress] = useLocalStorage('USER_PROGRESS', null);

    useEffect(() => {
        if(!userInfo?.loading && !userInfo?.pristine && userInfo?.data) {
            const newProgress = progress === null ? new MyProgress(userInfo.data?.hasBeenWelcomed) : progress
            if (userInfo.data?.initialFundsRecieved) newProgress.initialFundsRecieved = true
            setProgress(newProgress)
        }
    }, [userInfo?.loading, JSON.stringify(userInfo?.data)])
    
    
    return [progress, setProgress]
}