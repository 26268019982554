// @ts-strict-ignore
import { ChartRange } from 'phoenix/constants';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { XS } from 'phoenix/xstream/XS';
import { useEffect } from 'react';
import { create } from 'zustand';

export interface ISecurityValues {
    price?: number;
    change?: number;
    changePercent?: number;
    open?: number;
    close?: number;
    high?: number;
    low?: number;
    volume?: number;
}

interface SecurityValuesStore extends ISecurityValues {
    valuesOverride: boolean;
    showAdditionalStats: boolean;
    startingValue: number;
}

export const useSecurityValuesStore = create<SecurityValuesStore>((set) => ({
    price: null,
    change: null,
    changePercent: null,
    open: null,
    close: null,
    high: null,
    low: null,
    volume: null,
    valuesOverride: false,
    showAdditionalStats: false,
    startingValue: null
}));

export const getSecurityValuesOverrideNonReactive = () => useSecurityValuesStore.getState().valuesOverride;

export const setSecurityValuesOverride = (valuesOverride: boolean) => {
    const store = useSecurityValuesStore;
    const prevState = store.getState();

    store.setState({
        ...prevState,
        valuesOverride
    });
};

export const useShowAdditionalStats = () => useSecurityValuesStore((s) => s).showAdditionalStats;
export const getShowAdditionalStatsNonReactive = () => useSecurityValuesStore.getState().showAdditionalStats;

export const setShowAdditionalStats = (showAdditionalStats: boolean) => {
    const store = useSecurityValuesStore;
    const prevState = store.getState();

    store.setState({
        ...prevState,
        showAdditionalStats
    });
};

export const useChangePercent = () => useSecurityValuesStore((s) => s).changePercent;
export const getChangePercentNonReactive = () => useSecurityValuesStore.getState().changePercent;

export const useSecurityValues = (symbol?: string, range?: ChartRange): ISecurityValues => {
    const store = useSecurityValuesStore((s) => s);
    const assetClass = useAssetClass(symbol);
    const quote = assetClass?.derivative === 'option' ? XS.OptionQuotes.use(symbol) : XS.Quotes.use(symbol);
    const allCharts = useSnexStore((s) => s.securityChart.bySymbol[symbol]);
    const streamingChartData = XS.SecurityCharts.use(symbol) || [];
    const historicalChartData = allCharts?.[range]?.data || [];
    const chart = streamingChartData.sort((a, b) => b.timestamp - a.timestamp).concat(historicalChartData);
    const latestPoint = chart?.[0];
    const price = latestPoint?.latestPrice || quote?.price;

    if (store.valuesOverride) {
        return {
            price: store.price,
            change: store.change,
            changePercent: store.changePercent,
            open: store.open,
            close: store.close,
            high: store.high,
            low: store.low,
            volume: store.volume
        };
    }

    const { changeValue, changePercentValue } = getChangeValues(store.startingValue, price);

    // Note that quote data may differ from chart data - this is not a bug!
    // The values above the chart must reflect the quote regardless of the most recent chart point data
    return {
        price: quote?.price,
        change: store?.startingValue ? changeValue : quote?.changePercent,
        changePercent: store?.startingValue ? changePercentValue : quote?.changePercent * 100,
        open: quote?.open,
        close: quote?.close,
        high: quote?.high,
        low: quote?.low,
        volume: quote?.volume
    };
};

export const setSecurityValues = (values: ISecurityValues) => {
    const store = useSecurityValuesStore;
    const prevState = store.getState();

    store.setState({
        ...prevState,
        ...values
    });
};

export const setStartingValue = (startingValue?: number) => {
    const store = useSecurityValuesStore;
    const prevState = store.getState();
    store.setState({
        ...prevState,
        startingValue
    });
};

export function getChangeValues(starting: number, current: number): { changeValue: number; changePercentValue: number } {
    const changeValue = current - starting === 0 ? 0.0 : current - starting;
    let changePercentValue = (starting ? (current - starting) / starting : 0) * 100;

    if (starting < 0) changePercentValue = changePercentValue * -1;

    return { changeValue, changePercentValue };
}
