import { subYears, addMonths } from "date-fns"
import { SecurityEarningsEstimates, EarningsData, SecurityEarnings } from "phoenix/redux/models"
import { Last } from "phoenix/util"


// Takes quarters (e.g., "2021 Q1") and returns a comparator number.
// Returns negative if q1 is larger, positive if q2 is larger, and zero if they are the same
const CompareQuarters = (q1: string, q2: string, ascending?: boolean): number => {
    if (!q1) return ascending ? 1 : -1
    if (!q2) return ascending ? -1 : 1
    const q1Year = q1.split(' ')[0]
    let result = 0
    if (q2.indexOf(q1Year) === 0) result = q2.charCodeAt(q2.length - 1) - q1.charCodeAt(q1.length - 1)
    else result = parseInt(q2.split(' ')[0]) - parseInt(q1Year)
    return result * (ascending ? 1 : -1)
}

export const GetEarningsChartData = (getQuarterText:  (quarterNumber: number) => string, estimates: SecurityEarningsEstimates | undefined | null, actualEarnings: SecurityEarnings | undefined | null) : 
{
    chartdataXAxis?: string[],
    chartdataActual?: number[],
    chartdataEstimated?: number[]

}  => {


    if (!estimates?.data|| !actualEarnings?.earnings) return {chartdataXAxis: undefined, chartdataActual: undefined, chartdataEstimated: undefined}
    
    const castedEstimates: EarningsData[] = estimates?.data?.map((e) => ({
        actualEps: undefined,
        estimatedEps: e.epsAvg,
        fiscalPeriod: `${e.year} ${getQuarterText(e.quarter)}`,
        fiscalPeriodEndDate: new Date(e.period)
    }))
    // Go back three years
    const minTime = subYears(new Date(), 3).getTime()

    const lastActualQuarter = Last(actualEarnings.earnings.map((e) => e.fiscalPeriod).sort((a, b) => CompareQuarters(a, b, false))) 
    const maxTime = addMonths(new Date(), 6).getTime()
    const includedEstimates = castedEstimates?.filter((e) => CompareQuarters(e.fiscalPeriod, lastActualQuarter, true) < 0).filter((p) => !!p)
    const earnings = actualEarnings.earnings
        .concat(includedEstimates)
        .filter((p) => {
            const t = new Date(p.fiscalPeriodEndDate).getTime()
            return t >= minTime && t <= maxTime
        })
        .sort(
            (a, b) => Date.parse(new Date(a.fiscalPeriodEndDate).toUTCString()) - Date.parse(new Date(b.fiscalPeriodEndDate).toUTCString())
        )

    const chartdataXAxis = earnings?.map((p, _) => p.fiscalPeriod)
    const chartdataActual = earnings?.map((p, _) => Number(p.actualEps?.toFixed(2))).filter((x) => x && !isNaN(x))
    const chartdataEstimated = earnings?.map((p, _) => Number(p.estimatedEps?.toFixed(2))).filter((x) => x && !isNaN(x))

    
    return {chartdataXAxis, chartdataActual, chartdataEstimated}
}
