// @ts-strict-ignore
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { Sum } from 'phoenix/util';
import { useRelavantPositions } from './UseRelavantPositions';

export const useQuantityHeld = (secId: QualifiedSecurityId, accountNumber: string): number => {
    const relevantPositions = useRelavantPositions(secId, accountNumber);

    if (!accountNumber || !secId) return null;
    else return Sum(relevantPositions?.map((p) => p?.quantity || 0));
};
