import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { Flex } from '..';
import { useColors } from 'hooks/UseColors';
import { Link } from 'react-router-dom';
import './SnexTableCell.scss';

export const SnexTableCell = (props: {
    children?: any,
    width?: any,
    fontWeight?: any,
    right?: boolean,
    style?: React.CSSProperties
    variant?: any,
    colSpan?: number,
    onClick?: (e: React.MouseEvent) => any,
    route?: string
}) => {
    const colors = useColors();

    const cellInterior = () => (
        <Flex column style={{ width: props.width || '100%', height: '100%', overflow: 'hidden', textOverflow: 'ellipsis', justifyContent: 'center' }}>
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: props.fontWeight || 500 }}>{props.children}</div>
        </Flex>
    )

    return (
        <TableCell className={ props.route ? 'Snex1SecurityCell' : undefined } onClick={props.onClick} colSpan={props.colSpan} style={{ overflow: 'hidden', textAlign: props.right ? 'right' : undefined, ...props.style, borderBottom: `1px solid ${colors.dividerColor}` }}>
            {props.route ? <Link to={props.route}>{ cellInterior() }</Link> : cellInterior() }
        </TableCell>
    );
};
