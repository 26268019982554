// @ts-strict-ignore
import axios, { Method } from 'axios'
import { Dispatch } from 'redux'
import { GetConfig, StorageKeys, Urls } from '../../constants'
import { AsyncOperationOutcome, CrossPlatformRelays } from '../../constants/CrossPlatformRelays'
import { DownOperationSubmitting, UpOperationSubmitting } from '../../hooks/UseOperationSubmitting'
import { ApiError } from '../../models'
import { ReduxAction } from '../../models/ReduxAction'
import { GetEnableDebugLogging, GetUseVerboseRedux, JwtHasExpired } from '../../util'
import { CheckCacheTime, SetCacheTime } from '../../util/CacheManager'
import { HitDebounce } from '../../util/DebounceManager'
import { MutexEnter, MutexExit } from '../../util/MutexManager'
import { ChaosIsHappening } from '../Chaos'
import { GlobalState } from '../GlobalState'
import { MyToken } from '../models/Users/MyToken'
import { ActionNames, Actions } from './Constants'
import { IncrementApiCallCount, IncrementBatchedCallCount, IncrementCachedCallCount, IncrementMutexdCallCount } from './DebugActions'
import { GetUserTokenAction } from './UserActions'
import { GetMemo, SetMemo } from '../../util/Memo'
import { SetRelay } from '../../hooks/UseRelay'
import { addMilliseconds } from 'date-fns'
import { GenerateFancifulName } from '../../util/GenerateFancifulName'
import { ErrorLogger } from '../../util/ErrorLogger'

let _onRequestTimeoutMs = 0
let _onRequestTime = new Date()
let _onRequestCallback: () => Promise<void> = null
export const OnApiRequestAfterTime = (timeoutMs: number, action: () => Promise<void>) => {
    if (_onRequestCallback !== null) {
        console.log(
            'WARNING! You are setting OnApiRequestAfterTime when you have already defined a callback. This will override the existing callback.'
        )
    }
    _onRequestTimeoutMs = timeoutMs
    _onRequestTime = addMilliseconds(new Date(), 2000) // Starting off, check after a couple of seconds in case the token is about to expire
    _onRequestCallback = action
}

export class ReduxApiRequest<TResult> {
    method: Method
    url: string | string[]
    names: ActionNames
    body?: any
    headers?: any
    actionExtras?: any
    successHandler?: (result: TResult, dispatch: Dispatch) => any
    errorHandler?: (error: any, dispatch: Dispatch) => any
    skipToken?: boolean
    useStoredSelector?: (s: GlobalState) => TResult
    useStoredFireSuccess?: boolean
    mutexKeySelector?: () => string
    mutexReuseResult: boolean
    cacheKeySelector?: () => string
    cacheTimeoutSeconds?: number
    cacheSlidingExpiration?: boolean
    toastableErrorCodes?: Set<string>
    universalErrorMessage?: string
    errorTransforms?: { [key: string]: string }
    upstreamErrorTransforms?: { [key: string]: string }
    batchIdSelector: () => string
    batchUrlCombiner: (urls: string[]) => string | string[]
    dispatchOnBatch: string
    batchDebounceMs: number
    skipLoading: boolean
    triggerAsyncOperationIndicator: boolean
    beforeRun: () => TResult | null
    noOutput?: boolean

    constructor(method: Method, url: string | string[], names: ActionNames, body?: any, headers?: any) {
        this.method = method
        this.url = url
        this.names = names
        this.body = body
        this.headers = headers
        this.actionExtras = {}
        this.skipToken = false
        this.useStoredFireSuccess = false
        this.mutexReuseResult = true
        this.mutexKeySelector = null
        this.cacheKeySelector = null
        this.cacheTimeoutSeconds = null
        this.cacheSlidingExpiration = false
        this.toastableErrorCodes = null
        this.universalErrorMessage = null
        this.errorTransforms = {}
        this.upstreamErrorTransforms = {}
        this.skipLoading = !GetUseVerboseRedux()
        this.dispatchOnBatch = null
        this.triggerAsyncOperationIndicator = false
        this.beforeRun = null
        this.noOutput = false
    }

    withPassthrough(extras: Partial<ReduxAction>) {
        this.actionExtras = { ...(this.actionExtras || {}), ...extras }
        return this
    }

    withSubject(subject: any) {
        return this.withPassthrough({ subject })
    }

    withDebug(debug: string) {
        return this.withPassthrough({ passthrough: { _debug: ` DEBUG=${debug}` } })
    }

    // Normally, requets will reach out to the API to get the current user's token to include in the request
    // If you call this, then it won't do that
    withoutToken() {
        this.skipToken = true
        return this
    }

    // Add mutual exclusion (mutex) per selected key. Each request gets a key. If a request with the same key is already
    // pending, the request will sleep until the first returns. If reuseResult is true, then the result of the first will
    // be used immediately for the others. Key selector is executed at runtime to minimize the chances of a race. If no
    // key selected is provied, then the request's URL is used as the mutex key
    withMutex(keySelector?: string | (() => string), reuseResult = true) {
        this.mutexKeySelector = (() => {
            if (!keySelector) return () => (Array.isArray(this.url) ? this.url.join(';') : this.url)
            else if (typeof keySelector === 'string') return () => keySelector
            else return keySelector
        })()
        this.mutexReuseResult = reuseResult
        return this
    }

    withoutLoading() {
        this.skipLoading = true
        return this
    }

    withLoading() {
        this.skipLoading = false
        return this
    }

    withAsyncOperationIndicator() {
        this.triggerAsyncOperationIndicator = true
        return this
    }

    showToasts(toastCodes: string[] = []) {
        this.toastableErrorCodes = new Set(toastCodes)
        return this
    }

    withErrorMessage(message: string) {
        this.universalErrorMessage = message
        return this
    }

    // If the request fails and its *root-level* `errorCode` field matches `key`, then replace its root-level `errorMessage`
    // field with the provided value. If you want to transform according to `errorCode` in the response's `upstreamData`
    // field, use withUpstreamErrorTransfrom instead.
    withErrorTransform(code: string, newMessage: string) {
        this.errorTransforms[code] = newMessage
        return this
    }

    withUpstreamErrorTransform(code: string, newMessage: string) {
        this.upstreamErrorTransforms[code] = newMessage
        return this
    }

    withBatching(
        batchId: string | (() => string),
        urlCombiner: (urls: string[]) => string | string[],
        debounceTimeMs: number,
        dispatchOnBatch: string = null
    ) {
        this.batchIdSelector = typeof batchId === 'string' ? () => batchId : batchId
        this.batchDebounceMs = debounceTimeMs
        this.batchUrlCombiner = urlCombiner
        this.dispatchOnBatch = dispatchOnBatch
        return this
    }

    withoutOutput() {
        this.noOutput = true
        return this
    }

    // Set a selector that runs before the API call. If the selector returns anything (i.e., something that isn't null, undefined, or false),
    // just return that result instead of reaching out to the API. Use this to cache data without hitting the API. Use the `sliding` argument
    // to indicate whether cache hits should extend the cache lifetime or not.
    //
    // Important note -- as a performance optimization, useStored will NOT cause the "Success" action to fire.
    // This means that cache hits will not cause any "useSelector"s to update. The most important implication of this
    // is that if your `selector` pulls from a different part of the state than where the reduce puts it, you'll
    // end up with no data. To account for this scenario, please use `useStoredExotic` instead, which will cause the
    // `success` event to fire on cache hits.
    useStored(
        selector: (s: GlobalState) => TResult,
        cacheKeySelector?: (() => string) | string,
        cacheTimeoutSeconds?: number,
        sliding?: boolean
    ) {
        this.cacheKeySelector = typeof cacheKeySelector === 'function' ? cacheKeySelector : () => cacheKeySelector
        this.cacheTimeoutSeconds = cacheTimeoutSeconds
        this.useStoredSelector = selector
        this.cacheSlidingExpiration = sliding
        return this
    }

    // Same as useStored, but fires off `success` event on cache hits
    useStoredExotic(
        selector: (s: GlobalState) => TResult,
        cacheKeySelector?: () => string,
        cacheTimeoutSeconds?: number,
        sliding?: boolean
    ) {
        this.cacheKeySelector = cacheKeySelector
        this.cacheTimeoutSeconds = cacheTimeoutSeconds
        this.useStoredSelector = selector
        this.useStoredFireSuccess = true
        this.cacheSlidingExpiration = sliding
        return this
    }

    // Used to run some imperative code before the API request is sent. Errors thrown from it are dispatched in an error event. Data returned from
    // it is discarded (for that purpose, please use useStored).
    onBeforeSend(run: () => TResult | null) {
        this.beforeRun = run
        return this
    }

    onSuccess(handler: (result: TResult, dispatch: Dispatch) => any) {
        this.successHandler = handler
        return this
    }

    onError(handler: (result: TResult, dispatch: Dispatch) => any) {
        this.errorHandler = handler
        return this
    }

    run(includeErrorCode?: boolean) {
        return async (dispatch: any, getState: () => GlobalState): Promise<any> => {
            /* Dispatch type has to be null for TS to quiet down about thunk-promise types */
            const handleError = (error) => {
                if (GetEnableDebugLogging()) console.log(`[HTTP] ${this.method} XXX ${this.url}${this.actionExtras._debug || ''}:`, error)
                const e: ApiError = error?.errorCode
                    ? error
                    : (() => {
                          const errorJson = 
                          includeErrorCode? 
                            JSON.stringify({
                                message: (<XMLHttpRequest>error?.responseText)?.status,
                                errorCode: (<XMLHttpRequest>error?.request)?.status
                            }) :
                          (<XMLHttpRequest>error?.request)?.responseText
                          try {
                              return errorJson && JSON.parse(errorJson)
                          } catch (x) {
                              console.warn(
                                  'Failed to parse error response',
                                  x,
                                  ` to request ${this.url}; original error:`,
                                  errorJson || error
                              )
                              return {}
                          }
                      })()

                const errorResult =
                    (this.errorHandler ? this.errorHandler({ ...e, ...this.actionExtras }, dispatch) : { ...e, failed: true }) || {}
                const effectiveCode = errorResult?.errorCode || e?.errorCode || ''
                errorResult.errorCode = effectiveCode

                const replacementMessage =
                    this.upstreamErrorTransforms[e?.upstreamData?.errorCode] ||
                    this.errorTransforms[effectiveCode || ''] ||
                    this.universalErrorMessage

                if (replacementMessage) errorResult.errorMessage = replacementMessage

                // Special relay effect for if the API says this feature is premium. API should not send premium errors
                // if feature flag is set, so no need to filter it here as well
                if (e?.errorCode === 'PREMIUM')
                    dispatch({ type: Actions.Relay.Update, subject: CrossPlatformRelays.ShowPremiumUpgradeModal, data: true })

                // Special redirect for UNAUTH_RELOGIN
                // const scheme = GetConfig()?.AuthenticationScheme || 'jwt';
                // const unauthorized = this.url === Urls.authentication.checkLoginStatus() && error.response?.status === 401 && scheme === 'cookies'
                // if (e.errorCode === 'UNAUTH_RELOGIN' || unauthorized) window.location.href = Urls.authentication.loginPage();

                const showToast =
                    !!this.toastableErrorCodes && (this.toastableErrorCodes.size === 0 || this.toastableErrorCodes?.has(e.errorCode || ''))
                const errorDisplay = showToast ? 'toast' : 'none'

                dispatch({ ...this.actionExtras, type: this.names.Failure, requestBody: this.body, error: errorResult, errorDisplay })
                dispatch({
                    ...this.actionExtras,
                    type: Actions.Errors.ReportApiError,
                    requestBody: this.body,
                    error: errorResult,
                    subject: this.names.Failure,
                    errorDisplay
                })
                return errorResult
            }

            // If it's time, run the onRequestCallback.
            const timeToRefresh = _onRequestTime < new Date()
            if (_onRequestCallback && timeToRefresh) {
                if (_onRequestTime < new Date()) {
                    // Checking _onRequestTime again since it's possible another bounce already got here
                    try {
                        // console.log('[REFRESH] (winner thread -- calling callback)')
                        await _onRequestCallback()
                        _onRequestTime = addMilliseconds(new Date(), _onRequestTimeoutMs)
                    } catch (e) {
                        console.log('Notice: onRequest callback failed with error', e)
                    }
                } else {
                    // console.log('[REFRESH] periodic refresh not triggering, another thread has already refreshed')
                }
            } else {
                // if (!_onRequestCallback) console.log('[REFRESH] NOT refreshing, no callback defined')
                // if (!timeToRefresh) console.log(`[REFRESH] NOT refreshing, it is too early still (${ _onRequestTime.getTime() - new Date().getTime() }ms left until we check)`)
            }

            if (this.beforeRun) {
                try {
                    this.beforeRun()
                } catch (e) {
                    return handleError(e)
                }
            }
            if (this.useStoredSelector) {
                const stored = this.useStoredSelector(getState())

                // @ts-ignore
                const storedValid = !(stored === null || stored === undefined || stored === false)
                const notExpired = !this.cacheKeySelector || CheckCacheTime(this.cacheKeySelector(), this.cacheTimeoutSeconds) === 'valid'
                if (storedValid && notExpired) {
                    if (this.cacheSlidingExpiration) SetCacheTime(this.cacheKeySelector())
                    IncrementCachedCallCount(this.names.BaseName)
                    if (this.useStoredFireSuccess || GetUseVerboseRedux()) {
                        dispatch({ ...this.actionExtras, type: this.names.Success, data: stored, requestBody: this.body, fromCache: true })
                    }
                    return stored
                }
            }

            if (this.batchIdSelector) {
                if (this.dispatchOnBatch) dispatch({ ...this.actionExtras, type: this.dispatchOnBatch })
                const id = this.batchIdSelector()
                const directive = await HitDebounce(
                    id,
                    { url: this.url, extras: this.actionExtras },
                    (combined: { url: string; extras: any }[]) => {
                        this.url = this.batchUrlCombiner(combined.map((c) => c.url))
                        this.actionExtras = { ...this.actionExtras, subject: combined.map((c) => c.extras?.subject).filter((x) => !!x) }
                    },
                    this.batchDebounceMs
                )
                if (directive === 'halt') {
                    if (!this.skipLoading)
                        dispatch({ ...this.actionExtras, type: this.names.Loading, requestBody: this.body, fromCache: true })
                    const shared = await MutexEnter(id)
                    IncrementBatchedCallCount(this.names.BaseName)
                    // if (shared && GetEnableDebugLogging()) console.log('Using batched result for', this.names.Success, 'data:', shared);
                    return shared
                }
            }

            if (!this.skipLoading) dispatch({ ...this.actionExtras, type: this.names.Loading, requestBody: this.body })
            if (this.triggerAsyncOperationIndicator) UpOperationSubmitting()

            const mutexKey = this.mutexKeySelector === null ? null : this.mutexKeySelector()
            if (mutexKey) {
                const shared = await MutexEnter(this.mutexKeySelector())
                if (this.mutexReuseResult && shared !== null) {
                    if (GetEnableDebugLogging()) console.log('Using raced result for', this.names.Success)
                    IncrementMutexdCallCount(this.names.BaseName)
                    if (GetUseVerboseRedux())
                        dispatch({ ...this.actionExtras, type: this.names.Success, data: shared, requestBody: this.body, fromCache: true })
                    return shared
                }
            }

            let headers = { ...this.headers }
            let jwt = ''
            if (!this.skipToken) {
                // Only go through the whole token Redux process if we really need to (and if we're using the API to store the token)
                jwt = GetMemo(StorageKeys.JwtMemo)
                const tokenExists = !!jwt
                const tokenExpired = JwtHasExpired(jwt)
                if ((!tokenExists || tokenExpired) && GetConfig().TokenRetrievalMethod === 'api') {
                    const accessToken: MyToken = await dispatch(GetUserTokenAction()) // <-- emits an event on success, which we don't want to happen unnecessarily
                    jwt = accessToken.accessToken
                    SetMemo(StorageKeys.JwtMemo, jwt, 'HTTP helper token step (retrieval method = "api")')
                }
                headers = { ...headers, Authorization: `Bearer ${jwt}` }
            }

            IncrementApiCallCount(this.names.BaseName)
            const urls: string[] = Array.isArray(this.url) ? this.url : [this.url]

            let success = true
            const handle = async (url: string) => {
                try {
                    if (GetEnableDebugLogging() && !this.noOutput)
                        console.log(`[HTTP] ${this.method}   > ${url}${this.actionExtras._debug || ''} / ${GenerateFancifulName(jwt)}`)

                    const start = new Date().getTime()
                    let { data }: { data: TResult } = await axios({ method: this.method, url, data: this.body, headers })
                    if (GetEnableDebugLogging() && !this.noOutput)
                        console.log(`[HTTP] ${this.method} <   ${url}${this.actionExtras._debug || ''} ${new Date().getTime() - start}ms`)

                    if (ChaosIsHappening())
                        throw {
                            errorCode: 'CHAOS',
                            errorMessage: 'This request was intentially sabotatged to simulate API unreliabillity',
                            errorNote: null
                        }
                    if (this.successHandler) {
                        const transformed = this.successHandler(data, dispatch)
                        data = transformed || data
                    }
                    dispatch({ ...this.actionExtras, type: this.names.Success, data, requestBody: this.body })
                    if (this.cacheKeySelector) SetCacheTime(this.cacheKeySelector())
                    if (mutexKey) MutexExit(mutexKey, data)
                    if (this.batchIdSelector) MutexExit(this.batchIdSelector(), data)

                    return data || true
                } catch (error) {
                    // <-- sometimes error is the error body... other times it a NetworkError object. THANK YOU AXIOS YOU ARE SO COOL!!! :)
                    success = false
                    return handleError(error)
                }
            }

            const promises = urls.map(async (u) => handle(u))

            await Promise.all(promises)

            if (this.triggerAsyncOperationIndicator) {
                SetRelay<AsyncOperationOutcome>(
                    CrossPlatformRelays.AsyncOperationOutcome,
                    success ? { success: 'success' } : { success: 'failure' }
                )
                DownOperationSubmitting()
            }
            return promises.length === 1 ? await promises[0] : null
        }
    }

    almostRun(simulateReduxActions?: boolean, mockedDataOrError?: any, fail?: boolean) {
        return async (dispatch: Dispatch) => {
            const log = () =>
                console.log(`Almost running but not quite! Would have sent:\n\n${this.method} ${this.url}\n\n${JSON.stringify(this.body)}`)
            if (!simulateReduxActions) {
                log()
                return
            }

            if (!this.skipLoading) dispatch({ ...this.actionExtras, type: this.names.Loading })
            return await new Promise((resolve) => {
                setTimeout(() => {
                    let result = null
                    log()
                    try {
                        if (fail) {
                            const e = mockedDataOrError || new Error()
                            result = this.errorHandler ? this.errorHandler(e, dispatch) : { ...e, failed: true }
                            const showToast =
                                !!this.toastableErrorCodes &&
                                (this.toastableErrorCodes.size === 0 || this.toastableErrorCodes?.has(e.errorCode || ''))
                            dispatch({ ...this.actionExtras, type: this.names.Failure, error: e })
                            dispatch({
                                type: Actions.Errors.ReportApiError,
                                error: e,
                                errorDisplay: showToast ? 'toast' : 'none',
                                subject: this.names.Failure
                            })
                        } else {
                            const data = mockedDataOrError || null
                            result = this.successHandler ? this.successHandler(data, dispatch) : data
                            dispatch({ ...this.actionExtras, type: this.names.Success, data })
                        }
                    } catch (e) {
                        console.error('Simulated Redux event failed:', e)
                    }
                    resolve(result)
                }, 3000)
            })
        }
    }
}

export const ReduxApiGet = <TResult>(url: string | string[], names: ActionNames, headers?: any): ReduxApiRequest<TResult> => new ReduxApiRequest('GET', url, names, headers)

export const ReduxApiDelete = (url: string | string[], names: ActionNames, headers?: any) =>
    new ReduxApiRequest('DELETE', url, names, headers)

export const ReduxApiPost = (url: string | string[], names: ActionNames, body?: any, headers?: any) =>
    new ReduxApiRequest('POST', url, names, body, headers)

export const ReduxApiPut = (url: string | string[], names: ActionNames, body?: any, headers?: any) =>
    new ReduxApiRequest('PUT', url, names, body, headers)
