// @ts-strict-ignore
import { GetConfig } from 'phoenix/constants'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GetAllFeatureFlagsAction } from '../redux/actions/FeatureFlagActions'
import { useSnexStore } from './UseSnexStore'

export const useFeatureFlag = (flag: string, ignoreUserFlags = false): boolean | null => {
    const dispatch = useDispatch()
    useEffect(() => { dispatch(GetAllFeatureFlagsAction()) }, [])
    const systemFlag = useSnexStore(s => s.featureFlags.byId[flag])

    const userFlags = useSnexStore(s => s.user.myInfo.data?.featureFlags)

    return useMemo(() => {
        if (ignoreUserFlags) return systemFlag?.enabled
        return systemFlag?.enabled || userFlags?.includes(flag)
    }, [ systemFlag, userFlags, ignoreUserFlags ])

}

// Non-reactive way to get a feature flag
export const GetFeatureFlag = (flag: string, ignoreUserFlags = false): boolean | null => {
    const store = GetConfig().Store.getState()
    const systemFlag = store.featureFlags?.byId?.[flag]
    const userFlags = store.user.myInfo.data?.featureFlags

    if (ignoreUserFlags) return systemFlag?.enabled
    return systemFlag?.enabled || userFlags?.includes(flag)
}
