// @ts-strict-ignore
import { Ts } from '../assets/lang/T'
import { SnexDocumentType } from '../redux/models'

export const DocumentTypeV2Ids = {
    accountNotices: 'AccountNotices',
    accountStatements: 'AccountStatements',
    checks: 'CmaChecks',
    tradeConfirmations: 'CorrespondentConfirms',
    marginRequirements: 'CustomerMarginRequirements',
    ifsNewAccts: 'IfsNewAccounts',
    intlPublicForms: 'IntlPublicForms',
    taxDocuments: 'TaxDocuments',
    monthlyErrorStatement: 'MonthlyErrorStatement',
    moneyOptionReport: 'MoneyOptionReport',
    detailedVariationAnalysis: 'DetailedVariationAnalysis',
    memoStatement: 'MemoStatement',
    monthlyDiscretionaryAccounts: 'MonthlyDiscretionaryAccounts',
    dailyPersonalAccounts: 'DailyPersonalAccounts',
    dailyErrorStatements: 'DailyErrorStatement',
    dailyDiscretionaryAccounts: 'DailyDiscretionaryAccounts',
    monthlyPersonalAccounts: 'MonthlyPersonalAccounts',
    T5008: 'T5008',
    monthlyStatementExcel: 'MonthlyStatementExcel',
    annualStatement: 'AnnualStatement',
    quarterlyStatement: 'QuarterlyStatement',
    monthlyStatement: 'MonthlyStatement',
    dailyStatement: 'DailyStatement',
    fullyPaidEarnings: 'FullyPaidEarnings',
    fullyPaidLendingConfirms: 'FullyPaidLendingConfirms',
    fullyPaidLendingPositions: 'FullyPaidLendingPositions'
}

export const GetDocumentTypeNameV2ById = (id: string) => {
    const text = Ts(s => s.documentsScreen.docTypes)
    const typeKey = Object.keys(DocumentTypeV2Ids).find(key => DocumentTypeV2Ids[key] === id)
    return typeKey ? text[typeKey] : id
}

/** @deprecated Please use DocumentTypeV2Ids **/
export const DocumentTypeIds = {
    confirms: 'c91c9fa2-95fb-e711-8105-005056b90b30',
    checks: 'b27d75a0-552c-e811-810a-005056b90b30',
    statements: '1d63c79f-592c-e811-810a-005056b90b30',
    taxes: '82bc14af-51fa-e811-8114-005056b90b30',
    misc: '2a3ad870-f7b5-e811-8113-005056b90b30',
    accountNotices: '7F70D3A9-1273-EC11-9F4B-005056BBB8F1'
}

/** @deprecated Please use GetDocumentTypeNameV2ById  */
export const DocumentTypeNames = (): object => {
    const text = Ts(s => s.documentsScreen.docTypes)
    return {
        'Correspondent Confirms': text.tradeConfirmations,
        'CMA Checks': text.checks,
        'IFS NEWACCTS': text.ifsNewAccts,
        'Tax Documents': text.taxDocuments,
        'Account Notices': text.accountNotices
    }
}

/** @deprecated Please use V2 document types models*/
export const DocumentTypesStaticList: SnexDocumentType[] = [
    {
        id: '1d63c79f-592c-e811-810a-005056b90b30',
        name: 'Account Statements',
        department: 'O1',
        description: 'Account Statements',
        categories: [
            'Account Information'
        ]
    },
    {
        id: 'b27d75a0-552c-e811-810a-005056b90b30',
        name: 'CMA Checks',
        department: 'O1',
        description: 'CMA Checks',
        categories: [
            'Operations'
        ]
    },
    {
        id: 'c91c9fa2-95fb-e711-8105-005056b90b30',
        name: 'Correspondent Confirms',
        department: 'O1',
        description: 'Correspondent Confirms',
        categories: [
            'Account Information',
            'Operations'
        ]
    },
    {
        id: '2a3ad870-f7b5-e811-8113-005056b90b30',
        name: 'IFS NEWACCTS',
        department: 'O1',
        description: 'IFS NEWACCTS',
        categories: [
            'New Accounts'
        ]
    },
    {
        id: '82bc14af-51fa-e811-8114-005056b90b30',
        name: 'Tax Documents',
        department: '1099',
        description: 'Includes 1099s, 5498s, and 1042s.',
        categories: null
    },
    {
        id: '7f70d3a9-1273-ec11-9f4b-005056bbb8f1',
        name: 'Account Notices',
        department: 'O1',
        description: 'Account Notices',
        categories: [
            'Account Information'
        ]
    }
]
