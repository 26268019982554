import React from 'react';
import { useColors } from 'hooks/UseColors';

interface TradeTicketSectionProps {
    children: any,
    noBorder?: boolean,
    padBottom?: boolean,
    padTop?: boolean,
    style?: React.CSSProperties
}

export const TradeTicketSection = React.memo((props: TradeTicketSectionProps) => {
    const colors = useColors();
    return (
        <div style={{ padding: '0 20px', borderBottom: props.noBorder ? undefined : `solid 1px ${colors.dividerColor}`,
            paddingTop: props.padTop ? 20 : undefined, paddingBottom: props.padBottom ? 20 : undefined, ...props.style }}>
            { props.children }
        </div>
    );
});