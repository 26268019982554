// @ts-strict-ignore
import { Checkbox, Typography } from '@mui/material';
import { CandleStickButton } from 'components/SecurityChartSection/CandleStickButton';
import { SnexButton } from 'components/SnexButton';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { StorageKeys } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import React, { useEffect, useMemo } from 'react';
import { Flex } from '..';
import { LineChartButton } from '../SecurityChartSection/LineChartButton';

export type ChartDisplayType = 'line' | 'candle';
interface CandleLineToggleControlsProps {
    onDisplayTypeChange: (newType: ChartDisplayType) => void;
    onToggleVolume?: (newValue: boolean) => void;
    allowVolumeToggle?: boolean;
}

export const CandleLineToggleControls = React.memo((props: CandleLineToggleControlsProps) => {
    const { onDisplayTypeChange, onToggleVolume, allowVolumeToggle } = props;
    const [appWindowSize] = useAppWindowSize();
    const text = useText((s) => s.misc);
    const [displayType, setDisplayType] = useLocalStorage<ChartDisplayType>(StorageKeys.BigChartDisplayType, 'line');
    const [showVolume, setShowVolume] = useLocalStorage<boolean>(StorageKeys.BigChartShowVolume, true);

    useEffect(() => onDisplayTypeChange(displayType), [displayType, onDisplayTypeChange]);
    useEffect(() => onToggleVolume(showVolume), [onToggleVolume, showVolume]);

    const nextDisplayType = displayType === 'candle' ? 'line' : 'candle';

    const size = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return 17;
            default:
                return 25;
        }
    }, [appWindowSize]);

    return (
        <Flex id='tour-01-candle-chart-button' align='flex-end'>
            <div className='candle-line-toggle'>
                <SnexButton eventTag={`Chart type switched to ${nextDisplayType}`} flavor='secondary-transparent' onClick={() => setDisplayType(nextDisplayType)}>
                    {displayType === 'candle' ? <LineChartButton height={size} /> : <CandleStickButton height={size} />}
                </SnexButton>
            </div>
            {displayType === 'candle' && allowVolumeToggle && (
                <div className='chart-toggle-checkbox' onClick={() => setShowVolume(!showVolume)}>
                    <Typography variant='subtitle2'>{text.showVolume}</Typography>
                    <Checkbox checked={showVolume} size='small' style={{ padding: 0, marginLeft: 5 }} />
                </div>
            )}
        </Flex>
    );
});
