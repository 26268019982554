import { Switch, Typography, switchClasses } from '@mui/material';
import { NumberInputSplit } from 'components/TradeTicket/Shared/TradeFormComponents';
import { AlertConditionType as AlertCondition, GetAlertConditionOperators } from 'phoenix/constants/Alerts';
import { useSecurityTypeFromStore } from 'phoenix/hooks/UseSecurityType';
import { useText } from 'phoenix/hooks/UseText';
import { DropdownOption } from 'phoenix/models/DropdownOptions';
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from '../..';
import { DropdownComponent } from '../../DropdownComponent';
import { SnexButton } from '../../SnexButton/SnexButton';
import { SnexSegmentedControl } from '../../SnexSegmentedControl';
import { PaddedSection } from '../Shared/PaddedSection';
import { Loop } from '@mui/icons-material';

export interface CreateConditionFormProps {
    onAdd: (condition: AlertCondition) => void;
    symbol?: string;
    isUpdate?: boolean;
    repeatDaily?: boolean;
    monitoredField?: string;
    operatorSymbol?: string;
    initialValue?: number;
    onRepeatDailyChange: (repeatDaily: boolean) => void;
}

export const CreateConditionForm = (props: CreateConditionFormProps): ReactElement => {
    const text = useText((t) => t);
    const alertConditionOperators = GetAlertConditionOperators(text.premiumModal.alerts);
    const [isUpdate] = useState(props.isUpdate ?? false);
    const alertConditionOperatorIdx = isUpdate ? alertConditionOperators.findIndex((op) => op.value === props.operatorSymbol) : 0;
    const [operator, setOperator] = useState<DropdownOption>(alertConditionOperators[alertConditionOperatorIdx] as DropdownOption);
    const [value, setValue] = useState<number | null>(isUpdate ? props.initialValue ?? null : null);
    const [key, setKey] = useState(0);
    const fieldOptions = useMemo(() => {
        return [
            { label: text.portfolioScreen.sidebar.price, value: 'Last' },
            { label: text.alerts.createAlertSheet.change, value: 'PercentChangeFromPreviousClose' }
        ];
    }, [text]);

    const fieldMapping: { [key: string]: number } = {
        Last: 0,
        changePercent: 1
    };

    const initialFieldIndex = useMemo(() => {
        if (isUpdate && props.monitoredField) {
            return fieldMapping[props.monitoredField] !== undefined ? fieldMapping[props.monitoredField] : 0;
        }
        return 0;
    }, [isUpdate, props.monitoredField, fieldMapping]);

    const [field, setField] = useState<{ label: string; value: string } | undefined>(
        isUpdate && props.monitoredField && fieldMapping[props.monitoredField] !== undefined ? fieldOptions[fieldMapping[props.monitoredField]] : fieldOptions[0]
    );

    const repeatDailyOptions = useMemo(() => {
        return [
            { label: text.general.oneTime, value: 'One-time' },
            { label: text.general.daily, value: 'Daily' }
        ];
    }, [text]);

    const [repeatDaily, setRepeatDaily] = useState<{ label: string; value: string } | undefined>(props.repeatDaily ? repeatDailyOptions[1] : repeatDailyOptions[0]);

    const handleRepeatDailyToggle = () => {
        setRepeatDaily((prevRepeatDaily) => {
            const newRepeatDaily = prevRepeatDaily === repeatDailyOptions[0] ? repeatDailyOptions[1] : repeatDailyOptions[0];
            props.onRepeatDailyChange(newRepeatDaily === repeatDailyOptions[1]);
            return newRepeatDaily;
        });
    };

    const clearInputs = useCallback(() => {
        setOperator({} as DropdownOption);
        setValue(null);
        setKey(key + 1);
    }, [key]);

    const handleAdd = useCallback(() => {
        const condition: AlertCondition = {
            label: field?.label || '',
            name: field?.value || '',
            operator: (operator?.value as string) || '',
            value: `${value}`
        };

        props.onAdd(condition);
        clearInputs();
    }, [clearInputs, field?.label, field?.value, operator?.value, props, value]);

    useEffect(() => {
        return () => {
            clearInputs();
        };
    }, [clearInputs]);

    const InputWrapper = useCallback(({ children }: { children: ReactNode }) => <div style={{ width: '100%', margin: '10px 0px' }}>{children}</div>, []);

    const canSubmit = useMemo(() => operator && value && props.symbol, [operator, value, props.symbol]);

    const getButton = (label: string, alertAllowed: boolean) => (
        <SnexButton
            disabled={isUpdate ? false : !canSubmit || !alertAllowed}
            flavor={!alertAllowed ? 'not-allowed' : 'submit'}
            style={{ margin: '20px 0px' }}
            onClick={!alertAllowed ? () => undefined : handleAdd}
        >
            {label}
        </SnexButton>
    );

    const AlertButton = () => {
        const type = useSecurityTypeFromStore(props.symbol || '');
        switch (type) {
            case 'mutual-fund':
                return getButton(text.alerts.mutualFundsUnavailable, false);
            case 'future':
                return getButton(text.alerts.futuresUnavailable, false);
            default:
                return isUpdate ? getButton(text.alerts.updateAlert, true) : getButton(text.alerts.saveAlert, true);
        }
    };

    return (
        <Flex center column style={{ width: '100%', marginTop: 20, flex: 1 }}>
            <PaddedSection flex>
                <Flex column style={{ width: '100%', flex: 1 }}>
                    <Flex center column style={{ width: '100%', flex: 1 }}>
                        <Flex align='center' justify='flex-start' style={{ width: '100%', marginBottom: 15 }}>
                            <Typography variant='h6'>{text.alerts.addCondition}</Typography>
                        </Flex>
                        <Flex column style={{ width: '100%' }}>
                            <Flex align='center'>
                                <div style={{ marginRight: 100 }}>
                                    <InputWrapper>
                                        <SnexSegmentedControl
                                            segments={fieldOptions}
                                            defaultIndex={initialFieldIndex}
                                            onSelect={(v) => setField(fieldOptions.find((o) => o.value === v))}
                                            width='fit-content'
                                        />
                                    </InputWrapper>
                                </div>
                                <Loop style={{ marginRight: 4 }} />
                                <div style={{ marginRight: 6, whiteSpace: 'nowrap', flexShrink: 0 }}>
                                    <Typography variant='h6'>{text.general.repeatDaily}</Typography>
                                </div>
                                <Switch classes={switchClasses} checked={repeatDaily === repeatDailyOptions[1]} size='medium' onChange={handleRepeatDailyToggle} />
                            </Flex>
                            <InputWrapper>
                                <DropdownComponent
                                    implementation='react-select'
                                    options={alertConditionOperators}
                                    placeholder='Operator'
                                    value={operator || ''}
                                    onChange={setOperator}
                                />
                            </InputWrapper>
                            <InputWrapper>
                                <NumberInputSplit
                                    arrows
                                    decimalPlaces={2}
                                    label='Target Value'
                                    onValueChange={(value: number) => setValue(value)}
                                    initialValue={isUpdate ? props.initialValue : undefined}
                                />
                            </InputWrapper>
                        </Flex>
                    </Flex>
                    <AlertButton />
                </Flex>
            </PaddedSection>
        </Flex>
    );
};
