// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models';
import { UniqueBy } from '../../util';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { PositionsSummary, SecuritiesState, SecurityLogo } from '../models';

const permitted = GroupNameChecker([Actions.Securities, Actions.Positions, Actions.Users]);
export const SecuritiesReducer = (state: SecuritiesState = new SecuritiesState(), action: ReduxAction): SecuritiesState => {
    if (!permitted(action)) return state;

    const {
        GetCompanyInfo,
        GetQuote,
        GetNews,
        GetRatings,
        GetEarnings,
        GetDividends,
        Logos,
        GetTopMovers,
        GetTopGainers,
        GetSectorPerformance,
        GetTopLosers,
        GetRiskFreeRate,
        GetFrontMonthTopMovers,
        GetUpcomingEarnings
    } = Actions.Securities;

    const withSymbolUpdate = (subState: string, update: (handle: ApiData<any>) => any, initialValue: any = null): SecuritiesState => {
        const existing = state.bySymbol[action.subject] ? state.bySymbol[action.subject][subState] || new ApiData<any>(initialValue) : new ApiData<any>(initialValue);

        return {
            ...state,
            bySymbol: {
                // TODO -- At some point, we'll want to trim this list down automatically so it doesn't get unmanageably large
                ...state.bySymbol,
                [action.subject]: {
                    ...state.bySymbol[action.subject],
                    [subState]: update(existing)
                }
            }
        };
    };
    // prettier-ignore
    switch (action.type) {
        // Used by Mobile for Selector Refactor
        case Actions.Securities.SetSelectedSymbol: return {...state, selectedSymbol: action?.data?.symbol};

        case Actions.Securities.GetMetadata.Loading: return withSymbolUpdate('metadata', u => u.startLoading(u.data))
        case Actions.Securities.GetMetadata.Success: return withSymbolUpdate('metadata', u => u.succeeded(action.data))
        case Actions.Securities.GetMetadata.Failure: return withSymbolUpdate('metadata', u => u.failed(action.error, true))

        case GetCompanyInfo.Loading: return withSymbolUpdate('companyInfo', u => u.startLoading(u.data))
        case GetCompanyInfo.Success: return withSymbolUpdate('companyInfo', u => u.succeededSpread(action.data))
        case GetCompanyInfo.Failure: return withSymbolUpdate('companyInfo', u => u.failed(action.error))

        case Actions.Securities.GetCompanyStats.Loading: return withSymbolUpdate('companyStats', u => u.startLoading(u.data))
        case Actions.Securities.GetCompanyStats.Success: return withSymbolUpdate('companyStats', u => u.succeeded(action.data))
        case Actions.Securities.GetCompanyStats.Failure: return withSymbolUpdate('companyStats', u => u.failed(action.error))

        case Actions.Securities.GetCompanyKeyStats.Loading: return withSymbolUpdate('companyKeyStats', u => u.startLoading(u.data))
        case Actions.Securities.GetCompanyKeyStats.Success: return withSymbolUpdate('companyKeyStats', u => u.succeeded(action.data))
        case Actions.Securities.GetCompanyKeyStats.Failure: return withSymbolUpdate('companyKeyStats', u => u.failed(action.error))

        case Actions.Securities.GetCryptoStats.Loading: return withSymbolUpdate('cryptoStats', u => u.startLoading(u.data))
        case Actions.Securities.GetCryptoStats.Success: return withSymbolUpdate('cryptoStats', u => u.succeeded(action.data))
        case Actions.Securities.GetCryptoStats.Failure: return withSymbolUpdate('cryptoStats', u => u.failed(action.error))

        case Actions.Securities.GetCryptoStatsForSymbol.Loading: return withSymbolUpdate('cryptoStatsForSymbol', u => u.startLoading(u.data))
        case Actions.Securities.GetCryptoStatsForSymbol.Success: return withSymbolUpdate('cryptoStatsForSymbol', u => u.succeeded(action.data))
        case Actions.Securities.GetCryptoStatsForSymbol.Failure: return withSymbolUpdate('cryptoStatsForSymbol', u => u.failed(action.error))

        case GetEarnings.Loading: return withSymbolUpdate('earnings', u => u.startLoading(u.data))
        case GetEarnings.Success: return withSymbolUpdate('earnings', u => u.succeeded(action.data))
        case GetEarnings.Failure: return withSymbolUpdate('earnings', u => u.failed(action.error))

        case GetUpcomingEarnings.Loading: return withSymbolUpdate('upcomingEarnings', u => u.startLoading(u.data))
        case GetUpcomingEarnings.Success: return withSymbolUpdate('upcomingEarnings', u => u.succeeded(action.data))
        case GetUpcomingEarnings.Failure: return withSymbolUpdate('upcomingEarnings', u => u.failed(action.error))

        case Actions.Securities.GetEarningsEstimates.Loading: return withSymbolUpdate('earningsEstimates', u => u.startLoading(u.data))
        case Actions.Securities.GetEarningsEstimates.Success: return withSymbolUpdate('earningsEstimates', u => u.succeeded(action.data))
        case Actions.Securities.GetEarningsEstimates.Failure: return withSymbolUpdate('earningsEstimates', u => u.failed(action.error))

        case GetDividends.Loading: return withSymbolUpdate('dividends', u => u.startLoading(u.data))
        case GetDividends.Success: return withSymbolUpdate('dividends', u => u.succeeded(action.data))
        case GetDividends.Failure: return withSymbolUpdate('dividends', u => u.failed(action.error))

        case GetQuote.Loading: return withSymbolUpdate('quote', u => u.startLoading(u.data))
        case GetQuote.Success: return withSymbolUpdate('quote', u => u.succeeded(action.data))
        case GetQuote.Failure: return withSymbolUpdate('quote', u => u.failed(action.error))

        case GetRatings.Loading: return withSymbolUpdate('analystTrends', u => u.startLoading(u.data))
        case GetRatings.Success: return withSymbolUpdate('analystTrends', u => u.succeeded(action.data))
        case GetRatings.Failure: return withSymbolUpdate('analystTrends', u => u.failed(action.error))

        case GetNews.Loading: return withSymbolUpdate('symbolNews', u => u.startLoading(u.data), [])
        case GetNews.Success: return withSymbolUpdate('symbolNews', u => u.succeeded(action.data), [])
        case GetNews.Failure: return withSymbolUpdate('symbolNews', u => u.failed(action.error), [])

        case GetRiskFreeRate.Loading: return { ...state, riskFreeRate: state.riskFreeRate.startLoading() }
        case GetRiskFreeRate.Success: return { ...state, riskFreeRate: state.riskFreeRate.succeeded(action.data) }
        case GetRiskFreeRate.Failure: return { ...state, riskFreeRate: state.riskFreeRate.failed(action.error) }

            // When the user changes their language we need to clear the news so it's in the right language
        case Actions.Users.UpdateMyPrefs.Loading: return withSymbolUpdate('symbolNews', u => u.reset(), [])

        case Logos.Loading: return { ...state, logos: { ...state.logos, [action.subject]: state.logos[action.subject]?.startLoading() || new ApiData<SecurityLogo>().startLoading() } }
        case Logos.Success: return { ...state, logos: { ...state.logos, [action.subject]: state.logos[action.subject]?.succeeded(action.data) || new ApiData<SecurityLogo>().succeeded(action.data) } }
        case Logos.Failure: return { ...state, logos: { ...state.logos, [action.subject]: state.logos[action.subject]?.failed(action.error) || new ApiData<SecurityLogo>().failed(action.error) } }

            // We also get logos when the positions call comes back, fold those into the store
        case Actions.Positions.GetAll.Success: {
            // @ts-ignore, good job TS :) Maps symbols to logos
            const bySymbolLookup: { [key: string]: ApiData<SecurityLogo> } = UniqueBy((<PositionsSummary[]>action.data).flatMap(s => s.positions)
                .filter(s => !!s.symbol), x => x.symbol)
                .reduce((lookup, next) => ({ ...lookup, [next.symbol]: new ApiData<SecurityLogo>().succeeded({ url: next.logoUrl }) }), {})
            return { ...state, logos: { ...state.logos, ...bySymbolLookup } }
        }

            // Same story with watchlists
        case Actions.Watchlists.GetAll.Success: {
            // @ts-ignore
            const bySymbolLookupWl: { [key: string]: ApiData<{ url: string }> } = UniqueBy((<Watchlist[]>action.data).flatMap(s => s.securities)
                .filter(s => !!s.symbol), x => x.symbol)
                .reduce((lookup, next) => ({ ...lookup, [next.symbol]: new ApiData().succeeded({ url: next.logoUrl }) }), {})
            return { ...state, logos: { ...state.logos, ...bySymbolLookupWl } }
        }

        case GetTopMovers.Loading: return { ...state, top: { ...state.top, equities: { ...state.top.equities, movers: state.top.equities.movers.startLoading(state.top.equities.movers.data) } } }
        case GetTopMovers.Success: return { ...state, top: { ...state.top, equities: { ...state.top.equities, movers: state.top.equities.movers.succeeded(action.data) } } }
        case GetTopMovers.Failure: return { ...state, top: { ...state.top, equities: { ...state.top.equities, movers: state.top.equities.movers.failed(action.error, true) } } }

        case GetTopGainers.Loading: return { ...state, top: { ...state.top, equities: { ...state.top.equities, gainers: state.top.equities.gainers.startLoading(state.top.equities.gainers.data) } } }
        case GetTopGainers.Success: return { ...state, top: { ...state.top, equities: { ...state.top.equities, gainers: state.top.equities.gainers.succeeded(action.data) } } }
        case GetTopGainers.Failure: return { ...state, top: { ...state.top, equities: { ...state.top.equities, gainers: state.top.equities.gainers.failed(action.error, true) } } }

        case GetTopLosers.Loading: return { ...state, top: { ...state.top, equities: { ...state.top.equities, losers: state.top.equities.losers.startLoading(state.top.equities.losers.data) } } }
        case GetTopLosers.Success: return { ...state, top: { ...state.top, equities: { ...state.top.equities, losers: state.top.equities.losers.succeeded(action.data) } } }
        case GetTopLosers.Failure: return { ...state, top: { ...state.top, equities: { ...state.top.equities, losers: state.top.equities.losers.failed(action.error, true) } } }

        case GetFrontMonthTopMovers.Loading: return { ...state, top: { ...state.top, futures: { ...state.top.futures, movers: state.top.futures.movers.startLoading(state.top.futures.movers.data) } } }
        case GetFrontMonthTopMovers.Success: return { ...state, top: { ...state.top, futures: { ...state.top.futures, movers: state.top.futures.movers.succeeded(action.data) } } }
        case GetFrontMonthTopMovers.Failure: return { ...state, top: { ...state.top, futures: { ...state.top.futures, movers: state.top.futures.movers.failed(action.error, true) } } }

        case Actions.Securities.GetRelatedSymbols.Loading: return withSymbolUpdate('relatedSymbols', u => u.startLoading(u.data))
        case Actions.Securities.GetRelatedSymbols.Success: return withSymbolUpdate('relatedSymbols', u => u.succeeded(action.data))
        case Actions.Securities.GetRelatedSymbols.Failure: return withSymbolUpdate('relatedSymbols', u => u.failed(action.error))

        case Actions.Securities.GetRelatedMonths.Loading: return withSymbolUpdate('relatedMonths', u => u.startLoading(u.data))
        case Actions.Securities.GetRelatedMonths.Success: return withSymbolUpdate('relatedMonths', u => u.succeeded(action.data))
        case Actions.Securities.GetRelatedMonths.Failure: return withSymbolUpdate('relatedMonths', u => u.failed(action.error))

        case Actions.Securities.GetFuturesContracts.Loading: return withSymbolUpdate('contracts', u => u.startLoading(u.data))
        case Actions.Securities.GetFuturesContracts.Success: return withSymbolUpdate('contracts', u => u.succeeded(action.data))
        case Actions.Securities.GetFuturesContracts.Failure: return withSymbolUpdate('contracts', u => u.failed(action.error))

        case Actions.Securities.GetFuturesTimeSpreads.Loading: return withSymbolUpdate('timeSpreads', u => u.startLoading(u.data))
        case Actions.Securities.GetFuturesTimeSpreads.Success: return withSymbolUpdate('timeSpreads', u => u.succeeded(action.data))
        case Actions.Securities.GetFuturesTimeSpreads.Failure: return withSymbolUpdate('timeSpreads', u => u.failed(action.error))

        case Actions.Securities.GetRevenueEstimates.Loading: return withSymbolUpdate('revenueEstimates', u => u.startLoading(u.data))
        case Actions.Securities.GetRevenueEstimates.Success: return withSymbolUpdate('revenueEstimates', u => u.succeeded(action.data))
        case Actions.Securities.GetRevenueEstimates.Failure: return withSymbolUpdate('revenueEstimates', u => u.failed(action.error, true))

        case Actions.Securities.GetStockSplits.Loading: return withSymbolUpdate('splits', u => u.startLoading(u.data))
        case Actions.Securities.GetStockSplits.Success: return withSymbolUpdate('splits', u => u.succeeded(action.data))
        case Actions.Securities.GetStockSplits.Failure: return withSymbolUpdate('splits', u => u.failed(action.error, true))

        case GetSectorPerformance.Loading: return { ...state, sectors: { ...state.sectors, performance: state.sectors.performance.startLoading(state.sectors.performance.data) } }
        case GetSectorPerformance.Success: return { ...state, sectors: { ...state.sectors, performance: state.sectors.performance.succeeded(action.data) } }
        case GetSectorPerformance.Failure: return { ...state, sectors: { ...state.sectors, performance: state.sectors.performance.failed(action.error, true) } }

        default: return state
    }
};
