import { Flex } from "components"
import { SecurityEarningsPresentation } from "components/SecurityEarnings/SecurityEarnings"
import { useColors } from "hooks/UseColors"
import { useText } from "phoenix/hooks/UseText"
import { MobileBlue, MobileLightBlue } from "phoenix/util"
import React from "react"

export const NeilDebugScreen = (): JSX.Element => {

const colors = useColors()
const text = useText((s) => s.securityScreen.earningsEstimates)

const chartdataXAxis = ['Q1 2024', 'Q2 2024', 'Q3 2024']

  return (
    <Flex column style={{maxWidth: 800, margin: 32}}>
      <h1>Missing Actual</h1>
<SecurityEarningsPresentation 
    estimatedData={[
      .50, .75, 1.00
    ]}
    actualData={undefined}
    chartBackgroundColor={colors.mainBackgroundColor}
    legendTextColor={colors.fadedTextColor}
    legendTextHoverColor={colors.generalTextColor}
    actualBarColor={MobileBlue}
    estimatedBarColor={MobileLightBlue}
    actualLegendLabel={`${text.actual} EPS (USD)`}
    estimatedLegendLabel={`${text.estimated} EPS (USD)`}
    dataLabelColor={colors.generalTextColor}
    xAxisData={chartdataXAxis}
    chartGridLineColor={colors.inputBorderColor}
    tooltipBackgroundColor={colors.cardBackgroundColor}
/>
      <h1>All Positive</h1>
    <SecurityEarningsPresentation 
    estimatedData={[
      .50, .75, 1.00
    ]}
    actualData={[
      .25, .5, 1.25
    ]}
    chartBackgroundColor={colors.mainBackgroundColor}
    legendTextColor={colors.fadedTextColor}
    legendTextHoverColor={colors.generalTextColor}
    actualBarColor={MobileBlue}
    estimatedBarColor={MobileLightBlue}
    actualLegendLabel={`${text.actual} EPS (USD)`}
    estimatedLegendLabel={`${text.estimated} EPS (USD)`}
    dataLabelColor={colors.generalTextColor}
    xAxisData={chartdataXAxis}
    chartGridLineColor={colors.inputBorderColor}
    tooltipBackgroundColor={colors.cardBackgroundColor}
/>
<h1>All Negative</h1>
    <SecurityEarningsPresentation 
    estimatedData={[
      -.50, -.75, -1.00
    ]}
    actualData={[
      -.25, -.5, -1.25
    ]}
    chartBackgroundColor={colors.mainBackgroundColor}
    legendTextColor={colors.fadedTextColor}
    legendTextHoverColor={colors.generalTextColor}
    actualBarColor={MobileBlue}
    estimatedBarColor={MobileLightBlue}
    actualLegendLabel={`${text.actual} EPS (USD)`}
    estimatedLegendLabel={`${text.estimated} EPS (USD)`}
    dataLabelColor={colors.generalTextColor}
    xAxisData={chartdataXAxis}
    chartGridLineColor={colors.inputBorderColor}
    tooltipBackgroundColor={colors.cardBackgroundColor}
/>
<h1>Mix</h1>
    <SecurityEarningsPresentation 
    estimatedData={[
      .50, .75, 1.00
    ]}
    actualData={[
      -.50, .25, .5
    ]}
    chartBackgroundColor={colors.mainBackgroundColor}
    legendTextColor={colors.fadedTextColor}
    legendTextHoverColor={colors.generalTextColor}
    actualBarColor={MobileBlue}
    estimatedBarColor={MobileLightBlue}
    actualLegendLabel={`${text.actual} EPS (USD)`}
    estimatedLegendLabel={`${text.estimated} EPS (USD)`}
    dataLabelColor={colors.generalTextColor}
    xAxisData={chartdataXAxis}
    chartGridLineColor={colors.inputBorderColor}
    tooltipBackgroundColor={colors.cardBackgroundColor}
/>

</Flex>
    )
   
}









