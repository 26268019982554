// @ts-strict-ignore
import { T } from 'phoenix/assets/lang/T';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Flex } from '../..';
import { TradeInputHeader } from '../Headers/TradeInputHeader';
import { ImmediateExecutionAdvisory } from '../Shared/ImmediateExecutionAdvisory';

import { useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { TradeTicketAccountDropdown } from 'components/AccountDropdown/TradeTicketAccountDropdown';
import { QuantityInputSplit } from 'components/InputSplit/QuantityInputSplit';
import { OrderTypes } from 'phoenix/constants/Trade';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useQuantityHeld } from 'phoenix/hooks/UseQuantityHeld';
import { useText } from 'phoenix/hooks/UseText';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { FormatNumber, IsWeekend } from 'phoenix/util';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { GetTradeTicketQuote } from '../Shared/helpers';
import {
    AlgoControl,
    BuyingPowerDisplayV2,
    ExtendedHoursExecutionCheckbox,
    MarketPriceDisplay,
    POVControl,
    PriceInputSplit,
    TimeInForceSplit,
    TotalCostDisplay
} from '../Shared/TradeFormComponents';
import { TradeInputValidationButton } from '../Shared/TradeInputValidationButton';
import { TradeTicketSection } from '../Shared/TradeTicketSection';
import { EquityTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';

export const EquityTradeInputPage = (): ReactElement => {
    const viewModel = useTradeTicketViewModel<EquityTradeTicketViewModel>();
    const {
        awaitingConfirmation,
        displayOrderType,
        initialLimitPrice,
        initialStopPrice,
        initialQuantity,
        isGtx,
        limitPrice,
        modifyingOrder,
        orderType,
        setViewModel,
        stopPrice,
        symbol,
        timeInForce,
        tradeAction
    } = viewModel;
    // Use quantity input key to force a remount of the quantity input component
    // such as when using "Sell All" button to override user input
    const [quantityInputKey, setQuantityInputKey] = useState<number>(0);
    const isOnClose = ['loc', 'moc'].includes(displayOrderType);
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata);
    const quote = GetTradeTicketQuote(symbol);
    const text = useText((t) => t);
    const logo = useSnexStore((s) => s.logos.bySymbol[symbol]);
    const userInfo = useSnexStore((s) => s.user?.myInfo.data);
    const hasAdvancedRouting = useMemo(() => userInfo?.hasAdvancedRouting, [userInfo]);
    const [currentMarketSegment] = useMarketTimeSegmentV2();
    const selectedAccountNumber = useSelectedAccountByAssetFamily(EquitiesAssetClass.family);
    const showNotes = userInfo?.role === 'admin';
    const secId = QualifiedSecurityId.Parse(symbol);
    const held = useQuantityHeld(secId, selectedAccountNumber);
    const quantityFormatter = (number: number) => FormatNumber.toCommas(number, true, 0);
    const priceFormatter = (price: number) => EquitiesAssetClass?.formatPrice(price, meta?.data);
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const showExtendedHoursCheckbox = orderType === 'limit' && !isOnClose && !IsWeekend() && ['premarket', 'postmarket'].includes(marketTimeSegment);
    const showAlgo = hasAdvancedRouting && !isOnClose && currentMarketSegment === 'open' && !(modifyingOrder && isGtx); // Do not display algo fields when modifyingOrdering a gtx pre/post order
    const tifsDisabled = marketTimeSegment === 'postmarket' && orderType.includes('stop'); // stop/stop-limit orders can only be placed as GTC in post-market

    const handleFieldUpdate = (name: keyof EquityTradeTicketViewModel, value: EquityTradeTicketViewModel[keyof EquityTradeTicketViewModel]) => {
        const newState = { [name]: value };

        // If selling, and changing qty to exceed currently held position, reset TIF; user must manually reselect "Day"
        if (name === 'quantity' && tradeAction === 'Sell' && timeInForce !== 'Day' && value > held) {
            newState.timeInForce = null;
        }

        // GTC orders cannot be GTX
        if (name === 'timeInForce' && value === 'GTC' && isGtx) newState.isGtx = false;

        setViewModel(newState);
    };

    const handleAccountSelect = useCallback(() => setViewModel({ awaitingConfirmation: false }), [setViewModel]);

    return (
        <Flex column>
            {awaitingConfirmation ? (
                <TradeCancelHeader
                    logo={logo}
                    text={text.tradeTicket.input}
                    title={symbol}
                    onCancel={() => setViewModel({ awaitingConfirmation: false, validateResponse: null })}
                />
            ) : (
                <TradeInputHeader canSellIfNotHeld={true} />
            )}
            <TradeTicketSection noBorder style={{ paddingTop: 20, paddingBottom: 10 }}>
                <TradeTicketAccountDropdown
                    assetFamily={EquitiesAssetClass.family}
                    balanceType={tradeAction?.toLowerCase()}
                    defaultToFirst={'if-only-one'}
                    disabled={modifyingOrder}
                    isByAssetClass
                    onSelect={handleAccountSelect}
                    skipInitialSelect={modifyingOrder || !!selectedAccountNumber}
                    style={{ marginBottom: 15 }}
                    symbol={symbol}
                />
                {['stop', 'stoplimit'].includes(orderType) && (
                    <PriceInputSplit
                        formatter={EquitiesAssetClass.formatPrice}
                        formatOptions={EquitiesAssetClass.getPriceFormatInfo()?.moneyFormatOptions}
                        initialValue={initialStopPrice}
                        label={text.tradeTicket.input.stopPrice}
                        onValueChange={(v) => handleFieldUpdate('stopPrice', v)}
                    />
                )}
                {['limit', 'stoplimit'].includes(orderType) && (
                    <PriceInputSplit
                        formatter={EquitiesAssetClass.formatPrice}
                        formatOptions={EquitiesAssetClass.getPriceFormatInfo()?.moneyFormatOptions}
                        initialValue={initialLimitPrice} // #92037 - explicitly changed by this ticket
                        label={orderType === 'limit' ? text.tradeTicket.input.limitPrice : text.tradeTicket.input.stopLimitPrice}
                        onValueChange={(v) => handleFieldUpdate('limitPrice', v)}
                    />
                )}
                <QuantityInputSplit
                    accountNumber={selectedAccountNumber}
                    arrows={true}
                    formatter={quantityFormatter}
                    initialValue={initialQuantity}
                    key={quantityInputKey}
                    label={T((t) => t.general.fundShares(0))}
                    liquidateOnSellAll={false}
                    onBuySellAll={() => setQuantityInputKey(quantityInputKey + 1)}
                    onValueChange={(v) => handleFieldUpdate('quantity', v)}
                    showPosition={true}
                    showSellAll={true}
                    step={1} // Used for shares only, 1 share per tick
                    symbol={symbol}
                    tradeAction={tradeAction}
                />

                {orderType !== 'market' && !isOnClose ? (
                    <TimeInForceSplit
                        disabled={tifsDisabled}
                        text={text.tradeTicket.input}
                        value={timeInForce}
                        onValueChange={(v) => handleFieldUpdate('timeInForce', v)}
                    />
                ) : null}
                <MarketPriceDisplay
                    ask={priceFormatter(quote?.ask)}
                    bid={priceFormatter(quote?.bid)}
                    loading={quote?.loading}
                    price={priceFormatter(quote?.price)}
                    text={text.tradeTicket.input}
                />
            </TradeTicketSection>
            {orderType !== OrderTypes.market && (
                <ImmediateExecutionAdvisory
                    action={tradeAction}
                    orderType={displayOrderType}
                    text={text.tradeTicket.input.advisories}
                    {...{ limitPrice, quote, stopPrice }}
                />
            )}
            <TradeTicketSection style={{ paddingBottom: 20 }}>
                <TotalCostDisplay />
                {showAlgo && <AlgoControl />}
                <POVControl isVisible={showAlgo && viewModel.algoStrategy === T((t) => t.tradeTicket.input.pov) && !showNotes} modifyingOrder={modifyingOrder} />
                {showExtendedHoursCheckbox && <ExtendedHoursExecutionCheckbox />}
                <TradeInputValidationButton />
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                {/* set action to buy to force buying power to show always on equity #108615 */}
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} meta={meta?.data} symbol={symbol} />
            </TradeTicketSection>
        </Flex>
    );
};
