// @ts-strict-ignore
import { IconButton } from '@mui/material';
import { Flex } from 'components/Flex';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const HorizScrollContainer = ({ showDebug, children, style }: { showDebug?: boolean; children: ReactNode; style?: React.CSSProperties }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);

    const logWidth = useCallback(
        (key: string, ref: React.MutableRefObject<HTMLDivElement>) => {
            if (!ref?.current || !showDebug) return;
            console.log({ [`${key}H`]: ref.current.offsetHeight, [`${key}W`]: ref.current.offsetWidth });
        },
        [showDebug]
    );

    const maxScrollPos = useCallback(() => {
        const diff = canvasRef?.current?.offsetWidth - containerRef?.current?.offsetWidth;
        return diff <= 0 ? 0 : diff;
    }, [containerRef, canvasRef]);

    const showLeftScrollButton = useCallback(() => {
        if (containerRef?.current?.offsetWidth === canvasRef?.current?.offsetWidth) return false;
        const scrollPosLeft = containerRef?.current?.scrollLeft;
        if (maxScrollPos() === 0) return false;
        if (scrollPosLeft === 0 || scrollPosLeft === 1) return false;
        return true;
    }, [maxScrollPos]);

    const showRightScrollButton = useCallback(() => {
        if (containerRef?.current?.offsetWidth === canvasRef?.current?.offsetWidth) return false;
        const scrollPosLeft = containerRef?.current?.scrollLeft;
        const maxPos = maxScrollPos();
        if (maxPos === 0) return false;
        if (scrollPosLeft >= maxPos - 3) return false;
        return true;
    }, [maxScrollPos]);

    const [showLeftButton, setShowLeftButton] = useState<boolean>(showLeftScrollButton());
    const [showRightButton, setShowRightButton] = useState<boolean>(showRightScrollButton());

    const doScroll = (direction: 'left' | 'right') => {
        const _containerRef = containerRef?.current;
        const scrollUnitPx = Math.floor(containerRef?.current?.offsetWidth / 2);
        const multiplier = direction === 'left' ? -1 : 1;
        let newLeftPos = _containerRef?.scrollLeft + scrollUnitPx * multiplier;
        if (newLeftPos > maxScrollPos()) newLeftPos = maxScrollPos();
        if (newLeftPos < 2) newLeftPos = 0;
        if (showDebug) console.log({ prevPos: containerRef?.current?.scrollLeft, newPos: newLeftPos, scrollUnitPx, multiplier, maxScrollPos: maxScrollPos() });
        _containerRef?.scroll({ left: newLeftPos, behavior: 'smooth' });
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    };

    const Scrollbar = ({ flavor }: { flavor: 'left' | 'right' }) => {
        if (!showLeftButton && !showRightButton) return null;
        if (flavor === 'left') {
            return (
                <Flex row style={{ width: 40, marginRight: 8 }} align='center' center>
                    {showLeftButton && (
                        <IconButton style={{ height: 40 }} onClick={() => (doScroll ? doScroll('left') : null)}>
                            <ArrowBackIosIcon style={{ paddingLeft: '4px' }} />
                        </IconButton>
                    )}
                </Flex>
            );
        }
        if (flavor === 'right') {
            return (
                <Flex row style={{ width: 40, marginLeft: 8 }} align='center' center>
                    {showRightButton && (
                        <IconButton style={{ height: 40 }} onClick={() => (doScroll ? doScroll('right') : null)}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    )}
                </Flex>
            );
        }
    };

    const handleWrapperResize = useCallback(() => {
        //logWidth('wrapper', wrapperRef)
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [logWidth, showLeftScrollButton, showRightScrollButton]);

    const handleContainerResize = useCallback(() => {
        //logWidth('container', containerRef)
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [logWidth, showLeftScrollButton, showRightScrollButton]);

    const handleCanvasResize = useCallback(() => {
        //logWidth('canvas', canvasRef)
        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [logWidth, showLeftScrollButton, showRightScrollButton]);

    const handleScroll = useCallback(() => {
        if (showDebug)
            console.log({
                top: containerRef?.current?.scrollTop,
                left: containerRef?.current?.scrollLeft,
                maxScrollPos: maxScrollPos(),
                showLeftScrollButton: showLeftScrollButton(),
                showRightScrollButton: showRightScrollButton()
            });

        setShowLeftButton(showLeftScrollButton());
        setShowRightButton(showRightScrollButton());
    }, [maxScrollPos, showDebug, showLeftScrollButton, showRightScrollButton]);

    useEffect(() => {
        const _containerRef = containerRef.current;
        const wrapperResizeObserver = new ResizeObserver(() => handleWrapperResize());
        const containerResizeObserver = new ResizeObserver(() => handleContainerResize());
        const canvasResizeObserver = new ResizeObserver(() => handleCanvasResize());
        _containerRef?.addEventListener('scroll', handleScroll);

        wrapperResizeObserver.observe(wrapperRef?.current);
        containerResizeObserver.observe(containerRef?.current);
        canvasResizeObserver.observe(canvasRef?.current);

        return () => {
            containerResizeObserver.disconnect();
            canvasResizeObserver.disconnect();
            _containerRef?.removeEventListener('scroll', handleScroll);
        };
    }, [handleContainerResize, handleCanvasResize, handleScroll, handleWrapperResize]);

    return (
        <Flex row fullWidth className='horiz-scroll-wrapper' ref={wrapperRef} style={{ overflow: 'hidden', ...style }}>
            <Scrollbar flavor='left' />
            <Flex row fullWidth className='horiz-scroll-container' ref={containerRef} style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
                <div className='horiz-scroll-canvas' ref={canvasRef}>
                    {children}
                </div>
            </Flex>
            <Scrollbar flavor='right' />
        </Flex>
    );
};
