// @ts-strict-ignore
import React, { useMemo } from 'react';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { Flex } from '../Flex';

interface SingleContentLayoutProps {
    children: any,
    width?: number,
    style?: React.CSSProperties,
    backgroundColor?: string
}

export const SingleContentLayout = React.memo((props: SingleContentLayoutProps) => {
    const [appWindowSize, _] = useAppWindowSize();
    const width = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet: return 900;
            case WindowSizes.small: return 1100;
            case WindowSizes.regular: return 1275;
            default: return 1400;
        }
    }, [appWindowSize])

    return (

        <Flex row justify='center' style={{ flex: 1, background: props.backgroundColor || null, marginTop: 30 }}>
            <div style={{ width: props.width || width, ...props.style }}>
                {props.children}
            </div>
        </Flex>

    );

});