// @ts-strict-ignore
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import style from './style.module.scss';
import { ListHeader, SubList, EditListModal, Flex, AddManyToListModal } from '..';
import uuid from 'uuid-random';
import { useDispatch } from 'react-redux';
import { GetWatchlistsAction } from 'phoenix/redux/actions/WatchlistsActions';
import { DeleteListModal } from '../Modals';
import { AddWatchlist } from '../AddWatchlist';
import { Skeleton, Collapse } from '@mui/material';

import { LazyLoadedSecurityCell } from '../SecurityCell/LazyLoadedSecurityCell';
import { T } from 'phoenix/assets/lang/T';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { WatchlistItemReorderDropZone } from '../SecurityCell/WatchlistCell/WatchlistItemReorderDropZone';
import { WatchlistReorderDropZone } from './WatchlistReorderDropZone';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { AddManyToListContent } from '../Modals/AddManyToListModal/AddManyToListContent';

interface WatchlistsProps {
    streamingNamespace?: string;
}

const WatchListComponent = (props: WatchlistsProps) => {
    const dispatch = useDispatch();
    const [addListOpen, setAddListOpen] = useState(false);
    const [editListOpen, setEditListOpen] = useState(false);
    const [deleteListOpen, setDeleteListOpen] = useState(false);
    const [activeWatchlistId, setActiveWatchlistId] = useState(null);
    const [listKey, setListKey] = useState(null);
    const [isAddToListOpen, setAddToListOpen] = useState(false);
    const watchlists = useSnexStore((s) => s.watchlists.all);
    const creating = useSnexStore((s) => s.watchlists.create);

    useEffect(() => {
        setListKey(uuid());
        dispatch(GetWatchlistsAction());
    }, [dispatch]);

    useEffect(() => {
        if (creating.success) setAddListOpen(false);
    }, [creating]);

    const handleShowDeleteListModal = (id: string) => {
        setActiveWatchlistId(id);
        setDeleteListOpen(true);
    };

    const handleRenameListModal = (id: string) => {
        setActiveWatchlistId(id);
        setEditListOpen(true);
    };

    const handleAddToListModal = (id: string) => {
        setActiveWatchlistId(id);
        setAddToListOpen(true);
    };

    const generateSubListMenuOptions = (listId: string) => [
        { label: T((s) => s.portfolioScreen.sidebar.add), onClick: () => handleAddToListModal(listId) },
        { label: T((s) => s.portfolioScreen.sidebar.delete), onClick: () => handleShowDeleteListModal(listId) },
        { label: T((s) => s.portfolioScreen.sidebar.edit), onClick: () => handleRenameListModal(listId) }
    ];

    const isWatchlistLoading = watchlists.pristine && !watchlists.data.length;
    const noWatchLists = !watchlists.loading && !watchlists.data.length;
    const sortedWatchlists = useMemo(() => {
        return watchlists.data.sort((a, b) => a.sequence - b.sequence);
    }, [watchlists.data]);

    const Loading = () => (
        <div style={{ width: '100%', height: '78px', boxSizing: 'border-box', padding: '5px 10px' }}>
            <Flex style={{ width: '100%' }}>
                <Skeleton animation='wave' style={{ height: 20, width: 20, marginRight: 5 }} variant='circular' />
                <Flex column style={{ flex: 1 }}>
                    <Skeleton animation='wave' />
                    <Skeleton animation='wave' />
                    <Skeleton animation='wave' height={20} style={{ marginTop: 5 }} />
                </Flex>
            </Flex>
        </div>
    );

    return (
        <div id={'watchlists-section'} className={style.wrapper}>
            <ListHeader isAddOpen={addListOpen} title={T((s) => s.portfolioScreen.sidebar.watchlists)} onOpenToggle={setAddListOpen} />
            <Collapse in={addListOpen}>
                <div style={{ padding: '10px 17px', boxSizing: 'border-box' }}>
                    <AddWatchlist />
                </div>
            </Collapse>
            {isWatchlistLoading && (
                <>
                    <Loading />
                    <Loading />
                    <Loading />
                </>
            )}
            {noWatchLists && null}
            {sortedWatchlists.map((list, listIdx) => (
                <Fragment key={listIdx}>
                    <WatchlistReorderDropZone watchlists={sortedWatchlists} index={listIdx}>
                        <SubList id={list.id} key={list.id} moreMenuItems={generateSubListMenuOptions(list.id)} title={list.name}>
                            <>
                                {list.securities.length < 1 ? (
                                    <AddManyToListContent variant='sidebar' watchlistId={list.id} />
                                ) : (
                                    list.securities.map((d, key) => (
                                        <WatchlistItemReorderDropZone watchlistId={list.id} index={key} key={key}>
                                            <LazyLoadedSecurityCell
                                                hideAccountName
                                                index={key}
                                                key={`${d.symbol}-${key}-${listKey}`}
                                                streamingNamespace={props.streamingNamespace}
                                                symbol={d.symbol}
                                                variant='watchlist'
                                                watchlistId={list.id}
                                                onDelete={() => setAddListOpen(false)}
                                            />
                                        </WatchlistItemReorderDropZone>
                                    ))
                                )}

                                <WatchlistItemReorderDropZone watchlistId={list.id} index={list.securities.length}>
                                    <div style={{ height: 5 }}></div>
                                </WatchlistItemReorderDropZone>
                            </>
                        </SubList>
                    </WatchlistReorderDropZone>
                    <WatchlistReorderDropZone watchlists={sortedWatchlists} index={sortedWatchlists.length}>
                        <div style={{ height: 5 }}></div>
                    </WatchlistReorderDropZone>
                </Fragment>
            ))}
            <AddManyToListModal isOpen={isAddToListOpen} toggleModal={setAddToListOpen} watchlistId={activeWatchlistId} />
            <EditListModal isOpen={editListOpen} listId={activeWatchlistId} toggleModal={setEditListOpen} />
            <DeleteListModal isOpen={deleteListOpen} listId={activeWatchlistId} toggleModal={setDeleteListOpen} />
        </div>
    );
};

export const Watchlists = React.memo(TelemetryProvider(WatchListComponent, TelemetryCategories.watchlist));
