// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { GetDisablePrestage } from 'phoenix/util';

interface PrestageProps {
    delayShowMs?: number,
    delayAnimMs?: number,
    leadingSpace?: number,
    minHeight?: number,
    debugTitle?: string,
    children: any
}

const PrestageFunction = (props: PrestageProps) => {

    const [ elapsed, setElapsed ] = useState(false);
    const [ visible, setVisible ] = useState(false);
    const show = visible && elapsed;

    useEffect(() => {
        setTimeout(() => {
            setElapsed(true); 
        }, props.delayShowMs || 1);
    }, []);

    if (GetDisablePrestage()) return props.children;

    // Only update visibility once-- once the component is in, it's in
    const handleVisibilityChange = (value: boolean) => {
        if (!visible && value) setVisible(true);
    };

    return (
        <ReactVisibilitySensor partialVisibility onChange={ handleVisibilityChange }>
            <div style={{
                minHeight: !show ? props.minHeight || 250 : null,
                transitionProperty: 'opacity',
                transitionDuration: '100ms',
                transitionDelay: `${ props.delayAnimMs || 200 }ms`
            }}>
                {visible && props.children}
            </div>
        </ReactVisibilitySensor>
    );
};

export const Prestage = React.memo(PrestageFunction);