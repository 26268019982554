// @ts-strict-ignore
import { SnexError } from "phoenix/models/SnexError"
import { create } from "zustand"
import { SnexAxios } from "./AxiosHelpers"
import { Urls } from "phoenix/constants"
import { FundingSource } from "phoenix/redux/models/Funding/FundingSource"
import { FundingSourceManagementResult } from "phoenix/util/Funding/FundingSourceHelpers"
import { ErrorLogger } from "phoenix/util/ErrorLogger"

export type FundingSourcesStoreData = {

    isLoading: boolean
    isLoadingBalance: boolean // Decided to keep this impl simple for now, but theoretically we'd need to keep a Set<string> of all balances being loaded
    fundingSources?: FundingSource[]  
    errors?: SnexError[]

}

export type FundingSourcesStoreMethods = {

    load: (bypassCache?: boolean) => Promise<FundingSource[]>
    add: (plaidPublicToken: string) => Promise<FundingSourceManagementResult>
    delete: (fundingSourceId: string) =>  Promise<FundingSourceManagementResult>
    loadAvailableBalance: (fundingSourceId: string) => Promise<number>;
    clearAll: () => void;
}

export type FundingSourcesStore = FundingSourcesStoreData & FundingSourcesStoreMethods

export const useFundingSourcesStore = create<FundingSourcesStore>((set, get) => {

    return {

        isLoading: false,
        isLoadingBalance: false,
        fundingSources: null,
        errors: null,

        load: async () => {
            try 
            {
                set({ isLoading: true })
                const response = await SnexAxios.ApiGet<{ errors: SnexError[], fundingSources: FundingSource[]}>(Urls.funding.v2.sources.getAll()).run();

                const fundingSources = response.fundingSources
                set({ fundingSources, isLoading: false, errors: response.errors })

                return fundingSources
            } catch (e) {
                ErrorLogger.RecordError(e, 'GetFundingSources')
                throw e
            }

        },
        add: async (plaidPublicToken: string) => {
            try
            {
                set({ isLoading: true })
                await SnexAxios.ApiPost(Urls.funding.v2.sources.add(), {data: { plaidPublicToken }})
                    .run();
                set({ isLoading: false })
                return { success: true }
            } catch (e) {
                set({ isLoading: false })
                ErrorLogger.RecordError(e, 'AddFundingSource', {info: {plaidPublicToken}})
            }
        },
        delete: async (fundingSourceId: string) => {
            try
            {
                set({ isLoading: true })
                await SnexAxios.ApiDelete(Urls.funding.v2.sources.void(), {data: { fundingSourceId }})
                    .run();
                set({ isLoading: false })
                return { success: true }
            } catch (e) {
                set({ isLoading: false })
                ErrorLogger.RecordError(e, 'DeleteFundingSource', {info: { fundingSourceId }})
                throw e
            }

        },
        loadAvailableBalance:async (fundingSourceId: string) => {
            try
            {
                set({ isLoadingBalance: true })
                const response = await SnexAxios.ApiGet<number>(Urls.funding.v2.sources.getExternalBankBalance(fundingSourceId))
                    .run();
                const balance = response

                const updatedSources = get().fundingSources.map(source => {
                    if (source.fundingSourceId === fundingSourceId) return { ...source, balance } // Updates reference, which will trigger rerender
                    else return source
                })

                set({ fundingSources: updatedSources, isLoadingBalance: false })
                return balance
            } catch (e) {
                set({ isLoadingBalance: false })
                ErrorLogger.RecordError(e, 'GetAvailableBalance', {info: {fundingSourceId}})
            }
        },
        clearAll: () => set({
            isLoading: false,
            isLoadingBalance: false,
            fundingSources: null,
            errors: null,
        })
    }

})