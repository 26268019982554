// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Flex } from '../..';
import { SecurityChartWrapper } from '../../Charting/SecurityChartWrapper';
import uuid from 'uuid-random';
import style from '../style.module.scss';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { XS } from 'phoenix/xstream/XS';
import { ChartRange } from 'phoenix/constants';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';

interface SecurityCellChartProps {
    range?: ChartRange;
    symbol: string;
    withQuoteOverride?: boolean;
    onCrosshairUpdate: (value?: any, isScrubbing?: boolean, percChange?: number, valueChange?: number) => void;
}

export const SecurityCellChartComponent = ({ onCrosshairUpdate, symbol, withQuoteOverride }: SecurityCellChartProps) => {
    const [key, setkey] = useState('');
    const assetClass = useAssetClass(symbol, { fetchMetadata: true });
    const range = assetClass.chartRanges()?.[0]?.value || '1d';

    // TODO Refactor the security chart wrapper so we don't need to do this.
    const xStreamQuote: StandardQuote = XS.Quotes.use(symbol);
    const onCrosshairUpdateWrapper = (value?: any, isScrubbing?: boolean, percChange?: number, valueChange?: number) => {
        if (!value?.[symbol]) return;

        const percentChange = withQuoteOverride ? xStreamQuote?.changePercent : value[symbol]?.chartPercChange;

        onCrosshairUpdate(value, isScrubbing, percentChange, valueChange);
    };

    useEffect(() => {
        setkey(uuid());
    }, [symbol]);

    return (
        <Flex column className={style.miniChartColumn}>
            <SecurityChartWrapper
                canScrub={false}
                chartLineWidth={1}
                chartResolution='lo'
                containerId={`${key}`}
                height={30}
                percentChangeOverride={withQuoteOverride ? xStreamQuote?.changePercent : null}
                range={range}
                symbols={[symbol]}
                width={60}
                withOffset={true}
                isDebugging={false}
                onCrosshairUpdate={onCrosshairUpdateWrapper}
            />
        </Flex>
    );
};

export const SecurityCellChart = React.memo(SecurityCellChartComponent);
