// @ts-strict-ignore
import { AssetFamily } from "phoenix/models/AssetClasses/AssetClass";
import { ErrorLogger } from "./ErrorLogger";

// This isn't in QualifiedSecurityId because we use the "F:" prefix for account and orders as well.
const prefixesPattern = /^(F|I|B|C|X):/

const classes = { 'F:': 'futures', 'X:': 'crypto' }
export type AssetClass = 'futures' | 'equities' | 'crypto' // TODO -- Rename this, since it collides with the AssetClass interface

const families: Record<string, AssetFamily> = {
    'F:': 'futures',
    'X:': 'cryptos'
}

export const QualifiedId = {
    /** @deprecated Please use RemovePrefix instead */
    Format: (raw: string) => raw?.replace(prefixesPattern, '') || raw,
    RemovePrefix: (raw: string) => raw?.replace(prefixesPattern, '') || raw,

    /** @deprecated Pleas use Family instead */
    Class: (raw: string): AssetClass => {
        if(!raw) 
        {
            ErrorLogger.RecordMessage(`Invalid symbol received [${raw}]`, 'QUALIFIEDID_CLASS')
            return 'equities'
        }
        return (raw.length || 0) >= 2 ? classes[raw.substr(0, 2)] || 'equities' : 'equities'
    },
    Family: (raw: string): AssetFamily => {
        if(!raw) 
        {
            ErrorLogger.RecordMessage(`Invalid symbol received [${raw}]`, 'QUALIFIEDID_FAMILY')
            return 'equities'
        }
        return (raw.length || 0) >= 2 ? families[raw.substr(0, 2)] || 'equities' : 'equities'
    }
}
