// @ts-strict-ignore
import { ExtendedSubscription } from '../models/ExtendedSubscription'
import uuid from 'uuid-random'
import { Lightstreamer } from './LightstreamerClientManager'
import { MutexEnter, MutexExit } from '.'
import { DebugDumpManager } from './DebugDumpManager'

// Ticket-tracked subscriptions overview:
// * A "subscription" is a connection that streams one or more "items" (e.g., quote:TSLA is an item). These are both Lightstreamer concepts.
//      * We have an "ExtendedSubscription" model that adds a few extra bookkeeping fields
// * A "namespace" is a group of subscriptions, typically all related to the same UI (most often, a page / screen), enumerated in LiveDataNamespaces
// * We want to avoid creating subscriptions for the same thing within the same namespace. Ticket-tracked subscriptions does this.
// * When you subscribe to something, the subscription code first asks the TicketManager (via PutTicketIfExists) if someone else in the same namespace
//        has already subscribed to that item(s) -- PutTicketIfExists function
//      * If a relevant subscription already exists, it gives you a "ticket" indicating your stake in the underlying subscription
//      * If no such subscription exists, it gives you nothing. You need to call PutSubscription first, and _then_ call PutTicketIfExists again to get your ticket.
// * When you're done streaming, you simply close your ticket using CloseTicket.
// * You can also close entire namespaces of subscriptions. This is crucial to avoid streaming memory leaks. Use CloseNamespace to do this.

type Ticket = string;
export type TicketTelemetryItem = { item: string, tickets: Set<string>, namespace: string, updates: number }
export type TicketTelemetry = TicketTelemetryItem[]

let ticketChangeHandler: (tele: TicketTelemetry) => void
let ticketChangeHandlerInterval: any

const Subscriptions: {
    [itemNameAtNamespace: string]: {
        tickets: Set<string>,
        rawItem: string,
        subscription: ExtendedSubscription,
        updates: number,
        namespace: string
    }
} = {}

const TicketsToItems: { [ticket: string]: string } = {}
const NamespacesToTickets: { [namespace: string]: string[] } = {}

const MakeSubKey = (itemNames: string[], namespace: string) => `${itemNames.join('/')}@${namespace}`

const PutTicketIfExists = (itemNames: string[], namespace: string): Ticket | null => {
    const key = MakeSubKey(itemNames, namespace)
    // console.log("[PutTicketIfExists]", {key, itemNames, namespace})
    if (Subscriptions[key]) {
        const ticket = uuid()
        Subscriptions[key].tickets.add(ticket)
        TicketsToItems[ticket] = key
        if (!NamespacesToTickets[namespace]) NamespacesToTickets[namespace] = []
        NamespacesToTickets[namespace].push(ticket)
        return ticket
    } else return null
}

const PutSubscription = (sub: ExtendedSubscription): void => {
    const key = MakeSubKey((sub.getItems() as string[]), sub.namespace)
    if (Subscriptions[key]) {
        console.log(`WARNING! Displacing existing subscription for ${key}; closing existing subscription to avoid leaks`)
        Lightstreamer.Unsubscribe(Subscriptions[key].subscription, true)
    }
    Subscriptions[key] = { tickets: new Set(), rawItem: sub.getItems().join('/'), subscription: sub, namespace: sub.namespace, updates: 0 }

    DebugDumpManager.RecordEvent({ item: Subscriptions[key].rawItem, event: 'Subscribed', other: `${Subscriptions[key].rawItem} / NS - ${Subscriptions[key].namespace}` })
}

const CloseTicket = async (ticket: Ticket) => {
    const key = TicketsToItems[ticket]
    // console.log("CLOSING TICKET => ", { key, ticket })

    if (!key) return; else delete TicketsToItems[ticket]

    const entry = Subscriptions[key]

    // Mutex UP
    await MutexEnter(entry.rawItem)
    if (!entry) { 
        console.log("No entry found!", { Subscriptions: Object.keys(Subscriptions), key })
        await MutexExit(entry.rawItem); 
        return
    }

    if (entry.tickets.has(ticket)) {

        DebugDumpManager.RecordEvent({ item: entry.rawItem, event: 'Unsubscribed', other: `${Subscriptions[key].rawItem} / NS - ${Subscriptions[key].namespace}` })

        entry.tickets.delete(ticket)
    }
    if (entry.tickets.size === 0) {
        // console.log("Fully unsub! ", { key, entryTickets: entry.tickets, ticketSize: entry.tickets?.size })
        Lightstreamer.Unsubscribe(entry.subscription, true)
        delete Subscriptions[key]
    } else {
        // console.log("some still remain: ", { key, entryTickets: entry.tickets, ticketSize: entry.tickets?.size })
    }
    await MutexExit(entry.rawItem)
    // Mutex DOWN
}

const CloseNamespace = (namespace: string): void => {
    const items = NamespacesToTickets[namespace] || []
    items.forEach(i => CloseTicket(i))
}

const CloseAll = (): void => {
    Object.values(NamespacesToTickets).flatMap(t => t).forEach(ticket => CloseTicket(ticket))
}

const ReportTelemetry = () => {
    if (ticketChangeHandler) ticketChangeHandler(GetTelemetry())
}

const GetTelemetry = (): TicketTelemetry => {
    return Object.entries(Subscriptions || {}).map(s => ({ item: s[1].rawItem, tickets: s[1].tickets, namespace: s[1].namespace, updates: s[1].updates }))
}

const TickUpdate = (itemNames: string[], namespace: string) => {
    const key = MakeSubKey(itemNames, namespace)
    if (Subscriptions[key]) ++Subscriptions[key].updates
}

const OnTelemetryChange = (handler: (tele: TicketTelemetry) => void) => {
    ticketChangeHandler = handler
    ticketChangeHandlerInterval = setInterval(ReportTelemetry, 2000)
}

const __debugGetSubscription = (ticket: string): ExtendedSubscription => Subscriptions[TicketsToItems[ticket]]?.subscription

export const TicketManager = {
    PutTicketIfExists, PutSubscription, CloseTicket, CloseNamespace, CloseAll,
    OnTelemetryChange, GetTelemetry, TickUpdate, __debugGetSubscription
}
