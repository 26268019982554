import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSSObject } from 'highcharts';
import { AppColorTheme } from 'phoenix/theming/ColorVariants/AppColorTheme';

export const UseDescriptionTextStyles = (colors: AppColorTheme) => makeStyles((theme: Theme) => ({
    root: {
        textTransform: 'none',
        'font-weight': theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: colors.grayText,
        '&:focus': {
            opacity: 1
        }
    }
}));