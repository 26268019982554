// @ts-strict-ignore
import { Flex } from "components/Flex"
import React, { forwardRef, useEffect, useMemo, useRef } from "react"
import { useGlobalMessageStore } from "./store"
import { useColors } from "hooks/UseColors"

export const GlobalMessageBanner = () => {
    const {message} = useGlobalMessageStore()
    const isShown = useMemo(() => !!message, [message])
    const colors = useColors()
    const bannerRef = useRef<HTMLDivElement>()
    const {setHeight} = useGlobalMessageStore()
    useEffect(() => {setHeight(bannerRef?.current?.offsetHeight || 0)}, [bannerRef?.current])

    return (
        isShown?
        <Flex ref={bannerRef} id={'global-message'} row center style={{
            padding: 16, 
            backgroundColor: '#ffc107', 
            backdropFilter: 'blur(10px)',
            borderRadius: 8,
            color: colors.black, 
            fontSize: '16px',
            boxSizing: 'border-box',
            margin: '8px 0',
            width: '100%',
            }}>
            {message}
        </Flex>
        : null
    )
}