import { makeStyles } from '@mui/styles';
import { AppColorTheme } from 'phoenix/theming/ColorVariants/AppColorTheme';

export const UseInputFieldStyles = (colors: AppColorTheme, overrides?: { 
    textAlign?: 'right' | 'left' | 'center';
    placeholderColor?: string,
    padding?: any,
    fontSize?: any,
    borderColor?: any,
    disabled?:boolean
 }) => makeStyles({
    root: {
        transition: '0.3s',
        width: '100%',
        boxSizing: 'border-box',
        background: colors.mainBackgroundColor,
        borderRadius: 5,
        border: `1px solid ${overrides?.borderColor || colors.inputBorderColor}`,
        color: colors.generalTextColor,
        fontSize: overrides?.fontSize || 15,
        fontWeight: 500,
        // padding: '10px',
        '&:hover': !overrides?.disabled && {
            // background: '#F8F8F8',
            border: `1px solid ${overrides?.borderColor || colors.inputBorderActiveColor}`
        },
        '& fieldset': {
            border: 0
        },
        '& input': {
            padding: overrides?.padding || '10px',
            fontSize: overrides?.fontSize || 15,
            textAlign: overrides?.textAlign,
            color: colors.generalTextColor,
            '&::placeholder': {
                color: colors.generalTextColor
            }
        }
    }
});

export const UseSearchFieldStyles = () => makeStyles({
    root: {
        transition: '0.3s',
        width: '100%',
        boxSizing: 'border-box',
        background: 'white',
        borderRadius: 3,
        border: 'none',
        color: 'black',
        fontSize: 15,
        fontWeight: 500,
        padding: '7px',
        textAlign: 'center',
        '&:hover': {
            background: 'white',
            border: 'none'
        },
        '& fieldset': {
            border: 0
        },
        '& ::placeholder': {
            textAlign: 'center',
            color: 'grey'
        },
        '& input': {
            width: '100%',
            padding: '0'
        }
    }
});