// @ts-strict-ignore
import React from 'react';
import { useParams } from 'react-router-dom';
import { ComponentWithAnimatedBackArrow } from 'components/ComponentWithAnimatedBackArrow';
import { CircularLogo } from 'components/CircularLogo';
import { Typography } from '@mui/material';
import { LastQuoteUpdateDisplay } from 'components/LastQuoteUpdateDisplay/LastQuoteUpdateDisplay';
import { SecurityValues } from 'components/SecurityValues/SecurityValues';
import { QuoteBadge } from 'screens/OptionContractScreen/OptionContractHeaderSection';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { XS } from 'phoenix/xstream/XS';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { getTimeSpreadLabel } from 'screens/FuturesSpreadsScreen/TimeSpreadContractList';
import { FormatDaysFromNow } from 'phoenix/util';
import { Routes } from 'util/Routes';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import '../SecurityChartSection/style.scss'; // security-values-wrapper styles
import './style.scss';

export function FuturesTimeSpreadDeeperViewHeader() {
    const { symbol } = useParams<{ symbol: string }>();
    const fsym = new FuturesSymbol(symbol);
    const latestUnderlyingQuote = XS.Quotes.use(fsym.nearMonthContract);
    const text = useText((s) => s);
    const { formatPrice: formatPriceUnderlying } = useAssetClass(fsym.baseContract);
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const metaUnderlying = useSnexStore((s) => s.securities.bySymbol[fsym.baseContract]?.metadata?.data);
    const { formatPrice } = useAssetClass(symbol);
    const formatValue = (value: number) => formatPrice(value, meta);
    const formatUnderlying = (value: number) => formatPriceUnderlying(value, metaUnderlying);
    const latestPrice = latestUnderlyingQuote?.price;
    const expDate = meta?.expirationDate ? text.optionContractScreen.expiresOnDate(new Date(meta?.expirationDate)) : '';
    const expDaysFromNow = meta?.expirationDate ? FormatDaysFromNow(new Date(meta?.expirationDate)) : '';

    return (
        <DeeperViewHeader
            backRoute={Routes.futuresSpreads(`${fsym.baseContract} FTS`, fsym.nearMonthContract)}
            expDate={expDate}
            expDaysFromNow={expDaysFromNow}
            formatValue={formatValue}
            hideQuoteBadge={true}
            label={`${getTimeSpreadLabel(symbol)} FTS`}
            lastQuote={formatUnderlying(latestPrice)}
            lastQuoteLabel={text.optionContractScreen.symbolLastQuote(QualifiedId.RemovePrefix(fsym.nearMonthContract))}
            lastQuoteLoading={latestUnderlyingQuote?.loading}
            logoSymbol={fsym.baseContract}
            symbol={symbol}
        />
    );
}

// This component can be utilized on deeper view screens for derivative asset types (options, futures spreads)

export function DeeperViewHeader({
    backRoute,
    expDate,
    expDaysFromNow,
    formatValue,
    hideQuoteBadge,
    label,
    lastQuote,
    lastQuoteLabel,
    lastQuoteLoading,
    logoSymbol,
    symbol
}: {
    backRoute: string;
    expDate: string;
    expDaysFromNow: string;
    formatValue: (value: number) => string;
    hideQuoteBadge: boolean;
    label: string;
    lastQuote: string;
    lastQuoteLabel: string;
    lastQuoteLoading: boolean;
    logoSize?: number;
    logoSymbol: string;
    symbol: string;
}) {
    return (
        <section className='deeper-view-header'>
            <ComponentWithAnimatedBackArrow route={backRoute}>
                <CircularLogo altText={label} symbol={logoSymbol} />
                <Typography variant='h3'>{label}</Typography>
                <div className='exp'>
                    <Typography variant='h6'>{expDate}</Typography>
                    <Typography variant='body1'>{expDaysFromNow}</Typography>
                </div>
            </ComponentWithAnimatedBackArrow>
            <div className='security-values-wrapper'>
                <SecurityValues symbol={symbol} formatValue={formatValue} />
                {!hideQuoteBadge && <QuoteBadge label={lastQuoteLabel} loading={lastQuoteLoading} value={lastQuote} />}
            </div>
            <LastQuoteUpdateDisplay debounceMs={50 * 1000} prefix='Price as of' qsi={symbol} style={{ marginTop: 10, color: 'rgba(150,150,150,0.8)', fontSize: 11 }} />
        </section>
    );
}
