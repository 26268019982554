// @ts-strict-ignore
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Flex } from 'components';
import { ShadedTextDisplay } from 'components/ShadedTextDisplay/ShadedTextDisplay';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { GetSecurityMetadataAction } from 'phoenix/redux/actions';
import { OptionSymbol, TradeRequest } from 'phoenix/redux/models';
import { FormatNumber, SafeFormat } from 'phoenix/util';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const AlexDebugScreen = () => {
    const dispatch = useDispatch();
    // const xdispatch = useXstreamDispatch();
    // const noop = useMemo(() => (() => {}), [])

    const qsi = 'F:ZCK23';
    const text = useText((s) => s.tradeTicket.review);
    const trade: Partial<TradeRequest> = {
        securityId: qsi,
        orderType: 'stoplimit',
        action: 'Buy',
        accountNumber: '28584836',
        quantity: 1,
        orderPrice: 1,
        stopLimitPrice: 0.5
    };

    const meta = useSnexStore(s => s.securities.bySymbol[qsi]?.metadata)
    const segments = text.futuresV2(FuturesAssetClass, trade, meta?.data)

    useEffect(() => {
        dispatch(GetSecurityMetadataAction(qsi));
    }, []);

    return (
        <>
            <Typography variant='h3'>Alexland</Typography>
            <Typography variant='body1'>This is where Alex gets to make a screen</Typography>

            <Flex column style={{ width: '100%', margin: '100px auto' }}>
                <ShadedTextDisplay segments={segments} />
            </Flex>
        </>
    );
};

const GainSymbologyDebug = () => {
    const optionSymbols = [
        { symbol: new OptionSymbol('OEWZ21 C4775', null, new Date('2021-12-31'), 'gain'), hint: 'gain' },
        { symbol: new OptionSymbol('SIRI211231C00007000'), hint: '' }
    ];

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Source</TableCell>
                    <TableCell>Type Hint</TableCell>
                    <TableCell>Is Option?</TableCell>
                    <TableCell>Underlying</TableCell>
                    <TableCell>Expiration Date</TableCell>
                    <TableCell>Put / Call</TableCell>
                    <TableCell>Strike</TableCell>
                    <TableCell>Upstream Symbol</TableCell>
                    <TableCell>As OSI</TableCell>
                    <TableCell>As OCC</TableCell>
                    <TableCell>Parsing Error</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {optionSymbols.map(({ symbol, hint }) => (
                    <TableRow>
                        <TableCell>
                            <code>{symbol.originalSymbol}</code>
                            <br />({symbol.originalFormat})
                        </TableCell>
                        <TableCell>
                            <code>{hint}</code>
                        </TableCell>
                        <TableCell>{symbol.isOption ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{symbol.underlyingSymbol}</TableCell>
                        <TableCell>{SafeFormat(symbol.expDate, 'dd MMMM yyyy hh:mm:ss bb')}</TableCell>
                        {/* <TableCell>{ symbol.putCall } ({ symbol.putCallWord() })</TableCell> */}
                        <TableCell>{FormatNumber.toMoney(symbol.strike)}</TableCell>
                        <TableCell>{symbol.upstreamSymbol}</TableCell>
                        <TableCell>{symbol.osiSymbol}</TableCell>
                        <TableCell>{symbol.occSymbol}</TableCell>
                        <TableCell>{symbol.error}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )

}
