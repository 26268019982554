import React from 'react';
import { TradeReceiptPage } from '../Shared/TradeReceiptPage';
import { TradeReviewPage } from '../Shared/TradeReviewPage';
import { CryptoTradeInputPage } from './CryptoTradeInputPage';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { CryptoTradeTicketViewModel } from '../Store/TradeTicketViewModel';

export default function CryptoTradeTicket() {
    const { state } = useTradeTicketViewModel<CryptoTradeTicketViewModel>();

    switch (state) {
        case 'input':
            return <CryptoTradeInputPage />;
        case 'review':
            return <TradeReviewPage />;
        case 'done':
            return <TradeReceiptPage />;
    }
}
