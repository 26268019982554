// @ts-strict-ignore
import { AddCircleOutline, Lock, MoreVert, RemoveCircleOutline } from '@mui/icons-material';
import { IconButton, LinearProgress, Skeleton, Tooltip, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { useTelemetry } from 'hooks/UseTelemetry';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { FromEnglish } from 'phoenix/assets/lang/T';
import { GetCancelRule, GetEditRule } from 'phoenix/constants/AssetTradeability';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { GetAssetClassForSecurity, useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GetSecurityMetadataAction, GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { Order } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { ChangeColor, FormatNumber, IsMutualFundBySymbol, IsOffshoreMutualFundByMetadata, SafeFormat } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { GetNotAllowedCancelMessage, GetNotAllowedEditMessage } from 'phoenix/util/Trading/DetermineNotAllowedMessages';
import { ConvertOrderTifToApiTif } from 'phoenix/util/Trading/TradeConversion';
import { GetVariant } from 'phoenix/util/Variant';
import { XS } from 'phoenix/xstream/XS';
import React, { MouseEvent, ReactElement, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { AnchoredMenu, Card, Flex, IAnchoredMenuItem } from '..';
import { AutoSubscribeStockQuotes } from '../AutoSubscribe/AutoSubscribe';
import { CircularLogo } from '../CircularLogo';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import { InfoMatrix, InfoMatrixGridletModel } from '../InfoMatrix/InfoMatrix';
import { MultilegFillsTable, PartialFillsTable } from './FillsTables';
import { getActionSummary, getFormattedCost, getInfoMatrixGridletModels, getMultiLegFills, getStatusColor } from './helpers';
import './index.scss';

export interface OrderRowProps {
    order?: Order;
    hideBottomBorder?: boolean;
    onDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
    onEdit?: (event: MouseEvent<HTMLButtonElement>) => void;
    canceling?: boolean;
    securityKnown?: boolean;
    alwaysExpanded?: boolean;
    isExpandedByDefault?: boolean;
    lockedForOpen?: boolean;
}

export const ActionIcon = ({ orderDetails }: { orderDetails: Order }): ReactElement => {
    const commonIconStyle = { fontSize: 14 };
    const commonContainerStyle = { borderRadius: 15, padding: 3, margin: '0', marginLeft: 10 };
    switch (orderDetails.action) {
        case 'Buy':
        case 'BuyToOpen':
        case 'BuyToClose':
            return (
                <Flex center style={{ ...commonContainerStyle, backgroundColor: ChangeColor(1, true) }}>
                    <AddCircleOutline style={{ ...commonIconStyle, color: ChangeColor(1) }} />
                </Flex>
            );
        case 'Sell':
        case 'SellToOpen':
        case 'SellToClose':
            return (
                <Flex center style={{ ...commonContainerStyle, backgroundColor: ChangeColor(-1, true) }}>
                    <RemoveCircleOutline style={{ ...commonIconStyle, color: ChangeColor(-1) }} />
                </Flex>
            );
        default:
            return null;
    }
};

export const OrderRow = React.memo(({ alwaysExpanded = false, isExpandedByDefault = false, onDelete, onEdit, order, securityKnown }: OrderRowProps) => {
    const LogEvent = useTelemetry();
    const dispatch = useDispatch();
    const colors = useColors();
    const [appWindowSize] = useAppWindowSize();
    const [hover, setHover] = useState(false);
    const [expanded, setExpanded] = useState(alwaysExpanded === true || isExpandedByDefault === true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const secId = useMemo(() => QualifiedSecurityId.FromOrder(order), [order]);
    const secMeta = useSnexStore((s) => s.securities.bySymbol[order.symbol]?.metadata)?.data;
    const isMultiLeg = order.complexOrders?.length > 0;
    const assetClass = useAssetClass(order?.symbol);
    const { formatPrice, getPriceFormatInfo } = assetClass;
    const { decimalPlaces } = getPriceFormatInfo(secMeta);
    const formatValue = useCallback((value: number) => formatPrice(value, secMeta), [formatPrice, secMeta]);

    const isOpen = order.orderStatus === 'Open' || order.orderStatus === 'Working';
    const isFilled = order.orderStatus?.toLocaleLowerCase() === 'filled';
    const filledQuantity = useMemo(() => order.orderQuantity - order.leavesQuantity, [order]);
    const percentComplete = useMemo(() => Math.floor((filledQuantity / order.orderQuantity) * 100), [filledQuantity, order]);
    const futuresSymbolInfo = new FuturesSymbol(order?.symbol);
    const isFuture = futuresSymbolInfo.isFuture;
    const isPartiallyFilled = useMemo(() => !isFuture && isOpen && order?.orderQuantity > 1 && filledQuantity > 0, [filledQuantity, isFuture, isOpen, order]);
    const showPartialFillsTable = (order?.fills?.length > 1 || isPartiallyFilled) && !isFuture && !isMultiLeg;
    const userInfo = useSelector((s: GlobalState) => s.user.myInfo.data);
    const marketTime = useSnexStore((s) => s.market?.status?.data);
    const tenMinutesFromNow = new Date(new Date().getTime() + 600000);
    const onCloseTimeValid = ['MAIN_MARKET', 'PRE_MARKET'].includes(marketTime?.segment) && new Date(marketTime?.todaysSchedule?.postMarketStart) > tenMinutesFromNow;
    const isOffshoreMutualFund = IsOffshoreMutualFundByMetadata(secMeta);
    const lang = useText((s) => s);
    const text = lang;
    const isLockedForOpenTime = useSnexStore((s) => s.market.status.data?.lockedForOpen);
    const assetType = GetAssetClassForSecurity(order.symbol)?.type;
    const lockedForOpen = useMemo(() => {
        switch (assetType) {
            case 'equity':
                return isLockedForOpenTime;
            default:
                return false;
        }
    }, [isLockedForOpenTime, assetType]);

    const tooltipTitle = [
        { rule: isOffshoreMutualFund && !['Completed', 'Canceled'].includes(order?.orderStatus), value: text.orders.contactRepToChange },
        { rule: lockedForOpen, value: text.orders.lockedForOpen },
        { rule: true, value: text.orders.cannotBeCanceled }
    ].find((x) => !!x.rule).value;

    const canModify = useMemo(() => {
        if (isOffshoreMutualFund) return false;
        if (lockedForOpen) return false;
        if (!order?.orderId) return false;
        return order?.canCancel ?? true;
    }, [isOffshoreMutualFund, lockedForOpen, order?.orderId, order?.canCancel]);

    const handleMenuOpen = (event: SyntheticEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const multilegFills = getMultiLegFills(order, text);
    const info: { statusColor: string; cost: string } = useMemo(() => {
        return { statusColor: getStatusColor(order, colors), cost: getFormattedCost(order, text, secMeta, decimalPlaces) };
    }, [colors, decimalPlaces, order, secMeta, text]);

    useEffect(() => {
        setExpanded(alwaysExpanded || isExpandedByDefault);
    }, [alwaysExpanded, isExpandedByDefault]);

    const { isClient = false } = GetVariant();

    const stylesByWindowSize = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return { dateSectionColumn: true, actionSectonWidth: 30, alwaysShowAction: true, actionSummaryFlex: 1.3, hideMarketPrice: true };
            default:
                return { dateSectionColumn: false, actionSectonWidth: 78, alwaysShowAction: false, actionSummaryFlex: 1, hideMarketPrice: false };
        }
    }, [appWindowSize]);

    const showEditOrder = useMemo(() => {
        return (
            !isMultiLeg && order?.action !== 'SellShort' && (!order?.onClose || onCloseTimeValid) && order?.action !== 'FullLiquidation' && IsMutualFundBySymbol(secId.id)
        );
    }, [isMultiLeg, order?.action, order.onClose, onCloseTimeValid, secId.id]);

    const convertedTif = ConvertOrderTifToApiTif(order?.timeInForce);
    const allowEditOrder = GetEditRule(assetClass?.tradeability, { timeInForce: convertedTif }) === 'allowed';
    const allowCancelOrder = GetCancelRule(assetClass?.tradeability) === 'allowed';
    const editTooltipText = !allowEditOrder && GetNotAllowedEditMessage(assetClass?.type, convertedTif, text);
    const cancelTooltipText = !allowCancelOrder && GetNotAllowedCancelMessage(assetClass?.type, convertedTif, text);

    const orderActions: IAnchoredMenuItem[] = [
        { label: 'Edit', onClick: onEdit, disabled: !allowEditOrder, tooltipText: editTooltipText, visible: showEditOrder },
        { label: 'Cancel', onClick: onDelete, disabled: !allowCancelOrder, tooltipText: cancelTooltipText, visible: true }
    ].filter((x) => x?.visible);

    const ActionSection = useCallback(() => {
        if (isClient) return null;
        if (canModify) {
            return (
                <IconButton onClick={handleMenuOpen}>
                    <MoreVert />
                </IconButton>
            );
        }
        return (
            <Tooltip title={tooltipTitle}>
                <Lock style={{ opacity: 0.4 }} />
            </Tooltip>
        );
    }, [canModify, isClient, tooltipTitle]);

    const BoldLine = useCallback(
        ({ orderDetails }: { orderDetails: Order }) => {
            // display multiple trades of complex option orders differently
            const isPrimaryOrder = orderDetails?.symbol?.replace(/ /g, '') === secId?.id?.replace(/ /g, '');
            const hideMarketPrice = stylesByWindowSize.hideMarketPrice || !isOpen || !isPrimaryOrder || isMultiLeg;
            const sym = isPrimaryOrder ? text.general.symbolName(secId.id, null, null, secMeta) : text.general.symbolName(orderDetails.symbol);
            const gtcLabel = (assetType === 'future' || assetType === 'futures-option') && orderDetails.timeInForce === 'GoodTillCancel' ? 'GTC ' : '';

            const actionSummary = getActionSummary({
                assetClass,
                isPrimaryOrder,
                isPartiallyFilled,
                meta: secMeta,
                order,
                text
            });

            return (
                <Flex row justify='space-between' style={{ marginBottom: 4 }}>
                    <Flex row style={{ flex: 0.6 }}>
                        <Link to={Routes.security(secId?.toString())}>
                            <Typography style={{ marginRight: 20 }} variant='h6'>
                                {sym}
                            </Typography>
                        </Link>
                    </Flex>
                    {isPartiallyFilled ? (
                        <LightText style={{ marginLeft: 5, textAlign: 'right' }}>
                            {text.orders.partialFill(secId?.id, order.orderQuantity - order.leavesQuantity, order.orderQuantity)}
                        </LightText>
                    ) : (
                        !hideMarketPrice && (
                            <Flex row align='center' justify='flex-start' style={{ minWidth: 170 }}>
                                <SharePriceNugget formatValue={formatValue} symbol={orderDetails.symbol} />
                            </Flex>
                        )
                    )}
                    <Flex row align='center' justify='flex-end' style={{ flex: stylesByWindowSize.actionSummaryFlex }}>
                        <BoldText style={{ marginLeft: 5, textAlign: 'right' }}>
                            {gtcLabel}
                            {actionSummary}
                        </BoldText>
                    </Flex>
                </Flex>
            );
        },
        [
            assetClass,
            assetType,
            formatValue,
            isMultiLeg,
            isOpen,
            isPartiallyFilled,
            order,
            secId,
            secMeta,
            stylesByWindowSize.actionSummaryFlex,
            stylesByWindowSize.hideMarketPrice,
            text
        ]
    );

    useEffect(() => {
        dispatch(GetSecurityMetadataAction(order.symbol));
    }, [dispatch, order.symbol]);

    const matrixData: InfoMatrixGridletModel[] = getInfoMatrixGridletModels({
        assetClass,
        hasAdvancedRouting: userInfo.hasAdvancedRouting,
        order,
        securityMetadata: secMeta,
        text
    });

    const isDebugOrder = order.orderWarnings?.includes('DEBUG ORDER');

    return (
        <div className={`order-row${expanded ? ' expanded' : ''}${hover ? ' hover' : ''}${isDebugOrder ? ' debug' : ''}`}>
            <Card className={expanded ? '' : 'collapsed'}>
                <Flex
                    row
                    align='center'
                    className='cell'
                    style={{ background: hover && !expanded ? colors.boxShadowLight : 'transparent' }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onFocus={() => setHover(true)}
                    onBlur={() => setHover(false)}
                >
                    {/* Logo */}
                    {securityKnown ? null : (
                        <Link to={Routes.security(secId?.toString())}>
                            <div style={{ cursor: 'pointer', position: 'relative', marginRight: 15 }}>
                                <CircularLogo altText={QualifiedId.RemovePrefix(order.symbol)} size={30} symbol={secId.id} />
                            </div>
                        </Link>
                    )}

                    <Flex
                        column
                        style={{ flex: 1 }}
                        onClick={() => {
                            LogEvent('Open order click');
                            !alwaysExpanded && setExpanded(!expanded);
                        }}
                    >
                        {/*  First line */}
                        <BoldLine orderDetails={order} />

                        {/* Multi-leg options trade(s) */}
                        {isMultiLeg && order.complexOrders.map((o, i) => <BoldLine key={i} orderDetails={o} />)}

                        {/*  Second line */}
                        <Flex row justify='space-between'>
                            <Flex
                                align={stylesByWindowSize.dateSectionColumn ? 'flex-start' : 'center'}
                                column={stylesByWindowSize.dateSectionColumn}
                                justify={stylesByWindowSize.dateSectionColumn ? 'center' : 'flex-start'}
                                style={{ flex: 0.6 }}
                            >
                                <Flex row align='center'>
                                    <LightText style={{ color: info.statusColor, minWidth: 80 }}>
                                        {FromEnglish((s) => s.orders.statuses, order.orderStatus, 'key')}
                                    </LightText>
                                </Flex>
                            </Flex>
                            {isPartiallyFilled && (
                                <Flex row align='center' justify='center' style={{ flex: 'auto' }}>
                                    <LinearProgress variant='determinate' value={percentComplete} style={{ flex: 'auto' }} />
                                    <Typography marginLeft={'5px'} variant='body2' color='text.secondary'>{`${percentComplete}%`}</Typography>
                                </Flex>
                            )}
                            <Flex row align='center' justify='flex-end' style={{ flex: 1 }}>
                                <LightText style={{ whiteSpace: 'nowrap' }}>{SafeFormat(order.placedDate || order.completedDate, 'dd MMM yyyy, h:mm a')}</LightText>
                                <ActionIcon orderDetails={order} />
                                {isMultiLeg && order.complexOrders.map((o, i) => <ActionIcon key={i} orderDetails={o} />)}
                            </Flex>
                        </Flex>
                    </Flex>

                    {/* Right-hand action menu */}
                    <div className='action-section'>
                        <ActionSection />
                        {anchorEl && (
                            <AnchoredMenu
                                anchorEl={anchorEl}
                                handleClose={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    e?.stopPropagation();
                                    setAnchorEl(null);
                                }}
                                items={orderActions}
                            />
                        )}
                    </div>
                </Flex>
                {alwaysExpanded ? (
                    <InfoMatrix data={matrixData} style={{ margin: 10 }} />
                ) : (
                    <CulledCollapse eventTag='Order Row' in={expanded}>
                        {showPartialFillsTable && <PartialFillsTable fills={order?.fills} />}
                        {isMultiLeg && isFilled && <MultilegFillsTable fills={multilegFills} />}
                        <InfoMatrix data={matrixData} style={{ margin: 10 }} />
                    </CulledCollapse>
                )}
            </Card>
        </div>
    );
});

export const BoldText = React.memo((props: { children: ReactNode; style?: React.CSSProperties }) => (
    <Typography style={props.style} variant='h6'>
        {props.children}
    </Typography>
));

export const LightText = React.memo((props: { children: ReactNode; style?: React.CSSProperties }) => (
    <Typography color='textSecondary' style={{ fontWeight: 400, ...props.style }} variant='h6'>
        {props.children}
    </Typography>
));

// To display share price. Our streaming system sucks right now so it doesn't stream correctly
export const SharePriceNugget = React.memo((props: { formatValue?: (value: number) => string; namespace?: string; symbol: string }) => {
    const { namespace = LiveDataNamespaces.OrderRow, symbol } = props;
    const dispatch = useDispatch();
    const assetClass = useAssetClass(symbol);
    const quote = assetClass?.derivative === 'option' ? XS.OptionQuotes.use(symbol) : XS.Quotes.use(symbol);
    const isFuture = QualifiedId.Class(symbol) === 'futures';
    const text = useText((t) => t.optionListScreen.price);

    const displayPrice = useMemo(() => {
        if (!quote?.price) return '---';
        if (props.formatValue) return props.formatValue(quote.price);
        if (isFuture) return FormatNumber.toDecimal(quote.price);
        return FormatNumber.toMoney(quote.price);
    }, [isFuture, props, quote]);

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
    }, [dispatch, symbol]);

    return (
        <AutoSubscribeStockQuotes namespace={namespace} symbol={symbol}>
            <Flex row align='center'>
                <LightText style={{ marginRight: 5, whiteSpace: 'nowrap' }}>{text}: </LightText>
                <LightText>{displayPrice}</LightText>
            </Flex>
        </AutoSubscribeStockQuotes>
    );
});

export const OrderRowSkeleton = (): ReactElement => {
    return (
        <Flex row className='cell'>
            <Flex column style={{ flex: 1, marginRight: 78 }}>
                <Flex row justify='space-between'>
                    <Skeleton animation='wave' style={{ width: 200 }} />
                    <Skeleton animation='wave' style={{ width: 90 }} />
                </Flex>
                <Flex row justify='space-between' style={{ marginTop: 5 }}>
                    <Skeleton animation='wave' style={{ width: 100 }} />
                    <Skeleton animation='wave' style={{ width: 390 }} />
                </Flex>
            </Flex>
        </Flex>
    );
};
