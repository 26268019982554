// @ts-strict-ignore
import { Typography } from '@mui/material';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { POSITION_DISPLAY } from 'phoenix/constants';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { ApiPosition, OptionSymbol } from 'phoenix/redux/models';
import { ChangeColor, FormatNumber } from 'phoenix/util';
import { GetGainLossFigures } from 'phoenix/util/HoldingsCalculations';
import { XS } from 'phoenix/xstream/XS';
import React, { useMemo } from 'react';
import { DirectionalArrow, Flex } from '../..';
import style from '../style.module.scss';

interface PositionValueProps {
    chartPercChange: number;
    chartPrice: number;
    position: ApiPosition;
    symbol: string;
    withQuoteOverride?: boolean;
}

export const PositionValue = React.memo((props: PositionValueProps) => {
    const [display] = useLocalStorage(POSITION_DISPLAY, 'price');
    const { symbol } = props;
    const standardQuote = XS.Quotes.use(props.symbol);
    const optionsQuote = XS.OptionQuotes.use(props.symbol);
    const isOption = OptionSymbol.IsOptionSymbol(props.symbol);
    const assetClass = useAssetClass(props.symbol);
    const quote = isOption ? optionsQuote : standardQuote;
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const positionValues = useMemo(() => GetGainLossFigures(props.position, quote), [JSON.stringify(props.position), JSON.stringify(quote)]);
    const price = useMemo(
        () => (display === 'value' ? positionValues.marketValue : quote?.price || props.chartPrice || positionValues.price),
        [JSON.stringify(positionValues), props.chartPrice, JSON.stringify(quote), display]
    );

    const { formatPrice } = useAssetClass(symbol, { fetchMetadata: true });
    const priceFormatted = formatPrice(price, meta);
    const percentDigits = assetClass === CryptosAssetClass ? 4 : 2;

    const percChange = useMemo(() => {
        switch (true) {
            case props.withQuoteOverride && !isNaN(quote?.changePercent):
                // TODO Hopefully we can remove this ternary when we normalize all percentages to base 1
                return isOption ? quote.changePercent : quote.changePercent * 100;
            case !isNaN(props.chartPercChange):
                return assetClass === CryptosAssetClass ? props.chartPercChange * 100 : props.chartPercChange;
            case !isNaN(positionValues.changePercent):
                return positionValues.changePercent / 100;
            case props.position.fallbackChangePercent || props.position.fallbackChangePercent === 0:
                return props.position.fallbackChangePercent * 100;
            default:
                return null;
        }
    }, [props.position.fallbackChangePercent, props.chartPercChange, positionValues.changePercent, JSON.stringify(quote)]);

    return (
        <Flex column className={style.pricingColumn}>
            <Typography variant='h6'>{priceFormatted}</Typography>
            {!isNaN(percChange) ? (
                <Flex align='center' justify='flex-end'>
                    <DirectionalArrow height={8} valueChange={percChange} />
                    <Typography style={{ paddingLeft: '5px', color: ChangeColor(percChange) }} variant='body1'>
                        {FormatNumber.toPercent(percChange, 'never', 2)}
                    </Typography>
                </Flex>
            ) : (
                <Typography className={ChangeColor(0)} style={{ paddingLeft: '5px' }} variant='body1'>
                    {FormatNumber.toPercent(0, 'never')}
                </Typography>
            )}
        </Flex>
    );
});
