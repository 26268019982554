// @ts-strict-ignore
import React from 'react';
import { TradeLoadingPage } from '../Shared/TradeLoadingPage';
import { TradeReceiptPage } from '../Shared/TradeReceiptPage';
import { TradeReviewPage } from '../Shared/TradeReviewPage';
import { FuturesTradeInputPage } from './FuturesTradeInputPage';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { FuturesTradeTicketViewModel } from '../Store/TradeTicketViewModel';

export const FutureTradeTicket = React.memo(() => {
    const { state } = useTradeTicketViewModel<FuturesTradeTicketViewModel>();

    switch (state) {
        case 'input':
            return <FuturesTradeInputPage />;
        case 'review':
            return <TradeReviewPage />;
        case 'submitting':
            return <TradeLoadingPage />;
        case 'done':
            return <TradeReceiptPage />;
    }
});
