// @ts-strict-ignore
import { Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import React, { useMemo, useState } from 'react'
import { Flex } from '..'
import { useColors } from 'hooks/UseColors'
import { LoadingBar } from '../LoadingBar/LoadingBar'

interface SnexBadgeProps {
    flavor?: 'blue' | 'orange' | 'green' | 'red'
    iconComponent?: any
    children?: any
    onClick?: (e?: any) => any
    onDelete?: (e?: any) => any
    style?: any
    labelStyle?: any
    loading?: boolean
    whiteSpace?: React.CSSProperties['whiteSpace'],
    noMargin?: boolean
}

export const SnexBadge = (props: SnexBadgeProps) => {
    const { style = {}, labelStyle = {}, whiteSpace = 'nowrap', ...restProps } = props
    const [hovered, setHovered] = useState(false)

    const { primaryItemColor, primaryItemBackdropColor, green, greenLight, orange, orangeLight, red, redLight } = useColors()

    const TRANSITION_SPEED = '100ms'
    const hoveredTextColor = 'white'

    const color = (() => {
        switch (props.flavor) {
            case 'blue':
                return {
                    reg: primaryItemColor,
                    light: primaryItemBackdropColor
                }
            case 'green':
                return { reg: green, light: greenLight }
            case 'orange':
                return { reg: orange, light: orangeLight }
            case 'red':
                return { reg: red, light: redLight }
            default:
                return { reg: green, light: greenLight }
        }
    })()

    const hoverProps = useMemo(
        () =>
            props.onClick
                ? {
                      onMouseEnter: () => setHovered(true),
                      onMouseLeave: () => setHovered(false)
                  }
                : {},
        [props.onClick]
    )

    if (props.loading)
        return (
            <div onClick={props.onClick}>
                <LoadingBar
                    style={{
                        width: 150,
                        height: 45,
                        margin: '-5px 10px 0 10px',
                        borderRadius: 7
                    }}
                />
            </div>
        )

    return (
        <Flex
            {...hoverProps}
            {...restProps}
            row
            align='center'
            style={{
                background: hovered ? color.reg : color.light,
                padding: '6px 15px',
                borderRadius: 7,
                margin: props.noMargin? 0 : '5px 10px',
                minHeight: 28,
                transition: TRANSITION_SPEED,
                ...style
            }}
            onClick={
                props.onClick
                    ? (e: any) => {
                          e.stopPropagation()
                          props.onClick()
                      }
                    : null
            }
            tabIndex={props.onClick ? 0 : -1}
        >
            {props.iconComponent && (
                <props.iconComponent
                    style={{
                        color: hovered ? hoveredTextColor : color.reg,
                        fontSize: 15,
                        transition: TRANSITION_SPEED,
                        ...(props.children ? { marginRight: 10 } : {}),
                        ...labelStyle
                    }}
                />
            )}
            {props.children && (
                <Typography
                    style={{
                        color: hovered ? hoveredTextColor : color.reg,
                        whiteSpace,
                        overflow: 'hidden',
                        transition: TRANSITION_SPEED,
                        ...labelStyle
                    }}
                    variant='body2'
                >
                    {props.children}
                </Typography>
            )}
            {props.onDelete && (
                <Close
                    style={{
                        color: hovered ? hoveredTextColor : color.reg,
                        fontSize: 15,
                        transition: TRANSITION_SPEED,
                        ...(props.children ? { marginLeft: 10 } : {}),
                        cursor: 'pointer',
                        ...labelStyle
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        props.onDelete()
                    }}
                />
            )}
        </Flex>
    )
}
