// @ts-strict-ignore
import { IconButton, Tooltip } from '@mui/material';
import { PremiumContentClickable } from 'components/PremiumContentClickable/PremiumContentClickable';
import { ShiningWrapper } from 'components/ShiningWrapper';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { SafeFormatToNow, SafeFormatToNowAbbrev } from 'phoenix/util';
import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import AlarmIcon from '@mui/icons-material/Alarm';
import { useText } from 'phoenix/hooks/UseText';
import { CalendarTodayRounded } from '@mui/icons-material';

export function EarningsDateTooltipContainer({ symbol }: { symbol: string }) {
    const upcomingEarnings = useSnexStore((s) => s.securities.bySymbol[symbol]?.upcomingEarnings);
    const earningsDate = upcomingEarnings?.data?.[0]?.reportDate;
    const text = useText((t) => t).securityScreen;
    const earningsDateAbbreviated = earningsDate && SafeFormatToNowAbbrev(earningsDate, null, true, 'day').replace('0', '');
    const earningsDateVerbose =
        earningsDate &&
        SafeFormatToNow(earningsDate, null, false, 'day').replace('in 0 days', text.today).replace('0 days ago', text.today).replace('in 1 day', text.tomorrow);

    return <EarningsDateTooltipPresentation {...{ earningsDateAbbreviated, earningsDateVerbose, symbol }} />;
}

export function EarningsDateTooltipPresentation({ earningsDateVerbose, earningsDateAbbreviated, symbol }) {
    return (
        <Tooltip title={`Earnings call ${earningsDateVerbose}`}>
            <div style={{ marginRight: 20, position: 'relative' }}>
                {/* TODO -- Please use prop to determine whether asset class should render deep analysis link */}
                <Link to={Routes.deepAnalysis(symbol)} >
                    <PremiumContentClickable hide='never' products={['sx1', 'mi+sx1']}>
                        <IconButton size='small'>
                            <div style={{ fontSize: '10pt', position: 'absolute', top: '13.75px', width: '16px' }}>
                                <ShiningWrapper>
                                    {earningsDateAbbreviated.length > 0 ? (
                                        earningsDateAbbreviated
                                    ) : (
                                        <AlarmIcon style={{ width: '16px', height: '16px' }} />
                                    )}
                                </ShiningWrapper>
                            </div>
                            <ShiningWrapper>
                                <CalendarTodayRounded style={{ fontSize: '1.75rem', marginBottom: '1px' }} />
                            </ShiningWrapper>
                        </IconButton>
                    </PremiumContentClickable>
                </Link>
            </div>
        </Tooltip>
    );
}

export default EarningsDateTooltipContainer;
