import {
    DeepPartial,
    IChartApi,
    ISeriesApi,
    PriceScaleOptions,
    Range,
    SeriesDataItemTypeMap,
    SeriesMarker,
    SeriesOptionsMap,
    SeriesPartialOptionsMap,
    SeriesType,
    Time
} from 'lightweight-charts';
import { isEqual } from 'lodash';
import React, { MutableRefObject, ReactElement, RefCallback, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ChartWorkingOrders from '../ChartWorkingOrders';
import { LightweightChartApiContext, lwcLog } from './LightweightChart';
import { AreaStyleDefaults, BarStyleDefaults, CandlestickStyleDefaults, HistogramStyleDefaults, LineStyleDefaults } from './LightweightDefaults';

export const LightweightSeriesApiContext = React.createContext<ISeriesApi<SeriesType> | null>(null);

type SeriesRef<T extends SeriesType> = MutableRefObject<ISeriesApi<T> | null> | RefCallback<ISeriesApi<T> | null>;

// We use generics here, but TS currently doesn't support narrowing type parameters inside generic function bodies.
// https://github.com/microsoft/TypeScript/issues/27808
// In our case, this means we can't infer the type of `options` by checking the value of `seriesType` (thereby narrowing it to a single value).
// So we'll ultimately be using a discriminated union rather than faff about with a generic React component.

export type LightweightSeriesProps =
    | LightweightSeriesPropsGeneric<'Bar'>
    | LightweightSeriesPropsGeneric<'Candlestick'>
    | LightweightSeriesPropsGeneric<'Area'>
    | LightweightSeriesPropsGeneric<'Line'>
    | LightweightSeriesPropsGeneric<'Histogram'>;

type LightweightSeriesPropsGeneric<T extends SeriesType> = React.PropsWithChildren<{
    data: DataTypeMap[T][];
    isScalingActive?: boolean;
    markers?: SeriesMarker<Time>[];
    options: PartialOptionsMap[T];
    priceScaleOptions?: DeepPartial<PriceScaleOptions>;
    /** @deprecated move away from imperative calling */
    seriesApiRef?: SeriesRef<T>;
    seriesType: T;
    showWorkingOrders?: boolean;
    visibleLogicalRange: Range<number>;
}>;

// The WhitespaceData interface is equivalent to the intersection of all other data item interfaces, which enables a problematic structual typing pitfall, in which
// series of one type can accept data of other types.
// We can't just exclude it from the union, because it's structurally compatible with all other data item types.
// Furthermore, just because Line and Area series both accept LineData doesn't mean they should be necessarily compatible.
// So we brand/Nominalize each data item type.
export type DataTypeMap = {
    [K in SeriesType]: Nominal<SeriesDataItemTypeMap[K], K>;
};
export type PartialOptionsMap = {
    [K in SeriesType]: Nominal<SeriesPartialOptionsMap[K], K>;
};

export const __brand: unique symbol = Symbol('lwc-brand');
export type Nominal<T, Name extends string> = T & {
    readonly [__brand]: Name;
};

export type BarSeriesData = DataTypeMap['Bar'];
export type CandlestickSeriesData = DataTypeMap['Candlestick'];
export type AreaSeriesData = DataTypeMap['Area'];
export type LineSeriesData = DataTypeMap['Line'];
export type HistogramSeriesData = DataTypeMap['Histogram'];

// To sync state with lightweight-chart APIs, we:
//  - calculate the full (non-partial) options set from the options initially given, as fullOptions.
//  - store a copy of this set, as initialOptions.
//  - create the series with initialOptions.
//  - if any options change, recalculate fullOptions and apply it to the series.
// Q: Why is it necessary to calculate non-partial options?
// A: Because applyOptions is partial, and omitting a property that was previously set will leave stale state inside the series options.

// TODO: how does changing data fit into these lifetime modes?

export const LightweightSeries = (props: LightweightSeriesProps): ReactElement | undefined => {
    const { children, isScalingActive, seriesType, options, seriesApiRef, data, markers, priceScaleOptions, showWorkingOrders = false, visibleLogicalRange } = props;

    const addBarSeries = useCallback((chart, options) => chart.addBarSeries(options), []);
    const addCandlestickSeries = useCallback((chart, options) => chart.addCandlestickSeries(options), []);
    const addAreaSeries = useCallback((chart, options) => chart.addAreaSeries(options), []);
    const addLineSeries = useCallback((chart, options) => chart.addLineSeries(options), []);
    const addHistogramSeries = useCallback((chart, options) => chart.addHistogramSeries(options), []);

    if (!data?.length) return;

    switch (seriesType) {
        case 'Bar':
            return (
                <Series
                    defaults={BarStyleDefaults}
                    onSeriesAdd={addBarSeries}
                    {...{ data, isScalingActive, markers, options, priceScaleOptions, seriesApiRef, showWorkingOrders, visibleLogicalRange }}
                >
                    {children}
                </Series>
            );
        case 'Candlestick':
            return (
                <Series
                    defaults={CandlestickStyleDefaults}
                    onSeriesAdd={addCandlestickSeries}
                    {...{ data, isScalingActive, markers, options, priceScaleOptions, seriesApiRef, showWorkingOrders, visibleLogicalRange }}
                />
            );
        case 'Area':
            return (
                <Series
                    defaults={AreaStyleDefaults}
                    onSeriesAdd={addAreaSeries}
                    {...{ data, isScalingActive, markers, options, priceScaleOptions, seriesApiRef, showWorkingOrders, visibleLogicalRange }}
                >
                    {children}
                </Series>
            );
        case 'Line':
            return (
                <Series
                    defaults={LineStyleDefaults}
                    onSeriesAdd={addLineSeries}
                    {...{ data, isScalingActive, markers, options, priceScaleOptions, seriesApiRef, showWorkingOrders, visibleLogicalRange }}
                >
                    {children}
                </Series>
            );
        case 'Histogram':
            return (
                <Series
                    defaults={HistogramStyleDefaults}
                    onSeriesAdd={addHistogramSeries}
                    {...{ data, isScalingActive, markers, options, priceScaleOptions, seriesApiRef, showWorkingOrders, visibleLogicalRange }}
                >
                    {children}
                </Series>
            );
        default:
            throw new Error(`Unsupported series type: ${seriesType}`);
    }
};

// I'd love to make these BarSeries/CandlestickSeries/etc into hooks, but:
//  (a) they will be called conditionally on the series type, and
//  (b) they need children for things like priceLines.
// So, better keep them as full functional components.

export type SeriesProps<T extends SeriesType> = React.PropsWithChildren<{
    /** the data to be displayed on the series. */
    data: SeriesDataItemTypeMap[T][];
    /** the set of default options for this series type. typeof == SeriesOptionsMap[T]. */
    defaults: SeriesOptionsMap[T];
    isScalingActive?: boolean;
    markers?: SeriesMarker<Time>[];
    /** a callback function that, when invoked, must add a series of type T to the chart. */
    onSeriesAdd: (theChartApi: IChartApi, theSeriesOptions: SeriesPartialOptionsMap[T]) => ISeriesApi<T>;
    /** a set of options that may differ from the default, to be applied on top. typeof == SeriesPartialOptionsMap[T]. */
    options: SeriesPartialOptionsMap[T];
    priceScaleOptions?: DeepPartial<PriceScaleOptions>;
    showWorkingOrders?: boolean;
    visibleLogicalRange: Range<number>;
}>;

/**
 * React component that renders a series of type T on the chart (obtained from {@link LightweightChartApiContext}), managing:
 * - the lifetime of the series
 * - applying partial updates
 * - (optional) providing a ref to the ISeriesApi
 * - (optional) providing a context for child components to access the ISeriesApi
 *
 * type param T - one of 'Bar', 'Line', 'Candlestick', 'Area', 'Histogram'
 */
function Series<T extends SeriesType>(props: SeriesProps<T>): ReactElement {
    const { markers = [], options, onSeriesAdd, children, data, isScalingActive, priceScaleOptions, showWorkingOrders, visibleLogicalRange } = props;

    const chartApi = useContext(LightweightChartApiContext);

    // TODO Remove defaults entirely
    // const fullOptions = useMemo(() => merge(defaults, options), [defaults, options]);
    const fullOptions = options;

    const initialOptionsRef = useRef(fullOptions);

    // whether we're passed a ref or not, we need to store the seriesApi *somewhere*.
    const [seriesApi, setSeriesApi] = useState<ISeriesApi<T> | null>(null);
    // Store the current data to compare new data against, to determine which method(s) to call
    const [currentData, setCurrentData] = useState<SeriesDataItemTypeMap[T][]>([]);
    const [currentOptions, setCurrentOptions] = useState<SeriesPartialOptionsMap[T]>({});
    const [currentMarkers, setCurrentMarkers] = useState<SeriesMarker<Time>[]>([]);
    const [currentPriceScaleOptions, setCurrentPriceScaleOptions] = useState<DeepPartial<PriceScaleOptions>>({});
    const [currentVisibleLogicalRange, setCurrentVisibleLogicalRange] = useState<Range<number>>();

    // create series
    useEffect(() => {
        lwcLog('Create Series Hook', { chartApi, initialOptionsRef });
        if (chartApi) {
            const newSeriesApi = onSeriesAdd(chartApi, initialOptionsRef.current);

            setSeriesApi(newSeriesApi);

            return () => {
                lwcLog('Create Series Hook - Removing series', { chartApi, initialOptionsRef });
                if (!chartApi?.destroyed && newSeriesApi) {
                    chartApi?.removeSeries(newSeriesApi);
                    setSeriesApi(null);
                }
            };
        }
    }, [chartApi, onSeriesAdd]);

    // apply changes in options to series
    useEffect(() => {
        lwcLog('Apply options hook', { currentOptions, fullOptions, seriesApi });
        if (seriesApi !== null && !isEqual(currentOptions, fullOptions)) {
            seriesApi.applyOptions(fullOptions);
            setCurrentOptions(fullOptions);
        }
    }, [currentOptions, fullOptions, seriesApi]);

    // set series markers
    useEffect(() => {
        lwcLog('Set series markers hook', { currentMarkers, markers, seriesApi });
        if (seriesApi !== null && !isEqual(currentMarkers, markers)) {
            seriesApi.setMarkers(markers);
            setCurrentMarkers(markers);
        }
    }, [currentMarkers, markers, seriesApi]);

    // apply changes in price scale options to series
    useEffect(() => {
        lwcLog('Apply price scale options hook', { currentOptions, currentPriceScaleOptions, priceScaleOptions, seriesApi });
        if (seriesApi !== null && priceScaleOptions && !isEqual(currentPriceScaleOptions, priceScaleOptions)) {
            seriesApi.priceScale()?.applyOptions(priceScaleOptions);
            setCurrentPriceScaleOptions(priceScaleOptions);
        }
    }, [currentOptions, currentPriceScaleOptions, priceScaleOptions, seriesApi]);

    // set or update data
    useEffect(() => {
        lwcLog('Set/Update data hook', { chartApi, currentData, currentVisibleLogicalRange, data, seriesApi, visibleLogicalRange });
        if (seriesApi !== null) {
            const shouldSetNewData = !currentData.length || !isEqual(data?.[0], currentData?.[0]);

            if (shouldSetNewData) {
                lwcLog('Setting new data...', { currentData, newData: data, currentVisibleLogicalRange, visibleLogicalRange });
                seriesApi.setData(data);
                setCurrentData(data);
                if (!isScalingActive) {
                    chartApi?.timeScale().setVisibleLogicalRange(visibleLogicalRange);
                    setCurrentVisibleLogicalRange(visibleLogicalRange);
                }

                return;
            }

            if (currentData[currentData?.length - 1]?.time < data?.[data?.length - 1]?.time) {
                lwcLog('Updating data...', data);
                seriesApi.update(data?.[data?.length - 1]);
            }
        }
    }, [chartApi, currentData, currentVisibleLogicalRange, data, isScalingActive, seriesApi, visibleLogicalRange]);

    // Update visible logical range
    useEffect(() => {
        lwcLog('Update visible logical range', { chartApi, currentVisibleLogicalRange, visibleLogicalRange });
        if (!isEqual(currentVisibleLogicalRange, visibleLogicalRange)) {
            chartApi?.timeScale().setVisibleLogicalRange(visibleLogicalRange);
            setCurrentVisibleLogicalRange(visibleLogicalRange);
        }
    }, [chartApi, currentVisibleLogicalRange, visibleLogicalRange]);

    return (
        <LightweightSeriesApiContext.Provider value={seriesApi}>
            {children}
            {showWorkingOrders && <ChartWorkingOrders series={seriesApi} />}
        </LightweightSeriesApiContext.Provider>
    );
}
