import React, { useEffect, useMemo } from 'react'
import Highcharts, { AxisLabelsFormatterContextObject, PointLabelObject, isString } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'phoenix/redux/GlobalState'
import { SectionHeader } from '../SectionHeader'
import { SecurityEarnings } from 'phoenix/redux/models'
import { GetSecurityEarnings, GetSecurityEarningsEstimatesAction } from 'phoenix/redux/actions/SecuritiesActions'
import { useText } from 'phoenix/hooks/UseText'
import { FormatNumber, MobileBlue, MobileLightBlue } from 'phoenix/util'
import { useColors } from 'hooks/UseColors'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'
import { GetEarningsChartData } from './GetEarningsChartData'

highchartsAccessibility(Highcharts)

export interface SecurityEarningsProps {
    symbol: string
}



const SecurityEarnings = (props: SecurityEarningsProps) => {
    const colors = useColors()
    const text = useText((s) => s.securityScreen.earningsEstimates)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetSecurityEarningsEstimatesAction(props.symbol))
        dispatch(GetSecurityEarnings(props.symbol))
    }, [props.symbol])

   
    const actualEarnings = useSelector((s: GlobalState) => s.securities.bySymbol[props.symbol]?.earnings)
    const estimates = useSelector((s: GlobalState) => s.securities.bySymbol[props.symbol]?.earningsEstimates)

    const {chartdataXAxis, chartdataActual, chartdataEstimated} =  GetEarningsChartData(text.quarter, estimates?.data, actualEarnings?.data)

    const dataReady = useMemo(() => !!(chartdataXAxis || chartdataActual || chartdataEstimated), 
        [chartdataXAxis, chartdataActual, chartdataEstimated])

    const WithTitle = (props: { children: any; ghosted?: boolean }) => {
        return (
            <div style={{ marginTop: '20px', marginBottom: '-20px', minHeight: 480, opacity: props.ghosted ? 0.4 : 1 }}>
                <SectionHeader label={text.earningsAndEstimates} />
                {props.children}
            </div>
        )
    }

    if (!dataReady)
        return (
            <WithTitle>
                <div style={{ backgroundColor: colors.greyBackgroundColor, height: 460, borderRadius: 15, margin: '20px 0' }}></div>
            </WithTitle>
        )

    if (!chartdataEstimated?.length && !chartdataActual?.length) return null

    return (
        <WithTitle ghosted={!chartdataActual || !dataReady}>
            <ErrorBoundary>
                <SecurityEarningsPresentation 
                    estimatedData={chartdataEstimated}
                    actualData={chartdataActual}
                    chartBackgroundColor={colors.mainBackgroundColor}
                    legendTextColor={colors.fadedTextColor}
                    legendTextHoverColor={colors.generalTextColor}
                    actualBarColor={MobileBlue}
                    estimatedBarColor={MobileLightBlue}
                    actualLegendLabel={`${text.actual} EPS (USD)`}
                    estimatedLegendLabel={`${text.estimated} EPS (USD)`}
                    dataLabelColor={colors.generalTextColor}
                    xAxisData={chartdataXAxis}
                    chartGridLineColor={colors.inputBorderColor}
                    tooltipBackgroundColor={colors.cardBackgroundColor}
                />
            </ErrorBoundary>
        </WithTitle>
    )
}

interface SecurityEarningsPresentationProps {
    chartBackgroundColor: string
    estimatedBarColor: string
    actualBarColor: string
    legendTextColor: string
    legendTextHoverColor: string
    estimatedLegendLabel: string
    actualLegendLabel: string
    dataLabelColor: string
    estimatedData?: number[]
    actualData?: number[]
    xAxisData?: string[]
    chartGridLineColor: string
    tooltipBackgroundColor: string
}

export const SecurityEarningsPresentation = (props: SecurityEarningsPresentationProps) => {

    const FormatValue = (point: any): string => {
        if(point.series.name === props.actualLegendLabel && !props.actualData) return '$--.--'
        return FormatNumber.toMoneyOpt2(point.y)
    }

    const options = {
        credits: {
            enabled: false
        },
        colors: [props.estimatedBarColor, props.actualBarColor],
        chart: {
            backgroundColor: props.chartBackgroundColor,
            plotBackgroundColor: null,
            type: 'column',
            style: {
                fontFamily: 'Lato'
            }
        },
        title: {
            enabled: false,
            text: null
        },
        xAxis: {
            categories: props.xAxisData,
        },
        yAxis: {
            title: false,
            opposite: false,
            showFirstLabel: false,
            plotLines: [{
                value: 0,
                width: 1,
                color: props.chartGridLineColor,
                dashStyle: 'shortdash',
                zIndex: 10
              }],
            gridLineColor: props.chartBackgroundColor,
            gridLineDashStyle: 'shortdash',
            showLastLabel: false,
            labels: {
                enabled: true,
                formatter: (x: AxisLabelsFormatterContextObject) : string | undefined => {
                    if(!x.value) return undefined
                    const val = parseFloat(x.value.toString()) 
                    if(isNaN(val)) return undefined
                    return FormatNumber.toMoneyOpt2(val)
                }
            }
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            margin: 30,
            squareSymbol: false,
            symbolRadius: 0,
            symbolWidth: 10,
            symbolHeight: 10,
            itemHoverStyle: {
                color: props.legendTextColor
            },
            itemStyle: {
                color: props.legendTextHoverColor,
                fontWeight: 500
            }
        },
        tooltip: {
            enabled: true,
            useHTML: true,
            shared: true,
            
            formatter: function (): string {
                //@ts-ignore
                return this.points.reduce(function(s, point){
                    return s + '<br/><span style="font-size: 14px; font-family: Lato; padding-bottom: 4px; margin-right: 32px; display:inline-block; width: 110px; color:' 
                    + props.legendTextColor + '">' + point.series.name 
                    + '</span><span style="font-size: 14px; font-family: Lato; padding-bottom: 4px; color:' 
                    + props.legendTextHoverColor 
                    + ';display: inline-block; width: 40px; text-align: right; font-weight: 700">' 
                    + FormatValue(point) + '</span>';
                //@ts-ignore
                }, '<span style="color:' + props.legendTextColor + ';display: inline-block; padding-bottom: 8px; font-family: Lato; font-size: 16px"><b>' + this.x + '</b></span>');
            },
            borderRadius: 8,
            borderWidth: 0,
            backgroundColor: props.tooltipBackgroundColor,
            valueDecimals: 2,
        },
        plotOptions: {
            series: {
                animation: false,
            },
            column: {
                pointPadding: 0.15,
                borderWidth: 0,
                borderRadius: 10,
            }
        },
        series: [
            {
                name: props.estimatedLegendLabel, 
                data: props.estimatedData,
                dataLabels: {
                    enabled: true,
                    color: props.dataLabelColor,
                    textOutline: '0px',
                    //@ts-ignore
                    formatter: function() {  /* ignoring this line due to unexpected behavior when trying to define 'this' */
                        //@ts-ignore
                        return FormatNumber.toMoneyOpt2(this.y)
                    },
                    style: {
                        textOutline: 0,
                        textShadow: false
                    }
                }
            },
            {
                name: props.actualLegendLabel, 
                data: !props.actualData ? props.estimatedData : props.actualData,
                color: !props.actualData? props.chartBackgroundColor : props.actualBarColor,
                borderColor: props.actualBarColor,
                borderWidth: !props.actualData ? 3 : 0,
                dashStyle: 'shortDash',
                dataLabels: {
                    enabled: !!props.actualData,
                    color: props.dataLabelColor,
                    textOutline: '0px',
                    borderWidth: 0,
                    //@ts-ignore
                    formatter: function() {  /* ignoring this line due to unexpected behavior when trying to define 'this' */
                        //@ts-ignore
                        return FormatNumber.toMoneyOpt2(this.y)
                    },
                    style: {
                        textOutline: 0,
                        textShadow: false,
                        color: props.dataLabelColor,
                    }
                }
            }
        ]
    }

    if (!props.estimatedData?.length && !props.actualData?.length) return null

    return (
        <div className='earnings-estimates'>
            <HighchartsReact Highcharts={Highcharts} options={options} />
        </div>
    )
}

export default React.memo(SecurityEarnings)
