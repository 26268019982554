import { ExpandMore } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { CancelOrderModal } from 'components/Modals/CancelOrderModal/CancelOrderModal';
import { SecurityChartContext } from 'components/SecurityChartSection';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useOrdersFunctions } from 'hooks/UseZustand';
import { Coordinate, ISeriesApi, SeriesType } from 'lightweight-charts';
import { Dictionary } from 'lodash';
import { StorageKeys } from 'phoenix/constants';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { Order } from 'phoenix/redux/models';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ChartWorkingOrders.css';
import { LightweightChartApiContext } from './LightweightChart/LightweightChart';

type OrderWithCoords = { coordinate: Coordinate | null | undefined; order: Order; price: string; quantity: string };

// Group orders within a certain pixel coordinate threshold so they don't overlap on the chart
export function groupOrdersByCoordinates(orders: OrderWithCoords[]): Dictionary<OrderWithCoords[]> {
    const threshold = 15;
    const sorted = orders.sort((a, b) => (a?.coordinate || 0) - (b?.coordinate || 0));

    const grouped = sorted.reduce<Dictionary<OrderWithCoords[]>>((a, c) => {
        const keys = Object.keys(a);
        const latestArr = a[keys[keys?.length - 1]]; // Last array of orders
        const latestValue = latestArr?.[latestArr.length - 1]; // Last order in that array

        // If the dictionary hasn't been populated yet, create the first key with the current order
        if (!latestValue) return { [c.price]: [c] };

        // If the next value is within the threshold, group these orders
        if ((latestValue?.coordinate || 0) + threshold >= (c.coordinate || 0)) {
            latestArr.push(c);
            return a;
        }

        // If it's not within the threshold, create a new entry
        return {
            ...a,
            [c.price]: [c]
        };
    }, {});

    return grouped;
}

export default function ChartWorkingOrders<T extends SeriesType>({ series }: { series: ISeriesApi<T> | null }): ReactElement | null {
    const [cancelOrderId, setCancelOrderId] = useState<string | null>(null);
    const { securityId = '', symbol: urlSymbol = '' } = useParams<{ securityId: string; symbol: string }>();
    const symbol = securityId || urlSymbol;
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const assetClass = useAssetClass(symbol);
    const selectedAccountNumber = useSelectedAccountByAssetFamily(assetClass?.family);
    const { orders } = useOrdersFunctions();
    const [focusRow, setFocusRow] = useState<number | undefined>();
    const chartApi = useContext(LightweightChartApiContext);
    const height = chartApi?.options()?.height || 0;
    const [showWorkingOrders] = useLocalStorage<boolean>(StorageKeys.BigChartShowWorkingOrders, true);
    const { workingOrdersInRange, setWorkingOrdersInRange } = useContext(SecurityChartContext);

    const workingOrders = useMemo(() => {
        if (!orders) return [];

        return orders.filter(
            (o) => (selectedAccountNumber ? o?.accountNumber === selectedAccountNumber : true) && ['Open', 'Working'].includes(o.orderStatus) && o.symbol === symbol
        );
    }, [orders, selectedAccountNumber, symbol]);

    // Map working orders to Y coordinates on the chart based on the limit/stop price
    const allOrdersWithCoords: OrderWithCoords[] = workingOrders.map((o) => ({
        coordinate: o?.limitPrice || o?.stopPrice ? series?.priceToCoordinate((o.limitPrice || o.stopPrice) as number) : null,
        order: o,
        price: assetClass.formatPrice((o?.limitPrice || o?.stopPrice) as number, meta),
        quantity: assetClass.formatOrderQuantity(o)
    }));

    // Filter out orders that won't plot on the chart at its current size
    // Since this isn't memoized, and the series prop reflects the data, it should correctly recalculate any time series data changes
    // If the vertical axis of the chart increases such that the working order should now appear, it won't get filtered out
    const filteredOrdersWithCoords = allOrdersWithCoords.filter((x) => !!x.coordinate && x.coordinate > 0 && x.coordinate < height);

    const localWorkingOrdersInRange = !!filteredOrdersWithCoords?.length;

    // Group multiple orders with the similar coordinates on the chart
    const groupedByCoords = groupOrdersByCoordinates(filteredOrdersWithCoords);

    const handleFocusRow = useCallback((i: number) => {
        setFocusRow(i);
    }, []);

    const handleOnBlurRow = useCallback(() => {
        setFocusRow(undefined);
    }, []);

    const handleCancelOrder = (id: string) => {
        setFocusRow(undefined);
        setCancelOrderId(id);
    };

    useEffect(() => {
        if (localWorkingOrdersInRange !== workingOrdersInRange) setWorkingOrdersInRange(localWorkingOrdersInRange);
    }, [localWorkingOrdersInRange, setWorkingOrdersInRange, workingOrdersInRange]);

    if (!showWorkingOrders) return null;

    return (
        <>
            {Object.keys(groupedByCoords)?.map((price, i) => (
                <WorkingOrderRow
                    className={`${focusRow === i ? ' selected' : ''}${typeof focusRow === 'number' ? ' something-selected' : ''}`}
                    onBlur={handleOnBlurRow}
                    onFocus={() => handleFocusRow(i)}
                    key={price}
                    orders={groupedByCoords[price]}
                    selectedAccountNumber={selectedAccountNumber}
                    onCancelOrder={handleCancelOrder}
                />
            ))}
            <CancelOrderModal open={!!cancelOrderId} orderId={cancelOrderId || ''} toggleModal={(on: boolean) => setCancelOrderId(on ? cancelOrderId : null)} />
        </>
    );
}

function WorkingOrderRow({
    className,
    onBlur,
    onFocus,
    orders,
    selectedAccountNumber,
    onCancelOrder
}: {
    className: string;
    onBlur: () => void;
    onFocus: () => void;
    orders: OrderWithCoords[];
    selectedAccountNumber: string;
    onCancelOrder: (orderId: string) => void;
}) {
    const text = useText((t) => t.tradeTicket.input.action);
    const [expanded, setExpanded] = useState<boolean>(false);
    const first = orders[0];
    const allBuy = orders.every((o) => o?.order?.action === 'Buy');
    const allSell = orders.every((o) => o?.order?.action === 'Sell');
    const actionText = allBuy ? text.buy : allSell ? text.sell : `${text.buy}/${text.sell}`;
    const actionSummaryText =
        orders.length > 1 ? `${orders?.length}x ${actionText}` : `${first?.order?.action === 'Buy' ? text.buy : text.sell} ${first.quantity} @ ${first.price}`;

    const ButtonComponent = () =>
        orders.length > 1 ? (
            <button className={`spinny ${expanded ? 'spin' : ''}`} onClick={() => setExpanded(!expanded)}>
                <ExpandMore />
            </button>
        ) : (
            <button onClick={() => onCancelOrder(first?.order?.orderId)}>X</button>
        );

    return (
        <div
            className={`working-order${className ? `${className}` : ''}`}
            key={first.price}
            style={{ top: first.coordinate } as React.CSSProperties}
            onBlur={onBlur}
            onFocus={onFocus}
            onMouseEnter={onFocus}
            onMouseLeave={onBlur}
        >
            <div>
                <div className='first-row'>
                    <Typography>
                        {!selectedAccountNumber && orders.length === 1 ? `${QualifiedId.RemovePrefix(first?.order?.accountNumber)} - ` : ''}
                        {actionSummaryText}
                    </Typography>
                    <ButtonComponent />
                    <hr />
                </div>
                {orders.length > 1 &&
                    expanded &&
                    orders.map((o) => (
                        <div key={o?.order?.orderId} className='working-order-row-expanded'>
                            <Typography>
                                {!selectedAccountNumber ? `${QualifiedId.RemovePrefix(first?.order?.accountNumber)} - ` : ''}
                                {o?.order?.action === 'Buy' ? text.buy : text.sell} {o.quantity} @ {o.price}
                            </Typography>
                            <button onClick={() => onCancelOrder(o?.order?.orderId)}>X</button>
                        </div>
                    ))}
            </div>
        </div>
    );
}
