// @ts-strict-ignore
import { ItemUpdate, Subscription, SubscriptionListener } from 'lightstreamer-client-web'
import { DescribeObject } from 'phoenix/util/DebugHelpers'
import { Dispatch } from 'redux'
import { GetConfig } from '../../constants'
import { LightstreamerSubscriptionMode } from '../../constants/LightstreamerSubscriptionMode'
import { SnexLsItemUpdate } from '../../ls-shim/models/SnexLsItemUpdate'
import { SnexLsSubscription } from '../../ls-shim/models/SnexLsSubscription'
import { SnexLsSubscriptionListener } from '../../ls-shim/models/SnexLsSubscriptionListener'
import { ExtendedSubscription } from '../../models/ExtendedSubscription'
import { GetDisableGainStreaming, GetDisableSnexStreaming, GetEnableDebugLogging, GetShowDebugHud, GetUseVerboseRedux, MutexEnter, MutexExit } from '../../util'
import { DebugDumpManager } from '../../util/DebugDumpManager'
import { Lightstreamer, LSUpstreamSource } from '../../util/LightstreamerClientManager'
import { TicketManager } from '../../util/TicketManager'
import { UserState } from '../models'
import { StreamingActionNames } from './Constants'

const log = (...args: any[]) => {
    if (GetEnableDebugLogging()) console.log(`[StreamingHelpers]`, ...args)
}

export const ReduxLiveSubscribe = (
    groupName: string,
    itemNames: string[],
    actionNames: StreamingActionNames,
    fields: string[],
    onUpdate?: (item: any, dispatch: Dispatch) => any,
    options?: Partial<{
        mode: LightstreamerSubscriptionMode,
        maxFrequencySeconds: number,
        subject: any,
        includeSnapshot: boolean,
        namespace: string,
        allEvents: boolean, // By default, only Error and Update events are fired; set this to true to emit all types
        upstream: LSUpstreamSource
    }>
) => {
    return async (dispatch: Dispatch) => {
        const me: UserState = GetConfig().Store.getState().user

        // check to see if user has delayed data and if so mutate itemNames to use dQuote
        if (me.myInfo?.data?.hasDelayedData === true) { itemNames = itemNames?.map(item => item.startsWith('quote:') ? `d${item}` : item) }

        const unLockMutex = async () => await Promise.all(itemNames?.map(item => MutexExit(item)))

        await Promise.all(itemNames?.map(item => MutexEnter(item)))

        options = options || {}
        const emitAllEvents = options?.allEvents === null ? GetUseVerboseRedux() : options?.allEvents

        const ns = options.namespace || 'default'

        // Check with TicketManager if subscription already exists
        let ticket = TicketManager.PutTicketIfExists(itemNames, ns)
        if (ticket) {
            await unLockMutex()
            log(`Ticket already exists in ${ns} for`, itemNames, ':', ticket)
            return ticket
        }

        // Otherwise make the whole subscription and report it to the ticket manager
        log(`Subscribing to ${itemNames.join(',')} in group ${groupName} w/ ${actionNames.Subscribing}`)
        const stack = GetShowDebugHud() ? (new Error().stack) : null

        const scrubbedItemNames = itemNames?.filter(i => i.indexOf(' ') === -1)
        if (!scrubbedItemNames.length) {
            log('WARN: No valid items to subscribe')
            await unLockMutex()
            return null
        }

        const sub = new SnexLsSubscription(options.mode || 'DISTINCT', itemNames, fields)

        const listener = new SnexLsSubscriptionListener()

        if (emitAllEvents) dispatch({ type: actionNames.Subscribing, subject: options.subject })

        if (emitAllEvents) {
            listener.onSubscription = () => {
                dispatch({ type: actionNames.Subscribed, subject: options.subject })
            }
        }
        listener.onSubscriptionError = (code: number, message: string) => {
            DebugDumpManager.RecordEvent({ item: itemNames?.join(', '), event: 'Subscription Error', other: `${code} - ${message} - ${JSON.stringify(options)}` })
            dispatch(
                {
                    type: actionNames.Terminated,
                    error: { errorCode: 'LS_SUBSCRIBE_ERROR', errorMessage: `Live data subscription failed with code ${code}`, errorNote: message },
                    subject: options.subject
                })
        }
        if (emitAllEvents) listener.onUnsubscription = () => dispatch({ type: actionNames.Unsubscribed, subject: options.subject })

        listener.onItemUpdate = async (update: SnexLsItemUpdate) => {
            if (GetShowDebugHud()) TicketManager.TickUpdate(itemNames, ns)
            let item = onUpdate ? onUpdate(update, dispatch) : update
            if (typeof (item.then) === 'function') item = await item
            const action = { type: actionNames.Update, data: item, subject: options.subject }
            dispatch(action)
        }
        listener.onListenEnd = (sub: Subscription) => {
            DebugDumpManager.RecordEvent({ item: itemNames?.join(', '), event: 'Subscription Terminated', other: `${sub.getItemGroup()} - ${sub?.getItems()?.join(', ')} - ${JSON.stringify(options)}` })
            return ({ type: actionNames.Terminated })
        }

        sub.setDataAdapter(groupName)
        sub.setRequestedMaxFrequency(options.maxFrequencySeconds || 1)
        sub.setRequestedSnapshot(options.includeSnapshot ? 'yes' : 'no')
        sub.addListener(listener)

        // @ts-ignore
        const esub: ExtendedSubscription = sub
        esub.namespace = ns
        esub.stack = stack
        esub.upstream = options?.upstream || 'snex'

        DebugDumpManager.RecordEvent({ item: itemNames?.join(', '), event: 'New Subscription', other: `${groupName} / ${itemNames?.join(', ')} /  ${JSON.stringify(options)}` })
        // Report new subscription to TicketManager, and get a new ticket for this consumer
        TicketManager.PutSubscription(esub)
        ticket = TicketManager.PutTicketIfExists(itemNames, ns)

        try {
            const disabled = options.upstream === 'gain' ? GetDisableGainStreaming() : GetDisableSnexStreaming()
            if (!disabled) Lightstreamer.Subscribe(esub)
            else DebugDumpManager.RecordEvent({ item: `<SESSION:${options.upstream}>`, event: 'Debug: Subscribe Disabled', other: 'Not subscribing per debug option' })
            await unLockMutex()
            return ticket
        } catch (error) {
            log('ERROR', { error, groupName, itemNames, actionNames, options })
            await unLockMutex()
            return null
        }
    }
}

export const ReduxLiveUnsubscribe = (ticket: string) => {
    return TicketManager.CloseTicket(ticket)
}

export const ReduxLiveUnsubscribeNamespace = (namespace: string) => TicketManager.CloseNamespace(namespace)

export const ReduxLiveUnsubscribeAll = () => TicketManager.CloseAll()
