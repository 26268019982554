// @ts-strict-ignore
import { Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { useMemo, useState } from 'react';
import { AnchoredMenu, StyledIcon } from '../..';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { Flex } from '../../Flex';
import { useColors } from 'hooks/UseColors';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { T } from 'phoenix/assets/lang/T';
import { OrderType } from 'phoenix/redux/models';
import { OptionsOpenClose, OrderTypes, TradeActions } from 'phoenix/constants/Trade';
import { Sum } from 'phoenix/util';
import { TabSelectPresentation } from 'components/TabSelect/TabSelect';
import { getDefaultPrice, useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { DisplayOrderType, OptionsTradeTicketViewModel, TradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { GetAssetClassForSecurity, useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { useSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { ApiOrderType, ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { XS } from 'phoenix/xstream/XS';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { AssetTradeabilityProfile, MarketTimeSegmentDefinition } from 'phoenix/constants/AssetTradeability';

export const TradeOptionHeader = React.memo(() => {
    const { limitPrice, modifyingOrder, orderType, setViewModel, stopPrice, symbol, tradeAction } = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const selectedAccountNumber = useSelectedAccountForOrderRequest(GetAssetClassForSecurity(symbol));
    const orderTypeDisabled = modifyingOrder;
    const [menuEl, setMenuEl] = useState<HTMLDivElement>(null);
    const [appWindowSize] = useAppWindowSize();
    const colors = useColors();
    const color = FuturesSymbol.IsFuturesSymbol(symbol) ? colors.futuresColor : colors.primaryItemColor;
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const permittedOrderTypes = useAssetClass(symbol)?.tradeability?.permittedOrderTypes;
    const assetClass: AssetClass = useAssetClass(symbol);
    const tradeability = assetClass.tradeability;
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const quote = XS.OptionQuotes.use(symbol);
    const [marketTimeSegment] = useMarketTimeSegmentV2();

    const fontSizes = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.small:
            case WindowSizes.tablet:
                return { secId: 16, subtitle: 16, tabs: 3 };
            default:
                return { secId: 16, subtitle: 16, tabs: 3 };
        }
    }, [appWindowSize]);

    const positions = usePositionsStore((s) => s.find({ qsi: symbol, accountNumber: selectedAccountNumber }));
    const holdsSecurity = !!positions?.length;
    const isLong = holdsSecurity ? Sum(positions?.map((p) => p.quantity)) > 0 : false;

    const tradeabilityMap = {
        closed: 'marketClosed' as keyof AssetTradeabilityProfile['schedule'],
        open: 'marketOpen' as keyof AssetTradeabilityProfile['schedule']
    };

    const tradeabilityKey: keyof AssetTradeabilityProfile['schedule'] =
        tradeabilityMap?.[marketTimeSegment as 'closed' | 'open'] || (marketTimeSegment as keyof AssetTradeabilityProfile['schedule']);
    const marketTimeSegmentDefinition: MarketTimeSegmentDefinition | undefined = tradeability?.schedule?.[tradeabilityKey];

    const permTypes = useMemo(() => {
        const set = !permittedOrderTypes ? null : new Set<ApiOrderType>(permittedOrderTypes);
        return [
            {
                label: 'Market',
                value: OrderTypes.market,
                disabled: marketTimeSegmentDefinition?.orderTypes && !marketTimeSegmentDefinition.orderTypes.find((y) => y.orderType === OrderTypes.market)
            },
            {
                label: 'Limit',
                value: OrderTypes.limit,
                disabled: marketTimeSegmentDefinition?.orderTypes && !marketTimeSegmentDefinition.orderTypes.find((y) => y.orderType === OrderTypes.limit)
            },
            {
                label: 'Stop',
                value: OrderTypes.stop,
                disabled: marketTimeSegmentDefinition?.orderTypes && !marketTimeSegmentDefinition.orderTypes.find((y) => y.orderType === OrderTypes.stop)
            },
            {
                label: 'Stop Limit',
                value: OrderTypes.stoplimit,
                disabled: marketTimeSegmentDefinition?.orderTypes && !marketTimeSegmentDefinition.orderTypes.find((y) => y.orderType === OrderTypes.stoplimit)
            }
        ].filter((x) => set === null || set.has(x.value));
    }, [marketTimeSegmentDefinition?.orderTypes, permittedOrderTypes]);
    const orderTypeLabel = permTypes.find((x) => x.value === orderType)?.label || 'Market';

    const actions = useMemo(() => {
        const actionsWhileHolding = isLong
            ? [
                  T((t) => t.tradeTicket.input.options.actionToOpenClose({ action: TradeActions.Buy }, OptionsOpenClose.Open)),
                  T((t) => t.tradeTicket.input.options.actionToOpenClose({ action: TradeActions.Sell }, OptionsOpenClose.Close))
              ]
            : [
                  T((t) => t.tradeTicket.input.options.actionToOpenClose({ action: TradeActions.Buy }, OptionsOpenClose.Close)),
                  T((t) => t.tradeTicket.input.options.actionToOpenClose({ action: TradeActions.Sell }, OptionsOpenClose.Open))
              ];

        const nonFuturesActions = holdsSecurity
            ? actionsWhileHolding
            : [
                  T((t) => t.tradeTicket.input.options.actionToOpenClose({ action: TradeActions.Buy }, OptionsOpenClose.Open)),
                  T((t) => t.tradeTicket.input.options.actionToOpenClose({ action: TradeActions.Sell }, OptionsOpenClose.Open))
              ];

        const [buy, sell] = isFuture ? [T((t) => t.optionListScreen.buy), T((t) => t.optionListScreen.sell)] : nonFuturesActions;

        return [
            { label: buy, value: TradeActions.Buy },
            { label: sell, value: TradeActions.Sell }
        ];
    }, [holdsSecurity, isFuture, isLong]);

    // Set default prices on order type OR trade action change, if the user has not entered/manipulated any values
    const onFieldUpdate = (field: 'tradeAction' | 'orderType', value: ApiTradeAction | DisplayOrderType) => {
        const newState: Pick<TradeTicketViewModel, 'tradeAction' | 'orderType' | 'initialLimitPrice' | 'initialStopPrice' | 'isGtx' | 'timeInForce'> = { [field]: value };
        const effectiveOrderType = field === 'orderType' ? value : orderType;
        const effectiveTradeAction = field === 'tradeAction' ? value : tradeAction;

        if (!limitPrice) {
            const converted: ApiOrderType = effectiveOrderType === 'loc' ? 'limit' : (effectiveOrderType as ApiOrderType);
            const defaultPrice = getDefaultPrice({ assetClass, orderType: converted, quote, tradeAction: effectiveTradeAction as ApiTradeAction });

            newState.initialLimitPrice = defaultPrice;
        }

        if (!stopPrice) {
            const converted: ApiOrderType = effectiveOrderType === 'loc' ? 'limit' : (effectiveOrderType as ApiOrderType);
            const defaultPrice = getDefaultPrice({ assetClass, orderType: converted, quote, tradeAction: effectiveTradeAction as ApiTradeAction });

            newState.initialStopPrice = defaultPrice;
        }

        // If the order type is changing in a way that removes a field, and the user has entered values in that field, set them to the initial value
        // Important to keep this after the above changes to override any default price settings
        if (field === 'orderType') {
            if (!value.includes('stop') && !!stopPrice) {
                newState.initialStopPrice = stopPrice;
            }

            if (!value.includes('limit') && !!limitPrice) {
                newState.initialLimitPrice = limitPrice;
            }

            // post-market orders can only be GTC, so change the TIF to that
            if (assetClass?.family === 'equities' && marketTimeSegment === 'postmarket') {
                newState.timeInForce = 'GTC';
            }
        }

        setViewModel(newState);
    };

    // Cannot open new positions on adjusted options
    const selectedAction =
        meta?.isAdjusted && !holdsSecurity
            ? // We want both options not selected and disabled in this case
              'NONE' // This string can be anything that is not indexable from the options provided
            : tradeAction;

    return (
        <div className={'trade-header'}>
            <Flex row style={{ flex: 1 }}>
                <TabSelectPresentation
                    textColorOverride={color}
                    color={color}
                    defaultSelected={selectedAction}
                    disabled={orderTypeDisabled || meta?.isAdjusted}
                    values={orderTypeDisabled ? actions.filter((a) => a.value === tradeAction) : actions}
                    onSelect={(v: ApiTradeAction) => onFieldUpdate('tradeAction', v)}
                />
            </Flex>
            {permTypes?.length > 1 ? (
                <Flex row align='center' style={{ background: 'transparent', cursor: 'pointer' }}>
                    <Flex align='center' onClick={(e) => setMenuEl(e.target)}>
                        <Typography style={{ fontSize: fontSizes.secId }} variant='h5'>
                            {orderTypeLabel}
                        </Typography>
                        {!orderTypeDisabled && <StyledIcon IconComponent={Edit} size={fontSizes.secId} style={{ marginLeft: 10, color: colors.generalTextColor }} />}
                    </Flex>
                    <AnchoredMenu
                        anchorEl={menuEl}
                        handleClose={() => setMenuEl(null)}
                        items={permTypes.map((t) => {
                            return { disabled: t.disabled, label: t.label, onClick: () => setViewModel({ orderType: t.value }) };
                        })}
                    />
                </Flex>
            ) : null}
        </div>
    );
});
