// @ts-strict-ignore
import { GetConfig } from 'phoenix/constants';
import { OptionSymbol, SecurityChartData, SecurityQuote } from 'phoenix/redux/models';
import { ReduxAction } from '../models';
import { QuoteAttribute } from '../util';
import { XstreamState } from './XstreamState';

export function getTimeDiffInMinutes(oldTime: number, newTime: number = new Date().getTime()): number {
    return (newTime - oldTime) / 1000 / 60;
}

function checkIfStreamPointIsWithinFrequency(streamingPointTimestamp: number, lastTimestampPlotted: number, chartFrequency: number): boolean {
    const currentPointMinuteCutoff = getMinuteFromTimestamp(lastTimestampPlotted) + chartFrequency;
    const streamingPointMinute = getMinuteFromTimestamp(streamingPointTimestamp);
    return streamingPointMinute < currentPointMinuteCutoff;
}

export function getMinuteFromTimestamp(timestamp: number): number {
    return Math.floor(timestamp / 1000 / 60);
}

// The stream returns total volume so subtract previous volume to get current
export function getBarVolume({
    chartFrequencyInMinutes,
    data,
    quote,
    incomingData
}: {
    chartFrequencyInMinutes: number;
    data: SecurityChartData[];
    incomingData: { timestamp: number; volume: number };
    quote: SecurityQuote;
}): number {
    if (!quote) return 0;
    const sorted = data?.length
        ? [...data.sort((a, b) => Date.parse(new Date(QuoteAttribute.getTime(b)).toUTCString()) - Date.parse(new Date(QuoteAttribute.getTime(a)).toUTCString()))]
        : [];

    const lastPoint = sorted.length ? sorted[0] : incomingData;
    const diffInMinutes = getTimeDiffInMinutes(QuoteAttribute.getTime(lastPoint), incomingData?.timestamp);
    const isNewBar = diffInMinutes > chartFrequencyInMinutes;

    // For updating current bar: Add up all previous streaming volume and static volume from the quote, excluding the volume from the current bar that's being updated
    const currentBarPreviousVolume = sorted.length > 1 ? sorted.slice(1).reduce((s, { volume }) => s + volume, quote?.volume || 0) : quote?.volume || 0;
    // For creating new bar: subtract volume from all previous bars and quote
    const newBarPreviousVolume = sorted.reduce((s, { volume }) => s + volume, quote?.volume);
    const previousVolume = isNewBar ? newBarPreviousVolume : currentBarPreviousVolume;
    const newVolume = incomingData?.volume - previousVolume;

    // If for some reason volume on the stream is out-of-date (lower) than the API quote, just return 0
    return newVolume < 0 ? 0 : newVolume;
}

export function getStreamingUpdate({
    action,
    apiQuote,
    state,
    lastApiPointTimestamp
}: {
    action: ReduxAction;
    apiQuote: SecurityQuote;
    state: XstreamState;
    lastApiPointTimestamp?: number;
}): SecurityChartData[] {
    const { chartFrequencyInMinutes } = state || {};

    const streamingData = state?.streamingCharts?.[action?.subject] || [];
    const sortedVals = streamingData.length
        ? [...streamingData.sort((a, b) => Date.parse(new Date(QuoteAttribute.getTime(b)).toUTCString()) - Date.parse(new Date(QuoteAttribute.getTime(a)).toUTCString()))]
        : [];

    const incomingPrice = QuoteAttribute.getPrice(action?.data);

    const lastPointTimestamp = sortedVals.length ? sortedVals[0]?.timestamp : lastApiPointTimestamp || QuoteAttribute.getTime(action?.data);

    const isPointWithinFrequency = checkIfStreamPointIsWithinFrequency(QuoteAttribute.getTime(action?.data), lastPointTimestamp, chartFrequencyInMinutes);

    let newData = [];
    let bar;

    if (isNaN(incomingPrice)) {
        newData = [...(sortedVals || [])];
    } else if (isPointWithinFrequency && incomingPrice) {
        const lastTime = lastPointTimestamp;
        const timestampforLastPoint = Date.parse(lastTime ? new Date(lastTime).toUTCString() : new Date().toUTCString());
        const { latestPrice, extendedPrice, extendedPriceTime, extendedChange, extendedChangePercent } = action?.data || {};

        newData = streamingData.length
            ? sortedVals
            : [
                  {
                      ...action?.data,
                      timestamp: timestampforLastPoint,
                      high: incomingPrice,
                      low: incomingPrice,
                      close: incomingPrice,
                      open: incomingPrice
                  }
              ];

        bar = {
            ...newData[0],
            latestPrice: incomingPrice || 0,
            high: Math.max(newData[0].high, incomingPrice),
            low: Math.min(newData[0].low, incomingPrice),
            close: incomingPrice,
            volume: getBarVolume({ chartFrequencyInMinutes, data: sortedVals, incomingData: action?.data, quote: apiQuote })
        };

        if (!isNaN(extendedPrice) && !isNaN(extendedPriceTime) && !isNaN(extendedChange) && !isNaN(extendedChangePercent)) {
            bar.extendedPrice = extendedPrice && !isNaN(extendedPrice) ? extendedPrice : latestPrice;
            bar.extendedChange = extendedChange && !isNaN(extendedChange) ? extendedChange : 0;
            bar.extendedChangePercent = extendedChangePercent && !isNaN(extendedChangePercent) ? extendedChangePercent : 0;
        }
        newData[0] = bar;
    } else {
        if (incomingPrice) {
            bar = {
                ...action?.data,
                timestamp: QuoteAttribute.getTime(action?.data) || Date.parse(new Date().toUTCString()),
                high: incomingPrice,
                low: incomingPrice,
                close: incomingPrice,
                open: incomingPrice,
                volume: getBarVolume({ chartFrequencyInMinutes, data: sortedVals, incomingData: action?.data, quote: apiQuote })
            };
            newData = [...(sortedVals || []), bar];
        } else {
            newData = [...(sortedVals || [])];
        }
    }
    newData = newData.sort((a, b) => Date.parse(new Date(b.timestamp).toUTCString()) - Date.parse(new Date(a.timestamp).toUTCString()));

    return newData;
}

export const XStreamUpdateChart = (state: XstreamState, action: ReduxAction): SecurityChartData[] => {
    const isOption = OptionSymbol.IsOptionSymbol(action.subject);

    // We should get the restful chart, and grab the last point to pass down to the getStreamingUpdate
    const chartFrequency = state.chartFrequencyInMinutes;
    const apiRangeByFrequency = chartFrequency === 5 ? 'loRes' : '1d';
    const apiChart = GetConfig().Store.getState().securityChart.bySymbol[action.subject]?.[apiRangeByFrequency]?.data;
    const lastApiPointTimestamp = apiChart?.[0]?.timestamp;

    const apiQuote = isOption
        ? GetConfig().Store.getState().options.quotesByOsi[action.subject]?.data
        : GetConfig().Store.getState().securityQuote.bySymbol[action?.subject]?.data;
    const newState = getStreamingUpdate({ action, apiQuote, state, lastApiPointTimestamp });

    return newState;
};
