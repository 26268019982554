// @ts-strict-ignore
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { GetSecurityMetadataAction } from 'phoenix/redux/actions';
import { TradeableSecurityType } from 'phoenix/util';
import { EquityWatchlistCell } from '../WatchlistCell/EquityWatchlistCell';
import { FuturesWatchlistCell } from '../WatchlistCell/FuturesWatchlistCell';
import { Card, Flex, StyledIcon } from '../..';
import style from '../style.module.scss';
import { CancelOutlined } from '@mui/icons-material';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { WatchlistSecurity } from 'phoenix/redux/models';
import { WatchlistCellProps } from '../WatchlistCell';
import { useColors } from 'hooks/UseColors';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';

export interface SearchResultCellProps {
    getCached?: boolean;
    onDelete?: () => void;
    removable?: boolean;
    secType?: TradeableSecurityType;
    security?: WatchlistSecurity;
    streamingNamespace?: string;
    symbol: string;
    visible?: boolean;
}

export const SearchResultCell = React.memo((props: SearchResultCellProps) => {
    const { removable, symbol } = props;
    const metadata = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata);
    const dispatch = useDispatch();
    const colors = useColors();

    useEffect(() => {
        if (!metadata) dispatch(GetSecurityMetadataAction(symbol));
    }, [metadata]);

    const onDelete = (e) => {
        e.stopPropagation();
        if (props.onDelete) props.onDelete();
    };

    const security = useMemo(() => ({ name: metadata?.data?.description1 || '' }), [metadata]);

    return (
        <Card>
            <Flex row className={`${style.withoutHoverEffectWrapper}`}>
                <Flex row className={`${style.innerWrapper} ${style.withoutBorder}`} style={{ minHeight: 60 }}>
                    <Content {...props} security={security} />
                    <Flex align='center' className={style.deleteAction} justify='flex-end' {...{ ...(removable && { tabIndex: 0, onClick: onDelete }) }}>
                        {removable && <StyledIcon IconComponent={CancelOutlined} size='22px' style={{ color: colors.fadedTextColor }} />}
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
});

export const Content = React.memo((props: WatchlistCellProps) => {
    const { getCached, streamingNamespace, secType: incomingType, visible, security, symbol } = props;
    const commonProps = { getCached, streamingNamespace, visible, security, symbol };
    const secType = incomingType || GetAssetClassForSecurity(symbol).type;
    /* Showcasing all the case we can possible expand to if needed and then some.  */
    switch (secType) {
        case 'future':
            return <FuturesWatchlistCell {...commonProps} />;
        case 'option':
        case 'mutual-fund':
        case 'equity':
        case 'adr':
        case 'unknown':
        default:
            return <EquityWatchlistCell {...commonProps} />;
    }
});
