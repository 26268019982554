// @ts-strict-ignore
import { AxiosError } from 'axios';
import { Urls } from 'phoenix/constants';
import { DownOperationSubmitting, UpOperationSubmitting } from 'phoenix/hooks/UseOperationSubmitting';
import { ApiTradeRequest } from 'phoenix/models/ApiTradeRequest';
import { SnexError } from 'phoenix/models/SnexError';
import { TradeResult, TradeResultWithoutOrder } from 'phoenix/models/TradeResult';
import { GetSingleOrderAction, RemoveOrderFromReduxStoreAction } from 'phoenix/redux/actions';
import { Order } from 'phoenix/redux/models';
import { DispatchIntoSnexStore } from 'phoenix/redux/util/DispatchIntoSnexStore';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { useOrdersStore } from 'phoenix/stores/OrdersStore';
import { CreateTradeResultForError, CreateTradeResultForErrorCode } from 'phoenix/util/Trading/TradeValidation';

export const SubmitTrade = async (trade: ApiTradeRequest): Promise<TradeResult> => {
    UpOperationSubmitting();
    try {
        const result = await SnexAxios.ApiPost<Order>(Urls.trading.submit(), { data: trade }).run();
        return { success: true, order: result };
    } catch (e) {
        return CreateTradeResultForError((e as AxiosError<SnexError>)?.response?.data || (e as SnexError), trade);
    } finally {
        DownOperationSubmitting();
    }
};

export const UpdateTrade = async (trade: ApiTradeRequest): Promise<TradeResult> => {
    UpOperationSubmitting();
    try {
        if (!trade.orderId) return CreateTradeResultForErrorCode('INTERNAL', trade);

        const result = await SnexAxios.ApiPut<Order>(Urls.trading.edit(trade.orderId), { data: trade }).run();
        useOrdersStore.getState().removeOrder(trade.orderId);
        // TODO: Remove when mobile integrates with OrdersStore
        DispatchIntoSnexStore(RemoveOrderFromReduxStoreAction(trade.orderId));
        return { success: true, order: result };
    } catch (e) {
        return CreateTradeResultForError((e as AxiosError<SnexError>)?.response?.data || (e as SnexError), trade);
    } finally {
        DownOperationSubmitting();
    }
};

export const CancelTrade = async (orderId: string): Promise<TradeResultWithoutOrder> => {
    UpOperationSubmitting();
    try {
        if (!orderId) return CreateTradeResultForErrorCode('INTERNAL', null) as TradeResultWithoutOrder;
        await SnexAxios.ApiDelete(Urls.trading.cancel(orderId)).run();
        // TODO: Remove when Mobile integrates with OrdersStore
        DispatchIntoSnexStore(GetSingleOrderAction(orderId));
        await useOrdersStore.getState().getSingleOrder(orderId, 'OrderId');
        return { success: true };
    } catch (e) {
        const ae = e as AxiosError;
        return { error: (ae?.response?.data as SnexError) || (e as SnexError), success: false };
    } finally {
        DownOperationSubmitting();
    }
};
