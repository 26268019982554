import { GetLocalUrls } from '../util';
import { StorageKeys } from './StorageKeys';
import { T } from '../assets/lang/T';
import { OptionSymbol, OrderIdentifierType } from '../redux/models';
import { PaperlessElectionType } from '../redux/models/Documents/PaperlessPref';
import { ClientMdcEventName } from '../redux/models/Messages/ClientMessageDeliveryControls';
import { GetMemo } from '../util/Memo';
import { ChartRange } from './ChartRange';
import { GetConfig } from './Config';
import { ProductCode } from './PremiumProducts';
import { ReportingPeriod } from './ReportingPeriod';
import { Okta } from '../util/Okta';
import { Whitelabel } from 'phoenix/constants/Whitelabels';
import { PaginationOffsets } from 'phoenix/redux/models/Paging/Page';
import { TransactionsSearchFiltersV2 } from 'phoenix/redux/actions/TransactionActions';
import { WeatherForecastType } from 'phoenix/stores/weather/useWeatherForecastStore';
import { OrderSearchFiltersV3 } from 'phoenix/redux/actions';

export type Environment =
    | 'local'
    | 'development'
    | 'test'
    | 'beta'
    | 'staging'
    | 'production'
    | 'pro'
    | Whitelabel.Aiva
    | Whitelabel.Galicia
    | Whitelabel.Winexco
    | Whitelabel.FolioNet
    | Whitelabel.AvSecurities
    | Whitelabel.ConosurInversiones
    | Whitelabel.Apac;
export type SnexDomain =
    | 'http://localhost:3000'
    | 'https://one.dev.stonex.com'
    | 'https://one.test.stonex.com'
    | 'https://one.beta.stonex.com'
    | 'https://one.staging.stonex.com'
    | 'https://one.stonex.com'
    | 'https://pro.stonexone.com'
    | 'https://Aiva.stonexone.com'
    | 'https://Galicia.stonexone.com'
    | 'https://Winexco.stonexone.com'
    | 'https://folionet.stonexone.com'
    | 'https://avsecurities.stonexone.com'
    | 'https://conosurinversiones.stonexone.com'
    | 'https://apac.stonexone.com';

type EnvironmentResolver = () => Environment;
let _envResolve: EnvironmentResolver = () => 'production';
let _altLocalHost = '';

export const SetEnvironmentResolver = (resolver: EnvironmentResolver): EnvironmentResolver => (_envResolve = resolver);
export const SetUseAlternateHostWhenLocal = (host: string): string => (_altLocalHost = host);
export const GetLocalAlternativeHost = (): string => _altLocalHost;

let _envApiPath = 'api';
export const SetApiPath = (pathWithoutSlashes: string): string => (_envApiPath = pathWithoutSlashes);

export const ResolveEnvironment = (): Environment => _envResolve();
export const IsEnvironment = (envs: Environment[]): boolean => envs.includes(ResolveEnvironment());
export const IsNonProd = (): boolean => IsEnvironment(['local', 'development', 'test']);
export const IsProduction = (): boolean => !IsNonProd();

const resolveBackend = (): string => {
    const env = ResolveEnvironment();
    if (env === 'local' && _altLocalHost) return _altLocalHost;
    return `${resolveFrontend()}/${_envApiPath}`;
};
export const ResolveBackend = (): string => resolveBackend();

const resolveFrontend = (overrideEnv?: Environment): SnexDomain => {
    const env = overrideEnv || ResolveEnvironment();
    switch (env) {
        case 'local':
            return 'http://localhost:3000';
        case 'development':
            return 'https://one.dev.stonex.com';
        case 'test':
            return 'https://one.test.stonex.com';
        case 'beta':
            return 'https://one.beta.stonex.com';
        case 'staging':
            return 'https://one.staging.stonex.com';
        case 'pro':
            return 'https://pro.stonexone.com';
        case Whitelabel.Aiva:
            return `https://${Whitelabel.Aiva}.stonexone.com`;
        case Whitelabel.Galicia:
            return `https://${Whitelabel.Galicia}.stonexone.com`;
        case Whitelabel.Winexco:
            return `https://${Whitelabel.Winexco}.stonexone.com`;
        case Whitelabel.FolioNet:
            return `https://${Whitelabel.FolioNet}.stonexone.com`;
        case Whitelabel.AvSecurities:
            return `https://${Whitelabel.AvSecurities}.stonexone.com`;
        case Whitelabel.ConosurInversiones:
            return `https://${Whitelabel.ConosurInversiones}.stonexone.com`;
        case Whitelabel.Apac:
            return `https://${Whitelabel.Apac}.stonexone.com`;
        case 'production':
            return 'https://one.stonex.com';
        default: {
            const exhaustiveCheck: never = env;
            console.warn(`Unhandled environment: ${exhaustiveCheck}`);
            return 'https://one.stonex.com'; // default to prod
        }
    }
};

export const ResolveFrontend = (overrideEnv?: Environment): SnexDomain => resolveFrontend(overrideEnv);

// TODO: Abstract these ids into more secure storage
export const resolveGainLsAppId = (): string =>
    ['local', 'development', 'test'].includes(ResolveEnvironment())
        ? 'fa097f61-c884-4f80-b35a-b3d4c2ee5872' // non-prod
        : '85e03267-0560-408d-a1bf-e38073980b56'; // prod

export const MakeQueryString = (query?: { [key: string]: string | number | undefined }, noEncodeKeys?: Set<string>): string =>
    Object.entries(query || {})
        .map(([key, value]) => {
            if (value === undefined || value === null) return null;
            const v = noEncodeKeys?.has(key) ? value?.toString() : encodeURIComponent(value?.toString());
            return `${key}=${v}`;
        })
        .filter((x) => !!x)
        .join('&');

const sanitizeUrl = (url: string): string => {
    url = url.replace('??', '?'); // clean up multiple ?? in urls
    url = url.replace(/(,$)/g, '').replace(/(%2C$)/g, ''); // remove trailing commas
    return url;
};

export const MakeUrlWithPagingOffsets = (path: string, query: { [key: string]: string | number }, offsets?: PaginationOffsets, noEncodeKeys?: Set<string>): string => {
    let url = MakeUrl(path, query, noEncodeKeys);

    if (offsets) {
        const getOffsetParams = (): string =>
            Object.entries(offsets)
                .map((e) => `skip[${e[0]}]=${e[1]}`)
                .join('&');
        url += `&${getOffsetParams()}`;
    }

    return url;
};

export const MakeUrl = (path: string, query?: { [key: string]: string | number | undefined }, noEncodeKeys?: Set<string>): string => {
    const queryString = MakeQueryString(query, noEncodeKeys);
    const runLocal = GetLocalUrls()?.some((p) => new RegExp(p).test(path));
    return sanitizeUrl(`${runLocal ? 'http://localhost:54965' : resolveBackend()}${path}?${queryString}`);
};

export const MakeUrlCustomQuery = (path: string, query: string): string => {
    const runLocal = GetLocalUrls()?.some((p) => new RegExp(p).test(path));
    return sanitizeUrl(`${runLocal ? 'http://localhost:54965' : resolveBackend()}${path}?${query}`);
};

export const MakeVulcanIdUrl = (path: string, query: { [key: string]: string }): string => {
    const backend = IsNonProd() ? 'https://vulcan.test.stonex.com/identity' : 'https://vulcan.stonex.com/identity';
    return `${backend}/${path.replace(/^\//, '').replace(/^identity/, '')}?${MakeQueryString(query)}`;
};

export const Urls = {
    azureFunctions: {
        resetPositions: (queryString: string): string => MakeUrlCustomQuery(`/admin/reset-position`, queryString)
    },
    algolia: {
        generateKey: (): string => MakeUrl('/algolia/generate-key')
    },
    authentication: {
        loginPage: (redirectUrl?: string): string => MakeUrl(`/login?${redirectUrl ? `redirectUrl=${redirectUrl}` : ''}`),
        getToken: (): string => MakeUrl('/debug/get-token'),
        getTokenFromCode: (code: string, state: string): string => MakeUrl('/token/mobile-code-exchange', { code, state }),
        /** @deprecated Old refresh endpoint, please use Okta.RefreshToken instead */ getNewTokenFromRefreshToken: (refreshToken: string): string =>
            MakeUrl('/token/refresh', { refreshToken }),
        unauthError: (): string => MakeUrl('/debug/unauth'),
        vulcanServer: (): string => (IsProduction() ? 'https://vulcan.stonex.com' : 'https://vulcan.test.stonex.com'),
        vulcanIdServer: (): string => (IsProduction() ? 'https://vulcan.stonex.com/identity' : 'https://vulcan.test.stonex.com/identity'),

        okta: {
            authn: (): string => `${Okta.GetHost()}/api/v1/authn`,
            authorize: (query: { [key: string]: string }): string =>
                `${Okta.GetHost()}/oauth2/${Okta.GetAuthServerId()}/v1/authorize?${MakeQueryString(query, new Set(['scope']))}`,
            webLogin: (sessionToken: string, redirectUrl: string): string =>
                `${Okta.GetHost()}/login/sessionCookieRedirect?token=${sessionToken}&redirectUrl=${redirectUrl}`,
            getTokenViaProxy: (sessionToken: string, upgradeToVulcan?: boolean): string =>
                MakeUrl('/token/okta/get-token', { sessionToken, upgradeToVulcan: upgradeToVulcan ? 'true' : 'false' }),
            refreshViaProxy: (refreshToken: string, upgradeToVulcan?: boolean): string =>
                MakeUrl('/token/okta/refresh', { refreshToken, upgradeToVulcan: upgradeToVulcan ? 'true' : 'false' }),
            getToken: (): string => `${Okta.GetHost()}/oauth2/${Okta.GetAuthServerId()}/v1/token`,
            resetPassword: (): string => `${Okta.GetHost()}/api/v1/authn/recovery/password`,
            forgotPassword: {
                sendCode: (): string => MakeUrl('/auth/forgot-password/send-code'), // Sends magic link email
                retrieveSession: (deeplinkUrl: string): string => MakeUrl('/auth/forgot-password/retrieve-session', { deeplinkUrl }), // Converts deeplink into recovery session ID
                updatePassword: (): string => MakeUrl('/auth/forgot-password/update-password')
            },
            unlock: (): string => `${Okta.GetHost()}/api/v1/authn/recovery/unlock`,
            changePasswordViaProxy: (): string => MakeUrl('/auth/change-password'),
            checkIfLockedViaProxy: (email: string): string => MakeUrl('/auth/okta/is-locked', { email }),
            endCurrentSession: (): string => `${Okta.GetHost()}/api/v1/sessions/me`,
            mfa: {
                sms: {
                    enroll: (): string => MakeUrl('/auth/mfa/sms/enroll')
                },
                challenge: (): string => MakeUrl('/auth/mfa/challenge'),
                verify: (): string => MakeUrl('/auth/mfa/verify')
            }
        },
        vulcanUpgrade: (): string => MakeUrl('/token/vulcan/upgrade'),
        logout: (): string => MakeUrl('/logout').replace(/dev/, 'test'),
        passwordUnlock: (email: string): string => MakeUrl('/auth/okta/unlock', { email })
    },
    authorizationSettings: {
        getSettings: (): string => MakeUrl('/admin/authSettings'),
        saveSettings: (): string => MakeUrl('/admin/authSettings')
    },
    registration: {
        addAccount: (redirectUrl = 'https://one.stonex.com'): string => {
            switch (ResolveEnvironment()) {
                case 'production':
                case 'staging':
                    return `https://vulcan.stonex.com/l/signup/onboarding?privatelabelcode=snex1&redirectUrl=${redirectUrl}`;
                default:
                    return `https://vulcan.test.stonex.com/l/signup/onboarding?privatelabelcode=snex1&redirectUrl=${redirectUrl}`;
            }
        },
        enableOptions: (accountNumber: string, redirectUrl = 'https://one.stonex.com'): string => {
            switch (ResolveEnvironment()) {
                case 'production':
                case 'staging':
                    return `https://vulcan.stonex.com/l/options?privatelabelcode=snex1&accountNumber=${accountNumber}&redirectUrl=${redirectUrl}`;
                default:
                    return `https://vulcan.test.stonex.com/l/options?privatelabelcode=snex1&accountNumber=${accountNumber}&redirectUrl=${redirectUrl}`;
            }
        },
        enableMargin: (accountNumber: string, redirectUrl = 'https://one.stonex.com'): string => {
            switch (ResolveEnvironment()) {
                case 'production':
                case 'staging':
                    return `https://vulcan.stonex.com/l/margin?privatelabelcode=snex1&accountNumber=${accountNumber}&redirectUrl=${redirectUrl}`;
                default:
                    return `https://vulcan.test.stonex.com/l/margin?privatelabelcode=snex1&accountNumber=${accountNumber}&redirectUrl=${redirectUrl}`;
            }
        },
        userExists: (email: string): string => MakeUrl('/registration/user/', { email }),
        selfSignup: (): string => MakeUrl('/registration'),
        verifyCodeAndComplete: (): string => MakeUrl('/registration/complete'),
        verifyComplete: (): string => MakeUrl('/users/registration-complete'),
        resendVerificationCode: (email: string): string => MakeUrl('/registration/verify-resend/', { email })
    },
    acats: {
        getDeliveringFirms: (): string => MakeUrl('/acats/delivering-firms'),
        submit: (): string => MakeUrl('/acats/submit')
    },

    accounts: {
        getAll: (useStorefront = false): string => MakeUrl('/accounts', { provider: useStorefront ? 'storefront' : 'torch' }),
        getProjectedIncome: (accountNumber: string): string => MakeUrl(`/accounts/${accountNumber}/projected-income`),
        getProjectedIncomeV2: (accountNumber: string): string => MakeUrl(`/v2/accounts/${accountNumber}/projected-income`),
        downloadProjectedIncome: (accountNumber: string): string => MakeUrl(`/accounts/${accountNumber}/projected-income/csv`),
        search: (query: string): string => MakeUrl('/accounts', { q: query }),
        updateNick: (accountNumber: string): string => MakeUrl(`/accounts/${accountNumber}/nick`),
        getPendingFuturesAccounts: (): string => MakeUrl('/accounts/futures/pending')
    },

    accountApplicationStatus: {
        getByAccountNumber: (accountNumber: string, applicationId: string): string => MakeUrl(`/accounts/${accountNumber}/application-status/${applicationId}`)
    },

    accountOpenings: {
        getAll: (): string => MakeUrl('/accounts/openings')
    },

    accountCharts: {
        getAggregate: (range: ChartRange): string => MakeUrl('/portfolio/chart', { range }),
        getForAccount: (accountNumber: string, range: ChartRange): string => MakeUrl(`/accounts/${accountNumber}/chart`, { range }),
        getForManyAccounts: (accountNumbers: string[], range: ChartRange): string =>
            MakeUrl('/accounts/multi/chart', {
                range,
                accountNumbers: accountNumbers.join(',')
            })
    },

    alerts: {
        fields: (): string => MakeUrl('/alerts/fields'),
        getRules: (): string => MakeUrl('/alerts/rules'),
        createRule: (): string => MakeUrl('/alerts/rule/create'),
        pauseRule: (ruleId: string): string => MakeUrl(`/alerts/rule/pause/${ruleId}`),
        resumeRule: (ruleId: string): string => MakeUrl(`/alerts/rule/resume/${ruleId}`),
        updateRule: (ruleId: string): string => MakeUrl(`/alerts/rule/${ruleId}/update`),
        deleteRule: (ruleId: string): string => MakeUrl(`/alerts/rule/${ruleId}/delete`)
    },

    device: {
        getAll: (): string => MakeUrl('/devices'),
        register: (): string => MakeUrl('/devices/upsert'),
        unregister: (): string => MakeUrl('/devices/delete')
    },

    documents: {
        getTypes: (): string => MakeUrl('/document/types'),
        getTypesV2: (assetFamily: string): string => MakeUrl('/v2/document/types', { assetFamily }),
        getOne: (documentType: string, documentId: string): string => MakeUrl('/document/search', { documentType, documentId }),
        getOneV2: (documentType: string, documentId: string, documentSource: string): string =>
            MakeUrl('/v2/document/search', {
                documentSource,
                documentType,
                documentId
            }),
        search: (documentType: string, queryParams: { [key: string]: string }): string => MakeUrl('/document/search', { documentType, ...queryParams }),
        searchV2: (documentType: string, queryParams: { [key: string]: string | number }): string => MakeUrl('/v2/document/search', { documentType, ...queryParams }),
        download: (documentType: string, documentIds: string[]): string =>
            MakeUrl('/document/download', {
                documentType,
                documentIds: documentIds.join(',')
            }),
        downloadV2: (documentType: string, documentIds: string[], documentSource: string): string =>
            MakeUrl('/v2/document/download', {
                documentSource,
                documentType,
                documentIds: documentIds.join(',')
            }),
        getPaperlessPrefs: (): string => MakeUrl('/document/delivery'),
        updatePaperlessPrefs: (documentType: string, accountNumber: string, election: PaperlessElectionType): string =>
            MakeUrl(`/document/delivery/${documentType}/${accountNumber}`, {
                preference: election
            }),
        completelyPaperless: (election: PaperlessElectionType): string => MakeUrl('/document/delivery/all/', { preference: election })
    },

    funding: {
        v2: {
            getJobs: (skip: number, take: number): string => MakeUrl(`/funding/v2/jobs/all/${skip}/${take}`),
            limits: (): string => MakeUrl('/funding/v2/management/limits'),
            transfer: {
                deposit: (brokerageAccountNumber: string): string => MakeUrl(`/funding/v2/${brokerageAccountNumber}/deposit`),
                withdraw: (brokerageAccountNumber: string): string => MakeUrl(`/funding/v2/${brokerageAccountNumber}/withdraw`),
                validate: (): string => MakeUrl('/funding/v2/transfer/validate'),
                getMinimumAllowedDeposit: (accountNumber: string): string => MakeUrl(`/funding/v2/transfer/minDeposit/${accountNumber}`)
            },
            ains: {
                getVoids: (email: string): string => MakeUrl(`/funding/v2/support/voids/${email}`),
                getDepositFailures: (email: string): string => MakeUrl(`/funding/v2/support/deposit-failures/${email}`),
                getWithdrawFailures: (email: string): string => MakeUrl(`/funding/v2/support/withdraw-failures/${email}`),
                deleteVoid: (id: string): string => MakeUrl(`/funding/v2/support/voids/${id}`),
                deleteFailure: (id: string): string => MakeUrl(`/funding/v2/support/deposit-failures/${id}`),
                withdrawFailure: (id: string): string => MakeUrl(`/funding/v2/support/withdraw-failures/${id}`)
            },
            sources: {
                getAll: (): string => MakeUrl('/funding/v2/sources'),
                getExternalBankBalance: (fundingSourceId: string): string => MakeUrl(`/funding/v2/sources/balance/${fundingSourceId}`),
                add: (): string => MakeUrl('/funding/v2/sources/add'),
                void: (): string => MakeUrl('/funding/v2/sources/delete')
            },
            plaid: {
                getLinkToken: (mode: 'create' | 'update', fundingSourceId?: string, env?: string): string =>
                    MakeUrl(`/funding/v2/plaid/get-token${fundingSourceId ? '/' + fundingSourceId : ''}`, {
                        env: env || GetConfig()?.Plaid?.Environment,
                        mode
                    }),
                resetAuthorization: (fundingSourceId?: string): string => MakeUrl(`/funding/v2/plaid/authorization/reset${fundingSourceId ? '/' + fundingSourceId : ''}`),
                authorization: (fundingSourceId?: string): string => MakeUrl(`/funding/v2/plaid/authorization${fundingSourceId ? '/' + fundingSourceId : ''}`),
                verificationStatus: (fundingSourceId?: string): string => MakeUrl(`/funding/v2/plaid/verification-status${fundingSourceId ? '/' + fundingSourceId : ''}`)
            },
            history: {
                getAll: (): string => MakeUrl('/funding/v2/history')
            }
        },
        getBalance: (fundingSourceId: string): string => MakeUrl(`/funding/sources/${fundingSourceId}`),
        plaid: {
            getLinkToken: (mode: 'create' | 'update', fundingSourceId?: string, env?: string): string =>
                MakeUrl(`/funding/plaid/get-token${fundingSourceId ? '/' + fundingSourceId : ''}`, {
                    env: env || GetConfig()?.Plaid?.Environment,
                    mode
                }),
            resetAuthorization: (fundingSourceId?: string): string => MakeUrl(`/funding/plaid/authorization/reset${fundingSourceId ? '/' + fundingSourceId : ''}`),
            authorization: (fundingSourceId?: string): string => MakeUrl(`/funding/plaid/authorization${fundingSourceId ? '/' + fundingSourceId : ''}`),
            verificationStatus: (fundingSourceId?: string): string => MakeUrl(`/funding/plaid/verification-status${fundingSourceId ? '/' + fundingSourceId : ''}`)
        },
        linkedAccounts: {
            getAll: (): string => MakeUrl('/funding/linked-accounts'),
            add: (accountNumber: string): string => MakeUrl(`/funding/linked-accounts/${accountNumber}/add`),
            void: (linkedAccountId: string): string => MakeUrl(`/funding/linked-accounts/void/${encodeURIComponent(linkedAccountId)}`),
            limits: (): string => MakeUrl('/funding/management/limits'),
            balance: (linkedAccountId: string): string => MakeUrl(`/funding/linked-account/${encodeURIComponent(linkedAccountId)}/balance`)
        },
        banks: {
            getTop: (): string => MakeUrl('/funding/banks/top'),
            search: (query: string): string => MakeUrl('/funding/banks/search', { query })
        },
        transfer: {
            deposit: (brokerageAccountNumber: string): string => MakeUrl(`/funding/${brokerageAccountNumber}/deposit`),
            withdraw: (brokerageAccountNumber: string): string => MakeUrl(`/funding/${brokerageAccountNumber}/withdraw`),
            history: (): string => MakeUrl('/funding/transfer/history'),
            limits: (): string => MakeUrl('/funding/transfer/limits'),
            validate: (): string => MakeUrl('/funding/transfer/validate')
        },
        sources: {
            getAll: (): string => MakeUrl('/funding/sources'),
            add: (): string => MakeUrl('/funding/sources/add'),
            void: (): string => MakeUrl('/funding/sources/delete'),
            loadExternalFundingSourcesToCosmos: (): string => MakeUrl('/funding/sources/load-external'),
            getExternalBankBalance: (fundingSourceId: string): string => MakeUrl(`/funding/sources/balance/${fundingSourceId}`)
        },
        ains: {
            getVoids: (email: string): string => MakeUrl(`/funding/support/voids/${email}`),
            getDepositFailures: (email: string): string => MakeUrl(`/funding/support/deposit-failures/${email}`),
            getWithdrawFailures: (email: string): string => MakeUrl(`/funding/support/withdraw-failures/${email}`),
            deleteVoid: (id: string): string => MakeUrl(`/funding/support/voids/${id}`),
            deleteFailure: (id: string): string => MakeUrl(`/funding/support/deposit-failures/${id}`),
            withdrawFailure: (id: string): string => MakeUrl(`/funding/support/withdraw-failures/${id}`)
        }
    },

    messages: {
        search: (filters: { page?: number; size?: number }): string => MakeUrl('/messages/', filters),
        getOne: (messageId: string): string => MakeUrl(`/messages/${messageId}`),
        countUnread: (): string => MakeUrl('/messages/unread'),
        countStats: (since?: Date): string =>
            MakeUrl('/messages/unread-stats', {
                since: since === undefined || isNaN(since?.getTime() || NaN) ? undefined : since.toISOString()
            }),
        markRead: (ids: string[]): string => MakeUrl('/messages/mark-read', { ids: ids.join(',') }),
        generate: (): string => MakeUrl('/messages/generate'),
        generatePush: (): string => MakeUrl('/messages/generate/push'),
        broadcast: (): string => MakeUrl('/messages/broadcast'),
        delete: (ids: string[]): string => MakeUrl('/messages', { ids: ids.join(',') }),
        deleteAll: (): string => MakeUrl('/messages/delete-all'),
        systemControls: (): string => MakeUrl('/messages/controls'),
        clientControls: (): string => MakeUrl('/messages/prefs'),
        clientControlsUpdate: (messageType: string, ev: ClientMdcEventName, onOff: 'on' | 'off'): string => MakeUrl(`/messages/prefs/${messageType}/${ev}/${onOff}`),
        adminControls: (): string => MakeUrl('/messages/admin/controls'),
        updateAdminControls: (controlId: string): string => MakeUrl(`/messages/admin/control/${controlId}`),
        verifyPhone: (pin: string): string => MakeUrl('/messages/phone/verify', { pin }),
        logReceipt: (correlationId: string) => MakeUrl('/messages/log-receipt', {correlationId})
    },

    market: {
        getHolidays: (): string => MakeUrl('/market/holidays'),
        modifyHoliday: (id: string): string => MakeUrl(`/market/holiday/${id}`),
        getOpenStatus: (): string => MakeUrl('/market/open-status')
    },

    positions: {
        all: (): string => MakeUrl('/portfolio/positions'),
        allUnpriced: (): string => MakeUrl('/portfolio/positions/unpriced'),
        accountPositions: (accountNumber: string | number): string => MakeUrl(`/accounts/${accountNumber}/positions`),
        downloadPositions: (status: 'Open' | 'Closed'): string => MakeUrl(`/portfolio/positions/csv/${status}`),
        download: (): string => MakeUrl('/portfolio/positions/csv'),
        closed: (year: number): string => MakeUrl('/portfolio/positions/closed', { year }),
        downloadTaxlots: (status: 'Open' | 'Closed'): string => MakeUrl('/portfolio/taxlots/csv', { status }),
        realizedPnl: (symbol: string): string => MakeUrl(`/portfolio/realized-pnl/${symbol}`),
        pnlStats: (): string => MakeUrl(`/positions/pnl-stats`)
    },

    news: {
        forMarket: (): string => MakeUrl('/news/market', { lang: T((s) => s.general.localeShort) }),
        forPortfolio: (): string =>
            MakeUrl('/news/portfolio', {
                lang: T((s) => s.general.localeShort)
            }),
        forAccount: (accountNumber: string): string =>
            MakeUrl(`/news/account/${accountNumber}`, {
                lang: T((s) => s.general.localeShort)
            }),
        forSymbol: (symbol: string): string =>
            MakeUrl(`/news/security/${symbol}`, {
                lang: T((s) => s.general.localeShort)
            }),
        articleWithOtt: (articleUrl: string, ott: string, convertWebApiToMobile?: boolean): string => {
            const qIdx = articleUrl.indexOf('?');
            const safeToken = encodeURIComponent(ott);
            const convertedUrl = convertWebApiToMobile ? articleUrl.replace(/stonex\.com\/api/, 'stonex.com/mobile-api') : articleUrl;
            if (qIdx === -1) return `${convertedUrl}?ott=${safeToken}`;
            else if (/\?$/.test(convertedUrl)) return `${convertedUrl}ott=${safeToken}`;
            else return `${convertedUrl}&ott=${safeToken}`;
        },
        articleContent: (articleId: string): string =>
            MakeUrl(`/news/article/${articleId}/content`, {
                lang: T((s) => s.general.localeShort)
            })
    },

    trading: {
        submit: (): string => MakeUrl('/order/submit', { dryRun: 'false' }),
        verify: (): string => MakeUrl('/order/submit', { dryRun: 'true' }),
        verifyUpdate: (orderId: string): string => MakeUrl(`/order/update/${orderId}`, { dryRun: 'true' }),
        edit: (orderId: string): string => MakeUrl(`/order/update/${orderId}`, { dryRun: 'false' }),
        cancel: (orderId: string): string => MakeUrl(`/order/cancel/${orderId}`),
        getStockLoanRate: (symbol: string): string => MakeUrl('/order/stockLoanRate', { symbol })
    },

    system: {
        getLanguages: (): string => MakeUrl('/system/languages'),
        getTimeZones: (): string => MakeUrl('/system/timezones'),
        getStatus: (): string => MakeUrl('/system/status'),
        getTermsDocument: (name: string): string => MakeUrl(`/system/terms/${name}`),
        getMaxNumberOfFuturesAccounts: (): string => MakeUrl('/system/max-allowed/futures-accounts')
    },

    transactions: {
        csv: (accountNumber: string): string => MakeUrl(`/accounts/${accountNumber}/activity/csv`),
        csvV2: (accountNumber: string): string => MakeUrl(`/v2/accounts/${accountNumber}/activity/csv`),
        csvV3: (accountNumber: string): string => MakeUrl(`/transactions/${accountNumber}/csv`),
        search: (accountNumber: string, filters: { [key: string]: string }): string => MakeUrl(`/accounts/${accountNumber}/activity`, filters),
        searchV2: (accountNumber: string, filters: TransactionsSearchFiltersV2, offsets?: PaginationOffsets): string =>
            MakeUrlWithPagingOffsets(`/v2/accounts/${accountNumber}/activity`, { ...filters }, offsets),
        searchV3: (accountNumber: string, filters: TransactionsSearchFiltersV2, offsets?: PaginationOffsets): string =>
            MakeUrlWithPagingOffsets(`/transactions/${accountNumber}/history`, { ...filters }, offsets),
        searchV3All: (filters: TransactionsSearchFiltersV2, offsets?: PaginationOffsets): string =>
            MakeUrlWithPagingOffsets(`/transactions/all/history`, { ...filters }, offsets)
    },

    options: {
        getMonths: (stockSymbol: string): string => MakeUrl(`/securities/options/${stockSymbol}/months`),
        getChain: (stockSymbol: string, date: string, putCall: 'put' | 'call' | 'both', numberOfStrikes: number): string =>
            MakeUrl(`/securities/options/${stockSymbol}/chain`, {
                date,
                putCall: ((): string => {
                    switch (putCall) {
                        case 'call':
                            return 'C';
                        case 'put':
                            return 'P';
                        case 'both':
                        default:
                            return 'B';
                    }
                })(),
                numberOfStrikes: `${numberOfStrikes}`
            }),
        getSingleQuote: (osiSymbol: string): string => MakeUrl(`/securities/options/${new OptionSymbol(osiSymbol).underlyingSymbol}/quote/${osiSymbol}`),
        getContractSize: (osiSymbol: string): string => MakeUrl(`/securities/options/${new OptionSymbol(osiSymbol).underlyingSymbol}/size/${osiSymbol}`),
        getGreeks: (osiSymbols: string[]): string => {
            const symbolsPath = osiSymbols.map(encodeURIComponent).join(',');
            return MakeUrl(`/securities/options/${symbolsPath}/greeks`);
        },
        getChart: (osiSymbol: string, range: string, res: string): string =>
            MakeUrl(`/securities/options/${new OptionSymbol(osiSymbol).underlyingSymbol}/chart/${osiSymbol}`, { range, res })
    },

    watchlists: {
        addSymbols: (listId: string, symbols: string[], index?: number): string => MakeUrl(`/watchlists/${listId}/${symbols.join(',')}`, { index }),
        create: (): string => MakeUrl('/watchlists'),
        delete: (id: string): string => MakeUrl(`/watchlists/${id}`),
        deleteSymbols: (listId: string, symbols: string[]): string => MakeUrl(`/watchlists/${listId}/${symbols.join(',')}`),
        edit: (listId: string): string => MakeUrl(`/watchlists/${listId}`),
        getAll: (): string => MakeUrl('/watchlists'),
        getSmartDataP1: (listId: string): string => MakeUrl(`/mobile-screens/watchlists/smart/${listId}/p1`),
        getSmartDataP2: (listId: string): string => MakeUrl(`/mobile-screens/watchlists/smart/${listId}/p2`),
        reorderSymbols: (listId: string): string => MakeUrl(`/watchlists/reorder/${listId}`),
        reorderWatchlists: (): string => MakeUrl('/watchlists/reorder/list')
    },

    watchlistChart: {
        getMany: (ids: string[]): string => MakeUrl('/watchlists/chart', { watchlistIds: ids.join(',') })
    },

    accountSummaries: {
        getAggregate: (): string => MakeUrl('/portfolio/summary'),
        getBatch: (accountNumbers: string[]): string =>
            MakeUrl('/accounts/multi/summary', {
                accountNumbers: accountNumbers.join(',')
            }),
        getAggregateBuyingPower: (): string => MakeUrl('/portfolio/buying-power'),
        getTaxlotAggregate: (status: 'Open' | 'Closed'): string => MakeUrl('/portfolio/taxlots', { status })
    },

    orders: {
        csvV3: (tradeableSecurityType?: string): string => MakeUrl('/v3/order/csv', { tradeableSecurityType }),
        openV3: (queryParams?: { [key: string]: string | number }): string => MakeUrl('/v3/order/open', { ...queryParams }),
        getOneV3: (orderIdentifier: string, orderIdentifierType: OrderIdentifierType): string => MakeUrl(`/v3/order/${orderIdentifier}`, { orderIdentifierType }),
        searchV3: (queryParams: OrderSearchFiltersV3, offsets?: PaginationOffsets): string => MakeUrlWithPagingOffsets('/v3/order', { ...queryParams }, offsets)
    },

    financialStatements: {
        getBalanceSheet: (symbol: string, period: 'quarterly' | 'annual'): string =>
            MakeUrl(`/securities/equities/${symbol}/documents/balance-sheet`, {
                period
            }),
        getCashFlow: (symbol: string, period: 'quarterly' | 'annual'): string =>
            MakeUrl(`/securities/equities/${symbol}/documents/cash-flow`, {
                period
            }),
        getIncomeStatement: (symbol: string, period: 'quarterly' | 'annual'): string =>
            MakeUrl(`/securities/equities/${symbol}/documents/income`, {
                period
            })
    },

    subscription: {
        subscribe: (): string => MakeUrl('/subscription/subscribe'),
        getMine: (): string => MakeUrl('/subscription/'),
        setMine: (tier: string): string => MakeUrl('/subscription/subscribe/set', { tier }),
        getStripeCheckoutUrl: (productCode: ProductCode): string =>
            MakeUrl(`/subscription/subscribe/${productCode}/stripe/checkout/url`, {
                successUrl: `${resolveFrontend()}/premium/${productCode}`,
                cancelUrl: window.location.href
            }),
        getStripePortalUrl: (): string =>
            MakeUrl('/subscription/subscribe/stripe/portal/url', {
                returnUrl: window.location.href
            }),
        cancel: (): string => MakeUrl('/subscription/cancel'),

        uncancel: (): string => MakeUrl('/subscription/uncancel'),
        updatePaymentAccount: (): string => MakeUrl('/subscription/update-payment-account')
    },

    // Note! These URLs are new as of an update to the API; things will not work for a bit
    securities: {
        core: {
            getRelatedSymbols: (symbol: string): string => MakeUrl(`/securities/${symbol}/related`),
            getLogo: (symbol: string): string => MakeUrl(`/securities/${symbol}/logo`),
            getLogoBatch: (symbols: string[]): string =>
                MakeUrl('/securities/multi/logo', {
                    symbols: symbols.join(',')
                }),
            getAllRangeChart: (symbol: string, res: 'hi' | 'lo'): string => MakeUrl(`/securities/${symbol}/chart/all-ranges`, { res }),
            getChart: (symbol: string, range: ChartRange = '1d', res: 'hi' | 'lo' | 'special-lo-market-xlo-extended'): string =>
                MakeUrl(`/securities/${symbol}/chart`, { range, res }),
            getCustomChart: ({ symbol, ...rest }: { countBack: number; from: number; resolution: string; symbol: string; to: number }): string =>
                MakeUrl(`/securities/${symbol}/chart/custom-range`, { ...rest }),
            getMultiSecurityChartQuoteBatch: (symbols: string[], range: ChartRange): string =>
                MakeUrl('/securities/multi/chart-quote', {
                    symbols: symbols.join(','),
                    range
                }),
            getMultiSecurityChartBatch: (symbols: string[], range: ChartRange, res: 'hi' | 'lo' | 'special-lo-market-xlo-extended'): string =>
                MakeUrl('/securities/multi/chart', {
                    symbols: symbols.join(','),
                    range,
                    res
                }),
            getV2MultiSecurityChartBatch: (symbols: string[], range: ChartRange, res: 'hi' | 'lo' | 'special-lo-market-xlo-extended'): string => {
                const syms = symbols.map((s) => `symbol=${encodeURIComponent(s)}`).join('&');
                return MakeUrlCustomQuery('/v2/securities/multi/chart', `${syms}&range=${encodeURIComponent(range)}&res=${encodeURIComponent(res)}`);
            },
            getMultiSecurityQuoteBatch: (symbols: string[], type?: string): string =>
                MakeUrl('/securities/multi/quote', {
                    symbols: symbols.join(','),
                    type
                }),
            getV2MultiSecurityQuoteBatch: (symbols: string[]): string =>
                MakeUrlCustomQuery('/v2/securities/multi/quote', symbols.map((s) => `symbol=${encodeURIComponent(s)}`).join('&')),
            getMetadata: (qsi: string): string => MakeUrl(`/securities/${qsi}/metadata`),
            getMetadataByAlt: (secIdType: string, secId: string): string =>
                MakeUrl('/securities/metadata', {
                    idType: secIdType,
                    id: secId
                }),
            getCompanyInfo: (symbol: string): string => MakeUrl(`/securities/${symbol}/company-info`),
            getRatings: (symbol: string): string => MakeUrl(`/securities/${symbol}/ratings`),
            getQuote: (symbol: string, type?: string): string => MakeUrl(`/securities/${symbol}/quote`, { type }),
            setLogo: (symbol: string): string => MakeUrl(`/securities/${symbol}/logo`)
        },
        futures: {
            getContracts: (symbol: string): string => MakeUrl(`/securities/futures/${symbol}`),
            getFrontMonthTopMovers: (count: number): string => MakeUrl('/securities/futures/front-month-top-movers', { count }),
            getRelatedEtfs: (symbol: string): string => MakeUrl(`/securities/futures/${symbol}/related/etfs`),
            getRelatedMonths: (symbol: string): string => MakeUrl(`/securities/futures/${symbol}/related/months`),
            getSingleContract: (symbol: string): string => MakeUrl(`/securities/futures/${symbol}/contract`),
            getTimeSpreads: (symbol: string): string => MakeUrl(`/securities/futures/time-spreads`, { qsi: symbol })
        },
        equities: {
            getCompanyStats: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/company-stats`),
            getCompanyKeyStats: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/key-stats`),
            getEarnings: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/earnings/history`),
            getEarningsEstimates: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/earnings/estimates`),
            getDividends: (symbol: string, range = '1y'): string => MakeUrl(`/securities/equities/${symbol}/dividends`, { range }),
            getEarningsCallIndex: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/earnings/transcripts`),
            getEarningsCallDetails: (symbol: string, id: string): string => MakeUrl(`/securities/equities/${symbol}/earnings/transcripts/${id}`),
            getRevenueEstimates: (symbol: string, period: ReportingPeriod): string =>
                MakeUrl(`/securities/equities/${symbol}/revenue/estimates`, {
                    period
                }),
            getStockSplits: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/splits`),
            getUpcomingEarningsForSymbol: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/upcoming-earnings`),
            getUpcomingEarnings: (symbols: string): string =>
                MakeUrl('/securities/equities/upcoming-earnings', {
                    symbols
                })
        },
        cryptos: {
            getCryptoStatsForSymbol: (symbol: string): string => MakeUrl(`/securities/cryptos/${symbol}/stats`),
            getCryptoStats: (symbols: string): string =>
                MakeUrl('/securities/cryptos/stats', {
                    symbols
                })
        },

        // Stuff that shouldn't be here:
        getNews: (symbol: string): string => MakeUrl(`/news?symbols=${symbol}`),
        getTopMovers: (): string => MakeUrl('/securities/list/movers', { family: 'equities', limit: 10 }),
        getTopGainers: (): string => MakeUrl('/securities/list/gainers', { family: 'equities', limit: 10 }),
        getTopLosers: (): string => MakeUrl('/securities/list/losers', { family: 'equities', limit: 10 }),
        getSectorPerformance: (): string => MakeUrl('/securities/equities/sectors/performance'),
        getRiskFreeRate: (): string => MakeUrl('/securities/risk-free-rate')
    },

    funds: {
        getProfile: (qsi: string): string => MakeUrl(`/securities/funds/${qsi}/profile`),
        getHoldings: (qsi: string): string => MakeUrl(`/securities/funds/${qsi}/holdings`),
        getSectorExposure: (qsi: string): string => MakeUrl(`/securities/funds/${qsi}/sector-exposure`),
        getCountryExposure: (qsi: string): string => MakeUrl(`/securities/funds/${qsi}/country-exposure`),
        getReinvestmentPreferences: (qsi: string, accountNumber: string): string => MakeUrl(`/securities/funds/${qsi}/reinvestment-preferences/${accountNumber}`),
        setReinvestmentPreferences: (qsi: string, accountNumber: string): string => MakeUrl(`/securities/funds/${qsi}/reinvestment-preferences/${accountNumber}`),
        getOffshoreStats: (isin: string): string => MakeUrl(`/securities/funds/${isin}/offshore-stats`)
    },

    sectors: {
        getEtfSecuritiesBySector: (sector: string): string => {
            let routeParam = sector;
            if (sector === 'Communication Services') routeParam = 'telecom';
            if (sector === 'Health Care') routeParam = 'healthcare';
            return MakeUrl(`/securities/funds/etf/${routeParam}`);
        }
    },

    securityAnalysis: {
        ownership: {
            getInsiders: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/ownership/insiders`),
            getInstitutions: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/ownership/institutions`),
            getFunds: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/ownership/funds`)
        },
        getPriceTarget: (symbol: string): string => MakeUrl(`/securities/equities/${symbol}/price-target`)
    },

    users: {
        checkLoginStatus: (): string => MakeUrl('/token/validate'),
        getMyFeatures: (): string => MakeUrl('/users/me/features'),
        updateAppReview: (): string => MakeUrl('/users/me/app-review'),
        getMyPrefs: (): string => MakeUrl('/users/me/prefs'),
        getMyTaxInfo: (): string => MakeUrl('/users/me/tax-info'),
        getMyToken: (): string => MakeUrl('/token'),
        getMyTokenEmail: (): string => MakeUrl('/token/email'),
        getUserInfo: (userId: string): string => MakeUrl(`/users/${userId}`),
        myInfo: (): string => MakeUrl('/users/me'),
        reportHasBeenWelcomed: (): string => MakeUrl('/users/me/welcomed'),
        reportHasAcceptedMobileTos: (): string => MakeUrl('/users/me/tos/mobile/accept'),
        resetMyProgress: (progressNames?: string[]): string => MakeUrl(`/users/me/progress/${progressNames?.join(',') || ''}`),
        updateMyImage: (): string => MakeUrl('/users/me/image'),
        updateMyOneClickTrading: (): string => MakeUrl('/users/me/one-click-trading'),
        updateMyTradingViewTicker: (): string => MakeUrl('/users/me/trading-view-ticker'),
        updateMyPrefs: (): string => MakeUrl('/users/me/prefs'),
        updateMyProgress: (progressName: string): string => MakeUrl(`/users/me/progress/${progressName}`),
        updateMyTaxInfo: (): string => MakeUrl('/users/me/tax-info'),
        updateMyTheme: (): string => MakeUrl('/users/me/theme'),
        updateFuturesStrikes: (): string => MakeUrl('/users/me/futures-strikes'),
        updateStrikes: (): string => MakeUrl('/users/me/strikes'),
        getOneTimeToken: (): string => MakeUrl('/users/ott'),
        getHasAcceptedWebTos: (): string => MakeUrl('/users/me/tos/web/accept'),
        reportHasAcceptedWebTos: (termsId: string): string => MakeUrl(`/users/me/tos/web/accept/${termsId}`),
        updateHasOnboarded: (value: boolean): string => MakeUrl(`/users/me/onboarding/${value}`)
    },

    admin: {
        getSearchedUserByEmail: (email: string): string => MakeUrl(`/admin/searched-user/${email}`),
        setUserByEmail: (email: string): string => MakeUrl(`/admin/searched-user/${email}`),
        getFullSystemStatus: (): string => MakeUrl('/admin/system-status'),
        adminBypassEnrollment: (email: string): string => MakeUrl(`/admin/premium/bypass-enrollment/${email}`),
        adminDemoteUserToFree: (email: string): string => MakeUrl(`/admin/premium/demote/${email}`),
        getSymbologyOverrides: (): string => MakeUrl(`/admin/symbology-overrides`),
        upsertSymbologyOverride: (inputSymbol: string): string => MakeUrl(`/admin/symbology-overrides/${inputSymbol}`),
        deleteSymbologyOverride: (inputSymbol: string): string => MakeUrl(`/admin/symbology-overrides/${inputSymbol}`)
    },

    featureFlags: {
        getAll: (): string => MakeUrl('/features')
    },

    footers: {
        marketData: 'https://www.xignite.com/'
    },

    mobileScreens: {
        landingScreen: (): string => MakeUrl('/mobile-screens/landing-screen'),

        appStartup: (): string => MakeUrl('/mobile-screens/app-startup/main'),
        appStartupPartTwo: (): string => MakeUrl('/mobile-screens/app-startup/part-two'),

        // range field is required, 1d by default
        summaryScreen: (range?: string): string => MakeUrl('/mobile-screens/summary-screen', { range: range || '1d' }),
        summaryScreenPartTwo: (): string => MakeUrl('/mobile-screens/summary-screen/part-two'),

        securityScreen: (qsi: string, lang: string): string => MakeUrl(`/mobile-screens/security-screen/main/${qsi}`, { lang }),
        securityScreenPartTwo: (qsi: string): string => MakeUrl(`/mobile-screens/security-screen/part-two/${qsi}`),
        securityScreenForFuture: (qsi: string): string => MakeUrl(`/mobile-screens/security-screen/future/${qsi}`),

        browseScreen: (lang: string): string => MakeUrl('/mobile-screens/browse-screen/main', { lang }),
        sectorScreen: (sector: string): string => MakeUrl(`/mobile-screens/sector-details-screen/${sector}`),
        profileScreen: (): string => MakeUrl('/mobile-screens/profile-screen/main'),
        equityDeepAnalysis: (qsi: string): string => MakeUrl(`/mobile-screens/deep-analysis/equity/${qsi}/main`),

        watchlists: {
            index: (): string => MakeUrl('/mobile-screens/watchlists/index'),
            details: (ids: string[] = []): string => MakeUrl(`/mobile-screens/watchlists/details/${encodeURIComponent(ids.join(','))}`)
        }
    },

    misc: {
        redirecting: 'https://snex1storage.blob.core.windows.net/$web/misc/redirect-to-app.html',
        zendesk: (): string => {
            const tokenChunks = GetMemo(StorageKeys.JwtMemo)?.split('.') || [];
            const payload = tokenChunks.length === 3 ? tokenChunks[1] : null;
            return `https://snex1storage.blob.core.windows.net/$web/misc/zendesk.html?token=${payload}`;
        },
        salesforce: (): string => 'https://snex1storage.blob.core.windows.net/$web/misc/salesforce-chat.html?openChat=true',
        privacyPolicy: (): string => 'https://stonexone.com/stonex-privacy-policy/',
        mobileTermsOfService: (): string => 'https://stonexone.com/wp-content/uploads/2022/12/Mobile-App-TC-8.20.2020.pdf'
    },
    futuresOnboarding: {
        addAccount: (): string => '/onboarding/futures/1',
        getFuturesOnboarding: (): string => MakeUrl('/futures-onboarding'),
        submitFuturesOnboarding: (): string => MakeUrl('/futures-onboarding')
    },
    equitiesOnboarding: {
        userExists: (email: string): string => `/l/api/SelfEnroll/userExists?email=${email}`,
        userExistsByProxy: (email: string): string => `/auth/user-exists?email=${email}`,
        verify: (): string => '/l/api/SelfEnroll/verification',
        resendVerificationCode: (email: string): string => `/l/api/SelfEnroll/VerificationReSend?email=${email}`,
        selfSignup: (): string => '/l/api/SelfEnroll/SignUp',
        hasProfile: (): string => '/torchapi/v1/Profile/HasProfile',
        userInfo: (): string => '/torchapi/v1/User/profile',
        profile: (profileId?: string): string => `/torchapi/v1/Profile/?Id=${profileId}`,
        createProfile: (): string => '/torchapi/v1/Profile/Profile',
        profileSettings: (profileId?: string): string => `/torchapi/v1/Profile/ProfileSettings/${profileId}`,
        privateLabelSetting: (privateLabelCode?: string): string => `/torchapi/v1/Profile/PrivateLabelSetting/?PrivateLabelCode=${privateLabelCode}`,
        individualInfo: (profileId?: string): string => `/torchapi/v1/Profile/Individual/${profileId}`,
        accountType: (profileId?: string, accountType?: number): string => `/torchapi/v1/AccountOpening/?ProfileId=${profileId}&Type=${accountType}`,
        tradingExperience: (profileId?: string): string => `/torchapi/v1/profile/TradingExperience/${profileId}`,
        investmentObjective: (profileId?: string, objective?: number): string => `/torchapi/v1/AccountOpening/InvestmentObjective/${profileId}?objective=${objective}`,
        optionsTradingLevel: (profileId?: string, level?: number): string => `/torchapi/v1/AccountOpening/OptionsTradingLevel/${profileId}?level=${level}`,
        locationInfo: (id?: { [key: string]: string }): string => `/torchapi/v1/Profile/Address${id ? `/${id}` : ''}`,
        networth: (profileId?: string): string => `/torchapi/v1/Profile/FinancialProfile/${profileId}`,
        sourceOfIncome: (profileId?: string): string => `/torchapi/v1/Profile/Demographic/${profileId}`,
        employmentInfo: (profileId?: string): string => `/torchapi/v1/Profile/Employment/${profileId}`,
        trustedContact: (trustedContactId?: string): string => `/torchapi/v1/Profile/TrustedContact${trustedContactId ? `/${trustedContactId}` : ''}`,
        stoneXAffiliation: (profileId?: string): string => `/torchapi/v1/Profile/FirmAffiliation/${profileId}`,
        stoneXAffiliationCustomerTypes: (profileId: string): string => `/torchapi/v1/Profile/CAISCustomerType/${profileId}`,
        industryAffiliation: (profileId: string): string => `/torchapi/v1/Profile/IndustryAffiliation/${profileId}`,
        publicCompanyAffiliation: (profileId: string): string => `/torchapi/v1/Profile/PublicCompanyAffiliation/${profileId}`,
        getTermsAndAgreement: (documentName: string): string => `/torchapi/v1/Profile/MasterDocument?DocumentName=${documentName}`,
        termsAndAgreements: (): string => '/torchapi/v1/AccountOpening/TermsAgreements',
        accountFeature: (accountOpeningId: string): string => `/torchapi/v1/AccountOpening/accountFeature/${accountOpeningId}`,
        submitOnboApplication: (accountOpeningId: string): string => `/torchapi/v1/AccountOpening/${accountOpeningId}/submit`
    },
    startup: {
        authorize: (options?: Partial<{ skipRedirectCheck?: boolean }>): string =>
            MakeUrl('/startup/authorize', { skipRedirectStep: options?.skipRedirectCheck ? 'true' : undefined })
    },

    mediantSSO: {
        getUrl: (selectedAccount: string): string => MakeUrl(`/accounts/corporate-actions/url/${selectedAccount}`)
    },
    whitelabels: {
        getSettings: (whitelabel: Whitelabel | null): string => MakeUrl(`/whitelabel/${whitelabel}`)
    },
    weather: {
        getRadarTile: (jwt: string, topLatitude: number, topLongitude: number, bottomLatitude: number, bottomLongitude: number): string =>
            MakeUrl('/weather/radar/tile', { jwt, topLatitude, topLongitude, bottomLatitude, bottomLongitude }),
        getForecast: (forecastType: WeatherForecastType, latitude: number, longitude: number): string =>
            MakeUrl(`/weather/${WeatherForecastType[forecastType]}`, { latitude, longitude }),
        getVideos: (): string => MakeUrl('/weather/video')
    },
    geocode: {
        getByAddress: (address: string): string => MakeUrl('/geocode', { address }),
        getByLatLong: (latitude: number, longitude: number): string => MakeUrl('/geocode', { latitude, longitude })
    },
    fullyPaidLending: {
        submit: (): string => MakeUrl('/fpl/submit'),
        getSubmittedDate: (accountNumber: string): string => MakeUrl(`/fpl/application/${accountNumber}/submit-date`)
    }
    // ATTENTION! If you're adding new URLs, make sure that they are functions:
    //
    // e.g., Good: mobileScreens: { summaryScreen: (): string => MakeUrl('...') }
    // e.g., Bad:  mobileScreens: { summaryScreen: MakeUrl('...') }
    //
    // On mobile, the bad example will cause the app to crash with no error message, since the
    // environment isn't set when static members are initialized
};
