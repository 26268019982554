// @ts-strict-ignore
import { Urls } from '../../constants'
import { Actions, ReduxApiGet } from '.'
import { Authorize } from '../models/Startup/Authorize'
import { UserAuthorization, UserAuthorizationType } from '../models/Login/UserAuthorization'

export const GetStartupAuthorizationAction = (options?: Partial<{
    skipRedirectCheck: boolean,
    hasOnboarded?: boolean
}>) => ReduxApiGet(Urls.startup.authorize(options), Actions.Startup.Authorize)
    .onSuccess((res: UserAuthorizationType): Authorize => {
        const newState: Authorize = {
            userAuthorization: res
        }
        return newState
    })
    .onError( (err: {message: string, errorCode: number})=> 
        { 
            if(err?.errorCode === 401) {
                return { userAuthorization: UserAuthorization.GoToLogin } 
            } else {
                return {userAuthorization: UserAuthorization.Denied }
            }
        }
    )
    .run(true)
