// @ts-strict-ignore
import {
    AnalystTrends,
    SecurityCompanyInfo,
    SecurityQuote,
    SecurityEarnings,
    SecurityChart
} from '.'
import { ApiData } from '../../../models/ApiData'
import { SecurityLogo } from './SecurityLogo'
import { EarningsCallIndex, UpcomingEarnings } from './EarningsCallIndex'
import { EarningsCallTranscript } from './EarningsCallTranscript'
import { SectorPerformance } from './SectorPerformance'
import { Dividends } from './Dividends'
import { SecurityEarningsEstimates } from './SecurityEarningsEstimates'
import { RevenueEstimates } from './RevenueEstimates'
import { CompanyStats } from './CompanyStats'
import { SecurityMetadata } from './SecurityMetadata'
import { StockSplit } from './StockSplit'
import { CompanyKeyStats } from './CompanyKeyStats'
import { FuturesContract } from './FuturesContract'
import { InitialOffering } from './InitialOffering'
import { CryptoStats } from './CryptoStats'

export interface SymbolSubstate {
    analystTrends: ApiData<AnalystTrends | null>;
    companyInfo: ApiData<SecurityCompanyInfo | null>;
    companyKeyStats: ApiData<CompanyKeyStats | null>;
    companyStats: ApiData<CompanyStats | null>;
    contracts: ApiData<FuturesContract[]>;
    cryptoStats: ApiData<CryptoStats | null>;
    cryptoStatsForSymbol: ApiData<CryptoStats | null>;
    dataChart: ApiData<SecurityChart | null>;
    dividends: ApiData<Dividends[]>;
    earnings: ApiData<SecurityEarnings | null>;
    earningsCalls: ApiData<EarningsCallIndex | null>;
    earningsCallsById: { [key: string]: ApiData<EarningsCallTranscript | null> }
    earningsEstimates: ApiData<SecurityEarningsEstimates | null>;
    metadata: ApiData<SecurityMetadata>;
    relatedMonths: ApiData<string[]>;
    relatedSymbols: ApiData<string[]>;
    revenueEstimates: ApiData<RevenueEstimates>,
    splits: ApiData<StockSplit[]>
    timeSpreads?: ApiData<SecurityMetadata[]>
    upcomingEarnings: ApiData<UpcomingEarnings | null>;
}

export class SecuritiesState {
    selectedSymbol?: string;
    riskFreeRate?: ApiData<number>;

    // This one is already by symbol, but it would be best to merge it in too at some point
    /** @deprecated Don't use this one!! Use state.logos.bySymbol instead :) */
    logos: {[key: string]: ApiData<SecurityLogo | null>};

    bySymbol: {
        [key: string]: Partial<SymbolSubstate>
    }

    top: {
        equities: {
            movers: ApiData<SecurityQuote[]>,
            gainers: ApiData<SecurityQuote[]>,
            losers: ApiData<SecurityQuote[]>
        },
        futures: {
            movers: ApiData<SecurityQuote[]>
        }
    }

    sectors: {
        performance: ApiData<SectorPerformance[]>
    }

    ipos: ApiData<InitialOffering[]>

    constructor () {
        this.riskFreeRate = new ApiData(null)
        this.logos = {}

        this.bySymbol = {}
        this.top = {
            equities: {
                movers: new ApiData([]),
                gainers: new ApiData([]),
                losers: new ApiData([])
            },
            futures: {
                movers: new ApiData([])
            }
        }
        this.sectors = {
            performance: new ApiData([])
        }
    }
}
