import React, { useMemo } from 'react';
import { Flex } from '..';
import { Card } from '../Card';
import useWindow from 'hooks/UseWindow';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { Typography } from '@mui/material';
import { useGlobalMessageStore } from 'components/GlobalMessageBanner/store';
import { he } from 'date-fns/locale';

interface WithSidebarLayoutProps {
    sidebarId?: string;
    ignoreWindowSize?: boolean;
    sidebar: JSX.Element;
    children?: any;
    sidebarAlign?: 'left' | 'right';
    sidebarWidth?: number;
    sidebarHeight?: string;
    sidebarPadding?: number;
    sidebarSpacing?: number;
    sidebarBottomSpacing?: number;
    noBottomGutter?: boolean;
    totalWidth?: number;
    style?: React.CSSProperties;
    noCard?: boolean;
    noScroll?: boolean;
    implementation?: 'fixed-position' | 'scrolling-divs';
}

interface Width {
    extraMargin: number;
    sidebar: number;
    total: number;
}

interface SidebarContainerProps extends WithSidebarLayoutProps {
    left?: number;
    position: 'relative' | 'fixed';
    width: Width;
}

const orNumber = (n: any, def: number) => (typeof n === 'number' ? n : def);

const SidebarContainer = React.memo((props: SidebarContainerProps) => {
    const [appWindowSize, _] = useAppWindowSize();

    const height = useMemo(() => {
        if (props.ignoreWindowSize) return { sidebarExtraSpacing: 165 };
        switch (appWindowSize) {
            case WindowSizes.small:
            case WindowSizes.tablet:
                return { sidebarExtraSpacing: 165 };
            default:
                return { sidebarExtraSpacing: 105 };
        }
    }, [appWindowSize, props.ignoreWindowSize]);

    const sidebarBottomSpacingCss = `calc(-${orNumber(props.sidebarBottomSpacing, 0) + height.sidebarExtraSpacing}px + 100vh)`;
    const sidebarBottomSpacingInnerCss = props.sidebarHeight || `calc(-${orNumber(props.sidebarBottomSpacing, 20) + height.sidebarExtraSpacing}px + 100vh)`;

    return (
        <div
            style={{
                width: props.sidebarWidth,
                height: sidebarBottomSpacingCss,
                zIndex: 25,
                margin: '0 10',
                padding: 0,
                left: props.left,
                position: props.position,
                paddingBottom: 0
            }}
        >
            {(() => {
                if (!props.sidebar) return null;
                else if (props.noCard === true) {
                    return (
                        <div
                            id='sidebar-anchor'
                            style={{ padding: orNumber(props.sidebarPadding, 10), paddingBottom: 0, overflowY: props.noScroll ? undefined : 'auto', height: '100%' }}
                        >
                            {props.sidebar}
                        </div>
                    );
                } else {
                    return (
                        <div id={props.sidebarId} style={{ height: sidebarBottomSpacingInnerCss }}>
                            <Card>
                                <div
                                    style={{
                                        padding: orNumber(props.sidebarPadding, 20),
                                        overflowY: props.noScroll ? undefined : 'auto',
                                        maxHeight: sidebarBottomSpacingInnerCss
                                    }}
                                >
                                    {props.sidebar}
                                </div>
                            </Card>
                        </div>
                    );
                }
            })()}
        </div>
    );
});

export const WithSidebarLayout = React.memo(function WithSideBarLayoutUnmemoized(props: WithSidebarLayoutProps) {
    const { viewportWidth } = useWindow();
    const [appWindowSize, _] = useAppWindowSize();

    const width = useMemo(() => {
        if (props.ignoreWindowSize) return { total: 1450, sidebar: 420, extraMargin: 10 };
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return { total: 940, sidebar: 370, extraMargin: 10 };
            case WindowSizes.small:
                return { total: 1050, sidebar: 370, extraMargin: 10 };
            case WindowSizes.regular:
                return { total: 1100, sidebar: 420, extraMargin: 10 };
            case WindowSizes.large:
            default:
                return { total: 1450, sidebar: 420, extraMargin: 10 };
        }
    }, [appWindowSize, props.ignoreWindowSize]);

    const sidebarPadding = orNumber(props.sidebarPadding, 10);
    const sidebarWidth = props.sidebarWidth || width.sidebar;

    const totalWidth = props.totalWidth || width.total;
    const contentWidth = totalWidth - sidebarWidth - 10;
    const sidebarAlign = props.sidebarAlign || 'right';
    const sidebarLeftMargin = Math.abs((viewportWidth - totalWidth) / 2 + contentWidth) + width.extraMargin;
    const {height, message} = useGlobalMessageStore()
    const bannerHeight = useMemo(() => (height || 0) + 30, [height])
    const containerMarginTop = useMemo(() => (30 + bannerHeight), [bannerHeight])

    if (props.implementation === 'scrolling-divs') return <Typography variant='h6'>scrolling-divs implementation not yet available</Typography>;
    else {
        return (
            <Flex inline row justify='space-between' style={{ width: totalWidth, margin: 'auto', height: '100%', marginTop: message? containerMarginTop : 30, ...props.style }}>
                {sidebarAlign === 'left' && <SidebarContainer position='relative' sidebarPadding={sidebarPadding} width={width} {...props} />}
                <div style={{ position: 'relative', width: contentWidth, marginBottom: props.noBottomGutter ? undefined : 100 }}>{props.children}</div>
                {sidebarAlign === 'right' && <SidebarContainer left={sidebarLeftMargin} position='fixed' sidebarWidth={sidebarWidth} width={width} {...props} />}
            </Flex>
        );
    }
});
