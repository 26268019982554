// @ts-strict-ignore
import { MenuItem, TextField } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import ReactSelect from 'react-select';
import { useColors } from 'hooks/UseColors';
import { DropdownOption } from 'phoenix/models/DropdownOptions';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';

interface DropdownComponentProps {
    defaultValue?: any;
    disabled?: boolean;
    implementation?: 'react-select' | 'mui' | 'native';
    onChange?: (value: { label: string | number; value: any; [key: string]: any }) => any;
    onValueChange?: (value: any) => any;
    optionComponent?: any; // TODO -- Type
    options?: DropdownOption[];
    placeholder?: string;
    required?: boolean;
    noBorder?: boolean;
    hasDividers?: boolean;
    style?: React.CSSProperties;
    value?: any;
    noOptionsMessage?: string;
    maxMenuHeight?: number;
    readonly?: boolean
}

export const DropdownComponent = ({
    defaultValue,
    disabled,
    implementation,
    onChange,
    onValueChange,
    optionComponent,
    options = [],
    placeholder,
    required,
    noBorder,
    hasDividers,
    style,
    value,
    noOptionsMessage,
    maxMenuHeight,
    readonly
}: DropdownComponentProps): ReactElement => {
    const colors = useColors();
    const handleChange = (change: { label: string | number; value: any }) => {
        if (onChange) onChange(change);
        if (onValueChange) onValueChange(change?.value);
    };

    const selectStyles = {
        container: (styles) => {
            return { ...styles, width: '100%', padding: '0', ...style };
        },
        control: (styles) => {
            return {
                ...styles,
                backgroundColor: colors.mainBackgroundColor,
                borderRadius: 5,
                '&:hover, &:focus-within': {
                    borderColor: required && !(value || value === 0) ? colors.requiredBorderColor : colors.inputBorderActiveColor
                }, // border style on hover
                border: !noBorder ? `1px solid ${required && !(value || value === 0) ? colors.requiredBorderColor : colors.inputBorderColor}` : 'none', // default border color
                boxShadow: 'none' // no box-shadow
            };
        },
        indicatorSeparator: () => ({ color: 'transparent', backgroundColor: 'transparent' }),
        input: (styles) => ({
            ...styles,
            color: colors.generalTextColor
        }),
        dropdownIndicator: (styles, state) => ({
            ...styles,
            color: state.isDisabled ? colors.blurredAdornmentColor : colors.focusedAdornmentColor
        }),
        placeholder: (styles, state) => ({
            ...styles,
            fontSize: 15,
            fontWeight: 500,
            color: state.isDisabled ? colors.blurredAdornmentColor : colors.generalTextColor
        }),
        singleValue: (styles, state) => ({
            ...styles,
            fontSize: 15,
            fontWeight: 500,
            color: state.isDisabled ? colors.blurredAdornmentColor : colors.generalTextColor
        }),
        menu: (style) => ({
            ...style,
            padding: 0,
            overflow: 'hidden',
            zIndex: '999'
        }),
        menuList: (style) => ({
            ...style,
            padding: 0,
            overflow: 'auto'
        }),
        option: (style, state) => {
            const { backgroundColor, color } =
                state.isSelected || state.isFocused
                    ? { backgroundColor: colors.cardBackgroundColor, color: colors.primaryItemColor }
                    : { backgroundColor: colors.mainBackgroundColor, color: colors.generalTextColor };

            return {
                ...style,
                fontSize: 13,
                borderBottom: hasDividers ? '1px solid rgba(100,100,100,0.1)' : null,
                boxSizing: 'border-box',
                color: state.isDisabled ? colors.blurredAdornmentColor : color,
                backgroundColor: state.isDisabled ? colors.mainBackgroundColor : backgroundColor,
                '&:hover': {
                    color: state.isDisabled ? colors.blurredAdornmentColor : colors.primaryItemColor,
                    backgroundColor: state.isDisabled ? colors.mainBackgroundColor : colors.cardBackgroundColor
                }
            };
        }
    };

    const isMatch = (optionValue: string | number | Partial<Snex1LanguagePack>, valueToMatch: any) => {
        if (typeof valueToMatch === 'number') {
            return parseFloat(optionValue?.toString()) === valueToMatch;
        }
        if (typeof optionValue === 'string') {
            return optionValue?.toString().toLowerCase() === valueToMatch?.toString().toLowerCase();
        }
        return optionValue === valueToMatch;
    };

    const actualValue = options.find((o) => isMatch(o.value, value)) || value;

    if (implementation === 'mui' || implementation === 'native') {
        return (
            <TextField
                select
                defaultValue={defaultValue}
                disabled={disabled}
                placeholder={placeholder}
                SelectProps={{
                    IconComponent: ExpandMore,
                    style: { fontSize: 14 }
                }}
                value={value}
                variant='outlined'
                onChange={(e) => handleChange(options.find((o) => o.value === e.target.value))}
            >
                {options.map((o, key) => {
                    return (
                        <MenuItem key={key} style={{ padding: 8, fontSize: 14 }}>
                            {o.label}
                        </MenuItem>
                    );
                })}
            </TextField>
        );
    } else {
        const defVal = defaultValue && !defaultValue.label ? options.find((o) => o.value === defaultValue) : defaultValue;

        return (
            <ReactSelect
                components={optionComponent ? { Option: optionComponent } : null}
                defaultValue={defVal}
                isDisabled={disabled}
                options={options}
                noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : () => ''}
                placeholder={placeholder}
                styles={selectStyles}
                value={actualValue}
                maxMenuHeight={maxMenuHeight}
                menuPlacement='auto'
                isSearchable={!readonly}
                onChange={handleChange}
            />
        );
    }
};
