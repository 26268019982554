// @ts-strict-ignore
import { Button, Typography, Skeleton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

import React from 'react';
import { Flex } from '..';
import { useColors } from 'hooks/UseColors';
import { T } from 'phoenix/assets/lang/T';
import { CircularLogo } from '../CircularLogo';

interface ExpandButtonProps {
    expanded: boolean;
    expandedLabel?: string;
    contractedLabel?: string;
    symbols?: string[];
    noMargin?: boolean;
    onClick?: () => any;
    overhang?: number;
    overcount?: number;
    loading?: boolean;
    style?: React.CSSProperties;
}

const MAX_LOGO_COUNT = 7;
export const ExpandButton = React.memo((props: ExpandButtonProps) => {
    const colors = useColors();

    const ButtonWrapper = (p: { children: any }) => (
        <Button
            style={{ borderRadius: 20, height: 40, minWidth: 200, background: colors.cardSecondaryBackgroundColor, zIndex: 100, color: colors.generalTextColor }}
            variant='contained'
            onClick={props.onClick}
        >
            {p.children || '-'}
        </Button>
    );

    const CenteredButton = (p: { children: any }) => (
        <Flex row justify='center' style={{ marginTop: -props.overhang || -20, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8, ...props.style }}>
            <ButtonWrapper>{p.children}</ButtonWrapper>
        </Flex>
    );

    if (props.symbols?.length && !props.expanded && !props.expandedLabel) {
        const symbolWindow = props.symbols.slice(0, MAX_LOGO_COUNT);
        const symbolOverCount = Math.max(props.symbols.length - MAX_LOGO_COUNT);
        const fullCount = props.overcount || props.symbols.length;
        return props.loading ? (
            <CenteredButton>
                <Flex row align='center'>
                    <Skeleton animation='wave' style={{ height: 20, width: 20, marginRight: 10 }} variant='circular' />
                    <Skeleton animation='wave' width={140} />
                </Flex>
            </CenteredButton>
        ) : (
            <CenteredButton>
                <Flex row align='center'>
                    <Typography style={{ marginRight: 20, color: colors.generalTextColor }} variant='h6'>
                        +{fullCount}
                    </Typography>
                    {symbolWindow.map((s) => (
                        <CircularLogo key={s} altText={s} size={15} style={{ margin: '0 3px' }} symbol={s} />
                    ))}
                    {symbolOverCount > 0 ? <MoreHoriz /> : null}
                </Flex>
            </CenteredButton>
        );
    }

    const label = (() => {
        const hasOvercount = !!props.overcount;
        if (props.expanded) return props.expandedLabel || T((s) => s.misc.showLess);
        else return hasOvercount ? T((s) => s.misc.showXMore(props.overcount)) : T((s) => s.misc.showMore);
    })();

    return props.loading ? (
        <CenteredButton>
            <Flex row align='center'>
                <Skeleton animation='wave' style={{ height: 20, width: 20, marginRight: 10 }} variant='circular' />
                <Skeleton animation='wave' width={120} />
            </Flex>
        </CenteredButton>
    ) : (
        <CenteredButton>{label}</CenteredButton>
    );
});
