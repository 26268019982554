// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from '../..';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { ApiPosition, OptionSymbol } from 'phoenix/redux/models';
import { GetClientAccountsAction, GetSecurityMetadataAction, GetSingleOptionQuoteAction } from 'phoenix/redux/actions';
import { CaptionLabel, DescriptionLabel, SymbolLabel } from '../Shared/Labels';
import { SecurityCellLogo } from '../Shared/SecurityCellLogo';
import { SecurityCellChart } from '../Shared/SecurityCellChart';
import { SecurityNameSection } from '../Shared/SecurityNameSection';
import style from '../style.module.scss';
import { PositionValue } from './PositionValue';
import { SecondaryRow } from './SecondaryRow';
import { useText } from 'phoenix/hooks/UseText';
import { FormatNumber } from 'phoenix/util';
import { useDispatch } from 'react-redux';
import { useSubscribeOnVisibility } from 'hooks/UseSubscribeOnVisibilty';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { T } from 'phoenix/assets/lang/T';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { ExpiresLabel } from 'components/ExpiresLabel/ExpiresLabel';

export interface OptionPositionCellProps {
    getCached: boolean;
    streamingNamespace: string;
    position: ApiPosition;
    hideAccountName?: boolean;
    symbol: string;
    visible?: boolean;
}

export const OptionPositionCell = React.memo((props: OptionPositionCellProps) => {
    const text = useText((s) => s.portfolioScreen.sidebar);
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();
    const { streamingNamespace, getCached, position, hideAccountName, symbol, visible } = props;
    const [latestChartPercChange, setLatestChartPercChange] = useState(null);
    const [latestChartPrice, setLatestChartPrice] = useState(null);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const optSym = useMemo(() => new OptionSymbol(symbol), [symbol]);
    const fsym = isFuture && new FuturesSymbol(optSym.underlyingSymbol);
    const { strikePrice = optSym.strike } = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data) || {};

    useEffect(() => {
        dispatch(GetClientAccountsAction());
        dispatch(GetSecurityMetadataAction(optSym.underlyingSymbol));
    }, []);

    useEffect(() => {
        dispatch(GetClientAccountsAction());
    }, []);

    const name = isFuture ? fsym.noPrefix : optSym.underlyingSymbol;
    const [, setVisibility] = useSubscribeOnVisibility(async () => await xdispatch(XS.OptionQuotes.start([symbol], streamingNamespace)), visible);

    useEffect(() => {
        setVisibility(visible);
        const getData = async () => {
            if (!getCached) await dispatch(GetSingleOptionQuoteAction(symbol));
        };
        getData();
    }, [visible]);

    const onCrosshairUpdate = useCallback((value?: any) => {
        setLatestChartPercChange(value[symbol]?.chartPercChange);
        setLatestChartPrice(typeof value[symbol] === 'number' ? value[symbol] : value[symbol]?.value);
    }, []);

    return (
        <>
            <SecurityCellLogo symbol={isFuture ? fsym.baseContract : optSym.underlyingSymbol} />
            <Flex column className={style.cellContent}>
                <Flex className={style.mainRow}>
                    <SecurityNameSection>
                        <SymbolLabel>{`${name} - ${T((t) => t.general.options.putCallWord(optSym.putCall))}`}</SymbolLabel>
                        <CaptionLabel>
                            {text.strike}: {FormatNumber.toMoney(strikePrice, null, isFuture ? 'never' : 'auto')}
                        </CaptionLabel>
                        <DescriptionLabel>{text.expires(optSym.expDate)}</DescriptionLabel>
                    </SecurityNameSection>
                    <SecurityCellChart withQuoteOverride symbol={symbol} onCrosshairUpdate={onCrosshairUpdate} />
                    <Flex column>
                        <PositionValue chartPercChange={latestChartPercChange} chartPrice={latestChartPrice} position={position} symbol={symbol} withQuoteOverride />
                        <ExpiresLabel expiryDate={optSym.expDate} />
                    </Flex>
                </Flex>
                <SecondaryRow hideAccountName={hideAccountName} position={position} secType='option' />
            </Flex>
        </>
    );
});
