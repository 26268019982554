// @ts-strict-ignore
import { ItemUpdate } from 'lightstreamer-client-web';
import { Urls } from '../../constants';
import { LiveDataGroups } from '../../constants/LiveDataGroups';
import { SafeFormat } from '../../util';
import { FuturesSymbol } from '../models/Futures/FuturesSymbol';
import { FuturesOptionChainUpdate } from '../models/Options/FuturesOptionChainUpdate';
import { OptionChainResult } from '../models/Options/OptionChainResult';
import { OptionMonth } from '../models/Options/OptionMonth';
import { OptionQuote } from '../models/Options/OptionQuote';
import { OptionSymbol } from '../models/Options/OptionSymbol';
import { Actions } from './Constants';
import { ReduxApiGet } from './Helpers';
import { ReduxLiveSubscribe, ReduxLiveUnsubscribeNamespace } from './StreamingHelpers';

export const GetOptionMonthsAction = (stockSymbol: string) =>
    ReduxApiGet(Urls.options.getMonths(stockSymbol), Actions.Options.GetMonths)
        .withLoading()
        .withSubject(stockSymbol)
        .onSuccess((res: OptionMonth[]) =>
            res.map((m) => ({
                ...m,
                dates: m.dates.map((d) => ({ ...d, date: d.date }))
            }))
        )
        .run();

const processContracts = (q: OptionQuote[]) => {
    return q
        .map((c) => ({
            ...c,
            price: c.last,
            timestamp: new Date(`${c.date} ${c.time}`).getTime(),
            symbolMetadata: new OptionSymbol(c.symbol)
        }))
        .sort((a, b) => b.symbolMetadata.strike - a.symbolMetadata.strike);
};

/** @deprecated You probably want to be using GetSecurityQuoteAction, since that figures out whether the symbol is for an option or now */
export const GetSingleOptionQuoteAction = (symbol: string) => {
    const optSym = new OptionSymbol(symbol);
    const effectiveSymbol = FuturesSymbol.IsFuturesSymbol(symbol) ? optSym.upstreamSymbol : optSym.osiSymbol;
    const subject = FuturesSymbol.IsFuturesSymbol(symbol) ? symbol : optSym.osiSymbol;
    return ReduxApiGet(Urls.options.getSingleQuote(effectiveSymbol), Actions.Options.GetSingleQuote)
        .onSuccess((res: OptionQuote) => processContracts([res])[0])
        .withSubject(subject)
        .run();
};

export const GetOptionQuotesAction = (stockSymbol: string, date: Date | string, putCall: 'put' | 'call' | 'both', numberOfStrikes: number) => {
    if (!numberOfStrikes) {
        console.warn(`numberOfStrikes is a required parameter when querying an options chain. Attempted to query chain for ${stockSymbol} - ${date}`);
    }
    const effectiveDate = typeof date === 'string' ? date : date?.toISOString()?.slice(0, 10);
    return ReduxApiGet(Urls.options.getChain(stockSymbol, effectiveDate, putCall, numberOfStrikes), Actions.Options.GetChain)
        .onSuccess((res: OptionChainResult): OptionChainResult => {
            return {
                expirationDate: new Date(res.expirationDate),
                quote: res.quote,
                calls: processContracts(res?.calls || []),
                puts: processContracts(res?.puts || [])
            };
        })
        .withSubject({ symbol: stockSymbol, date })
        .run();
};

/**
 * @deprecated Use SecurityMetadata unitFactor instead
 */
export const GetOptionContractSizeAction = (osiSymbol: string) =>
    ReduxApiGet(Urls.options.getContractSize(osiSymbol), Actions.Options.GetContractSize)
        .withSubject(osiSymbol)
        .withMutex()
        .useStored((s) => s.options.sizesByOsi[osiSymbol])
        .onSuccess((res: { sharesPerContract: number }) => res.sharesPerContract)
        .run();
