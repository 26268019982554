import { Snex1English } from 'phoenix/assets/lang/Snex1English'
import { useTextStore } from 'phoenix/hooks/UseText'
import { Snex1LanguagePack } from './Snex1LanguagePack'

export const T = (select: (l: Snex1LanguagePack) => string, opts?: Partial<{
    case: 'title' | 'sentence' | 'lower' | 'default'
}>): string => {

    return useTextStore.getState().getTextString(select, opts)
}

export const Ts = <TSection>(select: (l: Snex1LanguagePack) => TSection): TSection => {
    return useTextStore.getState().getText(select)
}


export const FromEnglish = (select: (l: Snex1LanguagePack) => {[ key: string ]: any}, english: string, by: 'value' | 'key' = 'value') => {
    const text = useTextStore.getState().text || Snex1English;
    const englishSet = select(Snex1English)
    const hit = Object.entries(englishSet).find(e => by === 'key' ? e[0].localeCompare(english, undefined, { sensitivity: 'accent' }) === 0 : e[1] === english)
    const key = hit?.[0]
    return key ? select(text)[key] || english : english
}