// @ts-strict-ignore
import { Flex } from 'components/Flex';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Control } from '../videoPlayer/controls';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Post } from 'phoenix/stores/OneProNetworkStore/OneProNetworkTypes';
import { SkeletonVideo } from '../videoPlayer/videoPlayer';
import style from './style.module.scss';
import { useModalVideoPlayerStore } from '../videoPlayer/videoPlayerHelpers';
import { PlayIcon } from 'components/OneProNetwork/assets/playIcon';
import { GetAsOf } from '../deskAlerts/deskAlerts';
import { PauseIcon } from 'components/OneProNetwork/assets/pauseIcon';
import { useAppWindowSize } from 'hooks/UseWindowSize';
import { Skeleton } from '@mui/material';
import ReactVisibilitySensor from 'react-visibility-sensor';

const VideoContent = ({
    loading,
    post,
    isPlaying,
    setIsBuffering,
    playerRef,
    isMuted,
    isAutoPlay
}: {
    loading?: boolean;
    post: Post;
    isPlaying?: boolean;
    setIsBuffering: (s: boolean) => void;
    playerRef?: React.MutableRefObject<ReactPlayer>;
    isMuted?: boolean;
    isAutoPlay?: boolean;
}): JSX.Element => {
    return loading ? (
        <SkeletonVideo height={'100%'} />
    ) : (
        <ReactPlayer
            url={post?.media?.content[0]}
            playing={isPlaying}
            ref={playerRef}
            loop={false}
            controls={false}
            muted={isMuted}
            onBuffer={() => setIsBuffering(true)}
            onBufferEnd={() => setIsBuffering(false)}
            height={'100%'}
            width={'auto'}
            style={{ opacity: isPlaying === null ? 0 : 1, transition: '500ms ease all' }}
            config={{
                youtube: {
                    playerVars: {
                        controls: 0,
                        autoplay: isAutoPlay? 1: 0,
                        isMuted: isMuted? 1: 0,
                        modestbranding: 1,
                        rel: 0,
                        fs: 0,
                        disablekb: 0,
                        showinfo: 0,
                    }
                },
                vimeo: {
                    playerOptions: {
                        controls: false,
                        muted: isMuted,
                        progressBar: false,
                        autoplay: !!isAutoPlay
                    }
                }
            }}
        />
    );
};

const VideoOverlay = ({
    loading,
    post,
    isPlaying,
    isBuffering,
    isMuted,
    play,
    expand,
    mute,
    unmute
}: {
    loading?: boolean;
    post: Post;
    isPlaying?: boolean;
    isBuffering?: boolean;
    isMuted?: boolean;
    play: FlagAction;
    expand: VoidAction;
    mute: VoidAction;
    unmute: VoidAction;
}): JSX.Element => {
    return loading || isBuffering ? null : (
        <Flex column fullWidth className={isPlaying ? style['in-frame-video-overlay-hidden'] : style['in-frame-video-overlay']}>
            <Flex column center align={'center'} fullWidth style={{ flex: '1 1 auto' }}>
                {!isPlaying ? (
                    <Control onClick={() => play(true)} title={'Play video'}>
                        <PlayIcon width={48} style={{ marginTop: 32 }} />
                    </Control>
                ) : (
                    <Control onClick={() => play(false)} title={'Pause video'}>
                        <PauseIcon width={48} style={{ marginTop: 32 }} />
                    </Control>
                )}
            </Flex>
            <Flex column fullWidth style={{ padding: 8 }}>
                {post?.author?.name && (
                    <Flex row fullWidth align={'center'} style={{ fontSize: 13, gap: 8 }}>
                        <div>{post?.author?.name}</div>
                        <div>·</div>
                        <div style={{ opacity: 0.75 }}>{GetAsOf(post?.created)}</div>
                    </Flex>
                )}
                <Flex row fullWidth align={'flex-end'} style={{ gap: 6 }}>
                    <div style={{ fontSize: 16, fontWeight: 600, flex: '1 1 auto' }}>{post?.title}</div>
                    <div style={{ flex: 0 }}>
                        {isMuted ? (
                            <Control onClick={() => unmute()} title={'Unmute sound'}>
                                <VolumeOffIcon style={{ height: 16, width: 16 }} />
                            </Control>
                        ) : (
                            <Control onClick={() => mute()} title={'Mute sound'}>
                                <VolumeUpIcon style={{ height: 16, width: 16 }} />
                            </Control>
                        )}
                    </div>
                    <div style={{ flex: 0 }}>
                        <Control onClick={expand} title={'Open modal'}>
                            <FullscreenIcon style={{ height: 16, width: 16 }} />
                        </Control>
                    </div>
                </Flex>
            </Flex>
        </Flex>
    );
};

export const CalculateSize = (containerRef: React.MutableRefObject<HTMLDivElement>, heightRatio: number): { width: number; height: number } => {
    const convertPaddingStringToNumber = (val?: string): number => {
        if (val.toLowerCase().includes('px')) return parseFloat(val.replace('px', ''));
        return 0;
    };

    const containerStyle = window.getComputedStyle(containerRef?.current);
    const paddingL = convertPaddingStringToNumber(containerStyle.getPropertyValue('padding-left'));
    const paddingR = convertPaddingStringToNumber(containerStyle.getPropertyValue('padding-right'));

    const newW = containerRef?.current?.clientWidth ? containerRef?.current?.clientWidth - (paddingL + paddingR) : 0;

    const newH = newW === 0 ? 0 : newW * heightRatio;
    return { width: Math.round(newW), height: Math.round(newH) };
};

export const InFrameVideoPlayer = ({
    loading,
    post,
    containerRef
}: {
    loading?: boolean;
    post: Post;
    containerRef: React.MutableRefObject<HTMLDivElement>;
}): JSX.Element => {
    const autoPlaySetting = true; /*set this to false if we do not want to auto play videos when the become visible */
    const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
    const [appWindowSize, _] = useAppWindowSize();
    const heightRatio = 9 / 16;

    const [isPlaying, setIsPlaying] = useState<boolean>(autoPlaySetting);
    const [isMuted, setIsMuted] = useState<boolean>(true);
    const [isBuffering, setIsBuffering] = useState<boolean>(false);

    const { actions } = useModalVideoPlayerStore();
    const playerRef = useRef<ReactPlayer>(null);



    const openModal = () => {
        const currentlyPlaying = isPlaying;
        if (isPlaying) setIsPlaying(false);
        actions.setPlaying(currentlyPlaying);
        actions.setVariant('modal');
        actions.setMuted(isMuted);
        actions.setData({
            post: {
                title: post?.title,
                url: post?.media?.content[0],
                thumbnail: post?.thumbnailUri
            },
            author: {
                title: post?.author?.title,
                name: post?.author?.name,
                imageUri: post?.author?.imageUri
            }
        });
        playerRef?.current?.seekTo(0);
        actions.setVisible(true);
    };

    useEffect(() => {
        const size = CalculateSize(containerRef, heightRatio);
        setSize(size);
    }, [appWindowSize, containerRef, heightRatio]);

    if (loading) return <Skeleton variant={'rectangular'} height={size.height} width={size.width} />;

    return (
        <ReactVisibilitySensor onChange={(value: boolean) => setIsPlaying(value)}>
        <Flex
            id={`inframe-wrapper-${post?.id}`}
            column
            className={style['in-frame-wrapper']}
            style={{
                height: size.height,
                minHeight: size.height,
                width: size.width,
                minWidth: size.width,
                backgroundImage: isPlaying !== null ? 'none' : `url(${post?.thumbnailUri})`
            }}
        >
            <VideoContent {...{ loading, post, isPlaying, setIsBuffering, playerRef, isMuted, isAutoPlay: !!isPlaying }} />
            <VideoOverlay
                {...{
                    loading,
                    post,
                    isPlaying,
                    isBuffering,
                    play: (s: boolean) => setIsPlaying(s),
                    expand: openModal,
                    isMuted,
                    mute: () => setIsMuted(true),
                    unmute: () => setIsMuted(false)
                }}
            />
        </Flex>
        </ReactVisibilitySensor>
    );
};
