// @ts-strict-ignore
import { useColors } from 'hooks/UseColors';
import { ColorType, createChart, IChartApi, LineStyle } from 'lightweight-charts';
import { GetLoadingChartPointsAction } from 'phoenix/redux/actions';
import React, { useEffect, useRef } from 'react';
import { Flex } from '../..';
import './Chart.scss';

interface SkeletonChartProps {
    containerId?: string,
    chartLineWidth?: number,
    height?: number,
    width?: number
}

export const SkeletonChart = React.memo((props: SkeletonChartProps) => {
    const {
        containerId,
        chartLineWidth = 2,
        height = 300,
        width = 500
    } = props;
    const colors = useColors();
    const chartRef = useRef(null);
    const PrepareChart = (chart: IChartApi | any, container) => {

        const seriesOptions = {
            topColor: 'rgba(143, 143, 143, 0.2)',
            bottomColor: 'rgba(143, 143, 143, 0.2)',
            color: 'rgba(143, 143, 143, 0.2)',
            lineColor: 'rgba(143, 143, 143, 0)',
            lineWidth: chartLineWidth,
            lineStyle: LineStyle.Solid,
            crosshairMarkerVisible: false,
            crosshairMarkerRadius: 0,
        }

        const data = GetLoadingChartPointsAction().data.map(d => ({ time: d.timestamp / 1000, value: d.value }));

        const series = chart.addAreaSeries(seriesOptions);
        series.setData(data);
        series.applyOptions({ priceLineVisible: false });

        chart.timeScale().setVisibleLogicalRange({ from: 1, to: data.length - 2 })

        const contID = document.getElementById(containerId);
        if (contID.hasChildNodes())
            contID.removeChild(contID.childNodes[0]);
        contID.appendChild(container);
    };

    useEffect(() => {
        if (chartRef.current) {
            const container = document.createElement('div');
            container.style.position = 'relative';
            container.style.height = `${height}px`;
            container.style.width = `${width}px`;
            const chart = createChart(container, {
                width,
                height,
                leftPriceScale: {
                    visible: false,
                    scaleMargins: {
                        top: 0.1,
                        bottom: 0.1
                    },
                    borderVisible: false
                },
                rightPriceScale: {
                    visible: false,
                },
                timeScale: {
                    fixLeftEdge: true,
                    shiftVisibleRangeOnNewBar: false,
                    lockVisibleTimeRangeOnResize: true,
                    borderVisible: false,
                    visible: false
                },
                layout: {
                    background: { type: ColorType.Solid, color: 'transparent' },
                    textColor: colors.grayDark
                },
                grid: {
                    horzLines: {
                        visible: false
                    },
                    vertLines: {
                        visible: false
                    }
                },
                crosshair: {
                    vertLine: {
                        visible: false
                    },
                    horzLine: {
                        visible: false
                    }
                }
            });

            PrepareChart(chart, container);

        }
    }, [colors])


    return (
        <Flex
            column
            className='wrapper'>
            {
                <div
                    className='chart-wrapper'
                    id={containerId}
                    ref={chartRef}
                    style={{ height, width, overflow: 'visible' }}>
                </div >
            }
        </Flex >
    );
})