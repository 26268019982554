import { GlobalConfig } from '../models/GlobalConfig'

let _config: GlobalConfig | null = null

export const SetConfig = (config: Partial<GlobalConfig>) => {
    // @ts-ignore
    _config = { ...(_config || {}), ...config }
}

export const GetConfig = (): GlobalConfig | null => _config
