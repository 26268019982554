// @ts-strict-ignore
import { Typography } from '@mui/material';
import { ArrowUpward, LocalAtm, SearchRounded } from '@mui/icons-material';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useColors } from 'hooks/UseColors';
import { usePageNotFound } from 'hooks/UsePageNotFound';
import { useProgress } from 'hooks/UseProgress';
import { useTelemetry } from 'hooks/UseTelemetry';
import { AlgoliaTypesThatCannotLookAtDeepAnalysis, AlgoliaTypesThatCannotLookAtOptions, GetConfig, SecurityTypes } from 'phoenix/constants';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useTier } from 'phoenix/hooks/UseTier';
import { Actions, GetMyUserInfoAction } from 'phoenix/redux/actions';
import AlgoliaHelper, { AlgoliaSecuritySearchHit } from 'phoenix/util/AlgoliaHelper';
import { GoldenTicket } from 'phoenix/util/ColorHelpers';
import { IsMutualFund } from 'phoenix/util/IsMutualFund';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { GetVariant } from 'phoenix/util/Variant';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Configure, connectAutoComplete, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Routes } from 'util/Routes';
import uuid from 'uuid-random';
import { Flex, StyledIcon } from '..';
import { CircularLogo } from '../CircularLogo';
import { KeyboardKeyDisplay } from '../KeyboardKeyDisplay/KeyboardKeyDisplay';
import './MainSearchBox.scss';
import _ from 'lodash';

/**
 * {@link AlgoliaHelper.ReportClickedSearchResult} requires additional arguments. Rather than carry these around separately from {@link AlgolioSecuritySearchHit}, we'll make it
 * a subtype.
 */
interface AlgoliaHit extends AlgoliaSecuritySearchHit {
    __queryID: string;
    __position: number;
}

interface MainSearchBoxInstantResultsProps {
    currentRefinement: string;
    fullWidth?: boolean;
    hide?: boolean;
    hideLogo?: boolean;
    hideMetadata?: boolean;
    highlightIndex: number;
    hits: AlgoliaHit[];
    onBlur?: () => void;
    onContextMenu?: () => void;
    onFocus?: () => void;
    onHover: (index: number) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onResultClick?: (symbol: string, nextRoute?: string) => void;
    onResults: (count: number) => void;
    refine: (newQuery: string) => void;
    resultCount: number;
    searchIsEmpty?: boolean;
    setOnEnterGlobal: React.Dispatch<React.SetStateAction<(index: number, e: React.FormEvent<HTMLInputElement>) => void>>;
    useResultRoutes?: boolean;
}

interface MainSearchBoxSearchResultCellProps {
    hit: AlgoliaHit;
    query: string;
    highlight?: boolean;
    hideLogo?: boolean;
    hideMetadata?: boolean;
    onClick: (hit: AlgoliaHit) => void;
    onHover: () => void;
    route?: string;
}

const SearchResultCell = React.memo((props: MainSearchBoxSearchResultCellProps) => {
    const colors = useColors();
    const sym = useMemo(() => QualifiedId.Format(props.hit.symbol), [props.hit.symbol]);

    const cell = (
        <Flex
            align='center'
            justify='space-between'
            style={{ background: props.highlight ? colors.cellHoverBackgroundColor : null, padding: 8 }}
            onClick={() => props.onClick(props.hit)}
            onMouseEnter={props.onHover}
        >
            <Flex align='center' style={{ flex: 1 }}>
                {!props.hideLogo && <CircularLogo size={20} url={props.hit.logoUrl} />}
                <Typography style={{ fontSize: 14, width: 100, maxWidth: 100, margin: '0 10px 0 10px' }}>{sym}</Typography>
                <Typography style={{ fontSize: 14, flex: 1, marginRight: 10 }}>{props.hit.name}</Typography>
            </Flex>
            {!props.hideMetadata && (
                <Typography style={{ fontSize: '14px', fontWeight: 400, color: colors.grayText, textTransform: 'capitalize', whiteSpace: 'nowrap' }}>{`${
                    props.hit.exchange
                } - ${SecurityTypes[props.hit.type] || props.hit.type}`}</Typography>
            )}
        </Flex>
    );

    return props.route ? <Link to={props.route}>{cell}</Link> : cell;
});

// Hook to run callback if click outside of element
function useOutsideAlerter(ref: React.MutableRefObject<HTMLDivElement>, callback?: () => void) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback && callback();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback, ref]);
}

const MainSearchBoxInstantResultsFunction = React.memo((props: MainSearchBoxInstantResultsProps) => {
    const { hits: hitsProp = [], onBlur, onMouseLeave, onResults, onResultClick, searchIsEmpty, setOnEnterGlobal } = props;
    const LogEvent = useTelemetry();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const colors = useColors();
    const text = useText((s) => s.misc.search);
    const showFutures = useSnexStore((s) => s.featureFlags.byId[FeatureFlags.FuturesTrading]?.enabled);
    const showOffshoreMutualFunds = useSnexStore((s) => s.featureFlags.byId[FeatureFlags.offshoreMutualFundViewing]?.enabled);
    const ref = useRef(null);
    useOutsideAlerter(ref, onMouseLeave);
    // Compare previous hits and search state for updating OnEnterGlobal fn
    const [{ _hits, _searchIsEmpty }, _setOnEnterProps] = useState({ _hits: hitsProp, _searchIsEmpty: searchIsEmpty });

    const handleClick = (hit: AlgoliaHit) => {
        LogEvent('Main Search Query', { symbol: hit.symbol, route: Routes.security(hit.symbol), trigger: 'result click' });

        if (onResultClick) onResultClick(hit.symbol, Routes.security(hit.symbol));

        AlgoliaHelper.Report.ClickedSecurityFromSearch(hit.symbol, hit.__position, hit.__queryID);
        dispatch({ type: Actions.Securities.GetLogo.Success, data: [{ symbol: hit.symbol, logo: hit.logoUrl }], subject: [hit.symbol] });
        if (onBlur) onBlur();
    };

    const { permittedForDeepAnalysis } = useTier();

    useEffect(() => {
        // Only do all this stuff if we haven't registered the result count yet
        if (props.resultCount !== hitsProp.length) {
            onResults(hitsProp?.length ?? 0);
        }

        const onEnterGlobal = () => (index: number, e: KeyboardEvent) => {
            if (!(hitsProp && index < hitsProp.length) || searchIsEmpty) return;

            const hit = hitsProp[index];
            const { symbol, type } = hit;

            let route = Routes.security(symbol);

            AlgoliaHelper.Report.ClickedSecurityFromSearch(hit.symbol, hit.__position, hit.__queryID);
            dispatch({ type: Actions.Securities.GetLogo.Success, data: [{ symbol: hit.symbol, logo: hit.logoUrl }], subject: [hit.symbol] });

            if (!IsMutualFund(type)) {
                if (e.ctrlKey && !AlgoliaTypesThatCannotLookAtDeepAnalysis.has(type) && permittedForDeepAnalysis) {
                    route = Routes.deepAnalysis(symbol);
                } else if ((e.shiftKey && GetVariant()?.showOptions && !AlgoliaTypesThatCannotLookAtOptions.has(type)) || hit?.optionsOnly) route = Routes.optionChain(symbol);
            }

            LogEvent('Main Search Query', { symbol, route, trigger: 'enter key' });

            if (onResultClick) onResultClick(symbol, route);
            else navigate(route);
        };

        // Only reset the definition of this function if these stateful props change
        if (searchIsEmpty !== _searchIsEmpty || JSON.stringify(_hits) !== JSON.stringify(hitsProp)) {
            setOnEnterGlobal(onEnterGlobal);

            _setOnEnterProps({ _hits: hitsProp, _searchIsEmpty: searchIsEmpty });
        }
    }, [
        LogEvent,
        _hits,
        _searchIsEmpty,
        dispatch,
        hitsProp,
        navigate,
        onResultClick,
        onResults,
        permittedForDeepAnalysis,
        props.resultCount,
        searchIsEmpty,
        setOnEnterGlobal
    ]);

    const NoResults = useCallback(() => {
        return (
            <Flex justify='center'>
                <Typography style={{ color: colors.fadedTextColor, fontWeight: 500 }} variant='body1'>
                    {text.noResults}
                </Typography>
            </Flex>
        );
    }, [colors]);

    const hits = useMemo(() => {
        const futuresRegex = /^future/i;
        const offshoreMutualFundsPrefix = 'offshore-mutual-funds';
        const filterFutures = (h) => !futuresRegex.test(h.type);
        const filterOffshoreMutualFunds = (h) => !h.objectID.startsWith(offshoreMutualFundsPrefix);
        let filteredHits = props.hits;
        if (!showFutures) {
            filteredHits = filteredHits.filter(filterFutures);
        }
        if (!showOffshoreMutualFunds) {
            filteredHits = filteredHits.filter(filterOffshoreMutualFunds);
        }

        return filteredHits;
    }, [props.hits, showFutures, showOffshoreMutualFunds]);

    if (!props.currentRefinement || props.hide) return null;

    return (
        <Flex column ref={ref} className='main-search-box-results' onBlur={props.onBlur} onFocus={props.onFocus} onMouseEnter={props.onMouseEnter}>
            {hits.length ? (
                hits.map((hit, idx) => {
                    const route = hit?.optionsOnly ? Routes.optionChain : Routes.security;
                    return (
                        <SearchResultCell
                            hideLogo={props.hideLogo}
                            hideMetadata={props.hideMetadata}
                            highlight={idx === props.highlightIndex}
                            hit={hit}
                            key={idx}
                            query={props.currentRefinement}
                            route={props.useResultRoutes ? route(hit.symbol) : undefined}
                            onClick={handleClick}
                            onHover={() => props.onHover(idx)}
                        />
                    );
                })
            ) : (
                <NoResults />
            )}
        </Flex>
    );
});

// FIXME: DefinitelyTyped has types, but they are incomplete. For example, here, this HOC isn't typed to allow passthrough props, but the implementation supports it in
// practice.
const MainSearchBoxInstantResults = connectAutoComplete(MainSearchBoxInstantResultsFunction);

let scrollListener = null;

export type MainSearchBoxProps = {
    onResultClick?: (symbol: string) => void;
    fullWidth?: boolean;
    hideLogo?: boolean;
    hideMetadata?: boolean;
    showGoldenTicket?: boolean;
    showHints?: boolean;
    translations?: { submitTitle?: string; resetTitle?: string; placeholder?: string };
    resultRoutes?: boolean;
    autoFocus?: boolean;
};

export const MainSearchBox = TelemetryProvider((props: MainSearchBoxProps) => {
    const { onResultClick } = props;
    const inputRef = useRef(null);
    const colors = useColors();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [highlightIndex, setHighlightIndex] = useState(0);
    const [resultCount, setResultCount] = useState(0);
    const [inputFocused, setInputFocused] = useState(false);
    const [resultsFocused, setResultsFocused] = useState(false);
    const [key, setKey] = useState<string>('1');
    const [progress, setProgress] = useProgress();

    const hasTraded = progress ? progress.hasTraded : true;
    const { showFirstTradePrompt = false } = GetVariant();
    const algoliaConfig = GetConfig()?.Algolia;
    const algoliaClient = algoliaConfig?.Client;
    const algoliaIndex = algoliaConfig?.index ?? 'unknown_securities';
    const [pageNotFound] = usePageNotFound();
    const text = useText((s) => s.header);
    const [searchIsEmpty, setSearchIsEmpty] = useState(true);

    const isCorrectRoute = location.pathname === '/' || location.pathname.indexOf('/account/') > -1;

    const goldenTicket = useMemo(() => hasTraded === false && showFirstTradePrompt && isCorrectRoute, [hasTraded, isCorrectRoute, showFirstTradePrompt]);
    const [scrolledDown, setScrolledDown] = useState(false);
    const [onEnterGlobal, setOnEnterGlobal] = useState<(index: number, e: KeyboardEvent) => void>(() => () => _.noop);

    const handleScroll = () => {
        setScrolledDown(window.scrollY > 30);
    };

    useEffect(() => {
        dispatch(GetMyUserInfoAction());
    }, []);

    useEffect(() => {
        if (goldenTicket && window) {
            scrollListener = window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [goldenTicket]);

    useEffect(() => {
        if (inputFocused && goldenTicket && !searchIsEmpty) {
            progress.hasTraded = true;
            setProgress(progress);
        }
    }, [goldenTicket, inputFocused, progress, searchIsEmpty, setProgress]);

    const close = useCallback(() => {
        setKey(uuid());
        setSearchIsEmpty(true);
    }, []);

    const handleResultClick = useCallback(
        (symbol, route) => {
            close();
            if (onResultClick) onResultClick(symbol);
            else navigate(route);
        },
        [close, onResultClick, navigate]
    );

    const handleKey = (ev: KeyboardEvent) => {
        if (!inputFocused) setInputFocused(true);
        if (!resultCount) return;
        if (ev.key === 'Home') setHighlightIndex(0);
        if (ev.key === 'End') setHighlightIndex(resultCount - 1);
        if (ev.key === 'ArrowDown' && highlightIndex < resultCount - 1) setHighlightIndex(highlightIndex + 1);
        if (ev.key === 'ArrowUp' && highlightIndex > 0) setHighlightIndex(highlightIndex - 1);
        if (ev.key === 'Enter' && !!onEnterGlobal) {
            ev.stopPropagation();
            ev.preventDefault();
            onEnterGlobal(highlightIndex, ev);
            close();
        }
        if (ev.key === 'Escape') close();
    };

    const open = inputFocused || resultsFocused;
    const closed = !open;
    const showGoldenTitle = useMemo(() => {
        return !scrolledDown && goldenTicket && closed;
    }, [scrolledDown, goldenTicket, closed]);

    const setResultsFocusedTrue = useCallback(() => setResultsFocused(true), []);
    const setResultsFocusedFalse = useCallback(() => setResultsFocused(false), []);

    return (
        <InstantSearch indexName={algoliaIndex} searchClient={algoliaClient}>
            <Configure clickAnalytics enablePersonalization hitsPerPage={15} userToken={ AlgoliaHelper.GetUserId() } />
            <Flex
                column
                className={`main-search-wrapper main-search-wrapper-${!closed ? 'active' : 'inactive'}`}
                key={key}
                style={{ width: props.fullWidth ? '100%' : undefined }}
            >
                <Flex
                    column
                    className={`main-search-interior ${goldenTicket ? 'main-search-wrapper-golden' : ''}`}
                    id='header-searchbox'
                    justify='flex-start'
                    style={{ width: props.fullWidth ? '100%' : undefined }}
                >
                    <Flex row align='center' className={`main-search-box-controls ${goldenTicket ? 'main-search-box-controls-golden' : ''}`} id='tour-04-search'>
                        <SearchRounded style={{ color: goldenTicket ? '#E99C23' : inputFocused ? colors.focusedAdornmentColor : colors.blurredAdornmentColor }} />
                        <SearchBox
                            autoFocus={props.autoFocus}
                            inputRef={inputRef}
                            translations={{ placeholder: text.searchForAnything, ...(props.translations || {}) }}
                            onBlur={() => {
                                setInputFocused(false);
                            }}
                            onChange={(e) => {
                                if (e.shiftKey) return;
                                setHighlightIndex(0);
                                setSearchIsEmpty(e.target.value === '');
                            }}
                            onFocus={() => setInputFocused(true)}
                            onKeyDown={handleKey}
                        />
                    </Flex>
                    <MainSearchBoxInstantResults
                        fullWidth={props.fullWidth}
                        hide={closed}
                        hideLogo={props.hideLogo}
                        hideMetadata={props.hideMetadata}
                        highlightIndex={highlightIndex}
                        onBlur={setResultsFocusedFalse}
                        onFocus={setResultsFocusedTrue}
                        onContextMenu={setResultsFocusedTrue}
                        onHover={setHighlightIndex}
                        onMouseEnter={setResultsFocusedTrue}
                        onMouseLeave={setResultsFocusedFalse}
                        onResultClick={handleResultClick}
                        onResults={setResultCount}
                        resultCount={resultCount}
                        searchIsEmpty={searchIsEmpty}
                        setOnEnterGlobal={setOnEnterGlobal}
                        useResultRoutes={props.resultRoutes}
                    />
                </Flex>
                {goldenTicket && props.showGoldenTicket ? (
                    <Flex row align='center' className='golden-ticket' justify='center' style={{ opacity: showGoldenTitle ? 1 : 0 }}>
                        <LocalAtm style={{ color: GoldenTicket }} />
                        <Typography style={{ color: GoldenTicket, margin: '0 15px' }} variant='h5'>
                            Get started by placing your first trade.
                        </Typography>
                        <ArrowUpward style={{ color: GoldenTicket }} />
                    </Flex>
                ) : null}
                {props.showHints && searchIsEmpty && (
                    <Flex row fullWidth className='options-hint' justify='flex-end' style={{ paddingTop: '8px' }}>
                        <Typography style={{ fontSize: '12px', opacity: 0.5 }}>
                            <KeyboardKeyDisplay>Shift</KeyboardKeyDisplay> + <KeyboardKeyDisplay>Enter</KeyboardKeyDisplay> {text.optionsHint}
                        </Typography>
                    </Flex>
                )}
                {pageNotFound && (
                    <Typography
                        id='search-something-label'
                        style={{ fontSize: 15, color: colors.grayText, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}
                        variant='body2'
                    >
                        <span style={{ marginRight: 8 }}>
                            <strong>{text.tip}:</strong> {text.trySearch}
                        </span>
                        <StyledIcon IconComponent={ArrowUpward} size={20} style={{ color: 'grey' }} />
                    </Typography>
                )}
            </Flex>
        </InstantSearch>
    );
}, TelemetryCategories.search);
