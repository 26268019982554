// @ts-strict-ignore
import { SetConfig } from '../../constants/Config'
import { resolveGainLsAppId, Urls } from '../../constants/Urls'
import { Jwt } from '../../util'
import { DebugDumpManager } from '../../util/DebugDumpManager'
import { ErrorLogger } from '../../util/ErrorLogger'
import { Lightstreamer } from '../../util/LightstreamerClientManager'
import { GetMemo, SetMemo } from '../../util/Memo'
import { UserLogin } from '../models/Login/UserLogin'
import { Actions } from './Constants'
import { ReduxApiGet, ReduxApiPost } from './Helpers'
import { UserAuthorization } from '../models/Login/UserAuthorization'

export const LogInAction = (username: string, password: string) =>
    ReduxApiPost(Urls.authentication.getToken(), Actions.Login, { username, password })
        .onSuccess((u: UserLogin) => HandleLoginSuccess(u, 'Log In action creator')).onError(HandleLoginError).run()

export const LogOutAction = () => {
    return async (dispatch: any) => {
        if (logoutHandler) await logoutHandler()

    // NOTE: If you do the below dispatch here instead of on LogoutScreen, about half of the screens in the app will stop
    // loading when you log back in. No one knows why, I think it's some kind of Wiccan curse.
    // dispatch({ type: Actions.Logout.Success })
    }
}

export const CheckLoginStatusAction = () => ReduxApiGet(Urls.users.checkLoginStatus(), Actions.CheckLogin)
    .withMutex()
    .onSuccess((result: any) => ({ ...result, authenticated: true }))
    .onError(_ => ({ userAuthorization: UserAuthorization.Denied }))
    .run()

/** @deprecated Any login flow that uses this functionality is likely not something that should be in use anymore */
export const ExchangeCodeForTokenAction = (code: string, state: string) =>
    ReduxApiGet(Urls.authentication.getTokenFromCode(code, state), Actions.GetTokenFromCode)
        .onSuccess((u: UserLogin) => HandleLoginSuccess(u, 'Legact token exchange action creator')).onError(HandleLoginError).run()

export const LogInWithToken = (jwt: string) => ({ type: Actions.JwtLogin, data: jwt })
export const LogOutWithToken = () => ({ type: Actions.JwtLogin, data: null })

/** @deprecated This refresh uses Torch; please use Okta.RefreshToken instead */
export const RefreshTokenAction = (refreshToken: string, options?: Partial<{
    skipLoginTriggerOnSuccess: boolean
}>) =>
    ReduxApiGet(Urls.authentication.getNewTokenFromRefreshToken(refreshToken), Actions.RefreshToken)
        .onSuccess((u: UserLogin) => { HandleLoginSuccess(u, 'Legacy refresh token action creator') })
        .withoutToken()
        .onError(HandleLoginError)
        .run()

type LoginHandlerType = (login: UserLogin) => Promise<void>
let loginHandler: LoginHandlerType = async () => {
    // do nothing by default
}
export const SetLoginHandler = (handler: LoginHandlerType) => { loginHandler = handler }

type LogoutHandlerType = () => Promise<void>
let logoutHandler: LogoutHandlerType = async () => {
    // do nothing by default
}
export const SetLogoutHandler = (handler: LogoutHandlerType) => { logoutHandler = handler }

export const HandleLoginSuccess = async (user: UserLogin, callsite: string = null) => {
    const jwt = user.accessToken

    try {
        console.log(`RUNNING THE LOGIN HANDLER FROM ${callsite || 'UNKNOWN CALLSITE'}`)
        if (loginHandler) await loginHandler(user)
        else console.log('Note: no login handler set')
    } catch (e) {
        console.log('LOGIN HANDLER ERROR', e)
        ErrorLogger.RecordError(e, "HandleLoginSuccess -> loginHandler() failed")
        return { ...user, authenticated: false }
    }

    try {
        const sessionId = GetMemo('SESSION_ID')
        const gainLsAppId = resolveGainLsAppId()
        SetConfig({ UserIdToken: jwt })
        Lightstreamer.SetUser('snex', jwt, sessionId)
        Lightstreamer.SetUser('gain', `${Jwt.TryGetSecureAuthCdsId(jwt)}:${gainLsAppId}`, jwt)
        DebugDumpManager.RecordEvent({ item: 'Login Success', event: JSON.stringify({ sessionId }) })
        SetMemo('USER_NAME', sessionId)
        return { ...user, authenticated: true }
    } catch (error) {
        ErrorLogger.RecordError(error, "HandleLoginSuccess -> Login authenticated user failed")
        return { ...user, authenticated: false, error }
    }
    
}

export const HandleLoginError = (error: any) => {
    try {
        SetConfig({ UserIdToken: null })
        const sessionId = GetMemo('SESSION_ID')
        DebugDumpManager.RecordEvent({ item: 'Login Error', event: JSON.stringify({ sessionId, e: error }) })
        Lightstreamer.SetUser('snex', '', sessionId)
        Lightstreamer.SetUser('gain', '', '')
    } catch (error) { ErrorLogger.Record(new Error(error)) }
    return false
}
