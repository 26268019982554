// @ts-strict-ignore
import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Flex } from '..';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { FormatNumber, GetValueChangeColor, IsMarketOpen } from 'phoenix/util';
import { DirectionalArrow } from '../DirectionalArrow';
import { XS } from 'phoenix/xstream/XS';

interface ExtendedHoursSnapshotProps {
    symbol: string;
    isLoading?: boolean;
}

const ExtendedHoursSnapshotComponent = (props: ExtendedHoursSnapshotProps) => {
    const quote = XS.Quotes.use(props.symbol);

    const [marketTimeSegment] = useMarketTimeSegmentV2();

    const isMarketOpen = useMemo(() => IsMarketOpen(), [marketTimeSegment]);

    if (!isMarketOpen) {
        return (
            <Flex
                align='center'
                justify='flex-start'
                style={{
                    alignSelf: 'flex-start',
                    margin: '3 0',
                    padding: '3 0',
                    borderTop: '1px solid rgba(150, 150, 150, 0.1)',
                    visibility: props.isLoading ? 'hidden' : 'visible'
                }}
            >
                <Typography style={{ marginRight: 3, fontWeight: 400 }} variant='h6'>
                    Extended hours:
                </Typography>
                <Flex align='center' style={{ marginLeft: 7 }}>
                    <DirectionalArrow valueChange={quote?.extendedChange} />
                    <Typography style={{ ...GetValueChangeColor(quote?.extendedChange), margin: '0 3', marginLeft: 5 }} variant='h6'>
                        {FormatNumber.toPercent(quote?.extendedChangePercent * 100)}
                    </Typography>
                    <Typography style={{ ...GetValueChangeColor(quote?.extendedChange), marginLeft: 10, fontWeight: 400 }} variant='h6'>
                        {FormatNumber.toMoney(quote?.extendedChange)}
                    </Typography>
                </Flex>
            </Flex>
        );
    }
    return null;
};

export const ExtendedHoursSnapshot = React.memo(ExtendedHoursSnapshotComponent);
