// @ts-strict-ignore
import { Typography, Skeleton } from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { components as ReactSelectComponents, OptionProps } from 'react-select';
import { Flex, WarningLabel } from '..';
import { GetClientAccountsAction } from 'phoenix/redux/actions/AccountsActions';
import { Account } from 'phoenix/redux/models/Accounts/Account';
import { GetEnableDebugLogging } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { AssetFamily } from 'phoenix/models/AssetClasses/AssetClass';
import { useText } from 'phoenix/hooks/UseText';
import { AssetClassLabel } from '../AssetClassLabel/AssetClassLabel';
import { DropdownComponent } from '../DropdownComponent';
import { useTelemetry } from '../../hooks/UseTelemetry';
import { AccountOption, makeAccountOptions } from './helpers';
import { SnexAccountOption, UseActiveAndPendingAccountOptions } from 'phoenix/hooks/useAccounts';
import { DropdownOption } from 'phoenix/models/DropdownOptions';
import { T } from 'phoenix/assets/lang/T';
import { OptionSymbol } from 'phoenix/redux/models';
import { UseSelectedAccount, useSelectedAccountByAssetFamily } from './Store/AccountSelectionStore';
import { getAccountAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { BuyingPowerStore_Load } from 'phoenix/stores/BuyingPowerStore';
import { useAppDispatch } from 'AppRoot';
import { useQueryParam } from 'hooks/UseQueryParams';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';

export interface AccountDropdownProps {
    allowAll?: boolean;
    assetFamily?: AssetFamily;
    ignoreAssetFamily?: AssetFamily[];
    balanceType?: 'buy' | 'sell' | 'custom' | string;
    defaultToFirst?: boolean | 'if-only-one';
    disabled?: boolean;
    filter?: (a: { accountNumber: string }) => boolean;
    isByAssetClass?: boolean;
    numberWhitelist?: Set<string>;
    onSelect?: (val: SnexAccountOption | null) => void;
    /** @deprecated Please use UseSelectedAccount */ selectedAccount?: SnexAccountOption | string | null;
    skipInitialSelect?: boolean; // For non-memo screens that redraw the dropdown every time the account changes
    style?: React.CSSProperties;
    symbol?: string;
    useAvailableBalance?: boolean;
    useOptionsBuyingPower?: boolean;
    accountsFilter?: (accounts: SnexAccountOption[]) => SnexAccountOption[];
}

type AccountDropdownCellOptionProps = {
    children: React.ReactChild;
    data: AccountOption;
};

const AccountDropdownCellPresentation = ({
    children,
    data,
    ...rest // These are react-select component props that get passed down from the parent
}: AccountDropdownCellOptionProps) => {
    return (
        <ReactSelectComponents.Option {...(rest as OptionProps<AccountOption, false>)}>
            <Flex column style={{ width: '100%', overflow: 'hidden', padding: '1 2', boxSizing: 'border-box' }}>
                <Flex row justify='space-between'>
                    <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', fontWeight: 400 }} variant='h6'>
                        {children}
                    </Typography>
                    <Flex row style={{ gap: 5 }}>
                        {(data.isAccountPending && (
                            <WarningLabel style={{ padding: '3px 12px', fontSize: '13px', margin: 0 }} variant='orange'>
                                {data.pendingText}
                            </WarningLabel>
                        )) || <AssetClassLabel thingId={data.value} />}
                    </Flex>
                </Flex>
                <Flex row align='center' justify='space-between' style={{ width: '100%', marginTop: 5 }}>
                    <Typography style={{ display: 'flex' }} variant='body2'>
                        <span style={{ opacity: 0.5, marginRight: 5, display: 'flex' }}>{data.leftLabel || data.buyingPowerLabel}:</span>{' '}
                        <span>{data.leftValue != null ? data.leftValue : <Skeleton animation='wave' width={60} />}</span>
                    </Typography>
                    {data.rightLabel ? (
                        <Typography style={{ display: 'flex', maxWidth: '100px', overflow: 'hidden', wordBreak: 'keep-all', textOverflow: 'ellipsis' }} variant='body2'>
                            <span style={{ opacity: 0.5, marginRight: 5, display: 'flex' }}>{data.rightLabel}:</span>{' '}
                            <span>{data.rightValue != null ? data.rightValue : <Skeleton animation='wave' width={60} />}</span>
                        </Typography>
                    ) : data.rightValue ? (
                        <Typography
                            variant='body2'
                            style={{ whiteSpace: 'nowrap', display: 'flex', maxWidth: '100px', overflow: 'hidden', wordBreak: 'keep-all', textOverflow: 'ellipsis' }}
                        >
                            {QualifiedId.RemovePrefix(data?.value)}
                        </Typography>
                    ) : null}
                </Flex>
            </Flex>
        </ReactSelectComponents.Option>
    );
};

const AccountDropdownComponent = (props: AccountDropdownProps) => {
    const {
        allowAll,
        assetFamily,
        ignoreAssetFamily,
        balanceType = 'buy',
        defaultToFirst,
        filter: filterByProps,
        isByAssetClass,
        onSelect,
        numberWhitelist,
        skipInitialSelect,
        symbol,
        useOptionsBuyingPower,
        useAvailableBalance,
        accountsFilter
    } = props;
    const LogEvent = useTelemetry();
    const dispatch = useAppDispatch();
    const text = useText((s) => s);
    const accountNumberByUrl = useQueryParam('selectedAccount');

    const { load: loadPositions } = usePositionsStore();
    const [selectedAccountFromStore, setSelectedAccount] = UseSelectedAccount();
    const accountByAssetClass = useSelectedAccountByAssetFamily(assetFamily);

    const selectedAccount = isByAssetClass ? accountByAssetClass : selectedAccountFromStore;

    const filter = useCallback(
        (a: { accountNumber: string }): boolean => {
            const accountFamily = getAccountAssetClass(a.accountNumber).family;
            if (!a?.accountNumber) return false;
            if (filterByProps && !filterByProps(a)) return false;
            if (assetFamily && accountFamily !== assetFamily) return false;
            if (ignoreAssetFamily && ignoreAssetFamily.length > 0 && ignoreAssetFamily.includes(accountFamily)) return false;
            if (numberWhitelist && numberWhitelist.size && !numberWhitelist.has(a.accountNumber)) return false;
            return true;
        },
        [filterByProps, assetFamily, ignoreAssetFamily, numberWhitelist]
    );

    const { data: accountsData, loading: accountsLoading } = UseActiveAndPendingAccountOptions({
        filter,
        selectedAccount,
        withAllAccountsOption: allowAll,
        withBuyingPower: true,
        withPositionsForSymbol: symbol,
        withRequests: true
    });

    /* added so that we can pass custom filters to accounts to the account dropdown */
    const accounts = accountsFilter ? accountsFilter(accountsData) : accountsData;

    const handleSelect = useCallback(
        (accountNumber: string) => {
            if (!selectedAccount || selectedAccount !== accountNumber) {
                setSelectedAccount(accountNumber);
                LogEvent('Account Selected');
                onSelect && onSelect(accounts?.find((a) => a.accountNumber === accountNumber));
            }
        },
        [LogEvent, accounts, onSelect, selectedAccount, setSelectedAccount]
    );

    useEffect(() => {
        const run = async () => {
            loadPositions();
            const accounts: Account[] = await dispatch(GetClientAccountsAction());
            if (!skipInitialSelect) {
                const match = accounts?.find((a) => a.accountNumber === selectedAccount);
                if (match && onSelect) onSelect(match);
                else if (GetEnableDebugLogging()) console.warn('AccountDropdown: Could not match an account to the globally selected number', selectedAccount);
            }
            BuyingPowerStore_Load();
        };
        run();
    }, [dispatch, loadPositions, onSelect, selectedAccount, skipInitialSelect]);

    // Default to first account if no account is selected
    // Or, default to accountNumber in URL query param if no account is selected
    useEffect(() => {
        if (!selectedAccount && accounts && Array.isArray(accounts) && accounts.length) {
            const match = accounts?.find((a) => a.accountNumber === selectedAccount);
            if (!match) {
                // URL query param should override component defaultToFirst prop
                if (accountNumberByUrl) {
                    handleSelect(accountNumberByUrl);
                    return;
                }
                if (defaultToFirst === 'if-only-one' && accounts.length > 1) return;
                handleSelect(accounts[0].accountNumber);
            }
        }
    }, [accounts, handleSelect, defaultToFirst, selectedAccount, accountNumberByUrl, selectedAccountFromStore, accountByAssetClass]);

    const qtyOwnedLabelLogic = [
        { rule: assetFamily === EquitiesAssetClass.family && !OptionSymbol.IsOptionSymbol(symbol), value: text.general.shares(2) },
        { rule: assetFamily === CryptosAssetClass.family, value: text.general.quantity },
        { rule: true, value: text.general.contracts(2) }
    ];

    const qtyOwnedLabel = qtyOwnedLabelLogic.find((x) => x.rule)?.value;

    const options = makeAccountOptions({
        accounts,
        balanceType,
        qtyOwnedLabel,
        symbol,
        text,
        useOptionsBuyingPower,
        useAvailableBalance
    });

    const selectedAccountEffective = useMemo(() => {
        return options?.find((a) => a.value === selectedAccount || (selectedAccount === 'all' && !a.value)) || undefined;
    }, [selectedAccount, options]);

    return (
        <AccountDropdownPresentation
            {...{ ...props, options, qtyOwnedLabel, text }}
            onSelect={handleSelect}
            placeholder={T((s) => s.misc.selectAnAccount)}
            value={selectedAccountEffective}
        />
    );
};

export const AccountDropdown = React.memo(AccountDropdownComponent);

export type AccountDropdownPresentationProps = {
    disabled?: boolean;
    onSelect?: (value: string) => void;
    options?: AccountOption[];
    placeholder?: string;
    style?: React.CSSProperties;
    value?: DropdownOption;
    defaultValue?: DropdownOption;
};

export const AccountDropdownPresentation = (props: AccountDropdownPresentationProps): ReactElement => {
    return <DropdownComponent onChange={(value) => props.onSelect(value?.value)} optionComponent={AccountDropdownCellPresentation} {...props} />;
};
