// @ts-strict-ignore
import { SnexAccountOption } from 'phoenix/hooks/useAccounts';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { getAccountAssetClass, GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { DropdownOption } from 'phoenix/models/DropdownOptions';
import { OptionSymbol } from 'phoenix/redux/models';
import { FormatNumber } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import React from 'react';

export interface AccountOption extends DropdownOption {
    leftLabel: string;
    leftValue: React.ReactChild;
    rightLabel?: string;
    rightValue?: React.ReactChild;
    label: string;
    value: string;
    isAccountPending?: boolean;
    pendingText?: Snex1LanguagePack['general']['pending'];
}

export function makeAccountOptions({
    accounts,
    balanceType,
    qtyOwnedLabel,
    symbol,
    text,
    useOptionsBuyingPower,
    useAvailableBalance
}: {
    accounts: SnexAccountOption[];
    balanceType?: 'buy' | 'sell' | 'custom' | string;
    qtyOwnedLabel?: string;
    symbol?: string;
    text: Snex1LanguagePack;
    useOptionsBuyingPower?: boolean;
    useAvailableBalance?: boolean;
}): AccountOption[] {
    const isOption = OptionSymbol.IsOptionSymbol(symbol);
    const ownedValue = isOption ? text.general.contracts(2) : text.general.shares(2);
    const { formatQuantity } = GetAssetClassForSecurity(symbol);

    const allAccountsLabel = (a) => (symbol ? `${text.misc.allAccounts}: ${a.sharesOwned} ${text.general.shares(a.sharesOwned)}` : text.misc.allAccounts);

    return accounts?.map((a) => {
        const isAllAccounts = a.accountNumber === 'all';

        const balanceLabel = balanceType === 'buy' ? text.general.buyingPower : ownedValue;
        const sellBalance = typeof a.sharesOwned === 'number' ? `${FormatNumber.toCommas(a?.sharesOwned)}` : null;
        const buyBalance = typeof a.buyingPower?.buyingPower === 'number' ? `${FormatNumber.toMoney(a.buyingPower?.buyingPower)}` : null;
        const balance = balanceType === 'buy' ? buyBalance : sellBalance;

        const buyingPowerString = useOptionsBuyingPower ? FormatNumber.toMoney(a.buyingPower?.optionsBuyingPower) : FormatNumber.toMoney(a.buyingPower?.buyingPower);
        const assetClass = getAccountAssetClass(a.accountNumber);

        const buyingPowerValue = useAvailableBalance
            ? assetClass && assetClass.type === FuturesAssetClass.type
                ? FormatNumber.toMoneyOpt2(a.buyingPower?.futuresCashAvailable)
                : FormatNumber.toMoneyOpt2(a.buyingPower?.cashAvailable)
            : a.buyingPower
            ? buyingPowerString
            : null;

        // Typically is the BuyingPowerLabel and BuyingPowerValue, but can be custom per asset class
        // Ticket hasn't been made for CryptosAssetFamily or FuturesAssetFamily yet, so we'll just keep the left label from before
        const leftLabel = useAvailableBalance ? text.general.availableBalance : text.general.buyingPower;
        const leftValue = buyingPowerValue;

        // Typically is the PositionsLabel and SharesOwnedValue, but can be custom per asset class
        // Ticket hasn't been made for CryptosAssetFamily or FuturesAssetFamily yet, so we'll just hide the right label
        // This will continue to show the Account Number for the time being
        let rightValue: string = formatQuantity(a?.sharesOwned || 0);
        if (!symbol) rightValue = a.accountNumber;
        if (rightValue === 'all') rightValue = null;

        let rightLabel: string = text.tradeTicket.position;
        if (!symbol || rightValue === null || [CryptosAssetClass, FuturesAssetClass].includes(assetClass)) rightLabel = null;

        return {
            label: isAllAccounts ? allAccountsLabel(a) : `${a.effectiveName || a.accountNumber} (${QualifiedId.RemovePrefix(a.accountNumber)})`,
            value: a.accountNumber,
            isAccountPending: a.isPending,
            pendingText: text.general.pending,
            leftLabel,
            leftValue,
            rightLabel,
            rightValue
        };
    });
}
