// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../..';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { WatchlistSecurity } from 'phoenix/redux/models';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { DescriptionLabel, SymbolLabel } from '../Shared/Labels';
import { SecurityCellChart } from '../Shared/SecurityCellChart';
import { SecurityCellLogo } from '../Shared/SecurityCellLogo';
import { SecurityNameSection } from '../Shared/SecurityNameSection';
import style from '../style.module.scss';
import { SecurityValue } from './SecurityValue';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { useInView } from 'react-intersection-observer';

export interface EquityWatchlistCellProps {
    getCached?: boolean;
    onDelete?: (watchlistId?: any) => void;
    security?: WatchlistSecurity;
    streamingNamespace?: string;
    symbol: string;
    visible?: boolean;
}

export const EquityWatchlistCell = React.memo((props: EquityWatchlistCellProps) => {
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();

    const { streamingNamespace, security, symbol } = props;

    const { ref, inView } = useInView({ trackVisibility: true, threshold: 0, delay: 400 });
    const [subscription, setSubscription] = useState<string>(null);

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
    }, [symbol]);

    useEffect(() => {
        const run = async () => {
            if (inView && !subscription) {
                const ticket: string = await xdispatch(XS.Quotes.start(symbol, streamingNamespace));
                setSubscription(ticket);
            } else if (!inView) {
                XS.stop(subscription);
                setSubscription(null);
            } else {
                await dispatch(GetSecurityQuoteAction(symbol));

                if (!subscription) {
                    const ticket: string = await xdispatch(XS.Quotes.start(symbol, streamingNamespace))
                    setSubscription(ticket)
                }
            }
        };

        run();
        return () => {
            XS.stop(subscription);
        };
    }, [inView]);

    const [latestChartPercChange, setLatestChartPercChange] = useState(null);
    const [latestChartPrice, setLatestChartPrice] = useState(null);

    const onCrosshairUpdate = useCallback((value?: any, isScrubbing?: boolean) => {
        setLatestChartPercChange(value[symbol]?.chartPercChange);
        setLatestChartPrice(typeof value[symbol] === 'number' ? value[symbol] : value[symbol]?.value);
    }, []);

    return (
        <>
            <SecurityCellLogo symbol={symbol} />
            <Flex ref={ref} column className={style.cellContent}>
                <Flex className={style.mainRow}>
                    <SecurityNameSection>
                        <SymbolLabel>{QualifiedId.RemovePrefix(symbol)}</SymbolLabel>
                        <DescriptionLabel>{security?.name?.toLowerCase()}</DescriptionLabel>
                    </SecurityNameSection>
                    <SecurityCellChart withQuoteOverride symbol={symbol} onCrosshairUpdate={onCrosshairUpdate} />
                    <SecurityValue withQuoteOverride chartPercChange={latestChartPercChange} chartPrice={latestChartPrice} symbol={symbol} />
                </Flex>
            </Flex>
        </>
    );
});
