// @ts-strict-ignore
import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux';
import { ActionModal } from '..';
import { Flex } from '../..';
import { useText } from 'phoenix/hooks/UseText';
import { GetEnableDebugLogging, GetUseDebugIdleTime } from 'phoenix/util';
import { GoToLogout } from 'util/GoToLogin';
import { SnexButton } from '../../SnexButton/SnexButton';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { Environment, ResolveEnvironment } from "phoenix/constants"

export const IdleTimeoutModal = () => {

    const dispatch = useDispatch();
    const env: Environment = ResolveEnvironment();

    const text = useText(t => t.modals.appIdle);

    const resolveTimeoutMs = () => {
        if (env === 'pro') return 10800000; //3h
        if (GetUseDebugIdleTime()) return 10000; //10s
        return 1800000; //30m
    }

    // Set timeout values
    const MILLISECONDS_PROMPT_TIMEOUT =  30000 // 30 Second countdown to take action before logout occurs
    const MILLISECONDS_TILL_IDLE = resolveTimeoutMs();

    // Modal open state
    const [isOpen, setIsOpen] = useState(false)

    // Time before idle
    const [remaining, setRemaining] = useState(0)
    const [lastActive, setLastActive] = useLocalStorage('LAST_ACTIVE', Date.now())

    const onPrompt = () => {
        // onPrompt will be called after the timeout value is reached
        // In this case 30 minutes. Here you can open your prompt. 
        // All events are disabled while the prompt is active. 
        // If the user wishes to stay active, call the `reset()` method.
        // You can get the remaining prompt time with the `getRemainingTime()` method,
        setIsOpen(true)
        setRemaining(MILLISECONDS_PROMPT_TIMEOUT)
    }

    const onAction = () => setLastActive(Date.now())

    const onIdle = () => {
        // onIdle will be called after the promptTimeout is reached.
        // In this case 30 seconds. Here you can close your prompt and 
        // perform what ever idle action you want such as log out your user.
        // Events will be rebound as long as `stopOnMount` is not set.
        handleLogout()
    }

    const onActive = () => {
        // onActive will only be called if `reset()` is called while `isPrompted()` 
        // is true. Here you will also want to close your modal and perform
        // any active actions. 
        setIsOpen(false)
        setRemaining(MILLISECONDS_TILL_IDLE)
    }

    /* done so that there would be no debounce on reset to make sure all reset calls are processed */
    const { reset } = useIdleTimer({
        timeout: MILLISECONDS_TILL_IDLE,
        promptTimeout: MILLISECONDS_PROMPT_TIMEOUT,
        onPrompt,
        onIdle,
        onActive,
        onAction,
    })

    const { getRemainingTime, isPrompted, getLastActiveTime } = useIdleTimer({
        timeout: MILLISECONDS_TILL_IDLE,
        promptTimeout: MILLISECONDS_PROMPT_TIMEOUT,
        onPrompt,
        onIdle,
        onActive,
        onAction,
        debounce: 500,
    })

    const handleStayLoggedIn = () => {
        reset()
        setIsOpen(false)
    }

    const handleLogout = useCallback(() => {
        setIsOpen(false)
        setRemaining(0)
        GoToLogout()
    }, [setIsOpen])

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [getRemainingTime, isPrompted])

    const ToMinutesAndSeconds = (millis) => {
        const minutes = Math.floor(millis / 60000);
        const seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
        return minutes + " minutes and " + (seconds < 10 ? '0' : '') + seconds + " seconds...";
    }


    useEffect(() => {
        const interval = setInterval(() => {
            const t1 = new Date().getTime()
            const t2 = getLastActiveTime().getTime()

            if ((t1 - t2) > (MILLISECONDS_TILL_IDLE + MILLISECONDS_PROMPT_TIMEOUT)) handleLogout() // handle case where computer went to sleep or locked 
            if (GetEnableDebugLogging()) console.log(`Remaining time till idle ${ToMinutesAndSeconds(getRemainingTime())}`); // write remaining time to console log
        }, 1000);

    }, []);

    return (
        <>
            <ActionModal height='auto' isOpen={isOpen} label={text.modalHeader} toggleModal={setIsOpen}>
                <>
                    <Flex column justify="center" align='center' style={{ flex: 1, margin: '35px 0' }}>
                        <Typography variant="h6" style={{ marginBottom: 10 }}>{text.noticedInactivity}</Typography>
                        <Typography variant="body2">{text.logoutInSeconds(Math.ceil(getRemainingTime() / 1000))}</Typography>
                    </Flex>
                    <Flex style={{ width: '100%' }}>
                        <SnexButton flavor="delete" onClick={handleLogout} style={{ marginRight: 10 }}>{text.logout}</SnexButton>
                        <SnexButton flavor="submit" onClick={handleStayLoggedIn} style={{ marginLeft: 10 }}>{text.stay}</SnexButton>
                    </Flex>
                </>
            </ActionModal>
        </>
    )
}
