import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';

interface SnexTableHeaderCellProps {
    align?: 'right' | 'left' | 'center', 
    colSpan?: number, 
    style?: React.CSSProperties, 
    children?: any, 
    onClick?: (event: any) => any, 
    width?: any, 
    withinCard?: boolean
}

export const SnexTableHeaderCell = (props: SnexTableHeaderCellProps) => {

    const colors = useColors();

    return (
        <TableCell align={props.align} colSpan={props.colSpan} style={{ backgroundColor: props.withinCard ? colors.cardBackgroundColor : colors.mainBackgroundColor, fontWeight: 700, cursor: 'pointer',
            fontSize: 15, padding: '0 16px', paddingBottom: '15px', ...props.style, borderBottom: `1px solid ${colors.dividerColor}` }} onClick={props.onClick}>
            <div style={{ width: props.width || null }}>
                <Typography style={{ fontWeight: 700, textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: colors.generalTextColor }} variant='h6'>{props.children}</Typography>
            </div>
        </TableCell>
    );
};