import { Skeleton, Typography } from '@mui/material';
import React from 'react';

export default function VolumeBadge({ loading, volume, volumeText }: { loading: boolean; volume: string; volumeText: string }) {
    return loading ? (
        <Skeleton animation='wave' width={100} />
    ) : (
        <div className='streaming-volume-wrapper'>
            <Typography style={{ marginRight: 7, fontWeight: 500 }} variant='h6'>
                {volumeText}
            </Typography>
            <Typography variant='h6'>{volume}</Typography>
        </div>
    );
}
