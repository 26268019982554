// @ts-strict-ignore
import { Tooltip } from '@mui/material';
import { Flex } from 'components/Flex';
import { useColors } from 'hooks/UseColors';
import { useText } from 'phoenix/hooks/UseText';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GetSecurityTypeFromStore } from 'phoenix/util';
import React from 'react';

export const QuantityWithShortIconBySymbol = React.memo(
    ({ symbol, quantity, text, style }: { symbol: string; quantity: string; text?: string; style?: React.CSSProperties }) => {
        if (!symbol) return QuantityWithoutShortIcon({ quantity, text });
        const secId = QualifiedSecurityId.Parse(symbol);
        const secType = GetSecurityTypeFromStore(secId.id);
        if (secType !== 'equity') return QuantityWithoutShortIcon({ quantity, text });
        return QuantityWithShortIcon({ quantity, text, style });
    }
);

export const QuantityWithShortIconByAccountNumber = React.memo(
    ({ accountNumber, quantity, text, style }: { accountNumber: string; quantity: string; text?: string; style?: React.CSSProperties }) => {
        if (!accountNumber || accountNumber.includes(':')) return QuantityWithoutShortIcon({ quantity, text }); // non-equities accounts
        return QuantityWithShortIcon({ quantity, text, style });
    }
);

export const QuantityWithoutShortIcon = ({ quantity, text }: { quantity: string; text?: string }) => {
    return (
        <Flex row style={{ color: 'inherit' }}>
            <div>
                {quantity} {text}
            </div>
        </Flex>
    );
};

const QuantityWithShortIcon = ({ quantity, text, style }: { quantity: string; text?: string; style?: React.CSSProperties }) => {
    const { holdingsGold, holdingsGoldBackground } = useColors();
    const isShort = Number(quantity) < 0;
    const t = useText((t) => t.general.isShort);
    return (
        <Flex row>
            <div style={{ color: isShort ? holdingsGold : 'inherit' }}>
                {quantity} {text}
            </div>
            {isShort ? (
                <Tooltip title={t}>
                    <div style={{ display: 'inline-flex', paddingLeft: '.4em' }}>
                        <Flex
                            column
                            align='center'
                            justify='center'
                            style={{
                                lineHeight: 1.3,
                                width: '1.3em',
                                height: '1.3em',
                                color: holdingsGold,
                                backgroundColor: holdingsGoldBackground,
                                whiteSpace: 'nowrap',
                                border: `1px solid ${holdingsGold}`,
                                borderRadius: '50%',
                                ...style
                            }}
                        >
                            <span style={{ fontSize: '.9em' }}>S</span>
                        </Flex>
                    </div>
                </Tooltip>
            ) : null}
        </Flex>
    );
};
