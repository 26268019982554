// @ts-strict-ignore
import { GetColors } from '../theming/Colors'
import { StorageKeys } from '../constants'

type rgb = { r: number, g: number, b: number }
type hsl = { h: number, s: number, l: number }

export const GenerateRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`

export const GetRgbValues = (rgb: string) => rgb.replace(/[^\d,]/g, '').split(',')

export const HexToRgb = (hex: string) => HexToRgba(hex, 1)

export const HexToRgba = (hex: string, alpha: number) => {
    const { r, g, b } = HexToRgbNum(hex)
    return `rgba(${r},${g},${b},${alpha})`
}

export const HexToHsl = (hex: string) => {
    const { r, g, b} = HexToRgbNum(hex);
    return RgbToHslNum(r, g, b)
}

export const HexToHsv = (hex: string) => {
    const { r, g, b} = HexToRgbNum(hex);
    return RgbToHsvNum(r, g, b)
}

export const HexToRgbNum = (hex: string): rgb => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return { r, g, b };
}

export const AdjustColorTransparency = (color: string, alphaValue: number) => {
    if (alphaValue < 0 || alphaValue > 1) return color

    if (/^#/.test(color)) return `${color}${(alphaValue * 255).toString(16)}`
    if (!color.includes('rgb')) return color
    if (!color.includes('rgba')) return `rgba(${GetRgbValues(color).concat(`${alphaValue}`)})`

    const adjustedColor = GetRgbValues(color)
    adjustedColor[3] = `${alphaValue}`

    return `rgba(${adjustedColor.join(',')})`
}

export const AdjustRgbValues = (rgba: string, modificationValue: number) => {
    const minimumValue = 0
    const maximumValue = 255

    const asRgb = GetRgbValues(rgba).map(v => parseInt(v))

    const r = asRgb[0]
    const g = asRgb[1]
    const b = asRgb[2]

    const rgbValues = [r, g, b]
    let newModificationValue = modificationValue

    const goesOutOfBounds = (value: number) => modificationValue < 0
        ? value + modificationValue > maximumValue
        : value + modificationValue < minimumValue

    const calculatePossibleValues = (value: number) => modificationValue < 0
        ? maximumValue + modificationValue - value
        : minimumValue - modificationValue + value

    if (rgbValues.some(goesOutOfBounds)) newModificationValue = Math.min(...rgbValues.filter(goesOutOfBounds).map(calculatePossibleValues))

    return `rgba(${r + newModificationValue}, ${g + modificationValue}, ${b + modificationValue}${asRgb[3] ? `, ${asRgb[3]}` : ', 1'})`
}

export const GetValueChangeColor = (value?: number, lighter?: boolean) => {
    return { color: ChangeColor(value, lighter) }
}

export const ChangeColor = (value?: number, lighter?: boolean) => {
    const appTheme = window.localStorage.getItem(StorageKeys.AppTheme)
    const colors = GetColors(JSON.parse(appTheme))
    if (value === 0) return (lighter ? colors.positiveValueLighterColor : colors.positiveValueColor) // Zero
    else if (value !== 0 && !value) return '#ccc' // Disabled
    return (value >= 0)
        ? (lighter ? colors.positiveValueLighterColor : colors.positiveValueColor) // Positive
        : (lighter ? colors.negativeValueLighterColor : colors.negativeValueColor) // Negative (Orange)
}

export const PrimaryBlue = '#0a3254'
export const TicketBlue = '#336094'
export const GoldenTicket = '#E99C23'
export const MoneyGreen = '#090'
export const MoneyGreenLight = '#1A1'

const estimateColor = '#D5E7FC' // light blue
const historicalColor = '#3268A8' // snex blue

// export const MobileBlue = '#0a3254';
// export const MobileBlueTranslucent = '#0a325422';

export const MobileBlue = '#0071DB' // '#2E7DB7' // '#25AEF2' // historicalColor // 'rgb(51,96,148)';
export const MobileMediumBlue = '#027ef2'
export const MobileLightBlue = '#44a5ff'
export const MobileBlueTranslucent = 'rgba(0, 132, 255, 0.2)'
export const AssetColors = {
    Default: '#336094',
    Fund: '#336094',
    Future: '#28BBD6'
}

// Source: https://stackoverflow.com/a/3732187
const RgbToHslNum = (r: number, g: number, b: number): hsl => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b); const min = Math.min(r, g, b);
    let h: number; let s: number; const l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return { h, s, l }
}

function RgbToHsvNum(r: number, g: number, b: number) {
    r /= 255;
    g /= 255;
    b /= 255;
  
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h: number;
    const v = max;
  
    const d = max - min;
    const s = max === 0 ? 0 : d / max;
  
    if (max === min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
  
      h /= 6;
    }
  
    return { h, s, v };
  }

export const ColorConvert = {
    Hex: {
        ToRgb: HexToRgbNum,
        ToRbgCss: HexToRgb,
        ToRbgaCss: HexToRgba,
        ToHsl: HexToHsl,
        ToHsv: HexToHsv
    },
    Rgb: {
        ToHsl: RgbToHslNum
    }
}

type ColorFamily = 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'purple' | 'gray' | 'black' | 'white'

export const GetColorFamily = (hex: string): ColorFamily => {

    if (!hex || !/#[a-zA-Z0-9]{6}/.test(hex)) return 'gray';

    const { r, g, b } = ColorConvert.Hex.ToRgb(hex)
    const { h, s, l } = ColorConvert.Rgb.ToHsl(r, g, b)

    if (l < 0.19607843137) return 'black'
    if (l > 0.78431372549) return 'white'

    if (s < 0.27450980392) return 'gray'

    if (h < 0.07843137254) return 'red'
    if (h < 0.13725490196) return 'orange'
    if (h < 0.19607843137) return 'yellow'
    if (h < 0.41176470588) return 'green'
    if (h < 0.6862745098) return 'blue'
    if (h < 0.85098039215) return 'purple'
    if (h >= 0.85098039215) return 'red'

}
