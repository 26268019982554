import { AxiosError } from 'axios';
import { T } from 'phoenix/assets/lang/T';
import { FundingErrors } from 'phoenix/constants/FundingErrors';

export interface SnexError {
    errorCode: string;
    errorDetails?: {
        insufficientQuantityAvailable?: number;
    };
    errorMessage?: string;
    errorTitle?: string;
    /** @deprecated */ errorNote?: string;
    /** @deprecated */ errorId?: string;
    upstreamData?: any;
    extras?: any;
}

export type warningVariant = 'info' | 'asset-class';
export interface SnexWarning {
    warningMessage?: string;
    variant?: warningVariant; 
}

export const SnexErrorFromError = (e: unknown): SnexError | null => {
    const ae = e as AxiosError<SnexError>;
    // @ts-ignore
    const snexError = e.errorCode ? { errorCode: e.errorCode } : { errorCode: FundingErrors.UnknownError, errorMessage: T((t) => t.bankingScreen.unknownError.title) };
    return ae.response?.data?.errorCode ? ae.response.data : snexError;
};

export const CreateInternalSnexError = (values: Partial<SnexError>): SnexError => ({
    errorCode: 'INTERNAL',
    errorMessage: 'An internal error occurred',
    ...values
});
