// @ts-strict-ignore
import { Typography } from '@mui/material';
import { ShadedTextList, Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { MutualFundAssetClass } from 'phoenix/models/AssetClasses/MutualFundAssetClass';
import { TradeableSecurityType, TradeRequest } from 'phoenix/redux/models';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { IsOffshoreMutualFundByMetadata } from 'phoenix/util';
import React, { ReactElement } from 'react';
import { Flex } from '../..';
import { ShadedTextDisplay } from '../../ShadedTextDisplay/ShadedTextDisplay';

export const ReinvestmentConfirmationText = React.memo(({ meta, text, trade }: { meta: SecurityMetadata; text: Snex1LanguagePack; trade: Partial<TradeRequest> }) => {
    // Do not return any reinvestment text if it is an offshore mutual fund; all offshore mutual funds are reinvested
    if (IsOffshoreMutualFundByMetadata(meta)) return null;

    // Only give text if all options are defined
    const keys = ['reinvestDividends', 'reinvestShortTermGains', 'reinvestLongTermGains'];
    const names = {
        reinvestDividends: text.tradeTicket.reinvestmentOptions.dividends,
        reinvestShortTermGains: text.tradeTicket.reinvestmentOptions.shortTermCapitalGains,
        reinvestLongTermGains: text.tradeTicket.reinvestmentOptions.longTermCapitalGains
    };
    if (keys.some((key) => trade[key] === undefined)) return null;

    const cashReturns = keys.filter((k) => trade[k] === false).map((k) => names[k]);
    const reinvestReturns = keys.filter((k) => trade[k] === true).map((k) => names[k]);
    const allCash = !reinvestReturns.length;
    const allReinvest = !cashReturns.length;

    // Case 1: All are false (All Cash)
    if (allCash) {
        return (
            <ExplainerTextWrapper>
                <ShadedTextDisplay segments={text.tradeTicket.review.reinvestmentOptions.allWillBeCashed} />
            </ExplainerTextWrapper>
        );
    }
    // Case 2: All are true (All Reinvest)
    else if (allReinvest) {
        return (
            <ExplainerTextWrapper>
                <ShadedTextDisplay segments={text.tradeTicket.review.reinvestmentOptions.allWillBeReinvested} />
            </ExplainerTextWrapper>
        );
    }
    // Base Case: A mixture of options
    else {
        return (
            <Flex column>
                <ExplainerTextWrapper style={{ marginBottom: 10, fontSize: 15 }}>
                    <ShadedTextDisplay segments={text.tradeTicket.review.reinvestmentOptions.willBeReinvested(reinvestReturns)} />
                </ExplainerTextWrapper>
                <ExplainerTextWrapper style={{ fontSize: 15 }}>
                    <ShadedTextDisplay segments={text.tradeTicket.review.reinvestmentOptions.willBeCashed(cashReturns)} />
                </ExplainerTextWrapper>
            </Flex>
        );
    }
});

const ExplainerTextWrapper = React.memo((props: { children: any; style?: React.CSSProperties }) => (
    <Typography style={{ fontSize: 20, fontWeight: 'bold', ...props.style }} variant='h5'>
        {props.children}
    </Typography>
));

type TradeConfirmationPresentationProps = {
    meta: SecurityMetadata;
    segments: ShadedTextList;
    text: Snex1LanguagePack;
    trade: Partial<TradeRequest>;
    tradeableSecurityType: TradeableSecurityType;
};

export function TradeConfirmationPresentation({ meta, tradeableSecurityType, segments, text, trade }: TradeConfirmationPresentationProps): ReactElement {
    const isMutualFund = tradeableSecurityType === MutualFundAssetClass.type;

    return (
        <Flex column style={{ margin: '20px 0' }}>
            <div style={{ marginBottom: 20 }}>
                <ShadedTextDisplay segments={segments} />
            </div>
            {isMutualFund && <ReinvestmentConfirmationText meta={meta} text={text} trade={trade} />}
        </Flex>
    );
}
