import { InsightsClickObjectIDsEvent, InsightsSearchClickEvent } from 'search-insights/dist/click';
import { GetConfig } from '../constants';
import { GetJwt } from 'phoenix/resolvers/JwtStorage';
import { Jwt } from 'phoenix/util/Jwt';

export interface AlgoliaSecuritySearchHit {
    companyDescription: string;
    currency: string;
    displaySymbol: string;
    exchange: string;
    exchangeName: string;
    frontMonth: string;
    isEnabled?: boolean;
    logoUrl: string;
    name: string;
    objectID: string;
    symbol: string;
    optionsOnly: boolean;
    type: string; // TODO -- do we have an enum for Algolia type field? (Answer AA -- on the back-end we sorta do, not over here though. It's mostly IexIssueTypes.cs, and 'futures')
};

/** @deprecated typo, use {@link AlgoliaSecuritySearchHit} */
export type AlgolioSecuritySearchHit = AlgoliaSecuritySearchHit;

// Please only use these AlgoliaGetSecurityBySymbol* functions to leverage Algolia as a source for supporting data; DON'T use it
// as a way to power a search experience for the user-- please use Algolia's InstantSearch UI for that.

/** @deprecated use {@link AlgoliaGetSecurityBySymbolExact} or {@link AlgoliaGetSecurityBySymbolMany} because of their narrower type signatures */
const algoliaGetSecurityBySymbol = async (
    symbol: string,
    exact = true,
    excludeParams?: boolean
): Promise<AlgoliaSecuritySearchHit | AlgoliaSecuritySearchHit[]> => {
    if (exact) {
        const result = await algoliaGetSecurityBySymbolExact(symbol, excludeParams);
        return result ?? [];
    } else {
        return algoliaGetSecurityBySymbolMany(symbol, excludeParams);
    }
};

const algoliaGetSecurityBySymbolMany = async (symbol: string, excludeParams?: boolean): Promise<AlgoliaSecuritySearchHit[]> => {
    const algoliaConfig = GetConfig()?.Algolia;
    const indexName = algoliaConfig?.index ?? 'unknown_securities';

    const extraParams = excludeParams ? { exactOnSingleWordQuery: 'word' } : {};

    const searchResult = await algoliaConfig?.Client?.search<AlgoliaSecuritySearchHit>([
        {
            indexName,
            ...extraParams,
            query: symbol
        }
    ]);

    if (searchResult === undefined) {
        return [];
    }

    const matches = searchResult.results.flatMap((r) => r.hits);
    return matches;
};

const algoliaGetSecurityBySymbolExact = async (symbol: string, excludeParams?: boolean): Promise<AlgoliaSecuritySearchHit | null> => {
    const matches = await algoliaGetSecurityBySymbolMany(symbol, excludeParams);
    const exactMatch = matches.find((h) => h.symbol.toLowerCase() === symbol.toLowerCase()) ?? null;
    return exactMatch;
};

// TODO: remove after mobile stops using it
/**
 * @deprecated
 * Please use the {@link AlgoliaHelper} default export from this module.
 * Also, use {@link AlgoliaHelper.GetSecurityBySymbolExact} or {@link AlgoliaHelper.GetSecurityBySymbolMany} because of their narrower type signatures.
 */
export const AlgoliaGetSecurityBySymbol = algoliaGetSecurityBySymbol;

// #region Insights: Shared

let _currentQueryId: string | null = null
let _currentQueryQsi: string | null = null

const getUserId = () => Jwt.TryGetClaim(GetJwt(), 'cds_id')

const locateInsights = async (qsi: string) => {

    const hit = await algoliaGetSecurityBySymbolExact(qsi);
    if (!hit) return {};

    const algoliaConfig = GetConfig()?.Algolia;
    if (!algoliaConfig?.SendInsight) return {};

    const userId = getUserId()
    const matchesCurrentQuery = _currentQueryQsi === qsi

    return {
        send: (name: any, data: any) => {
            try {
                algoliaConfig.SendInsight?.(name, data)
                console.log(`[ALGOLIA] Sending ${name} for ${qsi}`, data);
            } catch (e) {
                console.log(`[ALGOLIA ERROR] ${e}`)
            }
        },
        //send: algoliaConfig.SendInsight,
        index: algoliaConfig.index || 'p_securities',
        userId,
        hit,
        queryId: matchesCurrentQuery ? _currentQueryId : null
    }

}


// #endregion Insights: Shared

// #region Insights: Clicks

const algoliaReportClickedSecurityFromSearch = async (qsi: string, position: number, queryId: string): Promise<void> => {

    const { send, index, hit, userId } = await locateInsights(qsi)
    if (!hit) return;

    _currentQueryId = queryId
    _currentQueryQsi = qsi

    const eventData: InsightsSearchClickEvent = {
        userToken: userId,
        index,
        eventName: 'Search Result Clicked',
        objectIDs: [hit.objectID],
        positions: [position],
        queryID: queryId
    };

    send('clickedObjectIDsAfterSearch', eventData);

};

const algoliaReportClickedSecurity = async (qsi: string): Promise<void> => {

    const { send, index, hit, userId } = await locateInsights(qsi)
    if (!hit) return;

    const eventData: InsightsClickObjectIDsEvent = {
        userToken: userId,
        index,
        eventName: 'Security Clicked',
        objectIDs: [hit.objectID]
    };

    send('clickedObjectIDs', eventData);
};

// #endregion Insights: Clicks

// #region Insights: Conversions

const algoliaReportAddedSecurityToWatchlist = async (qsi: string): Promise<void> => {

    const { send, index, hit, userId, queryId } = await locateInsights(qsi)
    if (!hit) return;

    const eventData = {
        userToken: userId,
        index,
        eventName: 'Added To Watchlist',
        objectIDs: [hit.objectID]
    }

    if (queryId) send('convertedObjectIDsAfterSearch', { ...eventData, queryID: queryId })
    else send('convertedObjectIDs', eventData)

};

const algoliaReportPlacedOrder = async (qsi: string): Promise<void> => {

    const { send, index, hit, userId, queryId } = await locateInsights(qsi)
    if (!hit) return;

    const eventData = {
        userToken: userId,
        index,
        eventName: 'Placed Order',
        objectIDs: [hit.objectID]
    }

    if (queryId) send('purchasedObjectIDsAfterSearch', { ...eventData, queryID: queryId })
    else send('purchasedObjectIDs', eventData)

};

// #endregion Insights: Conversions


const AlgoliaHelper = {
    GetSecurityBySymbol: algoliaGetSecurityBySymbol, // TODO: remove after mobile stops using it
    GetUserId: getUserId,
    Report: {
        ClickedSecurityFromSearch: algoliaReportClickedSecurityFromSearch,
        ClickedSecurity: algoliaReportClickedSecurity,
        AddedToWatchlist: algoliaReportAddedSecurityToWatchlist,
        PlacedOrder: algoliaReportPlacedOrder
    },
    GetSecurityBySymbolExact: algoliaGetSecurityBySymbolExact,
    GetSecurityBySymbolMany: algoliaGetSecurityBySymbolMany,
};

export default AlgoliaHelper;