// @ts-strict-ignore
import { CandleLineToggleControls } from 'components/CandleLineToggleControls/CandleLineToggleControls';
import { RangeSelector } from 'components/Charting/RangeSelectors';
import { CrosshairUpdateObject, SecurityChartWrapper } from 'components/Charting/SecurityChartWrapper';
import { setSecurityValues, setSecurityValuesOverride, setShowAdditionalStats, setStartingValue } from 'components/SecurityValues/SecurityValuesViewModel';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { ChartMinuteOffsetsByRange, ChartRange, ChartRanges } from 'phoenix/constants';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import FuturesTimeSpreadAssetClass from 'phoenix/models/AssetClasses/FuturesTimeSpreadsAssetClass';
import { XstreamUpdateChartFrequencyInMinutesAction } from 'phoenix/xstream/actions/XstreamChartActions';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';

export default function () {
    const xdispatch = useXstreamDispatch();
    const { symbol } = useParams<{ symbol: string }>();
    const [latestChangePercent, setLatestChangePercent] = useState(null);
    const [range, setRange] = useState<ChartRange>('1d');
    const [seriesType, setSeriesType] = useState<'candle' | 'line'>('line');
    const [showVolume, setShowVolume] = useState(true);
    const includeRanges = FuturesTimeSpreadAssetClass.chartRanges().map((r) => r.value);
    const excludeRanges = new Set(Object.values(ChartRanges).filter((r) => !includeRanges.includes(r)));
    const quote = XS.Quotes.use(symbol);
    const apiQuote = useSnexStore((s) => s.securityQuote.bySymbol[symbol]);
    const chartData = useSnexStore((s) => s.securityChart.bySymbol[symbol]?.[range]?.data);
    const startingValue = range === '1d' ? apiQuote?.data?.previousClose || chartData?.[chartData?.length - 1]?.close : chartData?.[chartData?.length - 1]?.close;
    const [appWindowSize] = useAppWindowSize();

    const updateRange = useCallback((newRange: ChartRange) => {
        setRange(newRange);
        xdispatch(XstreamUpdateChartFrequencyInMinutesAction(ChartMinuteOffsetsByRange[newRange]));
    }, []);

    const onCrosshairUpdate = useCallback(
        (update?: Record<string, CrosshairUpdateObject>, isScrubbing?: boolean) => {
            const updateObj: CrosshairUpdateObject = update && Object.entries(update)?.[0]?.[1];
            if (!isScrubbing) {
                setSecurityValuesOverride(false);
                setSecurityValues({ ...quote });
                if (startingValue) setStartingValue(startingValue);
                setLatestChangePercent(quote?.changePercent);
                setShowAdditionalStats(false);
                return;
            } else {
                setSecurityValuesOverride(true);
                setSecurityValues({ ...updateObj, price: updateObj.value, change: updateObj?.chartValChange, changePercent: updateObj?.chartPercChange });
                if (startingValue) setStartingValue(startingValue);
                setLatestChangePercent(updateObj?.chartPercChange);
            }

            if (seriesType === 'line') {
                setShowAdditionalStats(false);
            } else if (
                seriesType === 'candle' &&
                typeof update !== 'number' &&
                !isNaN(updateObj?.open) &&
                !isNaN(updateObj?.close) &&
                !isNaN(updateObj?.high) &&
                !isNaN(updateObj?.low)
            ) {
                setShowAdditionalStats(true);
            }
        },
        [quote, seriesType, startingValue]
    );

    const chartDimensions = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return { width: 560, height: 320 };
            case WindowSizes.regular:
            case WindowSizes.small:
                return { width: 670, height: 350 };
            case WindowSizes.large:
            default:
                return { width: 1000, height: 350 };
        }
    }, [appWindowSize]);

    return (
        <section className='deeper-view-chart'>
            <SecurityChartWrapper
                bypassBatching
                canScrub
                chartResolution='hi'
                containerId='lalala'
                crosshairType='vertical'
                height={chartDimensions.height}
                onCrosshairUpdate={onCrosshairUpdate}
                percentChangeOverride={range === '1d' ? latestChangePercent : null}
                range={range}
                seriesType={seriesType}
                showNoDataLabel
                showOpenLine
                showVolume={showVolume}
                symbols={[symbol]}
                width={chartDimensions.width}
            />
            <div className='deeper-view-chart-controls'>
                <RangeSelector colorSymbol={symbol} selectedRange={range} setRange={updateRange} valueChange={latestChangePercent} excludeRanges={excludeRanges} />
                <CandleLineToggleControls onDisplayTypeChange={setSeriesType} onToggleVolume={setShowVolume} />
            </div>
        </section>
    );
}
