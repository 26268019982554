import { ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { GetSecurityMetadataAction } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { GetOptionsOrderPermitted } from 'phoenix/util/OptionsHelpers';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnexStore } from './UseSnexStore';

type UseOptionsOrderPermittedArgs = {
    accountNumber: string;
    action: ApiTradeAction;
    quantity: number;
    symbol: string; // OSI
    withoutRequests?: boolean;
};

/**
 * Stateful method of determining options order permissions
 * Relies on Redux store for metadata & account options level
 * Relies on positions store for positions data
 * */

export default function ({ accountNumber, action, quantity, symbol, withoutRequests }: UseOptionsOrderPermittedArgs): boolean {
    const dispatch = useDispatch();
    const { load, setLoaded, findOne, findOption } = usePositionsStore();
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const deliverableCount = meta?.deliverableCount;
    const optionsLevel = useSnexStore((s) => s.accounts?.all?.data?.find((a) => a.accountNumber === accountNumber)?.optionsLevel);
    const optSym = new OptionSymbol(symbol);
    const optionsPositions = findOption({ baseSymbol: optSym.underlyingSymbol, accountNumber });
    const underlyingPositionQty = findOne({ qsi: optSym.underlyingSymbol, accountNumber })?.quantity;

    useEffect(() => {
        if(!withoutRequests) {
            dispatch(GetSecurityMetadataAction(symbol));
            load();
        } else {
            setLoaded(true)
        }
    }, [dispatch, load, symbol, withoutRequests]);

    return GetOptionsOrderPermitted({ action, optionsLevel, optionsPositions, quantity, deliverableCount, symbol, underlyingPositionQty });
}
