import { ArrowUpward } from '@mui/icons-material'
import { useColors } from 'hooks/UseColors'
import React, {useCallback} from 'react'
import { SnexTableHeaderCell } from '.'
import { Flex, Spinny, StyledIcon } from '..'

export type SortDirection = 'asc' | 'desc'

export interface SortData {
    field?: string,
    direction? : SortDirection
}

export interface SnexTableHeaderCellProps { 
    align?: 'right' | 'left' | 'center', 
    field?: string, 
    colSpan?: number, 
    style?: React.CSSProperties, 
    children?: any, 
    onClick: any, 
    currentSort: SortData, 
    width?: number 
}

export const SortableSnexTableHeaderCell = (p:SnexTableHeaderCellProps ) => {
    const { selectListSelectedItemColor } = useColors()
        return (
            
            <SnexTableHeaderCell align={p.align} width={p.width} onClick={() => p.onClick(p.field)}>
                <Flex row align='center' justify={p.align}>
                    <div>{ p.children }</div>
                    <Flex style={{ position: 'relative', width: 1, height: 16, marginLeft: 2 }}>
                        <div style={{ position: 'absolute', top: 0 }}>
                            { p.currentSort.field === p.field ? (<Spinny spun={ p.currentSort.direction === 'desc' }><StyledIcon
                                IconComponent={ArrowUpward}
                                size='18px'
                                style={{ color: selectListSelectedItemColor }} /></Spinny>) : '' }
                        </div>
                    </Flex>
                </Flex>
            </SnexTableHeaderCell>
        )
    }