// @ts-strict-ignore
import { produce } from 'immer';
import { LineWidth } from 'lightweight-charts';
import _, { isEqual } from 'lodash';
import { ChartRange, DetermineChartKey } from 'phoenix/constants';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { ApiData } from 'phoenix/models';
import { GetSecuritySymbolChart } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { SecurityChartData, SecurityQuote } from 'phoenix/redux/models';
import { XS } from 'phoenix/xstream/XS';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import MultiSeriesChart, { CrosshairUpdateValueWithChange } from '../Chart/MultiSeriesChart';
import { SeriesConfig } from '../Chart/SeriesConfig';
import { manipulateData } from './Helpers';

interface SecurityChartWrapperInterface {
    accentColor?: string;
    bypassBatching?: boolean;
    canScrub?: boolean;
    canToggleSeries?: boolean;
    changeOverride?: number;
    chartLineWidth?: LineWidth;
    chartResolution?: 'hi' | 'lo';
    containerId: string;
    crosshairType?: 'none' | 'vertical' | 'marker';
    filterToMarketOpen?: boolean;
    getCached?: boolean;
    height?: number;
    isDebugging?: boolean;
    onCrosshairUpdate?: (value?: any, isScrubbing?: boolean, percChange?: number, valueChange?: number) => void;
    percentChangeOverride?: number;
    range?: ChartRange;
    seriesType?: 'candle' | 'area' | 'line';
    showNoDataLabel?: boolean;
    showOpenLine?: boolean;
    showVolume?: boolean;
    symbol?: string;
    symbols?: string[];
    width?: number;
    withOffset?: boolean;
    withSnapshot?: boolean;
}

export type CrosshairUpdateObject = {
    open?: number;
    high?: number;
    low?: number;
    close?: number;
    volume?: number;
    latestPrice?: number;
    chartPercChange?: number;
    chartValChange?: number;
    value?: number;
};

export type SeriesList = { [key: string]: SeriesConfig };

const makeSelectChartData = () =>
    createSelector(
        (state: GlobalState) => state.securityChart.bySymbol,
        (_: any, symbols: string[]) => symbols,
        (symbolCharts, symbols) =>
            symbols.reduce((f, s) => {
                return { ...f, [s]: symbolCharts[s] };
            }, {})
    );

export const SecurityChartWrapper = React.memo(function SecurityChartWrapperUnmemoized(props: SecurityChartWrapperInterface) {
    const selectChartData = useMemo(makeSelectChartData, []);

    const {
        bypassBatching = false,
        canScrub = false,
        canToggleSeries = false,
        chartLineWidth,
        chartResolution = 'hi',
        containerId,
        crosshairType = 'none',
        height = 350,
        onCrosshairUpdate = () => undefined,
        range: rangeProp = '1d',
        seriesType = 'line',
        showNoDataLabel = false,
        showOpenLine = false,
        showVolume = true,
        symbols = [],
        width = 970,
        withOffset = true
    } = props;

    const range = DetermineChartKey(rangeProp, chartResolution);

    const dispatch = useDispatch();

    const [marketTimeSegment] = useMarketTimeSegmentV2();

    const [isLoading, setIsLoading] = useState(false);

    const [seriesList, setSeriesList] = useState<SeriesList>({});

    const data: { [symbol: string]: { [range: string]: ApiData<SecurityChartData[]> } } = useSnexStore((s) => selectChartData(s, symbols));

    const quotesReducer = (acc: { [symbol: string]: SecurityQuote }, s) => {
        if (s) acc[s] = XS.Quotes.use(s);
        else acc[s] = null;

        return acc;
    };
    const quotes = symbols.reduce(quotesReducer, {});

    const wrapperCrosshairUpdate = useCallback(
        (value?: CrosshairUpdateValueWithChange, isScrubbing?: boolean) => {
            if (!value) {
                onCrosshairUpdate(value, isScrubbing);
                return;
            }
            if ((!Object.entries(value)?.length && !isLoading) || !isScrubbing) {
                const newValue = Object.entries(value).reduce(
                    (f, v: any) => ({
                        ...f,
                        [v[0]]: { value: v?.[1]?.value, chartPercChange: v?.[1]?.chartPercChange || null, chartValChange: v?.[1]?.chartValChange || null }
                    }),
                    {}
                );
                onCrosshairUpdate(newValue, isScrubbing);
            } else {
                onCrosshairUpdate(value, isScrubbing);
            }
        },
        [isLoading, onCrosshairUpdate]
    );

    const totalNumberOfPoints = (() => {
        if (!withOffset) return undefined;
        switch (chartResolution) {
            case 'lo':
                return 80;
            case 'hi':
            default:
                return 420;
        }
    })();

    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, [marketTimeSegment]);

    useEffect(() => {
        // Prevent multiple fetches if this hook fires more often than it should
        if (!isLoading) {
            setIsLoading(true);
            const fetchChartDataAsync = async () => {
                symbols.forEach((s) => {
                    const length = data[s]?.[range]?.data?.length;
                    if (!length) dispatch(GetSecuritySymbolChart(s, rangeProp, chartResolution, bypassBatching));
                });
            };
            fetchChartDataAsync();
        }

        return () => {
            setIsLoading(false);
        };
    }, [bypassBatching, chartResolution, data, dispatch, isLoading, range, rangeProp, symbols]);

    useEffect(() => {
        const newSeriesList = symbols.reduce((f, s) => ({ ...f, [s]: seriesList[s] || { seriesId: s, data: [], seriesType } }), {});
        if (!isEqual(seriesList, newSeriesList)) {
            setSeriesList(newSeriesList);
        }
    }, [seriesList, seriesType, symbols]);

    useEffect(() => {
        setIsLoading(true);
        if (Object.entries(data).some((d) => d[1]?.[range] === undefined)) {
            setIsLoading(true);
        } else if (Object.entries(data).some((d) => d[1]?.[range]?.data?.length)) {
            const newSeriesList = manipulateData(seriesList, range, data, symbols, quotes, showOpenLine, seriesType);
            if (!isEqual(newSeriesList, seriesList)) setSeriesList(newSeriesList);
            setIsLoading(false);
        } else if (Object.entries(data).some((d) => !d[1]?.[range]?.data?.length) && Object.entries(data).some((d) => d[1]?.[range]?.pristine)) {
            setIsLoading(true);
        } else if (Object.entries(data).some((d) => !d[1]?.[range]?.data?.length) && Object.entries(data).some((d) => d[1]?.[range]?.error)) {
            setIsLoading(false);
        } else if (
            Object.entries(data).every((d) => !d[1]?.[range]?.data?.length) &&
            Object.entries(data).every((d) => !d[1]?.[range]?.pristine) &&
            Object.entries(data).every((d) => !d[1]?.[range]?.loading)
        ) {
            const newSeriesList = produce(seriesList, (draft) => {
                _.each(symbols, (s) => {
                    if (draft[s]) {
                        draft[s].data = [];
                    }
                });
            });
            setSeriesList(newSeriesList);
            setIsLoading(false);
        }
    }, [range, data, quotes, seriesList, symbols, showOpenLine, seriesType]);

    return (
        <div id={containerId}>
            <MultiSeriesChart
                canScale={seriesType === 'candle'}
                canScrub={canScrub}
                canToggleSeries={canToggleSeries}
                chartLineWidth={chartLineWidth}
                crosshairType={crosshairType}
                height={height}
                hidePriceLine={true}
                isLoading={isLoading}
                logicalRangeOverride={totalNumberOfPoints}
                multiSeries={Object.entries(seriesList).map((s) => s[1])}
                onCrosshairUpdate={wrapperCrosshairUpdate}
                range={props.range}
                showNoDataLabel={showNoDataLabel}
                showVolume={showVolume}
                width={width}
            />
        </div>
    );
});
