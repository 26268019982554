// @ts-strict-ignore
import { useText } from 'phoenix/hooks/UseText';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Watchlists } from '../../components';
import { FilterableSecurity } from '../../components/FilterableSecurityTable/FilterableSecurity';
import { FilterableSecurityTable } from '../../components/FilterableSecurityTable/FilterableSecurityTable';
import { WithSidebarLayout } from '../../components/Layouts/WithSidebarLayout';
import { Prestage } from '../../components/Prestage/Prestage';
import { TelemetryCategories } from '../../constants/Telemetry/TelemetryCategories';
import { LiveDataNamespaces } from '../../phoenix/constants/LiveDataNamespaces';
import { useSnexStore } from '../../phoenix/hooks/UseSnexStore';
import { GetWatchlistsAction } from '../../phoenix/redux/actions';
import { GetEtfSecuritiesBySectorAction } from '../../phoenix/redux/actions/SectorSecuritiesActions';
import { TelemetryProvider } from '../../providers/TelemetryContext';
import { SectorsLists } from './SectorsLists';

export const GetSectorDisplayName = (sectorKey, text) => {

    const s = sectorKey.replace('+', ' ')
    switch (s.toLowerCase()) {
        case 'utilities': return text.sectorNames.utilities  
        case 'health care': return text.sectorNames.healthCare
        case 'financials': return text.sectorNames.financials
        case 'technology': return text.sectorNames.technology
        case 'industrials': return text.sectorNames.industrials
        case 'communication services': return text.sectorNames.communicationServices
        case 'consumer discretionary': return text.sectorNames.consumerDiscretionary
        case 'consumer staples': return text.sectorNames.consumerStaples
        case 'materials': return text.sectorNames.materials
        case 'energy': return text.sectorNames.energy
        case 'real estate': return text.sectorNames.realEstate

        default: return s;
    }
}

export const GetSectorForRoute = (sectorKey) => sectorKey.replace(' ', '+');
export const GetSectorKeyFromRoute = (sectorFromRoute) =>  sectorFromRoute.replace('+', ' ');

export const SectorScreen = () => {
    const { sector } = useParams<{ sector: string }>();
    const sectorKey = GetSectorKeyFromRoute(sector);

    const dispatch = useDispatch()
    const [addListOpen, setAddListOpen] = useState(false);
    const text = useText(s => s.sectorScreen);

    useEffect(() => {
        dispatch(GetEtfSecuritiesBySectorAction(sectorKey))
    }, [sectorKey])

    useEffect(() => { dispatch(GetWatchlistsAction()) }, [])

    const etfSecurities = useSnexStore(s => s.sectorSecurities?.byName[sectorKey]?.etfs)
    const securities = useMemo<FilterableSecurity[]>(() => etfSecurities?.data, [etfSecurities])
    const loading = useMemo(() => etfSecurities?.loading || etfSecurities?.pristine, [etfSecurities?.loading, etfSecurities?.pristine]);

    return (
        <WithSidebarLayout noBottomGutter sidebar={<div>
            <Prestage delayShowMs={800}><SectorsLists /></Prestage>
            <Prestage delayShowMs={800}><Watchlists streamingNamespace={LiveDataNamespaces.SecurityCellPortfolio} /></Prestage>
        </div>} sidebarBottomSpacing={90} sidebarPadding={0}>
            <FilterableSecurityTable loading={loading} securities={securities}
                primaryTitle={`${GetSectorDisplayName(sectorKey, text)} ETFs`}
                defaultSort={{ field: 'marketCap', direction: 'desc' }}
                isAddToWatchlist
            />
        </WithSidebarLayout>
    )
}

export default TelemetryProvider(SectorScreen, TelemetryCategories.sector);