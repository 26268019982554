// @ts-strict-ignore
import _ from 'lodash';
import { Button } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { Flex } from '../..';
import { UseSubmitButtonStyles } from 'theming';
import { useText } from 'phoenix/hooks/UseText';
import { ModalViewHeader } from '../Shared';
import { AnimatedModal } from '../AnimatedModal';
import { WithScrollbar } from '../../WithScrollbar/WithScrollbar';
import { useColors } from 'hooks/UseColors';
import './ActionModal.scss';

interface ActionModalProps {
    isOpen: boolean;
    toggleModal?: (nextOpenState: boolean, event?: React.MouseEvent) => void; // Optional: it should be the caller's perogative on when the modal closes
    children: any;
    label?: string;
    height?: string | number;
    width?: string | number;
    closerStyle?: 'done-button' | 'button' | 'x-icon' | 'none';
    closerLabel?: string;
    showScrollbar?: boolean;
}

export const ActionModal = (props: ActionModalProps) => {
    const {
        isOpen,
        toggleModal = _.noop,
        children,
        label,
        height = 'auto',
        width = '400px',
        closerStyle = 'x-icon',
        closerLabel = null,
        showScrollbar
    } = props;

    const colors = useColors();
    const classes = UseSubmitButtonStyles(colors)();
    const text = useText(s => s.modals);

    const headerProps = useMemo(() => {
        const showCloser = closerStyle && toggleModal
        if (showCloser) return {
            onAction: () => toggleModal(false),
            ActionIcon: closerStyle === 'x-icon' && CloseRounded
        }
        return {}
    }, [ closerStyle, closerLabel, toggleModal ])

    return (
        <AnimatedModal open={isOpen} onClose={(e) => toggleModal(false, e)}>

            <Flex column style={{ background: colors.cardBackgroundColor, height, width, borderRadius: '10px', overflow: 'hidden', maxHeight: 750 }}>

                <ModalViewHeader title={label} {...headerProps} />

                <WithScrollbar hideScrollbar={ !showScrollbar } style={{ flex: 1, margin: 15, maxHeight: '100%', overflowY: 'scroll' }} >
                    {children}
                </WithScrollbar>
                { closerStyle === 'done-button' || closerStyle === 'button' ? (
                    <Flex style={{ width: '100%', padding: 15, boxSizing: 'border-box' }}>
                            <Button classes={classes} color='primary' variant='contained' onClick={ (e) => toggleModal(false, e) }>
                                { closerLabel || text.done }
                            </Button>
                    </Flex>
                ) : null }
            </Flex>
        </AnimatedModal>
    );
};