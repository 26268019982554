// @ts-strict-ignore
import React from 'react';
import { useSelector } from 'react-redux';
import { useTelemetry } from 'hooks/UseTelemetry';
import { FromEnglish } from 'phoenix/assets/lang/T';
import { ChartRange, ChartRanges } from 'phoenix/constants/ChartRange';
import { QuoteSelector } from 'phoenix/constants/ReduxSelectors';
import { ChangeColor } from 'phoenix/util';
import { useSecurityValues } from 'components/SecurityValues/SecurityValuesViewModel';

interface RangeSelectorProps {
    colorSymbol?: string;
    disabled?: boolean;
    excludeRanges?: Set<ChartRange | string>;
    isLoading?: boolean;
    negativeColorOverride?: string;
    positiveColorOverride?: string;
    range?: ChartRange;
    selectedRange: ChartRange;
    setRange: (range: ChartRange) => void;
    valueChange?: number;
}

export const RangeSelector = (props: RangeSelectorProps) => {
    const { valueChange, isLoading, selectedRange, setRange, disabled, excludeRanges, positiveColorOverride, range, negativeColorOverride, colorSymbol } = props;
    const changePercent = useSecurityValues(props?.colorSymbol, range)?.changePercent;
    const quote = useSelector(QuoteSelector(colorSymbol));
    const LogEvent = useTelemetry();

    const colorStyles = () => {
        if (colorSymbol) return { backgroundColor: ChangeColor(quote?.change || quote?.changePercent || changePercent) };
        if (positiveColorOverride && valueChange >= 0) return { backgroundColor: positiveColorOverride };
        else if (negativeColorOverride && valueChange < 0) return { backgroundColor: negativeColorOverride };
        else return null;
    };

    const determineRangeButtonColorClass = (chg) => {
        const className = 'range-label-active';
        if (colorStyles()) return className;
        if (chg >= 0 && !isLoading) return className + ' range-label-positive';
        else if (!chg || isLoading) {
            return className + ' range-label-neutral';
        } else return className + ' range-label-negative';
    };

    return (
        <div className='range-list'>
            {Object.entries(ChartRanges)
                .filter((r) => !excludeRanges || !excludeRanges.has(r[1]))
                .map((range, key) => (
                    <button
                        key={key}
                        className={`range-label ${selectedRange === range[1] ? determineRangeButtonColorClass(valueChange) : 'range-label-non-active'}`}
                        style={selectedRange === range[1] ? colorStyles() : null}
                        onClick={
                            disabled
                                ? null
                                : () => {
                                      LogEvent('Chart range change', { range: range[1] });
                                      setRange(range[1] as ChartRange);
                                  }
                        }
                    >
                        {FromEnglish((s) => s.misc.ranges, range[1].toUpperCase())}
                    </button>
                ))}
        </div>
    );
};
