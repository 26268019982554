import { addDays } from 'date-fns';
import { ChartRange } from '../../constants/ChartRange';
import { LiveDataGroups } from '../../constants/LiveDataGroups';
import { Urls } from '../../constants/Urls';
import { Lightstreamer } from '../../util/LightstreamerClientManager';
import { GlobalState } from '../GlobalState';
import { AccountChartPoint } from '../models/AccountChart/AccountChartPoint';
import { PortfolioChartPoint } from '../models/AccountChart/PortfolioChartPoint';
import { Actions } from './Constants';
import { ReduxApiGet } from './Helpers';
import { ReduxLiveSubscribe } from './StreamingHelpers';

export const GetAccountChartAction = (accountNumber: string, range: ChartRange) =>
    ReduxApiGet(accountNumber, Actions.AccountCharts.GetMany)
        .withBatching('account-charts', (numbers) => Urls.accountCharts.getForManyAccounts(numbers, range), 100)
        .withLoading()
        .useStored((s: GlobalState) => {
            const dataSet = s.accountChart.byAccount[accountNumber]?.[range]?.data;
            return dataSet?.data?.length && range !== '1d' && range !== '24h' ? dataSet : null;
        })
        .withSubject({ accountNumber, range })
        .run();

export const GetAggregateAccountChartAction = (range: ChartRange = '1d') => {
    return (
        ReduxApiGet(Urls.accountCharts.getAggregate(range), Actions.AccountCharts.GetAggregate)
            // .withLoading()
            .withSubject(range)
            .useStored((s: GlobalState) => {
                const dataSet = s.accountChart.aggregate[range]?.data;
                return dataSet?.length &&  range !== '1d' && range !== '24h' ? dataSet : null;
            })
            .withMutex(() => `oldchart:portfolio agg @${range}`)
            .run()
    );
};

export const ResetAccountChartsAction = () => ({ type: Actions.AccountCharts.ResetCharts });

const serialize = (nums: number[]): AccountChartPoint[] => {
    const ref = new Date(2021, 1, 1);
    return nums.map((n, idx) => ({ timestamp: addDays(ref, idx).getTime(), value: n }));
};
export const GetDummyAggregateAccountChartAction = () => ({
    type: Actions.AccountCharts.GetAggregate.Success,
    subject: '1d',
    data: serialize([0, -500, -600, -500, -200, -400, -100, 200, 600, 780, 780, 500, 430, 700, -200, -400, -340, -200, 0])
});

export const GetLoadingChartPointsAction = () => ({
    type: Actions.AccountCharts.GetAggregate.Success,
    subject: '1d',
    data: serialize([0, 1, 2, 1, 2, 3, 4, 5, 4, 3, 4, 3, 4, 5, 6, 7])
});

export const SubscribeToLiveAccountChartAction = (accountNumber: string, namespace: string) =>
    ReduxLiveSubscribe(
        LiveDataGroups.AccountValuations,
        [`acct:${accountNumber}`],
        Actions.AccountCharts.LiveUpdates,
        ['id', 'value', 'timestamp'],
        AccountChartPoint.FromLiveData,
        { mode: 'MERGE', maxFrequencySeconds: 0.5, subject: { accountNumber, range: '1d' }, namespace }
    );

export const SubscribeToLivePortfolioChartAction = (namespace: string) =>
    ReduxLiveSubscribe(
        LiveDataGroups.PortfolioValuations,
        [`portfolio:${Lightstreamer.GetCurrentUserId()}`],
        Actions.AccountCharts.AggregateLiveUpdates,
        ['timestamp', 'totalValue'],
        PortfolioChartPoint.FromLiveData,
        { mode: 'MERGE', maxFrequencySeconds: 0.5, subject: '1d', namespace }
    );
