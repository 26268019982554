// @ts-strict-ignore
import { Chat, CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Card, CustomTextField, DropdownComponent, LoadingSpinner } from 'components';
import { Flex } from 'components/Flex';
import Logo from 'components/Logo';
import { SnexButton } from 'components/SnexButton';
import { XBackgroundScreen } from 'components/XBackgroundScreen/XBackgroundScreen';
import { useOpenChatWindow } from 'hooks/UseChat';
import { useColors } from 'hooks/UseColors';
import get from 'lodash/get';
import set from 'lodash/set';
import Lottie from 'lottie-react';
import { AppliedAppThemes } from 'phoenix/constants/AppliedAppTheme';
import { useText } from 'phoenix/hooks/UseText';
import { DropdownOption } from 'phoenix/models/DropdownOptions';
import {
    OnboardingData,
    OnboardingDataAccountInfo,
    OnboardingDataAffiliationsInfo,
    OnboardingDataContactInfo,
    OnboardingDataEmploymentInfo,
    OnboardingDataFinancialInfo,
    OnboardingDataPersonalInfo,
    OnboardingDataRegulatoryInfo,
    OnboardingDataTradingExperience
} from 'phoenix/redux/models/FuturesOnboarding/OnboardingData';
import React, { CSSProperties, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GetAppTheme } from 'theming';
import './index.css'

export interface DocumentLink {
    text: string;
    url?: string;
    subText?: string[];
}
export interface OnboardingScreenProps<TData> {
    data?: TData;
    isLoading?: boolean;
    returnUrl?: string;
    form: JSX.Element;
    steps?: OnboardingScreenStepProps[];
    stepsWidth?: number;
    formMinWidth?: number;
    disableSteps?: boolean;
    showDisclaimer?: boolean;
    footerText?: string;
    logoLink?: string;
}

export interface OnboardingScreenStepProps {
    label: string;
    step: number;
    isCurrent?: boolean;
    isDisabled?: boolean;
    isComplete?: boolean;
}

export interface OnboardingInputFieldProps {
    type?: 'text' | 'dropdown' | 'date' | 'button' | 'yes' | 'no';
    buttonSelected?: boolean;
    buttonIcon?: JSX.Element;
    label?: string;
    sublabel?: string;
    data: OnboardingData;
    align?: 'left' | 'center' | 'right';
    handleChange?: (data: string, name?: string) => void;
    handleClick?: () => void;
    required?: boolean;
    fieldName?: string;
    readonly?: boolean;
    options?: DropdownOption[];
    secret?: boolean;
    validationError?: string;
}
export interface OnboardingFormError {
    key: string;
    formName: string;
    url: string;
}

export interface FullCardFormProps {
    children: React.ReactNode;
    step: string;
    data: Record<string, unknown>;
    title: string;
    gap?: string;
}

export interface LottieCardFormProps {
    children: React.ReactNode;
    data: Record<string, unknown>;
    animationData: Record<string, unknown>;
    title: string;
    loopAnimation?: boolean;
    variant?: 'left' | 'right';
    flyOutForm?: JSX.Element;
    showFlyOutForm?: boolean;
    gap?: string;
}

interface ReviewData {
    value?: string;
    isPassword?: boolean;
}

interface ReviewSectionProps {
    title: string;
    text: Record<string, string>;
    step: string;
    data:
        | OnboardingDataContactInfo
        | OnboardingDataPersonalInfo
        | OnboardingDataAccountInfo
        | OnboardingDataEmploymentInfo
        | OnboardingDataTradingExperience
        | OnboardingDataAffiliationsInfo
        | OnboardingDataRegulatoryInfo
        | OnboardingDataFinancialInfo;
    keys?: ReviewData[];
    errors: OnboardingFormError[];
}

type FlavorProp =
    | 'submit'
    | 'card'
    | 'secondary'
    | 'secondary-solid'
    | 'secondary-transparent'
    | 'delete'
    | 'action-link'
    | 'cancel'
    | 'radio'
    | 'single-tab'
    | 'not-allowed'
    | 'custom'
    | 'onboarding'
    | 'onboarding-selected'
    | 'loading';

export const GetUpdatedDataObject = (data: Record<string, unknown>, keys: string, defaultValue?: string | boolean): Record<string, unknown> => {
    const d = { ...data };
    set(d, keys, defaultValue);
    return d;
};

export const OnboardingScreen = <T extends Record<string, unknown>>(props: OnboardingScreenProps<T>): JSX.Element => {
    const { isLoading, form, steps, stepsWidth, disableSteps, showDisclaimer, footerText, logoLink } = props;

    return (
        <XBackgroundScreen>
            {isLoading ? (
                <OnboardingLoading />
            ) : (
                <Flex row fullWidth style={{ flex: '1', padding: '2rem 0' }}>
                    {steps?.length > 0 ? (
                        <Flex column style={{ flex: `0 0 ${stepsWidth || 232}` }}>
                            <OnboardingScreenSteps steps={steps} stepsWidth={props?.stepsWidth} disableSteps={disableSteps} externalLink={logoLink} />
                        </Flex>
                    ) : null}
                    <Flex column align={'center'} center style={{ flex: '1' }}>
                        <Flex column align={'center'} center style={{ flex: '1' }}>
                            <OnboardingForm showDisclaimer={showDisclaimer}>{form}</OnboardingForm>
                        </Flex>
                        {footerText ? <footer style={{ textAlign: 'center' }}>{footerText}</footer> : undefined}
                    </Flex>
                </Flex>
            )}
        </XBackgroundScreen>
    );
};

export const OnboardingForm = (props: PropsWithChildren<{ showDisclaimer?: boolean }>): JSX.Element => {
    const text = useText((t) => t.futuresOnboarding);
    const colors = useColors();
    const showDisclaimer = props.showDisclaimer !== undefined ? props.showDisclaimer : true;

    const disclaimerStyling = { color: colors.disclosureTextColor, padding: 8, borderRadius: '8px 8px 0 0', backgroundColor: colors.cardTitleBackgroundColor };
    return (
        <Card className='onboarding-form'>
            {showDisclaimer ? (
                <Flex row fullWidth center style={disclaimerStyling}>
                    <Typography variant='h6' style={{ fontWeight: 300, fontSize: 14 }}>
                        {text.sfi}
                    </Typography>
                </Flex>
            ) : null}
            <Flex column style={{ padding: '0 64px 32px' }}>
                {props.children}
            </Flex>
        </Card>
    );
};

export const OnboardingPageTitle = (props: {
    title: string | JSX.Element;
    subtitle?: string | string[];
    noMargin?: boolean;
    align?: 'left' | 'center' | 'right';
}): JSX.Element => {
    const colors = useColors();

    const subtitleData = useMemo(() => (typeof props?.subtitle === 'string' ? [props?.subtitle] : props?.subtitle), [props?.subtitle]);

    return (
        <Flex column fullWidth align={props?.align || 'center'}>
            <Typography
                variant='h2'
                style={{
                    padding: '2rem  0 0',
                    marginBottom: props.subtitle ? 0 : '2rem',
                    color: colors.selectListSelectedItemColor
                }}
            >
                {props.title}
            </Typography>
            {subtitleData?.map((s, key) => (
                <Typography
                    variant='h4'
                    color={'textSecondary'}
                    key={`subtitle_${key}`}
                    style={{
                        padding: key === subtitleData.length - 1 ? '0 0 2rem' : 0,
                        marginBottom: key === subtitleData.length - 1 ? (props.noMargin ? 0 : '2rem') : 0,
                        opacity: '.85',
                        textAlign: 'center'
                    }}
                >
                    {s}
                </Typography>
            ))}
        </Flex>
    );
};

export const OnboardingNextButton = (props: {
    style?: React.CSSProperties;
    flavor?: FlavorProp;
    label: string;
    isDisabled?: boolean;
    handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}): JSX.Element => {
    const colors = useColors();
    return (
        <OnboardingActionButton
            style={{
                ...props?.style,
                color: props?.isDisabled ? colors.onboardingDisabledButtonColor : colors.onboardingButtonColor,
                backgroundColor: props?.isDisabled ? colors?.onboardingDisabledButtonBackground : colors.onboardingButtonBackground
            }}
            afterIcon={<ArrowForwardIcon />}
            flavor={props?.flavor}
            label={props?.label}
            isDisabled={props?.isDisabled}
            handleClick={props.handleClick}
        />
    );
};

export const OnboardingActionButton = (props: {
    style?: React.CSSProperties;
    flavor?: FlavorProp;
    label: string;
    isDisabled?: boolean;
    handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    beforeIcon?: JSX.Element;
    afterIcon?: JSX.Element;
}): JSX.Element => {
    return (
        <SnexButton
            size='large'
            flavor={props?.flavor}
            disabled={props?.isDisabled}
            style={{
                marginTop: props?.style?.marginTop || '3rem',
                width: props?.style?.width || '400px',
                ...props?.style
            }}
            onClick={props?.handleClick}
        >
            {props?.beforeIcon ? <div style={{ marginRight: '1rem' }}>{props?.beforeIcon}</div> : null}
            <Typography variant='h6' style={{ minHeight: '24px' }}>
                {props?.label}
            </Typography>
            {props?.isDisabled ? <BlockIcon style={{ marginLeft: '6px' }} /> : props?.afterIcon ? <div style={{ marginLeft: '1rem' }}>{props?.afterIcon}</div> : null}
        </SnexButton>
    );
};

export const OnboardingInputField = (props: OnboardingInputFieldProps): JSX.Element => {
    const control = useMemo(() => {
        switch (props?.type) {
            case 'date':
                return (
                    <CustomTextField
                        style={{ width: '100%' }}
                        secret={props?.secret ? 'peekable' : null}
                        type={'date'}
                        disabled={props?.readonly}
                        required={props?.required}
                        align='left'
                        InputProps={{
                            inputProps: {
                                min: '01/01/1901',
                                max: '9999-12-31'
                            }
                        }}
                        value={get(props?.data, props?.fieldName, '') || ''}
                        onChange={(e) => props?.handleChange(e.target.value, props?.fieldName)}
                        validationError={props?.validationError}
                    />
                );
            case 'dropdown':
                return (
                    <DropdownComponent
                        style={{ width: '100%' }}
                        required={props?.required}
                        options={props?.options}
                        value={get(props?.data, props?.fieldName, '')}
                        onValueChange={(e) => props?.handleChange(e, props?.fieldName)}
                    />
                );
            case 'button':
            case 'yes':
            case 'no':
                return (
                    <OnboardingButton
                        isSelected={props?.buttonSelected}
                        isRequired={props?.required}
                        handleClick={props?.handleClick}
                        icon={
                            (props?.buttonIcon || props?.type === 'yes' || props?.type === 'no') && (
                                <Flex column center style={{ marginRight: '1rem' }}>
                                    {props?.type === 'yes' ? <ThumbUpIcon /> : props?.type === 'no' ? <ThumbDownIcon /> : props?.buttonIcon}
                                </Flex>
                            )
                        }
                        label={props?.label}
                        sublabel={props?.sublabel}
                    />
                );
            case 'text':
            default:
                return (
                    <CustomTextField
                        style={{ width: '100%' }}
                        secret={props?.secret ? 'peekable' : null}
                        type={'text'}
                        disabled={props?.readonly}
                        required={props?.required}
                        align='left'
                        value={get(props?.data, props?.fieldName, '') || ''}
                        onChange={(e) => props?.handleChange(e.target.value, props?.fieldName)}
                        validationError={props?.validationError}
                    />
                );
        }
    }, [props]);

    return (
        <Flex column align={props?.align} style={{ flex: '1 1 auto' }}>
            {props?.type !== 'button' && props?.type !== 'yes' && props?.type !== 'no' && (
                <Typography variant='h6' style={{ fontWeight: 600, paddingBottom: '.5rem' }}>
                    {props?.label}
                </Typography>
            )}
            {control}
        </Flex>
    );
};

export const OnboardingButton = ({
    isSelected,
    isRequired,
    handleClick,
    icon,
    label,
    sublabel
}: {
    handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
    sublabel?: string;
    isSelected?: boolean;
    isRequired?: boolean;
    icon?: JSX.Element;
}): JSX.Element => {
    const colors = useColors();
    return (
        <SnexButton
            size='large'
            flavor={isSelected ? 'onboarding-selected' : 'onboarding'}
            style={{
                padding: '12px',
                border: `1px solid ${isRequired ? colors.requiredBorderColor : colors.inputBorderColor}`
            }}
            onClick={handleClick}
        >
            <Flex row style={{ width: '100%' }}>
                {icon && (
                    <Flex column center style={{ marginRight: '1rem' }}>
                        {icon}
                    </Flex>
                )}
                <Flex column style={{ flex: '1 !important' }}>
                    <Typography variant='h5' align='left' style={{ fontWeight: 500 }}>
                        {label}
                    </Typography>
                    {sublabel && (
                        <Typography variant='h6' align='left' style={{ fontWeight: 300 }}>
                            {sublabel}
                        </Typography>
                    )}
                </Flex>
            </Flex>
        </SnexButton>
    );
};

export const OnboardingInputRow = (props: PropsWithChildren<{ center?: boolean; style?: CSSProperties; noPadding?: boolean }>): JSX.Element => {
    return (
        <Flex
            row
            center={props?.center}
            fullWidth
            style={{
                gap: '1rem',
                paddingTop: props.noPadding ? 0 : '1.5rem',
                ...props?.style
            }}
        >
            {props?.children}
        </Flex>
    );
};

export const OnboardingInputColumn = (props: PropsWithChildren<{ gap?: string; noPadding?: boolean; style?: CSSProperties }>): JSX.Element => {
    return (
        <Flex
            column
            fullWidth
            style={{
                ...props?.style,
                paddingTop: props.noPadding ? 0 : '1.5rem',
                gap: props?.gap || 0
            }}
        >
            {props?.children}
        </Flex>
    );
};

export const OnboardingYesNoQuestion = (props: {
    data: OnboardingData;
    question: string;
    value?: boolean;
    fieldName: string;
    handleChange: (value: string | boolean, keys: string) => void;
    style?: CSSProperties;
    required?: boolean;
}): JSX.Element => {
    const { data, value, fieldName, handleChange, style } = props;

    const handleDataChange = (valueToSet: boolean) => {
        if (valueToSet === value) valueToSet = null;
        handleChange(valueToSet, fieldName);
    };

    const isRequired = (data: boolean) => {
        return data === null || data === undefined || data;
    };

    return (
        <OnboardingInputColumn gap={'0'} style={{ ...style }}>
            <Flex row fullWidth align='left' style={{ marginBottom: '.5rem' }}>
                <Typography variant='h6'>{props.question}</Typography>
            </Flex>
            <OnboardingInputRow noPadding>
                <OnboardingInputField
                    type='yes'
                    required={isRequired(value)}
                    buttonSelected={value === true}
                    data={data}
                    label={'Yes'}
                    handleClick={() => handleDataChange(true)}
                />
                <OnboardingInputField
                    type='no'
                    required={isRequired(value)}
                    buttonSelected={value === false}
                    data={data}
                    label={'No'}
                    handleClick={() => handleDataChange(false)}
                />
            </OnboardingInputRow>
        </OnboardingInputColumn>
    );
};

const OnboardingScreenSteps = (props: { steps: OnboardingScreenStepProps[]; returnUrl?: string; stepsWidth?: number; disableSteps?: boolean; externalLink?: string }) => {
    const navigate = useNavigate();
    const chatText = useText((s) => s.misc.chatWithSupport);
    const openChatWindow = useOpenChatWindow();

    const handleClick = (step: number) => {
        navigate(step.toString());
    };

    const handleChatClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (e) e.preventDefault();
            openChatWindow();
        },
        [openChatWindow]
    );

    const LogoBox = () => (
        <Flex row center style={{ marginBottom: '2rem' }}>
            <Logo />
        </Flex>
    );

    return (
        <Flex column fullWidth style={{ padding: '0 16px 32px' }}>
            {props.externalLink ? (
                <a href={props.externalLink}>
                    <LogoBox />
                </a>
            ) : (
                <Link to={props.returnUrl || '/'}>
                    <LogoBox />
                </Link>
            )}
            <Flex column style={{ flex: '1 1 auto', marginTop: '32px' }}>
                {props?.steps?.map((s, idx) => (
                    <SnexButton
                        key={`steplabel_${idx}`}
                        route={!s.isDisabled ? s.step.toString() : undefined}
                        disabled={props?.disableSteps || s.isDisabled || idx > props?.steps.length - 1}
                        flavor={'secondary-transparent'}
                        onClick={idx <= props?.steps.length - 1 ? () => handleClick(s.step) : null}
                        style={{
                            padding: 0,
                            width: props?.stepsWidth || '175px',
                            opacity: props?.disableSteps || s.isDisabled ? '.5' : '1'
                        }}
                    >
                        <OnboardingScreenStep {...s} />
                    </SnexButton>
                ))}
                <SnexButton flavor={'secondary-transparent'} onClick={handleChatClick} style={{ margin: '1rem 0' }}>
                    <Chat />
                    <span style={{ marginLeft: '32px' }}>{chatText}</span>
                </SnexButton>
            </Flex>
        </Flex>
    );
};
const OnboardingScreenStep = (props: OnboardingScreenStepProps) => {
    const colors = useColors();
    const theme = GetAppTheme();

    return (
        <Flex row align='center' style={{ flex: '1 1 auto', padding: '6px 12px' }}>
            <Typography
                variant='h6'
                color={theme === AppliedAppThemes.light ? 'primary' : 'textPrimary'}
                style={{ fontWeight: props.isCurrent ? 600 : 300, flex: 1, textAlign: 'left' }}
            >
                {props.label}
            </Typography>
            <Typography
                variant='h6'
                color={theme === AppliedAppThemes.light ? 'primary' : 'textPrimary'}
                style={{
                    width: '50px',
                    flex: 0,
                    opacity: props.isCurrent || props.isComplete ? 0.75 : 0.45
                }}
            >
                {props.isComplete ? <CheckBoxOutlined style={{ color: colors.positiveValueColor }} /> : <CheckBoxOutlineBlank />}
            </Typography>
        </Flex>
    );
};
const OnboardingLoading = () => {
    return (
        <Flex column center style={{ height: '100%', width: '100%' }}>
            <LoadingSpinner />
        </Flex>
    );
};

export const LottieCardForm = (props: LottieCardFormProps): JSX.Element => {
    const defaultOptions = useMemo(() => {
        return {
            loop: props.loopAnimation,
            autoplay: true,
            animationData: props.animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
    }, [props.animationData, props.loopAnimation]);

    const [lottieWidth, setLottieWidth] = useState(0);
    const isLeftVariant = useMemo(() => props.variant !== 'right', [props.variant]);
    const refLottie = useRef(null);
    useEffect(() => {
        refLottie.current.offsetWidth > 32 ? setLottieWidth(refLottie.current.offsetWidth - 32) : setLottieWidth(0);
    }, [refLottie]);

    const lottieCard = useMemo(() => {
        return (
            <div ref={refLottie} style={{ flex: '0 0 40%', position: 'relative' }}>
                {props.flyOutForm && (
                    <div
                        style={{
                            position: 'absolute',
                            backgroundColor: 'transparent',
                            zIndex: 600,
                            top: 0,
                            left: isLeftVariant ? (props.showFlyOutForm ? 0 : -2000) : undefined,
                            right: !isLeftVariant ? (props.showFlyOutForm ? 0 : -2000) : undefined,
                            transition: '1s',
                            width: '98%',
                            height: '100%'
                        }}
                    >
                        <Flex column center fullWidth>
                            {props.flyOutForm}
                        </Flex>
                    </div>
                )}
                <Lottie
                    {...defaultOptions}
                    width={lottieWidth || 0}
                    height={lottieWidth || 0}
                    style={{
                        opacity: props.showFlyOutForm ? 0 : 1,
                        transition: '1s'
                    }}
                />
            </div>
        );
    }, [defaultOptions, isLeftVariant, lottieWidth, props.flyOutForm, props.showFlyOutForm]);

    return (
        <Flex column center style={{ padding: '0 1rem' }}>
            <OnboardingPageTitle title={props.title} />
            <Flex row fullWidth style={{ gap: '12px' }}>
                {isLeftVariant && lottieCard}
                <div style={{ flex: '0 0 60%' }}>
                    <Flex column fullWidth center style={{ gap: props.gap, paddingTop: 0 }}>
                        {props.children}
                    </Flex>
                </div>
                {!isLeftVariant && lottieCard}
            </Flex>
        </Flex>
    );
};

export const ReviewSection = (props: ReviewSectionProps): JSX.Element => {
    const { title, data, text, step, errors, keys } = props;
    const colors = useColors();
    const options2 = useText((s) => s.futuresOnboarding.accountInfo);
    const options = useText((s) => s.futuresOnboarding.financialInfo);
    const GetValue = (key: string) => {
        if (!key || !data) return undefined;
        switch (key) {
            case 'intendedInitialDeposit':
                return options2.intendedInitialDepositOptions.find((x) => x.value === data[key])?.label || undefined;
            case 'expectedMonthlyTrading':
                return options2.expectedMonthlyTradingOptions.find((x) => x.value === data[key])?.label || undefined;
            case 'annualRevenue':
                return options.annualRevenueOptions.find((x) => x.value === data[key])?.label || undefined;
            case 'netWorth':
                return options.netWorthOptions.find((x) => x.value === data[key])?.label || undefined;
            case 'workingCapital':
                return options.workingCapitalOptions.find((x) => x.value === data[key])?.label || undefined;
            default:
                return data[key];
        }
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Flex row fullWidth style={{ gap: '12px' }}>
                            <Typography
                                variant='h4'
                                style={{
                                    padding: 0,
                                    color: colors.selectListSelectedItemColor
                                }}
                            >
                                {title}
                            </Typography>
                            <Link to={step}>
                                <IconButton
                                    style={{
                                        color: colors.selectListSelectedItemColor,
                                        padding: '0'
                                    }}
                                    size='large'
                                >
                                    <EditIcon />
                                </IconButton>
                            </Link>
                        </Flex>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {keys &&
                    keys.map((key, idx) => (
                        <ReviewRow
                            key={`review_row_${idx}`}
                            step={step}
                            isPassword={key?.isPassword}
                            label={key ? text[key.value] : null}
                            value={data && key ? GetValue(key.value) : undefined}
                            error={errors.filter((e) => e.key?.toLowerCase() === key?.value?.toLowerCase())[0]}
                        />
                    ))}
            </TableBody>
        </Table>
    );
};

export const RequiredError = (props: { idx?: number; error: OnboardingFormError | string; handleClick: () => void }): JSX.Element => {
    const colors = useColors();
    return (
        <Flex row fullWidth style={{ padding: '3px 0', gap: '12px' }} onClick={props?.handleClick}>
            <Flex column center>
                <WarningIcon style={{ color: colors.negativeValueColor, opacity: 0.5 }} />
            </Flex>
            {typeof props.error === 'string' ? (
                <Typography variant='h6' color='error'>
                    {props.error}
                </Typography>
            ) : (
                <Typography key={props.idx ? `${props.error.formName}_errors_${props.idx}` : null} variant='h6' color='error'>
                    {props.error}
                </Typography>
            )}
        </Flex>
    );
};

export const ReviewRow = (props: {
    label?: string;
    step: string;
    value: string | boolean | null | undefined | number;
    isPassword?: boolean;
    error?: OnboardingFormError;
}): JSX.Element => {
    const { label, error, step, value, isPassword } = props;
    const navigate = useNavigate();
    const colors = useColors();
    const [showData, setShowData] = useState<boolean>(isPassword !== true);
    const text = useText((t) => t.futuresOnboarding?.missingRequiredData);

    const displayValue = useMemo(() => {
        switch (typeof value) {
            case 'boolean':
                return value ? 'Yes' : 'No';
            default:
                return value?.toString();
        }
    }, [value]);

    return (
        <TableRow style={{ cursor: 'pointer' }}>
            <TableCell onClick={() => navigate(step)} style={{ padding: '.5rem 1rem', width: '300px' }}>
                <Typography variant='h6'>{label}</Typography>
            </TableCell>
            <TableCell style={{ padding: '6px' }}>
                {error ? (
                    <RequiredError error={text || 'Missing required data'} handleClick={() => navigate(step)} />
                ) : showData ? (
                    <Typography variant='h6' onClick={isPassword ? () => setShowData(false) : () => navigate(step)} style={{ fontWeight: 400 }}>
                        {displayValue}
                    </Typography>
                ) : (
                    <IconButton
                        style={{
                            color: colors.selectListSelectedItemColor,
                            padding: '0'
                        }}
                        onClick={() => setShowData(true)}
                        size='large'
                    >
                        <VisibilityOffIcon />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
};
