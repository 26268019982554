// @ts-strict-ignore
import { Typography } from '@mui/material';
import React from 'react';
import { DirectionalArrow, Flex } from '../..';
import { ChangeColor, FormatNumber } from 'phoenix/util';
import style from '../style.module.scss';
import { XS } from 'phoenix/xstream/XS';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';

interface SecurityValueProps {
    chartPercChange: number;
    chartPrice: number;
    decimalPlaces?: number;
    symbol: string;
    withQuoteOverride?: boolean;
}

export const SecurityValue = React.memo((props: SecurityValueProps) => {
    const { symbol } = props;
    const quote = XS.Quotes.use(props.symbol);
    const price = quote?.price || props.chartPrice;
    const percChange = props.withQuoteOverride ? quote?.changePercent || props.chartPercChange || null : props.chartPercChange || null;
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const { formatPrice } = useAssetClass(symbol);
    const formattedPrice = formatPrice(price, meta);

    return (
        <Flex column className={style.pricingColumn}>
            <Typography variant='h6'>{formattedPrice}</Typography>
            {!isNaN(percChange) ? (
                <Flex align='center' justify='flex-end'>
                    <DirectionalArrow height={8} valueChange={percChange} />
                    <Typography style={{ paddingLeft: '5px', color: ChangeColor(percChange) }} variant='body1'>
                        {FormatNumber.toPercent(percChange * 100, 'never')}
                    </Typography>
                </Flex>
            ) : (
                <Typography className={ChangeColor(0)} style={{ paddingLeft: '5px' }} variant='body1'>
                    {FormatNumber.toPercent(0, 'never')}
                </Typography>
            )}
        </Flex>
    );
});
