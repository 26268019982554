// @ts-strict-ignore
import { format } from 'date-fns';
import { useColors } from 'hooks/UseColors';
import { ChartOptions, ColorType, DeepPartial, isUTCTimestamp, LineStyle, LineWidth, Logical, MouseEventParams, Range, Time, UTCTimestamp } from 'lightweight-charts';
import _, { groupBy } from 'lodash';
import { ChartRange } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import { AppColorTheme } from 'phoenix/theming/ColorVariants/AppColorTheme';
import { GetDisableCharts } from 'phoenix/util';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DetermineChange } from 'util/Utils';
import { LoadingSpinner } from '../..';
import { Flex } from '../../Flex';
import './Chart.scss';
import { CrosshairOptions, CrosshairOptionsSetValue } from './ChartConstants';
import { LightweightChart } from './LightweightChart/LightweightChart';
import { LightweightPriceLine } from './LightweightChart/LightweightPriceLine';
import { DataTypeMap, LightweightSeries, PartialOptionsMap, __brand } from './LightweightChart/LightweightSeries';
import { NoDataView } from './NoDataView';
import { ExtraOptions, SeriesConfig, SeriesDataPoint, SeriesDataPointWithChange } from './SeriesConfig';

type LightweightSupportedSeriesType = 'Line' | 'Area' | 'Candlestick' | 'Histogram';

export interface CrosshairUpdateValueWithChange extends SeriesDataPoint {
    chartPercChange: number;
    chartValChange: number;
}
export type CrosshairUpdateHandler = (value?: CrosshairUpdateValueWithChange, isScrubbing?: boolean) => void;

interface ChartProps {
    canScale?: boolean;
    canScrub?: boolean;
    canToggleSeries?: boolean;
    chartLineWidth?: LineWidth;
    crosshairType?: 'none' | 'vertical' | 'marker';
    height: number;
    hidePriceLine?: boolean;
    isLoading?: boolean;
    logicalRangeOverride?: number;
    multiSeries: SeriesConfig[];
    negativeColorOverride?: string;
    onCrosshairUpdate?: CrosshairUpdateHandler;
    onFocusChange?: (newFocusState: boolean) => void;
    positiveColorOverride?: string;
    range?: ChartRange;
    showNoDataLabel?: boolean;
    showVolume?: boolean;
    showWorkingOrders?: boolean;
    viewLeftPriceScale?: boolean;
    viewRightPriceScale?: boolean;
    viewTimeScale?: boolean;
    width: number;
}

const ToolTipWidth = 125;

const priceScaleOptionsVolume = {
    scaleMargins: {
        top: 0.8,
        bottom: 0
    },
    visible: false
};

const priceScaleOptionsCandle = {
    scaleMargins: {
        top: 0.1,
        bottom: 0.1
    },
    visible: false
};

const MultiSeriesChartUnmemoized = (props: ChartProps) => {
    const {
        canScale = false,
        canScrub = true,
        chartLineWidth = 2,
        crosshairType = 'vertical',
        height,
        hidePriceLine,
        isLoading = false,
        logicalRangeOverride,
        multiSeries,
        negativeColorOverride,
        onCrosshairUpdate = _.noop as CrosshairUpdateHandler,
        onFocusChange = () => undefined,
        positiveColorOverride,
        range,
        showNoDataLabel = true,
        showVolume = false,
        showWorkingOrders = false,
        viewLeftPriceScale = false,
        viewRightPriceScale = false,
        viewTimeScale = false,
        width
    } = props;

    const { dateFnsLocale } = useText((s) => s.general);
    const colors = useColors();

    // TODO: group all these so showing/hiding the tooltip is an atomic operation.
    const [isScrubbingChart, setIsScrubbingChart] = useState(false);
    const [toolTipText, setToolTipText] = useState('');
    const [toolTipLeftPx, setToolTipLeftPx] = useState(0);
    const [scrubbingTime, setScrubbingTime] = useState<number>();
    const groupedData = groupBy(multiSeries, 'seriesType');

    // Area and line chart colors change based on change value, candlesticks change colors individually
    const colorData = (groupedData?.['line'] || groupedData?.['area'])?.flatMap((s) => s?.data);
    const isColoredNegative = getIsColoredNegative(colorData);
    const areaColor = isColoredNegative ? negativeColorOverride ?? colors.areaChartNegativeColor : positiveColorOverride ?? colors.areaChartPositiveColor;

    // This visible logical range fallback currently only supports line and area data
    const combinedDataLength: Logical = (groupedData[Object.keys(groupedData)?.[0]]?.flatMap((s) => s.data)?.length || 0) as Logical;
    const visibleLogicalRange = { from: 0 as Logical, to: (logicalRangeOverride || combinedDataLength) as Logical };

    const crosshairOptions = useMemo(() => CrosshairOptions({ colors })[canScrub ? crosshairType : 'none'], [canScrub, crosshairType, colors]);

    const getLabelForTime = useCallback(
        (datetime: Date) => {
            switch (range) {
                case '24h':
                case '1d':
                case '5d':
                case '1m':
                    return format(datetime, 'h:mma E, MMM dd', { locale: dateFnsLocale });
                default:
                    return format(datetime, 'MMM do, yyyy', { locale: dateFnsLocale });
            }
        },
        [range, dateFnsLocale]
    );

    const handleCrosshairMove = useCallback(
        (param: MouseEventParams<Time>) => {
            if (!canScrub) {
                return;
            }

            if (param.time === undefined || param.point === undefined || param.point.x < 0 || param.point.x > width || param.point.y < 0 || param.point.y > height) {
                const updateObj = getLatestDataPointWithOverallChange(multiSeries, param);
                onCrosshairUpdate(updateObj, false);

                setIsScrubbingChart(false);
                setScrubbingTime(undefined);
                onFocusChange(false);
            } else {
                if (!isUTCTimestamp(param.time)) {
                    console.error('Not implemented: mouseEventParams.time is not a UTC timestamp:', param.time);
                    return;
                }
                if (!isScrubbingChart) {
                    onFocusChange(true);
                }
                setIsScrubbingChart(true);
                setScrubbingTime(param.time);
                const dateStr = getLabelForTime(new Date(param.time * 1000));
                setToolTipText(dateStr);
                let left = param.point.x - ToolTipWidth / 2;
                left = Math.max(0, Math.min(width - ToolTipWidth, left));
                setToolTipLeftPx(left);

                const updateObj = getLatestDataPointWithOverallChange(multiSeries, param);

                onCrosshairUpdate(updateObj, true);
            }
        },
        [canScrub, width, height, multiSeries, onCrosshairUpdate, onFocusChange, isScrubbingChart, getLabelForTime]
    );

    const series = (() => {
        return multiSeries.map((s, idx) => {
            const isFaded = isScrubbingChart && (scrubbingTime < s?.data?.[0]?.time || scrubbingTime > s?.data?.[s?.data?.length - 1]?.time);
            const positiveValueColor = isFaded ? s?.lineColorLighter || colors.positiveValueChartLighterColor : s?.lineColor || colors.positiveValueColor;
            const negativeValueColor = isFaded ? s?.lineColorLighter || colors.negativeValueChartLighterColor : s?.lineColor || colors.negativeValueColor;
            const lineColor = isColoredNegative ? negativeColorOverride ?? negativeValueColor : positiveColorOverride ?? positiveValueColor;
            const shouldShowPriceLine = ['24h', '1d'].includes(range) && !hidePriceLine;

            return (
                <Series
                    areaColor={areaColor}
                    chartLineWidth={chartLineWidth}
                    colors={colors}
                    crosshairOptions={crosshairOptions}
                    key={s.seriesId || idx}
                    lineColor={lineColor}
                    negativeColorOverride={negativeColorOverride ?? null}
                    positiveColorOverride={positiveColorOverride ?? null}
                    seriesConfig={s}
                    showVolume={showVolume}
                    showWorkingOrders={showWorkingOrders && idx === 0} // For segmented series, only render orders on the first instance
                    visibleLogicalRange={visibleLogicalRange}
                >
                    {shouldShowPriceLine && idx === 0 && (
                        <LightweightPriceLine
                            options={{
                                axisLabelColor: 'red', // Unused but required
                                axisLabelTextColor: 'red', // Unused but required
                                axisLabelVisible: false,
                                color: colors?.generalTextColor,
                                lineStyle: LineStyle.Dashed,
                                lineVisible: true,
                                lineWidth: 1,
                                price: _.first(s.data)?.value ?? 0,
                                title: 'starting price'
                            }}
                        />
                    )}
                </Series>
            );
        });
    })();

    const chartWrapperOpacity = useMemo(() => (isLoading ? 0.2 : 1.0), [isLoading]);

    const fixRightEdge = logicalRangeOverride === null || logicalRangeOverride === undefined;

    const chartOptions = createChartOptions(width, height, viewLeftPriceScale, viewRightPriceScale, fixRightEdge, viewTimeScale, colors, crosshairOptions, canScale);

    // Original design: if scrubbing is disabled, call onCrosshairUpdate only once.
    // Current design: Call onCrosshairUpdate every time the data changes. (Which should update change and change percent on initial render)
    // TODO: this could all probably be handled outside of any charting components, by the caller, since it's only a function of the data we get from props.
    // TODO: This triggers regardless of whether scrubbing is enabled or not on non-MF screens. To resolve a sprint bug, this conditional was removed. We need to find a better way to handle this.
    useEffect(() => {
        // If the chart is being scrubbed, don't override that
        if (!isScrubbingChart) {
            const updateObj = getLatestDataPointWithOverallChange(multiSeries);
            onCrosshairUpdate(updateObj, false);
        }
    }, [canScrub, isScrubbingChart, multiSeries, onCrosshairUpdate]);

    if (GetDisableCharts()) return null;

    const shouldShowNoDataLabel = multiSeries.some((s) => s.data.length === 0) && !isLoading && !!showNoDataLabel;

    return (
        <Flex column className='multi-series-chart wrapper'>
            {shouldShowNoDataLabel ? (
                <NoDataView height={height} width={width} range={range} />
            ) : (
                <div
                    className='chart-wrapper'
                    style={{
                        height,
                        width,
                        opacity: chartWrapperOpacity
                    }}
                >
                    {isLoading && showNoDataLabel && multiSeries.every((s) => !s.data.length) ? (
                        <Flex center style={{ height: '100%', width: '100%' }}>
                            <LoadingSpinner />
                        </Flex>
                    ) : (
                        <>
                            <LightweightChart
                                canScale={canScale}
                                options={chartOptions}
                                style={{
                                    position: 'relative',
                                    height: `${height}px`,
                                    width: `${width}px`
                                }}
                                onCrosshairMove={handleCrosshairMove}
                                visibleLogicalRange={visibleLogicalRange}
                            >
                                {series}
                            </LightweightChart>
                            {crosshairType === 'vertical' && isScrubbingChart && (
                                <div className='tooltip' style={{ display: isScrubbingChart ? 'block' : 'none', left: `${toolTipLeftPx}px` }}>
                                    {toolTipText}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </Flex>
    );
};

const MultiSeriesChart = React.memo(MultiSeriesChartUnmemoized);

export default MultiSeriesChart;

type SeriesProps = React.PropsWithChildren<{
    areaColor: string;
    chartLineWidth: LineWidth;
    colors: AppColorTheme;
    crosshairOptions: CrosshairOptionsSetValue;
    lineColor: string;
    negativeColorOverride: string | null;
    positiveColorOverride: string | null;
    seriesConfig: SeriesConfig;
    showVolume: boolean;
    showWorkingOrders?: boolean;
    visibleLogicalRange: Range<number>;
}>;

const Series = (props: SeriesProps): React.ReactElement => {
    const { areaColor, chartLineWidth, children, colors, crosshairOptions, lineColor, seriesConfig: s, showVolume, showWorkingOrders, visibleLogicalRange } = props;

    switch (s.seriesType) {
        case 'candle': {
            const options = createCandlestickSeries(colors, s.extraOptions);

            return (
                <div id={s.seriesId}>
                    {/* candle has an extra histogram for volume. */}
                    {showVolume && (
                        <LightweightSeries
                            data={createVolumeData(s, colors)}
                            options={options.volumeSubseries.options}
                            priceScaleOptions={priceScaleOptionsVolume}
                            seriesType='Histogram'
                            visibleLogicalRange={visibleLogicalRange}
                        />
                    )}
                    <LightweightSeries
                        seriesType='Candlestick'
                        data={createCandlestickData(s)}
                        markers={s?.markers}
                        options={options.options}
                        priceScaleOptions={priceScaleOptionsCandle}
                        showWorkingOrders={showWorkingOrders}
                        visibleLogicalRange={visibleLogicalRange}
                    >
                        {children}
                    </LightweightSeries>
                </div>
            );
        }
        case 'line': {
            const options = createLineSeries(lineColor, chartLineWidth, crosshairOptions, s.extraOptions);

            return (
                <LightweightSeries
                    seriesType='Line'
                    markers={s?.markers}
                    options={options.options}
                    data={createLineData(s)}
                    showWorkingOrders={showWorkingOrders}
                    visibleLogicalRange={visibleLogicalRange}
                >
                    {children}
                </LightweightSeries>
            );
        }
        case 'histogram': {
            const options = createHistogramSeries(lineColor, s.extraOptions);
            return (
                <LightweightSeries
                    seriesType='Histogram'
                    options={options.options}
                    priceScaleOptions={priceScaleOptionsVolume}
                    data={createHistogramData(s)}
                    showWorkingOrders={showWorkingOrders}
                    visibleLogicalRange={visibleLogicalRange}
                />
            );
        }
        case 'area': {
            const options = createAreaSeries(lineColor, areaColor, crosshairOptions, s.extraOptions);
            return <LightweightSeries seriesType='Area' options={options.options} data={createAreaData(s)} visibleLogicalRange={visibleLogicalRange} />;
        }
        default:
            throw new Error(`Unsupported series type: ${s.seriesType}`);
    }
};

function createCandlestickData(s: SeriesConfig): Array<DataTypeMap['Candlestick']> {
    return s.data.map(createCandlestickDataPoint);
}

function createCandlestickDataPoint(d: SeriesDataPoint): DataTypeMap['Candlestick'] {
    return {
        [__brand]: 'Candlestick',
        time: d.time as UTCTimestamp,
        open: d.open ?? 0,
        high: d.high ?? 0,
        low: d.low ?? 0,
        close: d.close ?? 0
    };
}

function createHistogramData(s: SeriesConfig): Array<DataTypeMap['Histogram']> {
    return s.data.map(createHistogramDataPoint);
}

function createHistogramDataPoint(d: SeriesDataPoint): DataTypeMap['Histogram'] {
    return {
        [__brand]: 'Histogram',
        time: d.time as UTCTimestamp,
        value: d.value ?? 0
    };
}

function createAreaData(s: SeriesConfig): Array<DataTypeMap['Area']> {
    return s.data.map(createAreaDataPoint);
}

function createAreaDataPoint(d: SeriesDataPoint): DataTypeMap['Area'] {
    return {
        [__brand]: 'Area',
        time: d.time as UTCTimestamp,
        value: d.value ?? 0
    };
}

function createLineData(s: SeriesConfig): Array<DataTypeMap['Line']> {
    const lineData = s.data.map(createLineDataPoint);
    return lineData;
}

function createLineDataPoint(d: SeriesDataPoint): DataTypeMap['Line'] {
    return {
        [__brand]: 'Line',
        time: (d.time || d.timestamp) as UTCTimestamp,
        value: d.value || d.latestPrice || 0
    };
}

function createVolumeData(s: SeriesConfig, colors: AppColorTheme): Array<DataTypeMap['Histogram']> {
    return s.data.map((d) => createVolumeDataPoint(d, colors));
}

function createVolumeDataPoint(d: SeriesDataPoint, colors: AppColorTheme): DataTypeMap['Histogram'] {
    return {
        [__brand]: 'Histogram',
        color: d.open > d.close ? colors.negativeValueColor : colors.positiveValueColor,
        time: d.time as UTCTimestamp,
        value: d.volume ?? 0
    };
}

interface OptionsTypeGeneric<T extends LightweightSupportedSeriesType> {
    seriesType: T;
    options: PartialOptionsMap[T];
}

interface OptionsTypeMap {
    Candlestick: OptionsTypeGeneric<'Candlestick'> & {
        volumeSubseries: OptionsTypeMap['Histogram'];
    };
    Histogram: OptionsTypeGeneric<'Histogram'>;
    Area: OptionsTypeGeneric<'Area'>;
    Line: OptionsTypeGeneric<'Line'>;
}

function createHistogramSeries(color: string, extraOptions: ExtraOptions): OptionsTypeMap['Histogram'] {
    return {
        seriesType: 'Histogram',
        options: {
            [__brand]: 'Histogram',
            priceLineVisible: false,
            priceFormat: {
                type: 'volume'
            },
            priceScaleId: '',
            color,
            ...extraOptions
        }
    };
}
function createCandlestickSeries(colors: AppColorTheme, extraOptions: ExtraOptions): OptionsTypeMap['Candlestick'] {
    return {
        seriesType: 'Candlestick',
        options: {
            [__brand]: 'Candlestick',
            borderDownColor: colors.negativeValueColor,
            borderUpColor: colors.positiveValueColor,
            downColor: colors.negativeValueColor,
            priceLineVisible: false,
            upColor: colors.positiveValueColor,
            visible: true,
            wickDownColor: colors.negativeValueColor,
            wickUpColor: colors.positiveValueColor,
            ...extraOptions
        },
        volumeSubseries: {
            seriesType: 'Histogram',
            options: {
                [__brand]: 'Histogram',
                priceFormat: {
                    type: 'volume'
                },
                priceLineVisible: false,
                /**
                 * It's not displayed, but using the left price scale for volume and the right for candles (default) is what allows different scaleMargins
                 * so the volume can be shrunk down to the lower 20% of the chart. See ({@link priceScaleOptionsVolume})
                 * */
                // priceScaleId: 'left'
                priceScaleId: ''
            }
        }
    };
}

function createAreaSeries(lineColor: string, areaColor: string, crosshairOptions: CrosshairOptionsSetValue, extraOptions: ExtraOptions): OptionsTypeMap['Area'] {
    return {
        seriesType: 'Area',
        options: {
            [__brand]: 'Area',
            priceLineVisible: false,
            lineColor,
            lineWidth: 1,
            crosshairMarkerVisible: crosshairOptions.markerVisible,
            crosshairMarkerRadius: 3,
            topColor: areaColor,
            bottomColor: areaColor,
            ...extraOptions
        }
    };
}

function createLineSeries(color: string, chartLineWidth: LineWidth, crosshairOptions: CrosshairOptionsSetValue, extraOptions: ExtraOptions): OptionsTypeMap['Line'] {
    return {
        seriesType: 'Line',
        options: {
            [__brand]: 'Line',
            color,
            crosshairMarkerRadius: 3,
            crosshairMarkerVisible: crosshairOptions.markerVisible,
            lineStyle: LineStyle.Solid,
            lineWidth: chartLineWidth,
            priceLineVisible: false,
            ...extraOptions
        }
    };
}

function getIsColoredNegative(data: SeriesDataPoint[]) {
    const startingPoint = _.first(data);
    const currentPoint = _.last(data);

    if (startingPoint === undefined || currentPoint === undefined) {
        return false;
    }

    const currentValue = currentPoint.value ?? null;
    const startingValue = startingPoint.value ?? null;

    if (currentValue === null || startingValue === null) {
        return false;
    }

    const valueChange = currentValue - startingValue;
    return valueChange < 0;
}

function createChartOptions(
    width: number,
    height: number,
    viewLeftPriceScale: boolean,
    viewRightPriceScale: boolean,
    lockRightEdge: boolean,
    viewTimeScale: boolean,
    colors: AppColorTheme,
    crosshairOptions: CrosshairOptionsSetValue,
    canScale: boolean
): DeepPartial<ChartOptions> {
    return {
        crosshair: crosshairOptions.options,
        grid: {
            horzLines: { visible: false },
            vertLines: { visible: false }
        },
        handleScale: {
            axisPressedMouseMove: canScale,
            mouseWheel: canScale,
            pinch: canScale
        },
        handleScroll: {
            mouseWheel: false,
            pressedMouseMove: canScale,
            horzTouchDrag: canScale,
            vertTouchDrag: false
        },
        height,
        layout: { background: { type: ColorType.Solid, color: 'transparent' }, textColor: colors.grayDark },
        rightPriceScale: {
            visible: viewRightPriceScale
        },
        timeScale: {
            borderVisible: false,
            fixLeftEdge: true,
            fixRightEdge: lockRightEdge,
            lockVisibleTimeRangeOnResize: true,
            shiftVisibleRangeOnNewBar: false,
            visible: viewTimeScale
        },
        width
    };
}

function getLatestDataPointWithOverallChange(multiSeries: SeriesConfig[], param?: MouseEventParams<Time>): SeriesDataPointWithChange | null {
    // Find the scrubbed data point by the time param (or fallback to latest data point)
    const latestData = multiSeries[multiSeries?.length - 1]?.data;
    const flatData = multiSeries.flatMap((s) => s?.data);
    const scrubbedPoint = (flatData || []).find((d) => d.time === param?.time);
    const latestPoint = scrubbedPoint || latestData?.[latestData?.length - 1];
    const firstPoint = multiSeries?.[0]?.data?.[0];

    // candlestick should use close of last, not 'value'
    const compareKey = multiSeries.find((s) => s.seriesType === 'candle') ? 'close' : 'value';
    const currentValue = latestPoint?.[compareKey];
    const firstValue = firstPoint?.[compareKey];

    const changes = DetermineChange(currentValue, firstValue, true);
    const updateObj: SeriesDataPointWithChange = {
        ...(latestPoint as SeriesDataPoint),
        chartPercChange: changes.percentChange,
        chartValChange: changes.valueChange
    };

    return updateObj;
}
