// @ts-strict-ignore
import { HighlightOff } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { CircularLogo, Flex, LoadingSpinner, UserAvatar } from 'components';
import { SetSelectedAccount } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { DateTimeText } from 'components/DateTimeText/DateTimeText';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useColors } from 'hooks/UseColors';
import { useTelemetry } from 'hooks/UseTelemetry';
import { useMessagesFunctions } from 'hooks/UseZustand';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { DeleteMessagesAction, MarkAsReadAction } from 'phoenix/redux/actions';
import { SnexMessage } from 'phoenix/redux/models/Messages/SnexMessage';
import { SnexOrderUpdateMessagePayload } from 'phoenix/redux/models/Messages/SnexOrderUpdateMessagePayload';
import { useMessagesStore } from 'phoenix/stores/MessagesStore';
import { GetVariant } from 'phoenix/util/Variant';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { useCallback, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Routes } from 'util/Routes';
import { LowercaseFirstOnly } from 'util/Utils';
import './index.module.css';

export interface MessageRowProps {
    message: SnexMessage;
    onClick?: () => any;
    markReadOnSight?: boolean;
    toast?: boolean;
    dense?: boolean;
    showDelete?: boolean;
    key?: number;
}

const pascalToCamelOrder = (pascal: SnexOrderUpdateMessagePayload) => {
    const camel: { symbol?: string; orderId?: string; accountNumber?: string } = {};
    Object.keys(pascal || {}).forEach((k) => {
        camel[LowercaseFirstOnly(k)] = pascal[k];
    });

    return camel;
};

export const MessageRow = TelemetryProvider((props: MessageRowProps) => {
    const LogEvent = useTelemetry();
    const colors = useColors();
    const dispatch = useDispatch();
    const { markAsRead, deleteMessages } = useMessagesFunctions();
    const { canViewTradeHistory } = GetVariant();
    const [hover, setHover] = useState(false);
    const [externalUrl, setExternalUrl] = useState(undefined);
    const m = props.message;
    const u = m.data as SnexOrderUpdateMessagePayload;
    const oUpdate = pascalToCamelOrder(u);
    // TODO: Bandaid until we fix serialization in messages
    const comments = m?.data?.comments || m?.data?.Comments;

    const handleClick = useCallback(() => {
        if (props.onClick) props.onClick();

        LogEvent('Message row click', { row: props.key + 1 });
    }, [m, props.onClick]);

    const retrieveRoute = useCallback(() => {
        if (m.payloadType.includes('Order')) {
            if (!canViewTradeHistory) return;

            return Routes.orders(encodeURIComponent(oUpdate.symbol), oUpdate.orderId);
        } else if (m.type === 'md-message') return Routes.message(m.id);
        else if (m.appLink) return m.appLink.replace(/ /g, '');
        else if (m.externalLink) {
            setExternalUrl(m.externalLink);
            return undefined;
        } else if (m.subjectSymbol) return Routes.security(encodeURIComponent(m?.subjectSymbol));
        else return Routes.messages();
    }, [m, props.onClick]);

    const handleVisible = useCallback(
        async (visible: boolean) => {
            if (visible && props.markReadOnSight && m.readDate === null) {
                await markAsRead(m.id);
            }
        },
        [props.markReadOnSight, m.readDate, m.id, markAsRead]
    );

    const handleDelete = useCallback(async () => {
        await deleteMessages([m.id]);
    }, [deleteMessages, m.id]);

    const rowButton = (
        <button onClick={handleClick}>
            {/* Unread dot */}
            <Flex column align='flex-start' justify='center' style={{ width: 15, height: 60 }}>
                {m.readDate === null ? (
                    <div
                        style={{
                            backgroundColor: colors.segmentedControlActiveColor,
                            borderRadius: '50%',
                            height: 8,
                            width: 8
                        }}
                    ></div>
                ) : null}
            </Flex>

            {/* Message image */}
            <div style={{ width: 45, marginRight: 10, marginTop: 10 }}>
                <Image message={m} />
            </div>

            {/* Main center area rows */}
            <Flex
                column
                justify='flex-start'
                style={{
                    flex: 1,
                    padding: props.dense ? '8px 0' : '15px 0'
                }}
                onClick={handleClick}
            >
                <Flex row justify='space-between'>
                    <Tooltip title={m.title}>
                        <Typography
                            style={{
                                flex: 1,
                                paddingRight: 3,
                                boxSizing: 'border-box'
                            }}
                            variant='h6'
                        >
                            <LinesEllipsis maxLine='1' text={m.title} />
                        </Typography>
                    </Tooltip>
                    {props.toast ? null : (
                        <DateTimeText
                            date={m.sendDate}
                            variant='to-now'
                            style={{
                                marginLeft: 15,
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal',
                                fontSize: 12,
                                color: colors.fadedTextColor
                            }}
                        />
                    )}
                </Flex>

                {/* Message preview */}
                {m.message ? (
                    <Typography variant='body1'>
                        <LinesEllipsis component='span' maxLine='2' text={`${m.message}${comments ? ` ${comments}` : ''}`} />
                    </Typography>
                ) : null}
            </Flex>
        </button>
    );

    return (
        <ReactVisibilitySensor onChange={handleVisible}>
            <Flex
                className='message-row'
                row
                style={{
                    background: hover && !props.toast ? colors.selectListItemColor : 'none',
                    borderBottom: props.toast ? undefined : `solid 1px ${colors.inputBorderColor}`,
                    opacity: m.deleting ? 0.6 : 1,
                    padding: props.toast ? 0 : '0 15'
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onFocus={() => setHover(true)}
                onBlur={(e) => {
                    setHover(false);
                    // If navigating back to header nav, close popover
                    if ((e.relatedTarget as Element)?.classList?.contains('stonex-header-nav-badge') && props.onClick) props.onClick();
                }}
            >
                {externalUrl ? (
                    <a href={externalUrl} target='_blank' rel='noreferrer'>
                        {rowButton}
                    </a>
                ) : (
                    <Link to={retrieveRoute()} onClick={() => SetSelectedAccount(oUpdate.accountNumber)}>
                        {rowButton}
                    </Link>
                )}

                {/* Action buttons */}
                {props.showDelete || m.deleting ? (
                    <Flex
                        column
                        align='center'
                        justify='center'
                        onBlur={() => setHover(false)}
                        onFocus={() => setHover(true)}
                        style={{
                            width: 60,
                            height: 60,
                            opacity: hover ? 1 : 0
                        }}
                    >
                        <IconButton disabled={m.deleting} onClick={handleDelete} size='large'>
                            {m.deleting ? <LoadingSpinner size={22} /> : <HighlightOff />}
                        </IconButton>
                    </Flex>
                ) : null}
            </Flex>
        </ReactVisibilitySensor>
    );
}, TelemetryCategories.messages);

const Image = React.memo((props: { message: SnexMessage }) => {
    const m = props.message;

    // -1. If this is an admin message, show the SNEX logo
    if (m.senderId === 'admin') return <CircularLogo size={38} symbol='SNEX' />;

    // 0. If a sender is provided, show their profile pic.
    if (m.senderId) return <UserAvatar size={38} userId={m.senderId} />;

    // 1. If a user is provided, show their profile pic. If a symbol is provided, show it as a little badge
    if (m.subjectUserId) {
        return (
            <UserAvatar size={38} userId={m.subjectUserId}>
                {m.subjectSymbol ? (
                    <Flex column align='flex-end' justify='flex-end' style={{ width: 38, height: 38 }}>
                        <CircularLogo altText={m.subjectSymbol} size={18} symbol={m.subjectSymbol} />
                    </Flex>
                ) : null}
            </UserAvatar>
        );
    }
    // 2. If a symbol is provided, show the logo
    else if (m.subjectSymbol) return <CircularLogo altText={m.subjectSymbol} size={38} symbol={m.subjectSymbol} />;
    // 3. If a hotlinked or inlined image is provided, show that
    else if (m.imageUrl) {
        return (
            <div
                style={{
                    backgroundImage: `url(${m.imageUrl})`,
                    backgroundSize: 'cover',
                    width: 38,
                    height: 38
                }}
            ></div>
        );
    }
    // Otherwise I guess we'll just show nothing
    else return null;
});
