import { Skeleton, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Flex, WarningLabel } from '..';
import { SnexPopover } from './SnexPopover';
import { FormatNumber } from 'phoenix/util';
import { Routes } from 'util/Routes';
import { PopoverHeader } from './PopoverHeader';
import { AssetClassLabel } from '../AssetClassLabel/AssetClassLabel';
import { useText } from 'phoenix/hooks/UseText';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { AccountRestrictionCodes } from 'phoenix/constants/AccountRestrictionCodes';
import { SnexAccountOption, UseActiveAndPendingAccountOptions } from 'phoenix/hooks/useAccounts';
import { UseSelectedAccount } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { Link } from 'react-router-dom';
import './AccountShortcutsPopover.css';

export interface AccountShortcutsPopoverProps {
    open: boolean;
    onClose: () => void;
}

export const AccountShortcutsPopover = ({ onClose, open }: AccountShortcutsPopoverProps): JSX.Element => {
    const [selectedAccount, setSelectedAccount] = UseSelectedAccount();

    const { data: accounts, loading: accountsLoading } = UseActiveAndPendingAccountOptions({
        selectedAccount,
        withBuyingPower: true,
        withRequests: true
    });

    const text = useText((s) => s);

    return (
        <AccountShortcutsPopoverPresentation
            accounts={accounts ?? []}
            selectedAccount={selectedAccount}
            loading={accountsLoading ?? false}
            onClose={onClose}
            open={open}
            onSelectAccount={setSelectedAccount}
            text={text}
        />
    );
};

type AccountRowProps = {
    account: SnexAccountOption;
    onClose: () => void;
    onSelectAccount: (accountNumber: string) => void;
    selected: boolean;
    text: Snex1LanguagePack;
};

const AccountRow = ({ account, onClose, selected, onSelectAccount, text }: AccountRowProps) => {
    const onboardingV2Flag = useSnexStore((s) => s.featureFlags.byId[FeatureFlags.onboardingV2]);
    const pending = onboardingV2Flag?.enabled ? account.isPending : account.restrictionCode === AccountRestrictionCodes.stonexTraderRestriction;

    const rawBuyingPowerValue = account?.buyingPower?.buyingPower;
    const buyingPowerValue = useMemo(
        () => (typeof rawBuyingPowerValue === 'number' ? FormatNumber.toMoney(rawBuyingPowerValue) : <Skeleton animation='wave' width={60} />),
        [rawBuyingPowerValue]
    );

    const handleClick = () => {
        onSelectAccount(account.accountNumber);
        onClose();
    };

    const classNames = [{ 'account-row': true }, { pending }, { selected }]
        .filter((x) => Object.values(x)[0])
        .map((x) => Object.keys(x)[0])
        .join(' ');

    return (
        <Link to={pending ? '' : Routes.account(account.accountNumber)}>
            <Flex
                align='center'
                className={classNames}
                id={`account-${account.accountNumber}`}
                onClick={pending ? undefined : handleClick}
                onBlur={(e) => {
                    // If blurring to something that isn't an account, close the modal
                    if (!(e.relatedTarget as Element).id.includes('account')) onClose();
                }}
                role={pending ? 'presentation' : 'button'}
            >
                <Flex column style={{ flex: 1, boxSizing: 'border-box', overflow: 'hidden' }}>
                    <Flex row align='center' justify='space-between' style={{ width: '100%', position: 'relative', boxSizing: 'border-box', overflow: 'hidden' }}>
                        <Typography
                            style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                flex: 1,
                                overflow: 'hidden',
                                marginRight: 5,
                                fontWeight: 400
                            }}
                            variant='h6'
                        >
                            {account?.effectiveName || QualifiedId.Format(account?.accountNumber)}
                        </Typography>
                        <Flex row style={{ gap: 5 }}>
                            {pending ? (
                                <WarningLabel style={{ padding: '3px 12px', fontSize: '13px', margin: 0 }} variant='orange'>
                                    {text.general.pending}
                                </WarningLabel>
                            ) : (
                                <AssetClassLabel thingId={account.accountNumber} />
                            )}
                        </Flex>
                    </Flex>
                    <Flex row align='center' justify='space-between' style={{ width: '100%', marginTop: 5 }}>
                        <Typography style={{ display: 'flex' }} variant='body2'>
                            <span
                                style={{
                                    opacity: 0.5,
                                    marginRight: 5,
                                    display: 'flex'
                                }}
                            >
                                {text.general.buyingPower}:
                            </span>{' '}
                            <span>{buyingPowerValue}</span>
                        </Typography>
                        <Typography variant='body2'>{QualifiedId.Format(account.accountNumber)}</Typography>
                    </Flex>
                </Flex>
            </Flex>
        </Link>
    );
};

const SkeletonAccountRow = ({ onClose, text }: { onClose: () => void; text: Snex1LanguagePack }): JSX.Element => {
    return (
        <Flex
            align='center'
            className={'account-row'}
            id={`account-skeleton`}
            onBlur={(e) => {
                // If blurring to something that isn't an account, close the modal
                if (!(e.relatedTarget as Element).id.includes('account')) onClose();
            }}
            role={'button'}
        >
            <Flex column style={{ flex: 1, boxSizing: 'border-box', overflow: 'hidden' }}>
                <Flex row align='center' justify='space-between' style={{ width: '100%', position: 'relative', boxSizing: 'border-box', overflow: 'hidden' }}>
                    <Typography
                        style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            flex: 1,
                            overflow: 'hidden',
                            marginRight: 5,
                            fontWeight: 400
                        }}
                        variant='h6'
                    >
                        <Skeleton />
                    </Typography>
                </Flex>
                <Flex row align='center' justify='space-between' style={{ width: '100%', marginTop: 5 }}>
                    <Typography style={{ display: 'flex' }} variant='body2'>
                        <span
                            style={{
                                opacity: 0.5,
                                marginRight: 5,
                                display: 'flex'
                            }}
                        >
                            {text.general.buyingPower}:
                        </span>{' '}
                        <span>
                            <Skeleton width={100} />
                        </span>
                    </Typography>
                    <Typography variant='body2'>
                        <Skeleton width={75} style={{ marginRight: 6 }} />
                    </Typography>
                </Flex>
            </Flex>
        </Flex>
    );
};

export type AccountShortcutsPopoverPresentationProps = {
    accounts: SnexAccountOption[];
    selectedAccount: string;
    loading: boolean;
    onClose: () => void;
    onSelectAccount: (accountNumber: string) => void;
    open: boolean;
    text: Snex1LanguagePack;
};

export const AccountShortcutsPopoverPresentation = ({
    accounts = [],
    selectedAccount,
    loading,
    onClose,
    open,
    onSelectAccount,
    text
}: AccountShortcutsPopoverPresentationProps): JSX.Element => {
    return (
        <SnexPopover open={open} paperStyles={{ width: 380 }} right={0} top={open ? 40 : 20} onClose={onClose}>
            <Flex column justify='center' style={{ height: '100%', width: '100%' }}>
                <PopoverHeader>{text.misc.accounts(1)}</PopoverHeader>
                {loading ? (
                    <SkeletonAccountRow onClose={onClose} text={text} />
                ) : (
                    <Flex column style={{ overflow: 'auto', width: '100%', maxHeight: 'calc(min(560px, 100vh - 250px))' }}>
                        {accounts.map((a, key) => (
                            <AccountRow account={a} key={key} onClose={onClose} onSelectAccount={onSelectAccount} selected={key === 0 && !!selectedAccount} text={text} />
                        ))}
                    </Flex>
                )}
            </Flex>
        </SnexPopover>
    );
};
