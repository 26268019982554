import { Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '..';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useTier } from 'phoenix/hooks/UseTier';
import { GetMySubscriptionInfoAction } from 'phoenix/redux/actions/SubscriptionActions';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import style from './style.module.scss';

export type ShiningWrapperVariant = 'gold' | 'green'
export const ShiningWrapperVariants = {
    Gold: 'gold' as ShiningWrapperVariant,
    Green: 'green' as ShiningWrapperVariant
}


const GetClassNameFromVariant = (variant?: ShiningWrapperVariant):string => {
    switch(variant)
    {
        case 'green' : return style.textAnimationGreen;
        case 'gold': 
        default: return style.textAnimation;
    }
}

export const ShiningWrapper = React.memo(({ children, withLock, permittedTiers, variant }: {children?: any, withLock?: boolean, permittedTiers?: string[], variant?: ShiningWrapperVariant }) => {

    const dispatch = useDispatch();
    const allowedTiers = useMemo(() => new Set(permittedTiers || [ 'trial', 'preferred' ]), [ permittedTiers ])
    const { tierPrefix } = useTier();
    

    useEffect(() => {
        if (withLock) dispatch(GetMySubscriptionInfoAction());
    }, [ withLock ])

    const permitted = useMemo(() => withLock ? (allowedTiers.has(tierPrefix)) : true, [ withLock, tierPrefix, allowedTiers ])

    const _className = GetClassNameFromVariant(variant)

    return (
        <Flex center className={_className}>
            {children}
        </Flex>
    )
})