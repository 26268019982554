// @ts-strict-ignore
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { Flex } from 'components/Flex';
import { floatMath, MoneyFormatOptions, parseDecimal } from 'phoenix/util';
import React, { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { DollarAdornment } from './DollarAdornment';
import './index.module.css';

export interface DecimalPriceInputProps {
    arrows?: boolean;
    disabled?: boolean;
    error?: boolean;
    formatOptions?: MoneyFormatOptions;
    formatter: (number: number) => string;
    helperText?: string;
    initialValue?: number;
    label?: string;
    max?: number;
    onBlur?: (v: string) => string;
    onDollarValueChange?: (dollars?: number) => void;
    showDollarSign?: boolean;
    step?: number;
    styles?: React.CSSProperties;
    symbol?: string;
}

type DecimalInputValues = { inputNumber?: number; display: string };

export const DecimalPriceInput = React.forwardRef((props: DecimalPriceInputProps, ref: React.MutableRefObject<HTMLInputElement>) => {
    const { arrows = null, disabled, formatter, helperText, initialValue, label, max, onDollarValueChange, showDollarSign = true, step = 0.01, styles } = props;

    const [values, setValues] = useState<DecimalInputValues>({ inputNumber: null, display: '' });
    const [cursor, setCursor] = useState<number>(null);
    // Use to determine whether to display initial values or stateful ones
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const formatOptions = props?.formatOptions || { showDecimals: true };
    const { allowNegative, maxDecimalPlaces, minDecimalPlaces } = formatOptions || {};
    const priceFormatter = useCallback((value: number) => formatter(value)?.replace('$', ''), [formatter]);
    const initialDisplay = isNaN(initialValue) || [null, undefined].includes(initialValue) ? '' : priceFormatter(initialValue);
    const effectiveDisplay = userChanged ? values.display : initialDisplay;

    const placeHolder = priceFormatter(0);

    const updateValue = useCallback(
        (number: number, originalInput: string, selectionStart?: number) => {
            setUserChanged(true);

            const { cursorPosition, inputNumber, display } = getUpdatedInputValues({
                allowNegative,
                maxDecimalPlaces,
                minDecimalPlaces,
                number,
                originalInput,
                priceFormatter,
                selectionStart,
                step,
                values
            });

            setCursor(cursorPosition);
            setValues({ inputNumber, display });
        },
        [allowNegative, maxDecimalPlaces, minDecimalPlaces, priceFormatter, step, values]
    );

    useEffect(() => {
        if (!ref?.current) return;
        const input = ref.current;
        if (input) input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, initialValue]);

    const handleKeyDown = (event: KeyboardEvent) => {
        switch (event?.key) {
            case 'ArrowUp':
                return handleIncrement();
            case 'ArrowDown':
                return handleDecrement();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { selectionStart, value } = e?.target || {};

        let number = value && parseDecimal(value, formatOptions);
        if (isNaN(number) || (!allowNegative && number < 0)) number = null;
        if (max && number > max) number = max;

        if (onDollarValueChange) {
            // use formatter to correct decimal places for external use
            const parsed = parseDecimal(value, formatOptions);
            const formatted = priceFormatter(parsed);
            const decimalCorrectedPrice = parseDecimal(formatted, formatOptions);
            const result = isNaN(decimalCorrectedPrice) ? null : decimalCorrectedPrice;
            onDollarValueChange(result);
        }

        updateValue(number, value, selectionStart);
    };

    const handleIncrement = () => {
        const number = parseDecimal(effectiveDisplay, formatOptions) || 0;
        const newNumber = floatMath(number, step, (v, s) => v + s);

        if (onDollarValueChange) onDollarValueChange(newNumber);
        updateValue(newNumber, priceFormatter(newNumber));
    };

    const handleDecrement = () => {
        const number = parseDecimal(effectiveDisplay, formatOptions) || 0;
        if (!allowNegative && number === 0) return;
        let newNumber = floatMath(number, step, (v, s) => v - s);
        if (isNaN(number) || (!allowNegative && number < 0)) newNumber = 0;
        if (onDollarValueChange) onDollarValueChange(newNumber);
        updateValue(newNumber, priceFormatter(newNumber));
    };

    const className = [{ 'custom-number-input': true }, { arrows }, { disabled }]
        .filter((x) => Object.values(x)[0])
        .map((x) => Object.keys(x)[0])
        .join(' ');

    // Reformat on blur
    const onBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) props.onBlur(e.target.value);
        const formatted = priceFormatter(values?.inputNumber);
        if (formatted !== values?.display && values?.display !== '') setValues({ ...values, display: formatted });
    };

    return (
        <TextField
            inputRef={ref}
            fullWidth
            placeholder={placeHolder}
            variant='outlined'
            {...{
                className,
                disabled,
                InputProps: {
                    endAdornment: arrows && (
                        <Flex column justify='space-around'>
                            <button title='Increment' onClick={handleIncrement}>
                                <KeyboardArrowUp />
                            </button>
                            <button title='Decrement' onClick={handleDecrement}>
                                <KeyboardArrowDown />
                            </button>
                        </Flex>
                    ),
                    onChange: (e) => handleChange(e),
                    onKeyDown: (e) => handleKeyDown(e),
                    startAdornment: showDollarSign ? <DollarAdornment /> : undefined
                },
                helperText,
                label,
                onBlur,
                styles,
                value: effectiveDisplay
            }}
        />
    );
});

export function getUpdatedInputValues({
    allowNegative = false,
    maxDecimalPlaces,
    minDecimalPlaces,
    number,
    originalInput,
    priceFormatter,
    selectionStart,
    step,
    values
}: {
    allowNegative?: boolean;
    maxDecimalPlaces: number;
    minDecimalPlaces: number;
    number?: number;
    originalInput: string;
    priceFormatter: (price: number) => string;
    selectionStart: number;
    step: number;
    values: { inputNumber?: number; display: string };
}): {
    cursorPosition: number;
    inputNumber: number;
    display: string;
} {
    if ([null, undefined].includes(originalInput) || (originalInput !== '.' && isNaN(number)) || originalInput === '') {
        return { cursorPosition: 0, display: '', inputNumber: null };
    }

    // Users need to be able to enter negative numbers (if negative is allowed)
    // so we need to allow a lone hyphen and various versions of negative zero
    const negativeZeroRegex = /^-(0\.0{0,4}|0|\.?)$/;
    if (negativeZeroRegex.test(originalInput) && allowNegative) {
        return {
            cursorPosition: (selectionStart = originalInput.length), // Move the cursor to the end of the string
            display: originalInput.match(negativeZeroRegex) ? originalInput.match(negativeZeroRegex)[0] : '-0.0000',
            inputNumber: originalInput.includes('0') ? 0 : null
        };
    }

    const source = ['.', '-.'].includes(originalInput) ? originalInput.replace('.', '0.') : originalInput;
    const inputDecimalIndex = originalInput.indexOf('.');
    const inputHasDecimal = inputDecimalIndex >= 0;

    let formatted = priceFormatter(number);
    const formattedDecimalIndex = formatted.indexOf('.');
    let trailingToAdd = 0;

    if (!minDecimalPlaces && source.charAt(source.length - 1) === '.') formatted += '.';

    // If the step/tick size >= 1, or if the formatter isn't required to format decimals, formattedDecimalIndex will be -1 causing this to render an empty string
    // The rest of the decimal manipulation is wrapped in case the user c/p's a value with decimals in it
    if (step < 1 && (minDecimalPlaces || formattedDecimalIndex !== -1)) {
        if (!inputHasDecimal) {
            formatted = formatted.substring(0, formattedDecimalIndex);
        } else {
            const afterDecimal = formatted.length - 1 - formattedDecimalIndex;
            const afterDecimalInput = originalInput.length - 1 - inputDecimalIndex;
            const decimalDelta = afterDecimalInput - afterDecimal;

            if (decimalDelta > 0) {
                while (afterDecimal + trailingToAdd < maxDecimalPlaces && trailingToAdd < decimalDelta) {
                    trailingToAdd++;
                }
                formatted = formatted + ''.padEnd(trailingToAdd, '0');
            } else {
                formatted = formatted.substring(0, formatted.length + decimalDelta);
            }
        }
    }

    // Input has trailing decimals, ie: 1.000
    const postDecimalArr = source.slice(inputDecimalIndex + 1).split('');
    const trailingZeroDecimals = minDecimalPlaces === 0 && postDecimalArr.length && postDecimalArr.every((n) => n === '0');
    // If there is a trailing decimal place and minDecimalPlaces = 0, a trailing decimal will get formatted out (but needs to stay)
    const sliceIndex = inputDecimalIndex === -1 ? 0 : inputDecimalIndex;

    let final = source === '0.' || trailingZeroDecimals ? source.substring(0, maxDecimalPlaces + sliceIndex + 1) : formatted;
    if (final.includes('---')) final = ''; // Common default for formatters but we never want it in an input

    const prevMatch = [...values?.display?.matchAll(/,/g)];
    const prevCommas = prevMatch?.length;
    const newMatch = [...final.matchAll(/,/g)];
    const newCommas = newMatch?.length;

    // If adding or subtracting a new comma from a position < current cursor position, add or subtract the diff
    // So the cursor appears to stay in the same place within the value
    // also adjust cursor when adding trailing zeroes.
    let newCursor = selectionStart || source?.length;
    if (prevCommas !== newCommas) {
        const diff = newCommas - prevCommas;
        if (newMatch.length && selectionStart > newMatch?.[0]?.index) {
            newCursor += diff;
        }

        if (trailingToAdd) newCursor -= trailingToAdd;
    }
    // Adding a leading zero here so move the cursor accordingly
    if (source === '0.') newCursor += 1;

    // Account for cases such as "." => "0.", where previous number is null but new number now evaluates to 0
    if (Number(source) === 0 && [null, undefined].includes(number)) number = 0;

    return {
        cursorPosition: newCursor,
        inputNumber: number,
        display: final
    };
}
