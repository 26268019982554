// @ts-strict-ignore
import { DropdownOption } from '../../models/DropdownOptions';
import { Account, DailyLimitFigures, OptionsOpenClose, OptionsPutCallAbbreviation, Order, SnexDocument, TradeRequest } from '../../redux/models';

import { AppliedAppTheme } from 'phoenix/constants/AppliedAppTheme';
import { ApiTradeRequest, ApiTradeStrategy } from 'phoenix/models/ApiTradeRequest';
import { AssetClass, AssetFamily } from 'phoenix/models/AssetClasses/AssetClass';
import { FundingLimits } from 'phoenix/redux/models/Funding/FundingLimits';
import { FundingSource } from 'phoenix/redux/models/Funding/FundingSource';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { Environment } from '../../constants';
import { TransferRequest } from '../../models/TransferRequest';
import { FundingManagementLimits } from '../../redux/models/Funding/FundingManagementLimits';
import { SnexMessage } from '../../redux/models/Messages/SnexMessage';
import { SnexOrderUpdateMessagePayload } from '../../redux/models/Messages/SnexOrderUpdateMessagePayload';
import { Transaction } from '../../redux/models/Transactions/Transaction';
import { TradeableSecurityType } from '../../util';

export type ShadedText = {
    shade?: 'light' | 'dark';
    text: string;
    color?: string;
    id?: string; // If supplied, should trigger a callback when the user presses this segment
    /** @deprecated Please use a separate component instance for accompanying text */ secondary?: string;
    assetClass?: string;
    lineBreak?: 'double' | boolean;
};
export type ShadedTextList = ShadedText[];
export const Dark = (text: string, secondary?: string, assetClass?: string, color?: string, lineBreak?: 'double' | boolean): ShadedText => ({
    shade: 'dark',
    text,
    secondary,
    assetClass,
    color,
    lineBreak
});
export const Light = (text: string, color?: string, lineBreak?: 'double' | boolean): ShadedText => ({ shade: 'light', text, color, lineBreak });

export type FormattedText = {
    text: string;
    color?: string;
    weight?: 'bold' | 'regular';
    size?: 'title' | 'large' | 'regular' | 'small';
    url?: string;
};
export type FormattedTextList = FormattedText[][];

export type DisclosuresBundle = {
    additionalClientDisclosures?: FormattedTextList;
    earningsAndRatingsData: FormattedTextList;
    featuresAndBalanceDefinitions: FormattedTextList;
    importantPositionInformation?: FormattedTextList;
    importantTechnicalAnalysisInformation?: FormattedTextList;
    marketIntelligenceDisclaimer: FormattedTextList;
    ordersAndActivity: FormattedTextList;
    pricing?: FormattedTextList;
    projectedIncomeInformation?: FormattedTextList;
};

export type InfoIconBundle = {
    aboutSecurity: FormattedTextList;
    aboutSrri: FormattedTextList;
    acatTransfer: FormattedTextList;
    buyingPower: FormattedTextList;
    marketCap?: FormattedTextList;
    news: FormattedTextList;
    portfolioValue: FormattedTextList;
    topMoversAndSectors: FormattedTextList;
};

export interface Snex1LanguagePack {
    funding: {
        delete: {
            title: string;
            message: string;
            submitButton: string;
            cancelButton: string;
        };
    };
    errorBoundary: {
        title: string;
        sorryMessage: string;
        oopsSomethingWentWrong: string;
        tryAgain: string;
        contactSupport: string;
        onboardingErrorOccurred: string;
        onboardingTryAgainOrContactSupport: string;
    };
    assetClass: {
        futures: string;
        cryptocurrency: string;
        crypto: string;
        futuresOptions: string;
        future: string;
        equitiesOption: string;
        otcOption: string;
        etf: string;
        mutualFund: string;
        oilGasReit: string;
        escrowReceiptsLetterGuaranty: string;
        treasury: string;
        municipleBond: string;
        corporateUit: string;
        municipalUit: string;
        unit: string;
        preciousMetals: string;
        commonStock: string;
        annuity: string;
        miscellaneous: string;
        offShoreMutualFund: string;
        brokerage: string;
    };
    welcome: {
        skipTour: string;
        showMeAround: string;
        pickLang: string;
        successText: string;
        loadingText: string;
        errorText: string;
        noAccountsText: string;
    };
    webTos: {
        title: string;
        url: string;
        linkText: string;
        agreeButtonLabel: string;
    };
    general: {
        to: string;
        ai: string;
        languageNameEnglish: string;
        languageNameLocal: string;
        dateFnsLocale: Locale;
        localeShort: string;
        localeLong: string;
        submit: string;
        continue: string;
        acceptRateAndSubmit: string;
        acceptTermsAndSubmit: string;
        submitting: string;
        loading: string;
        cancel: string;
        search: string;
        trade: string;
        news: (showMI?: boolean) => string;
        writtenInteger0Thru10: (n: number) => string;
        unavailable: string;
        open: string;
        done: string;
        none: string;
        dismiss: string;
        confirmAndContinue: string;
        contactSupport: string;
        dateUnknown: string;
        unknown: string;
        browseDataUnavailable: string;
        unableToAuthenticate: string;
        yesLeave: string;
        noStayHere: string;
        returningToStonex: string;
        aboutToReturnToStonex: string;
        months: string[];
        monthsShort: string[];
        quantity: string;
        strikes: string;
        shares: (n: number) => string;
        contracts: (n: number) => string;
        fundShares: (n: number) => string;
        bonds: (n: number) => string;
        unit: (symbol: string, quantity: number, typeHint?: string) => string;
        coins: (n: number) => string;
        // symbol-putcall -> "SIRI Call"
        // extra-short    -> "SIRI $4 Call, 08/12"
        // short          -> "SIRI $4.00 Call, exp 08/12/2022"
        // long           -> "SIRI 12 August 2022 weekly $4.00 Calls"
        symbolName: (
            symbol: string,
            optionVariant?: 'extra-short' | 'short' | 'long' | 'symbol-putcall' | 'no-exp',
            quantity?: number,
            secMeta?: SecurityMetadata // for tick size
        ) => string;
        spokenJoin: (items: string[]) => string;
        isShort: string;
        buyingPower: string;
        availableBalance: string;
        pendingApproval: string;
        pending: string;
        maintenanceCall: string;
        maintenanceCallForAccounts: string;
        maintenanceCallTooltip: string;
        dayTradingFlag: string;
        fplEligible: string;
        fplEnabled: string;
        ninetyDayRestriction: {
            description: string;
            forAccounts: string;
            list: string[];
            secondParagraph: string;
            title: string;
            withDate: (date: Date | string) => string;
        };
        fedCallTooltip: string;
        options: {
            call: string;
            exp: string;
            put: string;
            putCallWord: (putCall: OptionsPutCallAbbreviation) => string;
            adjustedOptionsSubtext: (deliverableCount: number) => string;
        };
        productTypes: Record<
            Exclude<TradeableSecurityType, 'loading' | 'futures-option' | 'futures-time-spread'>,
            { singular: string; plural: string; upperSingular?: string }
        >;
        assetClassLabels: { [key in AssetFamily]: string };
        sorrySomethingWentWrong: string;
        notApplicable: string;
        learnMore: string;
        expiresSoon: string;
        expiresToday: string;
        expiresTomorrow: string;
        daily: string;
        oneTime: string;
        repeatDaily: string;
        numberBought: string;
        numberSold: string;
        avgBuyPrice: string;
        avgSellPrice: string;
        topDayActivity: string;
    };
    webRegistration: {
        verifySMSCodeTitle: string;
        enterCodeAndLogin: string;
        verifySMSCodeSubtitle: (phone: string) => string;
        messageChargesAlert: string;
        sendingSMSCode: string;
        invalidSMSCode: string;
        resentSMSCode: string;
        getSMSCode: string;
        submitSMSCode: string;
        alternateMFA: string;
        welcome: string;
        getAnAccount: string;
        getUserInfo: string;
        nextButton: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
        confirmEmail: string;
        verifyEmail: string;
        emailVerified: string;
        userExists: (email: string) => string;
        personalInfo: string;
        usCitizenQuestion: string;
        yes: string;
        no: string;
        usCitizenAnswer: string;
        nonUSCitizenAnswer: string;
        usCitizenAlert: string;
        createPassword: string;
        createUser: string;
        passwordRequirement: string;
        password: string;
        confirmPassword: string;
        verifyYourEmail: string;
        sendingVerificationCode: string;
        enterInBoxBelow: string;
        invalidVerificationCode: string;
        resentVerificationCode: string;
        verificationCode: string;
        submit: string;
        resendCode: string;
        completeTitle: string;
        completeSubtitle: string;
        login: string;
        alreadyExists: string;
        wouldYouLikeToLogin: string;
        steps: {
            welcome: string;
            userInfo: string;
            personalInfo: string;
            setupPassword: string;
            verifyCreate: string;
            enrollMFA: string;
            login: string;
        };
        errors: {
            required: string;
            userInfo: {
                invalidPhone: string;
                invalidEmail: string;
                emailDoesNotMatch: string;
                emailExists: string;
            };
            password: {
                passwordsDoNotMatch: string;
                invalidPassword: string;
            };
        };
    };
    appReview: {
        yes: string;
        no: string;
        enjoyingStoneX: string;
        rateApp: string;
        later: string;
        noThanks: string;
        feedback: string;
    };
    connectionStatus: {
        title: string;
        message: string;
        retryConnection: string;
    };
    premiumModal: {
        addTheseTools: string;
        ready: string;
        unlock: string;
        upgradeToday: string;
        deepAnalysis: {
            title: string;
            content: string;
        };
        multiCharts: {
            title: string;
            content: string;
        };
        streamingNews: {
            title: string;
            content: string;
        };
        alerts: {
            title: string;
            content: string;
            isAbove: string;
            isAboveOrEqual: string;
            isBelow: string;
            isBelowOrEqual: string;
        };
        miPremium: {
            title: string;
            content: string;
        };
    };
    marketTimeBadge: {
        open: string;
        premarket: string;
        holiday: string;
        postmarket: string;
        closed: string;
        loading: string;
    };
    blankViews: {
        holdingsTable: {
            main: string;
            secondary: string;
            notFundedMain: string;
            notFundedSecondary: string;
            notFundedAction: string;
        };
        documents: {
            main: (ducumentType: string) => string;
            secondary: (ducumentType: string) => string;
            mainDocumentTypes?: {
                'Trade Confirmations': string;
                '1099s + Tax Documents': string;
                Miscellaneous: string;
                Checks: string;
                'Account Statements': string;
            };
            secondaryDocumentText?: {
                'Trade Confirmations': string;
                '1099s + Tax Documents': string;
                Miscellaneous: string;
                Checks: string;
                'Account Statements': string;
            };
        };
        transactions: {
            main: string;
            secondary: string;
        };
        projectedIncome: {
            main: string;
            secondary: string;
        };
        tradeHistory: {
            main: string;
            secondary: string;
        };
        messages: {
            main: string;
            secondary: string;
        };
    };
    premiumScreen: {
        createAlert: (symbol: string) => string;
        deepAnalysisAlt: string;
        viewMi: (symbol: string) => string;
        viewMiAlt: string;
        returnToSummary: string;
        subHeader: string;
        viewCharts: (symbol: string, symbol2: string) => string;
        viewChartsAlt: string;
        viewDeepAnalysis: (symbol: string) => string;
        welcomeHeader: (name: string) => string;
        checkout: {
            title: string;
            changePlan: string;
            nextBilled: (date: Date) => string;
            cancelAnytime: string;
            offerTerms: string;
            details: {
                title: string;
                body: (productName: string) => string;
                multipleAccounts: string;
            };
            selectAccount: (hasMultipleAccounts?: boolean) => string;
            buttonText: string;
        };
        selectProduct: {
            goPremium: string;
            or: string;
            pickYourPackage: string;
            perMonth: string;
            comingSoon: string;
            select: string;
            comingSoonToStonexOne: string;
            bestValue: string;
            jumbotron: {
                ourBestDeal: string;
                title: FormattedTextList;
                perMonthAbbr: string;
                moreOptions: string;
                getStarted: string;
            };
            products: {
                sx1Premium: string;
                miPremium: string;
                sx1miBundle: string;
            };
            features: {
                deepAnalysis: { title: string; subtitle?: string };
                multiChart: { title: string; subtitle?: string };
                customAlerts: { title: string; subtitle?: string };
                miPremiumArticles: { title: string; subtitle?: string };
            };
        };
    };

    managePremiumScreen: {
        premium: string;
        havePremiumTitle: string;
        havePremiumSubtitle: string;
        dontHavePremiumTitle: string;
        dontHavePremiumSubtitle: string;
        features: {
            earningsCalls: string;
            financials: string;
            institutions: string;
        };
        startPremiumNow: string;
        startForJust: (price: string | number) => string;
        howPaymentsAreMade: string;
        paymentAccount: string;
        selectPaymentAccount: string;
        nextBill: string;
        contactSupportForBillingHistory: string;
        contactSupport: string;
        cancelMyPremium: string;
        cancelAreYouSure: string;
        cancelYouCanUncancel: (tierExpiration: Date) => string;
        yesCancelMyPremium: string;
        noKeepMyPremium: string;
        premiumEnd: string;
        canceledTitle: (tierExpiration: Date) => string;
        canceledSubtitle: string;
        undoCancelation: string;
    };

    weather: {
        sectionTitle: string;
        current: {
            chanceOfPrecipitation: string;
            dewPoint: string;
            humidity: string;
            visibility: string;
            feelsLike: string;
            airPressure: string;
            wind: string;
            gusts: string;
            sprayCondition: string;
        };
        hourly: {
            hour: string;
            climate: string;
            rainChance: string;
            rainAmount: string;
            temperature: string;
            windGust: string;
        };
    };
    // Helper Components
    misc: {
        backToTorch: string;
        selectAnAccount: string;
        manageAccountApplications: string;
        account: string;
        allAccounts: string;
        showLess: string;
        showFewer: string;
        showXMore: (n: number) => string;
        showMore: string;
        accounts: (n: number) => string;
        heldBy: string;
        addAccountButton: string;
        addAccount: string;
        addFuturesAccount: string;
        addEquitiesAccount: string;
        noResults: string;
        noMoreResults: string;
        errorLoadingResults: string;
        tryAgain: string;
        today: string;
        ranges: {
            oneDay: string;
            twentyFourHours: string;
            fiveDay: string;
            oneWeek: string;
            oneMonth: string;
            threeMonths: string;
            sixMonths: string;
            oneYear: string;
            fiveYear: string;
            all: string;
        };
        search: {
            noResults: string;
            placeholder: string;
            // TODO -- Types
        };
        news: {
            noTitle: string;
            noSummaryAvailable: string;
        };
        tradeTypeButton: {
            buyToOpen: string;
            sellToOpen: string;
            sellToClose: string;
            buyToClose: string;
            liquidate: string;
            buySell: string;
            sellBuy: string;
            buy: string;
            sell: string;
            shortSell: string;
            liquidatePosition: (securityId: string) => string;
        };
        chatWithSupport: string;
        showVolume: string;
        showWorkingOrders: string;
        loggingOut: string;
        amountEstimate: string;
        newIPO: string;
        offeringPrice: string;
        screenNotExist: string;
        goHomeScreen: string;
        stopAllStreaming: string;
    };
    disclosureMessages: {
        tax: string;
        finra: string;
        delayedQuotes: string;
        glancePreviousClose: string;
        projectedOrderCost: string;
        topMovers: string;
        totalPnL: string;
    };
    // Header
    header: {
        alerts: string;
        tip: string;
        trySearch: string;
        summary: string;
        positions: string;
        banking: string;
        messages: string;
        profile: string;
        help: string;
        legalMore: string;
        goPaperless: string;
        documents: string;
        tradeHistory: string;
        fixedPlus: string;
        transactions: string;
        projectedIncome: string;
        disclosures: string;
        logOut: string;
        buyingPower: string;
        portfolioValue: string;
        holdings: string;
        upcomingEarnings: string;
        upcomingIpos: string;
        searchForAnything: string;
        addToList: string;
        exploreMarkets: string;
        optionsHint: string;
        chatWithUs: string;
        emailUs: string;
        resetTours: string;
        verifyResetTours: string;
        amex: string;
        helpPopup: {
            contactUs: string;
            liveChat: string;
        };
    };
    notFoundSceen: {
        header: string;
        securityHeader: string;
        otherOptions: string;
        portfolioButton: string;
        positionsButton: string;
        mobile: {
            wrongTurn: string;
            backToStoneX: string;
        };
    };

    watchlistScreen: {
        items: (numItems?: number) => string;
        notFound: {
            primary: string;
            secondary: string;
        };
        noItems: {
            primary: string;
            secondary: (name: string) => string;
        };
        marketCap: {
            header: string;
        };
        noLists: {
            noListTitle: string;
            noListSubtitle: string;
            createNewList: string;
            addToList: string;
            emptyListTitle: string;
            emptyListSubtitle: string;
            addMore: string;
        };
        defaultDescription: string;
        createNewWatchlist: string;
        addToWatchlist: (qsi: string) => string;
        editWatchlist: string;
        createWatchlist: string;
        watchlistName: string;
        create: string;
        topStories: string;
        update: string;
    };

    // Portfolio Screen
    portfolioScreen: {
        skipToWatchlists: string;
        portfolio: string;
        summary: {
            allAccounts: string;
            assetsHeldAway: string;
            buyingPower: string;
            cash: string;
            cashAndEquivalents: string;
            initialMargin: string;
            longMarketValue: string;
            longOptionValue: string;
            totalAccountValue: string;
            netLiq: string;
            netOptionValue: string;
            openPnL: string;
            realizedPnL: string;
            shortMarketValue: string;
            shortOptionValue: string;
            summary: string;
            totalPnL: string;
            unrealizedPnL: string;
            viewProjectedIncome: string;
        };
        portfolioBreakdown: {
            contractsBreakdown: string;
            portfolioBreakdown: string;
            holdingsBreakdown: string;
            accountBreakdown: string;
            mutualFunds: string;
            rightsAndWarrants: string;
            fixedIncome: string;
            options: string;
            equities: string;
            stocks: string;
            futures: string;
            cashAndEquivalents: string;
            uits: string;
            others: string;
            preciousMetals: string;
            entertainment: string;
            miscellaneous: string;
            telecommunications: string;
            broadcastingRadioAndTv: string;
            instrumentation: string;
            notGrouped: string;
            investmentCompanies: string;
            automobileAndTrucks: string;
            shares: string;
            breakdown: string;
            sectorExposure: string;
            top10holdings: string;
            investing: string;
            representingAssets: (number: string) => string;
        };
        topMovers: {
            futuresTopMovers: string;
            topMovers: string;
            topGainers: string;
            topLosers: string;
            unavailable: string;
            topMoversUnavailable: string;
            frontMoversUnavailable: string;
            topGainersUnavailable: string;
            topLosersUnavailable: string;
        };
        sectors: {
            sectors: string;
            asOf: (date: Date) => string;
            customerDiscretionary: string;
            healthCare: string;
            technology: string;
            realEstate: string;
            consumerStaples: string;
            utilities: string;
            communicationServices: string;
            financials: string;
            materials: string;
            industrials: string;
            energy: string;
            addToWatchlist: string;
            noSecurities: string;
            emptyList: string;
        };
        buyingPower: {
            buyingPower: string;
            cashAvailable: string;
            marginCashAvailable: string;
            marginAvailable: string;
            nyseExcess: string;
            startUsingMargin: string;
            totalAvailableForTrading: string;
            whichAccountToAddMargin: string;
            initialMargin: string;
            netLiquidity: string;
        };
        glance: {
            title: string;
            portfolioValue: string;
            accountValue: string;
            amountInvested: string;
            amountInvestedInfo: string;
            todaysGainLoss: string;
            todaysGainLossInfo: string;
            unrealizedGainLoss: string;
            unrealizedGainLossInfo: string;
            buyingPower: string;
            cash: string;
            asOfPreviousClose: string;
            lastUpdated: (updated: string) => string;
        };
        sidebar: {
            stocks: string;
            mutualFunds: string;
            fixedIncome: string;
            options: string;
            watchlists: string;
            shares: string;
            sortBy: string;
            price: string;
            value: string;
            display: string;
            add: string;
            delete: string;
            edit: string;
            expires: (date: Date) => string;
            strike: string;
        };
        warnings: {
            pleaseCheckLater: string;
            systemBulletin: string;
            resolvingIssues: string;
            system: string;
            systems: string;
            weAreResolvingIssues: string;
            theseSystems: string;
            theSystem: string;
            incidentFirstReported: string;
            statusEvaluated: (systems: string) => string;
        };
        totalPortfolioValue: string;
        asOfDate: string;
        commonStock: string;
        preferredStock: string;
        newListName: string;
        create: string;
        documents: string;
        readyToStart: (isBeta: boolean) => string;
        depositFunds: string;
        getStarted: string;
        myPositions: string;
        topStories: string;
    };

    accountScreen: {
        accountDeficientChartPoints: string;
        summaryDeficientChartPoints: string;
        pending: {
            title: (plural?: boolean) => string;
            info: (plural?: boolean) => string;
            extrasHeader: string;
            explore: string;
            getHelp: string;
        };
        myAccountStatusApplication: {
            docsRequired: {
                title: string;
                info: string;
            };
            inProgress: {
                title: string;
                info: string;
            };
            rejected: {
                title: string;
                info: string;
            };
            verificationFailed: {
                title: string;
                info: string;
            };
            submitted: {
                title: string;
                info: string;
            };
            viewApplication: string;
        };
        accountBreakdown: string;
        viewDetails: string;
        byAsset: string;
        byIndustry: string;
        noAssetBreakdownAvailable: string;
        noIndustryBreakdownAvailable: string;
        buyingPower: {
            initialMargin: string;
            netLiquidity: string;
        };
    };

    positionsScreen: {
        account: string;
        allIndustries: string;
        allTypes: string;
        averageCost: string;
        change: string;
        changePercent: string;
        closed: string;
        cost: string;
        date: string;
        downloadAll: string;
        downloadClosed: string;
        downloadClosedFileName: () => string;
        downloadFilename: () => string;
        hide: string;
        industryGroups: { [groupNumber: number]: string };
        longAmount: string;
        marketValue: string;
        marketValueFull: string;
        name: string;
        noOpenLots: string;
        notGrouped: string;
        open: string;
        openPnL: string;
        positions: string;
        price: string;
        quantity: string;
        realizedPnlFor: (thingItsFor: string) => string;
        rollPosition: string;
        shortAmount: string;
        show: string;
        showClosedLots: string;
        taxLotPreviousDayDisclosure: string;
        taxLotsFor: (thingItsFor: string) => string;
        taxYear: string;
        term: string;
        todaysGainLoss: string;
        todaysGainLossPercent: string;
        todaysGainLossTab: string;
        totalCost: string;
        totalGainLoss: string;
        totalGainLossPercent: string;
        totalGainLossTab: string;
        type: string;
        unrealizedProfitLoss: string;
        closedPositions: {
            parentHeader: {
                symbolId: string;
                description: string;
                totalQuantity: string;
                totalCost: string;
                totalProceeds: string;
                totalRealizedGainLoss: string;
                totalRealizedGainLossPercent: string;
            };
            childHeader: {
                // equities
                adjustedCost: string;
                closeDate: string;
                closePrice: string;
                cost: string;
                openDate: string;
                proceeds: string;
                quantity: string;
                realizedGainLoss: string;
                realizedGainLossPercent: string;
                term: string;
                totalCost: string;
                // realized PnL
                exchange: string;
                tradeDate: string;
                side: string;
                price: string;
                priceOf: (qsi: string) => string;
                realizedGl: string;
            };
        };
    };

    bankingScreen: {
        errors: {
            ainsIdentity: {
                title: string;
                description: string;
            };
            ainsMax: string;
            ainsMaxVoid: string;
            betaDowntimeWindow: string;
            transfersUnavailable: string;
            insufficientWithdrawalBalanceTitle: string;
            insufficientWithdrawalBalance: string;
            noAccountsAvailable: {
                title: string;
                description: string;
            };
            pendingAccount: string;
            retrievingAccountsFailed: {
                title: string;
                actionTitle: string;
            };
        };
        fundingError: {
            title: string;
            subtitle: string;
            tryAgain: string;
        };
        nextVoidAllowed: (nextVoid: Date) => string;
        maxReached: (maxAccounts: number) => string;
        dontSeeAccount: string;
        history: string;
        transactionHistory: string;
        requests: string;
        disclaimer: string;
        transferFunds: string;
        depositVerb: string;
        withdrawVerb: string;
        depositNoun: string;
        withdrawalNoun: string;
        fundsWillBeCredited: string;
        fundsWillBeDebited: string;
        from: string;
        to: string;
        cashHeader: string;
        depositFunds: string;
        withdrawFunds: string;
        selectLinkedBankAcct: string;
        retrievingBankAcct: string;
        linkBankAcctWith: string;
        amountMayNotExceed: (limit: number) => string;
        amountMustBeAtleast: (limit: number) => string;
        dailyLimitText: (limits: DailyLimitFigures | null) => ShadedTextList;
        dailyMaximumText: (limits: FundingLimits | null, isDeposit: boolean) => ShadedTextList;
        minimumAllowedDepositText: (isDeposit: boolean, minAllowedDeposit: number | null) => ShadedTextList;
        dailyLimitText2: (limits: DailyLimitFigures | null) => string;
        managementLimitText: (limits: FundingManagementLimits | null) => string;
        fundingAvailableBalance: (availableBalance?: number) => ShadedTextList;
        tryAgainIn: (dateForUnlock: Date) => string;
        amountToTransfer: (transferDirection?: 'deposit' | 'withdraw') => string;
        tryAgainTomorrow: string;
        tryAgainOnMonday: string;
        amount: string;
        review: string;
        reviseTransfer: string;
        depositsLocked: (days: number) => string;
        withdrawLocked: (days: number) => string;
        back: string;
        amountExceedsLimit: string;
        amountExceedsBalance: string;
        amountExceedsAccountBalance: string;
        accountIsPending: string;
        plaidReauthNeeded: string;
        plaidAuthenticationBroken: string;
        addYourBankAccount: string;
        addABankAccount: string;
        addASandboxBankAccount: string;
        pleaseWait: string;
        validatingTransfer: string;
        thereWasAProblem: string;
        pleaseTryAgain: string;
        unknownError: {
            title: string;
            subtitle: string;
        };
        fundingNotAllowed: {
            title: string;
            subtitle: string;
        };
        failedDailyFundingLimitCheck: {
            title: string;
            subtitle: string;
        };
        cosmosPlaidAccessFailed: {
            title: string;
            subtitle: string;
        };
        plaidBalanceCheckFailed: {
            title: string;
            subtitle: string;
        };
        cosmosPlaidLinkIdMatchFailed: {
            title: string;
            subtitle: string;
        };
        plaidIdentityInfoFailed: {
            title: string;
            subtitle: string;
        };
        likeKindNameCheckFailed: {
            title: string;
            subtitle: string;
        };
        previouslyLinkedAccountIdLookupFailed: {
            title: string;
            subtitle: string;
        };
        failedToLinkAccount: {
            title: string;
            subtitle: string;
        };
        transferWindowClosed: {
            title: string;
            subtitle: string;
        };
        transferFailed: {
            title: string;
            subtitle: string;
        };
        transferRequestFailed: string;
        submittedRequestSuccessfully: string;
        asyncInfo: string;
        depositsLockedError: {
            title: string;
            subtitle: string;
        };
        minimumDepositNotMet: {
            title: (amt: number) => string;
            subtitle: string;
        };
        maximumAccountsReached: {
            title: string;
            subtitle: string;
        };
        ainsInstructionsInactive: {
            title: string;
            subtitle: string;
        };
        maximumDepositsReached: {
            title: string;
            subtitle: (amt: number, days: number) => string;
        };
        maximumWithdrawalsReached: {
            title: string;
            subtitle: (amt: number, days: number) => string;
        };
        submittedSuccessfully: string;
        operationSubmittedSuccessfully: (transfer: Partial<TransferRequest>) => string;
        returnToSummary: string;
        startAnotherTransfer: string;
        bankingUnavailable: string;
        pleaseComeBackLaterOrContactUs: string;

        depositUnavailableExplanation: string;
        depositUnavailableDueToTimeExplanationShort: string;
        depositUnavailableDueToTimeExplanation: string;
        depositUnavailableDueHolidaysExplanationShort: string;

        withdrawUnavailableExplanationShort: string;
        withdrawUnavailableExplanation: string;
        withdrawUnavailableWeekendsExplanation: string;
        withdrawUnavailableHolidaysExplanation: string;

        accountPending: string;

        billPay: string;
        creditCardDirect: string;
        wiringInstructions: string;
        viewWiringInstructions: string;
        wiringInstructionsPdfUrl: string;
        verificationStatus: (status: string) => string;

        // Mobile
        selectAccount: string;
        forAddedSecurity: string;
        cannotAddLimitReachedTitle: string;
        cannotOpenPlaid: string;
        cannotAddLimitReachedExplainer: string;
        linkAcctContactSupport: string;
        confirmText: (transfer: Partial<TransferRequest>, snexAccount: Account, fundingSource?: FundingSource) => ShadedTextList;
        lost: { title: string; subtitle: string; startOver: string };
        authenticateWithPlaid: string;

        noBankAccounts: string;
        linkAnotherBankAccount: string;
        linkYourBankAccount: string;
        linkYourAccountToAddFunds: string;
        limitReached: string;
        limitReachedExplainer: (limits: FundingManagementLimits) => ShadedTextList;
        limitReachedExplainerV2: (maxAdd: number, maxVoid: number, voidDays: number) => ShadedTextList;
        youMayLinkNMoreAccounts: (nAllowed: number) => string;

        acats: {
            acatsComingSoon: {
                title: string;
                subtitle: string;
            };
            infoIcon: string;
            transferAccount: string;
            introText: string;
            from: string;
            accountNumber: string;
            accountTitle: string;
            accountSubtitle: string;
            to: string;
            start: string;
            continue: string;
            skip: string;
            back: string;
            submit: string;
            selectTransferType: string;
            fullTransfer: string;
            fullTransferDescription: string;
            partialTransfer: string;
            partialTransferDescription: string;
            cashOptions: string;
            transferAllCash: string;
            transferNoCash: string;
            transferCustomCash: string;
            positions: string;
            transferNoPositions: string;
            addPosition: string;
            cash: string;
            margin: string;
            all: string;
            custom: string;
            or: string;
            positionAccountType: string;
            positionQuantity: string;
            done: string;
            transferringAllCash: string;
            transferringNoneCash: string;
            transferringCustomCash: (amount: number) => string;
            transferringNoPositions: string;
            transferringFull: string;
            confirmTransfer: (type: 'Full' | 'Partial' | 'Unknown') => string;
            assets: string;
            noAssetsSelected: {
                title: string;
                subtitle: string;
                switchToFull: string;
            };
            submitting: { title: string; subtitle: string };
            success: { title: string; subtitle: string };
            failure: { title: string; subtitle: string };
            returnToSummary: string;
        };
    };

    newVersionScreen: {
        newVersion: string;
        nowAvailable: string;
        updateAndSee: string;
        update: string;
        notNow: string;
        accountInfoNotShared: string;
        privacyPolicyLearnMore: string;
    };

    messagesScreen: {
        messages: string;
        noMessages: string;
        errorFetchingMessages: string;
        viewAll: string;
        read: string;
        messageId: string;
        stoneXOneTeam: string;
        unknownSender: string;
        backToAllMessages: string;
        deleteAll: string;
        noMessagesTitle: string;
        noMessagesSubtitle: string;
        newMessage: string;
    };

    profileScreen: {
        profile: string;
        logOut: string;
        copyClipBoard: string;
        emailSettings: string;
        settings: string;
        about: string;
        build: string;
        version: string;
        snex1Premium: string;
        deactivate: string;
        errorDuplicateValue: string;
        tooltip: {
            oneProNetwork: string;
        },
        tabs: {
            personalInfo: string;
            accounts: string;
            paperless: string;
            local: string;
        };
        themes: {
            dark: string;
            light: string;
            system: string;
            marketHours: string;
        };
        personalInfo: {
            tabs: {
                profilePicture: string;
                personalDetails: string;
                preferences: string;
                notifications: string;
                taxInformation: string;
                premiumSubscription: string;
            };
            viewPersonalInfo: {
                title: string;
                name: string;
                email: string;
                birthDate: string;
            };
            // This probably shouldn't be here but it is what it is
            loginForm: {
                signup: string;
                next: string;
                code: string;
                codeSent: string;
                phoneNumber: string;
                tosAndPrivacyConsentText: ShadedTextList;
                verificationCodeSentMessage: string;
                confirmPassword: string;
                logIn: string;
                startTradingMessage: string;
                getStarted: string;
                wantMore: string;
                startTrading: (qsi: string) => string;
                couldNotLogIn: string;
                couldNotResetYourPassword: string;
                username: {
                    email: string;
                };
                password: {
                    forgotPassword: string;
                    resetPassword: string;
                    recoverAccount: string;
                    password: string;
                };
                confirmSignup: {
                    newUserTitle: string;
                    newUserSubtitle: (email: string) => string;
                    returningUserTitle: string;
                    returningUserSubtitle: string;
                };
                confirmEmployeeEmail: {
                    title: string;
                    subtitle: string;
                    androidInstructionsTitle: string;
                    androidInstructions: string;
                    continueAnyway: string;
                };
                mfa: {
                    title: string;
                    sendingCodePleaseWait: string;
                    mfaCodeSendMessage: string;
                    pleaseEnterYourPhone: string;
                };
                bioauth: {
                    biometrics: string;
                    whatBiometricsAre: string;
                    allUnlocksTitle: string;
                    allUnlocksDescription: string;
                    verificationOnlyTitle: string;
                    verificationOnlyDescription: string;
                    enableTitle: string;
                    enableDescription: string;
                    notDesiredTitle: string;
                    notDesiredDescription: string;
                    disableTitle: string;
                    disableDescription: string;
                    bioEnabledTitle: string;
                    bioEnabledDescription: string;
                    bioDisabledTitle: string;
                    bioDisabledDescription: string;
                    notpermitted: {
                        title: string;
                        message: string;
                        link: string;
                        dismiss: string;
                    };
                    disableBiometrics: string;
                    yesUpdateBiometrics: string;
                    passwordUpdateSuccess: string;
                    confirmReenableBioAuth: string;
                };
                country: {
                    countryOfCitizenship: string;
                };
                passwordReset: {
                    resetSent: string;
                    instructions: string;
                    returnToLogin: string;
                };
                whiteLabelAlert: {
                    title: string;
                    message: string;
                    dismiss: string;
                };
            };
            profile: string;
            firstName: string;
            lastName: string;
            emailAddress: string;
            email: string;
            smsPhone: string;
            notificationsDescription: string;
            sendEmail: string;
            sendText: string;
            smsNumber: string;
            addPhoneNumber: string;
            verificationNeeded: string;
            language: string;
            theme: string;
            themeDescription: string;
            oneClickTrading: string;
            oneClickTradingDescription: string;
            oneProNetwork: string;
            oneProNetworkDescription: string;
            futuresStrikes: string;
            optionsChainStrikes: string;
            systemDefault: string;
            dockSidePanel: string;
            dockSidePanelDescription: string;
            tradingViewTicker: string;
            tradingViewTickerDescription: string;
            timezone: string;
            edit: string;
            profilePictureDisclaimer: (maxMb: number) => string;
            removeProfilePicture: string;
            imageTooLarge: (maxMb: number) => string;
            unexpectedUploadError: string;
            idType: {
                idType: string;
                alienId: string;
                governmentId: string;
                passport: string;
                ssn: string;
                taxIdEin: string;
            };
            mdcVerify: {
                email: {
                    title: string;
                    inputText: string;
                    tryDifferent: string;
                    successText: string;
                };
                phone: {
                    title: string;
                    phone: string;
                    phoneNumber: string;
                    inputText: string;
                    tryDifferent: string;
                    successText: string;
                };
                pin: string;
                badPin: string;
                resendPin: string;
                unableToSendPin: string;
                unableToSendPinInstructions: (phoneNumber: string) => string;
                tryAgain: string;
                unexpectedError: string;
                alreadyHaveAVerificationCode: string;
                clickHereToEnterYourCode: string;
                updateButton: string;
                verifyButton: string;
                pinSentPleaseEnter: string;
                toRecieveTextNotifications: string;
                addPhoneNumber: string;
            };
            taxId: string;
            dateOfBirth: string;
            birthYear: string;
            birthMonth: string;
            birthDay: string;
            whyDoWeNeedThis: string;
            taxInfoExplainer: string;
            toUpdateThisInformation: string;
            okay: string;
            save: string;
            saving: string;
            saved: string;
            mdcControls: {
                notificationSettings: string;
                notificationSettingsToolTips: {
                    missingPhone: string;
                    invalidEventOrDeliveryMethod: string;
                };
                email: string;
                sms: string;
                smsSettings: string;
                pushNotifications: string;
                notifications: string;
                events: {
                    newOrderIsPlaced: string;
                    orderIsFilled: string;
                    orderIsPartiallyFilled: string;
                    orderIsCanceled: string;
                    customAlertFired: string;
                    orderIsRejected: string;
                    orderIsModified: string;
                    orderIsStopped: string;
                    orderIsSuspended: string;
                    orderIsOpenForBidding: string;
                    optionExpiringSoon: string;
                };
            };
            managePremium: {
                cancel: string;
                cancelModalText: (exp: string) => string;
                canceled: (exp: string) => string;
                confirmCancelation: string;
                get: string;
                loading: string;
                pitch: string;
                reactivate: string;
                subscribed: (exp: string) => string;
            };
        };

        accounts: {
            accounts: string;
            top: string;
            addNewAccount: string;
            accountApplicationStatus: string;
            accountInformation: {
                accountInformation: string;
                acctHolderInformation: string;
                accountName: string;
                address: string;
                altPhone: string;
                dateOfBirth: string;
                email: string;
                foreignId: string;
                homePhone: string;
                taxId: string;
            };
            registration: {
                registration: string;
                accountType: string;
                lastStatement: string;
                institutionCode: string;
                restrictionCode: string;
                accountStatus: string;
                accountOpened: string;
            };
            setup: {
                setup: string;
                marginAgreementOnFile: string;
                fplAgreementsOnFile: string;
                principalPayments: string;
                dividendReinvest: string;
                cashStandingInstructions: string;
                marginStandingInstructions: string;
                dividendStandingInstructions: string;
                calls: string;
                puts: string;
                price: string;
                noOptionsContracts: string;
                optionsLevel: {
                    optionsLevel: string;
                    disallowed: string;
                    level1: string; // Covered calls
                    level2: string; // Purchase Calls + Puts
                    level3: string; // Spreads / Long Straddles
                    level4: string; // Equity Put Writing
                    level5: string; // Uncovered call writing + Index put writing / short straddles
                    level6: string; // Uncovered index options
                };
            };
            investmentProfile: {
                investmentProfile: string;
                investmentObjective: string;
                netWorth: string;
                federalTaxBracket: string;
                liquidityNeeds: string;
                sourceOfFunds: string;
                occupation: string;
                stockExperience: string;
                bondExperience: string;
                optionExperience: string;
                mutualFundExperience: string;
                annuityExperience: string;
                investmentTimeHorizon: string;
            };
            accountDetails: {
                title: string;
                type: string;
                accountNumber: string;
                accountName: string;
                rename: string;
                startMargin: string;
                enableOptions: string;

                optionsTrading: string;
                optionsEnabled: string;
                optionsLevelsForPendingAcct: string;
                youveEnabledOptions: string;
                optionsNotEnabledDesc: string;

                marginAgreementAddedDesc: string;
                accountDoesNotHaveMarginDesc: string;

                corporateActionDesc: string;
                corporateActionsAvailable: string;
                corporateActions: string;
                viewCorporateActions: string;
                noCorporateActionsAvailable: string;

                gotoAccount: string;
                closeAccount: string;
                contactUsToCloseAccount: string;
                options: string;
                margin: string;
                changeOptionLevel: string;
                marginEnabled: string;
                unableToFindInformation: string;
                ohNo: string;
                execute: string;
                useButtonModify: string;
                renameAccount: string;
                cancel: string;
                general: {
                    general: string;
                    dateOpened: string;
                    lastStatement: string;
                    status: string;
                    restrictionCode: string;
                };
            };
        };

        paperless: {
            goPaperless: string;
            paperless: string;
            confirms: string;
            stonexCommunications: string;
            investorRelations: string;
            prospectus: string;
            statements: string;
            taxDocuments: string;
            goCompletelyPaperless: string;
            goPaperlessLine1: string;
            goPaperlessLine2: string;
            completelyPaperlessExplain: string;
            allMail: string;
            allEDelivery: string;
            allElectronic: string;
            mail: string;
            edelivery: string;
            paperlessButton: string;
            customizeOptions: string;
            youArePaperless: (percent: string) => string;
            paperlessPopup: {
                title: string;
                message: string;
                confirm: string;
            };
        };

        bioauth: {
            biometrics: string;
            whatBioauthIs: string;
            bioauthIsCurrentlyOnOff: (on: boolean) => ShadedTextList;
            clearMyDataAndTurnBioauthOff: string;
            turnBioauthOn: string;

            whatBiounlockIs: string;
            biounlockIsCurrentlyOnOff: (on: boolean) => ShadedTextList;
            turnBiounlockOff: string;
            turnBiounlockOn: string;

            errors: {
                unableToLogYouIn: string;
                somethingWentWrong: string;
                couldNotVerifyYourIdentity: string;
            };
            verifyYourLoginToActivate: string;
            verify: string;
            email: string;
            password: string;
        };

        changePassword: {
            changeFailedTitle: string;
            changeFailedMessage: string;
            validationFailedTitle: string;
            validationFailedMessage: string;
            authenticationFailedTitle: string;
            authenticationFailedMessage: string;
            tryAgain: string;
            contactSupport: string;
            changePassword: string;
            forgotPassword: string;
            currentPassword: string;
            newPassword: string;
            confirmPassword: string;
            updatePassword: string;
            passwordMinLength: (minLength: number) => string;
            passwordLetter: string;
            passwordRequirementsMessage: (minLength: number) => string;
            cannotReuseOldPassword: string;
            newPasswordConfirmMismatch: string;
            passwordRequirements: string;
            passwordRecoveryRequirements: (minLength: number) => string[];
        };
    };

    documentsScreen: {
        documents: string;
        filters: string;
        name: string;
        // symbolId: string,
        date: string;
        account: string;
        preview: string;
        download: string;
        share: string;
        moreActions: string;
        viewDocument: string;
        allAccounts: string;
        downloading: string;
        saveToDownloads: string;
        documentNotFound: string;
        docTypes: {
            taxDocuments: string;
            intlPublicForms: string;
            ifsNewAccts: string;
            marginRequirements: string;
            tradeConfirmations: string;
            checks: string;
            accountStatements: string;
            accountNotices: string;
            detailedVariationAnalysis: string;
            moneyOptionReport: string;
            memoStatement: string;
            monthlyDiscretionaryAccounts: string;
            annualStatement: string;
            quaterlyStatement: string;
            monthlyStatementExcel: string;
            monthlyStatement: string;
            dailyStatement: string;
            fullyPaidEarnings: string;
            fullyPaidLendingConfirms: string;
            fullyPaidLendingPositions: string;
        };
        downloadToasts: {
            savingUnavailableTitle: string;
            savingUnavailableDesc: string;
            permissionDenied: string;
            allowStonexSaveFiles: string;
            fileSavedTitle: string;
            fileSavedInDevice: (foldername: string) => string;
            savingErrorTitle: string;
            problemDownloading: string;
        };
        docNames: {
            statement: (doc: SnexDocument) => string;
            check: (doc: SnexDocument) => string;
            taxes: (doc: SnexDocument) => string;
            misc: (doc: SnexDocument) => string;
        };
        symbol: string;
        type: string;
        noDocumentsTitle: string;
        noDocumentsDescription: string;
        searchDocuments: string;
        goPaperless: string;
        modal: {
            loading: string;
            untitled: string;
        };
    };

    tradeHistory: {
        tradeHistory: string;
        filters: string;
        account: (accountNumber?: string) => string;
        allAssetClasses: string;
        equities: string;
        futures: string;
        futuresOptions: string;
        futuresTimeSpreads: string;
        mutualFunds: string;
        cryptos: string;
        allAccounts: string;
        invalidFilters: {
            popoverHeader: string;
            popoverBody: string;
            popoverActionLabel: string;
        };
        status: {
            status: string;
            assetClass: string;
            all: string;
            allStatuses: string;
            openAll: string;
            closed: string;
            closedAll: string;
            verification: string;
            created: string;
            open: string;
            route: string;
            routed: string;
            filled: string;
            cancel: string;
            canceled: string;
            rejected: string;
            deleted: string;
            expired: string;
            confirmed: string;
            unknown: string;
            pending: string;
            completed: string;
            cancelled: string;
            cancelPending: string;
            cancelPartialFill: string;
            allocated: string;
            preAllocated: string;
        };
        orders: string;
        symbol: string;
        downloadTooltip: string;
        accountTitle: string;
        fetchingError: string;
        noOrdersFound: string;
        noOrdersFoundDescription: string;

        downloadFilename: () => string;
    };

    transactionsScreen: {
        tooltips: {
            partialResults: string;
        };
        account: string;
        transactions: string;
        symbolId: string;
        date: string;
        amount: string;
        type: string;
        anyType: string;
        description: string;
        noTransactionsTitle: string;
        noTransactionsDesc: string;
        sendingOrder: string;
        tradeDescription: (transaction: Transaction) => string;
        expand: string;
        action: string;
        quantity: string;
        price: string;
        baseCurrency: string;
        securityType: string;
        asOfDate: string;
        tradeDate: string;
        settleDate: string;
        principal: string;
        interest: string;
        cancelStatus: string;
        rebillStatus: string;
        controlNumber: string;
        securityNumber: string;
        security: string;
        netAmount: string;
        processDate: string;
        defaultSort: string;
        ascending: string;
        descending: string;
        search: string;
        all: string;
        swipeUpSubmit: string;
        exportAll: (accountNumber: string) => string;
        filename: (accountNumber: string) => string;
        filters: {
            filters: string;
            sort: string;
            date: string;
        };
        activityType: {
            tradeActivity: string;
            cashAndEquivalents: string;
            dividendsAndInterest: string;
            options: string;
            receiveAndDeliver: string;
            exerciseAndExpiration: string;
            stockLoans: string;
            misc: string;
            deposit: string;
            withdrawal: string;

            trade: string;
            cash: string;
            dividentInterest: string;
            exerciseExpire: string;
            receiveDeliever: string;
            receiveDelieverAbbrev: string;
            funding: string;
            other: string;
        };
        accountType: {
            accountType: string;
            cash: string;
            longMargin: string;
            shortMargin: string;
            tefraWithhold: string;
            whenIssued: string;
            stockLoan: string;
            annuitiesAway: string;
        };
    };

    projectedIncomeScreen: {
        projectedIncome: string;
        symbol: string;
        saveFilter: string;
        reset: string;
        accountNumber: string;
        viewAll: string;
        pleaseSelectAnAccount: string;
        noDataAvailable: string;
        name: string;
        income: string;
        yield: string;
        paymentDate: string;
        nextPaymentDate: string;
        maturityDate: string;
        frequency: {
            frequency: string;
            semiAnnually: string;
            quarterly: string;
            monthly: string;
            annually: string;
        };
        projectedTotal: string;
        projectedFor: (qsi: string) => string;
        couponRate: string;
        type: string;
        downloadTooltip: string;
        downloadFilename: () => string;
        overall: string;
        selectAnAccountEmptyTitle: string;
        incomeBreakdown: string;
        totalProfits: string;
        noIncomeForMonth: string;
        selectAnotherPeriod: string;
        noIncomeProjection: string;
        noPositionSecurity: string;
    };
    disclosuresPage: {
        disclosures: string;
        documentContent: {
            pricingDisclosure: string[];
            projectedIncomeDisclosure: string[];
            marketIntelligenceDisclosure: ShadedTextList;
        };
        documentTitles: {
            termsAndConditions: string;
            pricingDisclosure: string;
            projectedIncomeAndYieldDisclosure: string;
            marketIntelligenceDisclosure: string;
            cryptoDisclosure: string;
            generalDisclosures: string;
        };
    };

    securityScreen: {
        advancedChart: string;
        advancedDomAndChart: string;
        deepAnalysis: string;
        tradeOptions: string;
        tradeFuturesSpreads: string;
        enableOptionTrading: string;
        changeOptionLevel: string;
        premarket: string;
        postmarket: string;
        afterhours: string;
        addToList: string;
        notOnAnyOfYourLists: string;
        lineChart: string;
        candlestick: string;
        showVolume: string;
        tomorrow: string;
        searchForSomethingElse: string;
        notFoundHeader: (qsi: string) => string;
        notFoundSubText: string;
        today: string;
        security: string;
        securities: string;
        viewAll: string;
        viewAllSpreads: string;
        viewFewer: string;
        viewMore: string;
        viewLess: string;
        chartDataComingSoon: string;
        problemLoadingSecuritiesFor: (qsi: string) => string;
        noRelatedSecuritiesFor: (qsi: string) => string;
        about: {
            about: (symbol: string) => string;
            noDescriptionAvailable: string;
            readMore: string;
            readLess: string;
            ceo: string;
            employees: string;
            headquarters: string;
            website: string;
            exchange: string;
            open: string;
            unavailable: string;
            whitepaper: string;
            reddit: string;
            coin: string;
        };
        stats: {
            stats: string;
            specifications: string;
            bid: string;
            ask: string;
            open: string;
            close: string;
            previousClose: string;
            previousSettle: string;
            high: string;
            low: string;
            previousVolume: string;
            volume: string;
            volume24h: string;
            averageVolume: string;
            averageVolume7d: string;
            week52High: string;
            week52Low: string;
            marketCap: string;
            peRatio: string;
            ytdChange: string;
            startTime: string;
            stopTime: string;
            endTime: string;
            contractSize: string;
            tickSize: string;
            tickPrice: string;
            initialMargin: string;
            dayTradeMargin: string;
            dayTradeMarginToolTip: string;
            maintenanceMargin: string;
            changePercent: string;
            low52: string;
            high52: string;
        };
        ratings: {
            analystRatings: string;
            asOf: string;
            noRatingsAvailable: string;
            analystRatingExplain: (secName: string, action: 'Buy' | 'Sell' | 'Hold' | string) => string;
            analystRatingExplainShaded: (secName: string, action: 'Buy' | 'Sell' | 'Hold' | string) => ShadedTextList;
            aConsensusOf: (totalNumberOfRatings: number, consensusDate: string | Date) => ShadedTextList;
            noRatingsDataAvailble: (secName: string) => string;
            problemLoadingRatings: (secName: string) => string;
            noRatingsAvailableFor: (secName: string) => string;
            buy: string;
            sell: string;
            hold: string;
            totalRatings: string;
        };
        earningsEstimates: {
            earningsAndEstimates: string;
            actual: string;
            estimated: string;
            actualEPS: string;
            estimatedEPS: string;
            quarter: (quarterNumber: number) => string;
            noData: (securityName?: string) => string;
            problemLoadingEarnings: (securityName: string) => string;
            noEarningsTimeline: (securityName: string) => string;
        };
        options: {
            options: string;
            optionsTitle: (symbol: string) => string;
            optionsSublabel: string;
            contracts: string;
            value: string;
            todaysGainLoss: string;
            totalGainLoss: string;
            account: string;
            exp: string;
            unknownExpiration: string;
            contractsLong: (quantity: number) => string;
            contractsShort: (quantity: number) => string;
            contract: (n: number) => string;
            timeSpreadsTitle: (symbol: string) => string;
            timeSpreadsSublabel: string;
            noTimeSpreadsSublabel: string;
        };
        history: {
            history: string;
            tradeHistory: string;
            orderHistory: string;
            viewMoreHistory: string;
        };
        related: {
            related: string;
            relatedEtfs: string;
            noRelatedStocks: string;
        };
        holdings: {
            account: string;
            accountsHolding: string;
            acct: (number: string) => string;
            assetsInFunds: string;
            averageCost: string;
            cost: string;
            marketValue: string;
            marketValueTooltip: string;
            marketValueTooltipFutures: string;
            portfolio: string;
            portfolioDiversity: string;
            positionPrice: string;
            quantity: string;
            share: (n: number) => string;
            shares: string;
            showAllAccounts: string;
            todaysReturn: string;
            topHoldings: string;
            totalReturn: string;
            viewMore: string;
            yourMarketValue: string;
            yourPosition: string;
            buyMore: string;
            shortMore: string;
            sellQsi: (qsi: string) => string;
            coverPosition: string;
            noTopHoldingAvailable: (security) => string;
        };
        openOrders: string;
        news: {
            news: string;
            marketIntelligence: string;
            andNews: string;
            newsUnavailable: string;
            /** @deprecated "MI" is not a well-known initialism */ newsAndMI: string;
            noNewsFor: (symbol?: string) => string;
        };
        crypto: {
            comingSoon: string;
            marketComparison: {
                title: string;
                pastYear: string;
            };
            socialActivity: {
                title: string;
                twitterFol: string;
                redditFol: string;
                githubFol: string;
            };
            popularity: string;
            circulatingSupply: string;
            totalSupply: string;
            maxSupply: string;
            coins: string;
        };
    };

    sectorScreen: {
        sectors: string;
        sectorNames: {
            consumerDiscretionary: string;
            healthCare: string;
            technology: string;
            realEstate: string;
            consumerStaples: string;
            utilities: string;
            communicationServices: string;
            financials: string;
            materials: string;
            industrials: string;
            energy: string;
        };
        symbol: string;
        name: string;
        price: string;
        change: string;
        changePercent: string;
        marketCap: string;
        item: string;
        items: string;
        securityChangeSelector: {
            rising: string;
            falling: string;
        };
        marketCapSelector: {
            large: string;
            mid: string;
            small: string;
            micro: string;
            nano: string;
            andBelow: string;
        };
    };

    fundScreen: {
        minimumInvestmentNotMet: (quantity: number) => string;
        profile: {
            about: string;
            benchmark: string;
            noDescription: string;
            indexTracked: string;
            category: string;
            netAssetValue: string;
            totalAssets: string;
            inceptionDate: string;
            beta: string;
            deferredLoad: string;
            expenseRatio: string;
            fee12b1: string;
            frontLoad: string;
            family: string;
            manager: string;
            maxRedepmtionFee: string;
            minimumInvestment: string;
            minimumInitialInvestment: string;
            aum: string;
            averageVolume: string;
            companySymbol: string;
            priceToBook: string;
            priceToEarnings: string;
            website: string;
            open: string;
            stats: string;
            bid: string;
            ask: string;
        };
        countryExposure: {
            countryExposureTitle: string;
            noExposureDataAvailable: string;
            country: string;
            exposure: string;
            countries: string;
            noCountryExposure: string;
        };
        sectorExposure: {
            sectorExposureTitle: string;
            noSectorExposure: string;
            sectors: string;
        };
        // Offshore
        Alternative: string;
        Equity: string;
        FixedIncome: string;
        Guaranteed: string;
        Hedge: string;
        High: string;
        Low: string;
        Medium: string;
        MoneyMarket: string;
        MultiAsset: string;
        Others: string;
        RealEstate: string;
        Sector: string;
        SpanishDomestic: string;
        Traditional: string;
        active: string;
        assetClass: string;
        cutoffTimeRedemptions: string;
        cutoffTimeRedemptionsDescription: string;
        cutoffTimeSubscriptions: string;
        cutoffTimeSubscriptionsDescription: string;
        fundCountry: string;
        fundCurrency: string;
        fundGroup: string;
        fundGroupDescription: string;
        fundHouse: string;
        fundHouseCountry: string;
        fundHouseDescription: string;
        geographicArea: string;
        geographicZone: string;
        inceptionDate: string;
        inceptionDateDescription: string;
        maximumAmount: string;
        maximumAmountDescription: string;
        minAdditionalAmount: string;
        minAdditionalAmountDescription: string;
        minInitialAmount: string;
        minInitialAmountDescription: string;
        notActive: string;
        preNotificationDaysRedemptions: string;
        preNotificationDaysRedemptionsDescription: string;
        preNotificationDaysSubscriptions: string;
        preNotificationDaysSubscriptionsDescription: string;
        redemptionsByAmount: string;
        redemptionsByAmountDescription: string;
        redemptionsByShares: string;
        redemptionsBySharesDescription: string;
        riskCategory: string;
        riskCategoryDescription: string;
        settlementDaysRedemptions: string;
        settlementDaysRedemptionsDescription: string;
        settlementDaysSubscriptions: string;
        settlementDaysSubscriptionsDescription: string;
        status: string;
        subAssetClass: string;
        subscriptionsByAmount: string;
        subscriptionsByAmountDescription: string;
        subscriptionsByShares: string;
        subscriptionsBySharesDescription: string;
        totalSettlementDaysRedemptions: string;
        totalSettlementDaysRedemptionsDescription: string;
        totalSettlementDaysSubscriptions: string;
        totalSettlementDaysSubscriptionsDescription: string;
        transferableFund: string;
        transferableFundDescription: string;
        typeOfFund: string;
        volatilityRange: string;
        volatilityRangeDescription: string;

        srri: string;
        potentiallyLowerReward: string;
        lowerRisk: string;
        higherRisk: string;
        potentiallyHigherReward: string;
        potentialRiskVsReward: string;
        riskIndicator: string;
        risk: string;
    };

    deepAnalysisScreen: {
        viewFull: {
            statement: string;
            balanceSheet: string;
            cashFlow: string;
            viewAll: string;
        };
        deepAnalysis: string;
        quarterly: string;
        annual: string;
        showMore: string;
        showLess: string;
        showAll: string;
        financials: string;
        loadingFinancials: string;
        sentimentAnalysis: string;
        noFinancialsFoundFor: (thing: string) => string;
        noDataFoundFor: (thing: string) => string;
        fundamentals: {
            fundamentals: string;
            projectedRevenue: string;
            dividends: string;
        };
        balanceSheet: {
            balanceSheet: string;
            reportDate: string;
            filingType: string;
            fiscalDate: string;
            fiscalQuarter: string;
            fiscalYear: string;
            fixedAssets: string;
            currency: string;
            currentCash: string;
            shortTermInvestments: string;
            receivables: string;
            inventory: string;
            otherCurrentAssets: string;
            currentAssets: string;
            longTermInvestments: string;
            propertyPlantEquipment: string;
            propertyPlantEquipmentShort: string;
            goodwill: string;
            intangibleAssets: string;
            otherAssets: string;
            totalAssets: string;
            accountsPayable: string;
            accountsReceivable: string;
            currentLongTermDebt: string;
            otherCurrentLiabilities: string;
            totalCurrentLiabilities: string;
            longTermDebt: string;
            otherLiabilities: string;
            minorityInterest: string;
            totalLiabilities: string;
            commonStock: string;
            retainedEarnings: string;
            treasuryStock: string;
            capitalSurplus: string;
            shareholderEquity: string;
            netTangibleAssets: string;
            longTermLiabilities: string;
        };
        incomeStatement: {
            incomeStatement: string;
            reportDate: string;
            filingType: string;
            fiscalDate: string;
            fiscalQuarter: string;
            fiscalYear: string;
            currency: string;
            totalRevenue: string;
            costOfRevenue: string;
            grossProfit: string;
            researchAndDevelopment: string;
            sellingGeneralAndAdmin: string;
            operatingExpense: string;
            operatingIncome: string;
            otherIncomeExpenseNet: string;
            ebit: string;
            interestIncome: string;
            interestIncomeNonOperating: string;
            interestExpense: string;
            pretaxIncome: string;
            incomeTax: string;
            minorityInterest: string;
            netIncome: string;
            netIncomeBasic: string;
        };
        cashFlow: {
            cashFlow: string;
            capitalExpenditures: string;
            cashChange: string;
            cashFlowFinancing: string;
            changesInInventories: string;
            changesInReceivables: string;
            currency: string;
            depreciation: string;
            dividendsPaid: string;
            exchangeRateEffect: string;
            filingType: string;
            fiscalDate: string;
            fiscalQuarter: string;
            fiscalYear: string;
            investingActivityOther: string;
            investments: string;
            netBorrowings: string;
            netIncome: string;
            otherFinancingCashFlows: string;
            reportDate: string;
            symbol: string;
            totalInvestingCashFlows: string;
        };
        earningsCalls: {
            earningsCalls: string;
            noEarningsCallsAvailable: string;
            play: string;
            playing: string;
            translateEarningsCall: (str: string) => string;
        };
        investors: {
            investors: string;
            insiders: string;
            institutions: string;
            funds: string;
            insideInvestors: string;
            institutionalInvestors: string;
            fundInvestors: string;
        };
        insiderRoster: {
            insiderRoster: string;
            noInsidersFound: (symbol: string) => string;
            shares: string;
            asOf: string;
        };
        topInsiders: string;
        advancedStats: {
            advancedStats: string;
            beta: string;
            totalCash: string;
            currentDebt: string;
            revenue: string;
            grossProfit: string;
            totalRevenue: string;
            ebitda: string;
            revenuePerShare: string;
            revenuePerEmployee: string;
            debtToEquity: string;
            profitMargin: string;
            enterpriseValue: string;
            enterpriseValueToRevenue: string;
            priceToSales: string;
            priceToBook: string;
            forwardPERatio: string;
            pegRatio: string;
            peHigh: string;
            peLow: string;
            week52HighDate: string;
            week52LowDate: string;
            week52HighDateSplitAdjustOnly: string;
            week52LowDateSplitAdjustOnly: string;
            putCallRatio: string;
        };
        priceTarget: {
            priceTarget: string;
            averagePriceTarget: string;
            range: string;
            low: string;
            median: string;
            high: string;
            average: string;
        };
        otherInvestors: {
            insititutionalInvestors: string;
            fundInvestors: string;
            holder: string;
            shares: string;
            asOf: string;
            value: string;
        };
        splits: {
            splits: string;
            noSplitsOnRecord: (symbol: string) => string;
            exDate: string;
            from: string;
            to: string;
            ratio: string;
            reverse: string;
            translateDescription: (desc: string) => string;
        };
        estimatedRevenue: {
            estimatedRevenue: string;
            noData: (symbol: string) => string;
            avg: string;
            high: string;
            low: string;
            nAnalysts: string;
            numberAnalysts: string;
            period: string;
            revenueAvg: string;
            revenueHigh: string;
            revenueLow: string;
        };
        dividends: {
            dividends: string;
            noData: (symbol: string) => string;
            mostRecentDividend: string;
            dividendForDate: string;
            amount: string;
            currency: string;
            declaredDate: string;
            exDate: string;
            flag: string;
            frequency: string;
            paymentDate: string;
            recordDate: string;
            symbol: string;
            date: string;
        };
    };

    // Option List Screen
    optionListScreen: {
        ask: string;
        askValue: string;
        bid: string;
        bidValue: string;
        breakEven: string;
        buy: string;
        calendarSpreads: string;
        call: string;
        change: string;
        changePercent: string;
        close: string;
        contractsYouSelect: string;
        expirationDate: string;
        high: string;
        impliedVolatility: string;
        intrinsicValue: string;
        lastPrice: string;
        low: string;
        open: string;
        openInterest: string;
        price: string;
        put: string;
        sell: string;
        sharePrice: string;
        strikePrice: string;
        toBreakEven: string;
        tradeFutures: string;
        tradeOptions: string;
        tradeStock: string;
        theGreeks: string;
        disclaimer: string;
        optionGreeksTooltipIntro: string;
        optionGreeksTooltipDelta: string;
        optionGreeksTooltipGamma: string;
        optionGreeksTooltipTheta: string;
        optionGreeksTooltipVega: string;
        optionGreeksTooltipRho: string;
        optionGreeksDelayed: string;
        viewMore: string;
        viewOptionChain: string;
        optionChain: string;
        volume: string;
        floatingButtonText: (qsi: string) => string;
    };
    greeks: {
        delta: {
            text: string;
            symbol: string;
            tooltip: string;
        };
        gamma: {
            text: string;
            symbol: string;
            tooltip: string;
        };
        theta: {
            text: string;
            symbol: string;
            tooltip: string;
        };
        vega: {
            text: string;
            symbol: string;
            tooltip: string;
        };
        rho: {
            text: string;
            symbol: string;
            tooltip: string;
        };
    };

    enableOptionsScreen: {
        getStartedWithOptions: string;
        title: string;
        enableOptions: string;
        noOptionsEnabled: string;
        enableOptionsTrading: string;
        notNow: string;
        noTradingOptionsEnabled: string;
    };

    optionContractScreen: {
        expiresOnDate: (date: Date) => string;
        expiresSoon: string;
        symbolLastQuote: (symbol: string) => string;
        tradeUnderlying: (symbol: string) => string;
        viewAllOptions: string;
        viewMoreDetails: string;
        viewMoreHistory: string;
        history: string;
        addLeg: string;
        removeLeg: string;
        stats: {
            stats: string;
            bid: string;
            ask: string;
            previousClose: string;
            breakEven: string;
            open: string;
            close: string;
            high: string;
            low: string;
            volume: string;
            openInterest: string;
            impliedVolatility: string;
            intrinsicValue: string;
        };
    };

    futuresContractScreen: {
        baseContract: string;
        expiresDate: (date: string | Date) => string;
        upgradeToPremium: string;
        viewMoreContracts: string;
        domNotInSession: {
            title: (symbol: string) => string;
            message: string;
        };
    };

    advancedChartScreen: {
        notifications: {
            orderNotPlaced: string;
            invalidSecurity: string;
            invalidAccount: string;
            shortSellNotAllowed: string;
        };
    };

    // Trade ticket
    tradeTicket: {
        adjustedOptionsDisclaimer: string;

        betaMaintenanceHours?: string;
        cancelEquityOrdersHours?: string;
        modifyEquitiesDayOrdersHours?: string;
        modifyEquitiesGtcOrdersHours?: string;
        timeFrames?: string;

        buyAll: string;
        oneClickTradingDescription: string;
        oneClickTradingTitle: string;
        position: string;
        sellAll: string;
        misc: {
            noChangesToReview: string;
            securityUnavailable: (symbol: string) => string;
            securityTypeUnavailable: (type: TradeableSecurityType | 'loading') => string;
            startTradingOptions: string;
            enableOptionsTradingNow: string;
            createFuturesAccount: string;
            createEquitiesAccount: string;
            accountPending: string;
            applicationPending: string;
            notAvailableToTrade: string;
            noPricing: string;
            noPricingCall: string;
            notAvailableToEdit: string;
            isUnderMaintenance: string;
            overnightMarginCost: string;
            cannotEdit: string;
            isHoliday: string;
            isWeekend: string;
            notInSessionGeneral: string;
            notInSession: (start: string, end: string) => string;
            notInSessionOptions: (start: string, end: string, timezone: string) => string;
            notInSessionOptionsSymbolic: (start: string, end: string, timezone: string) => string;
            notInSessionFutures: (start: string, end: string) => string;
            notInSessionFuturesOptions: (start: string, end: string) => string;
            notInSessionShortSell: string;
            residualSharesWillBeSettled: string;
            orderExtendedHoursError: (orderType: string) => string;
            extendedHoursMustBeChecked: string;
            badTimeInForce: string;
            badTimeForStrat: string;
            premarketRequired: string;
            postmarketRequired: string;
            invalidEditTimeAdvisory: string;
            minimumNotMetTitle: string;
            minimumNotMetSubtext: (minimumInvestment: number) => string;
            closedToNewInvestors: string;
            validatingOrder: string;
            setQuantity: string;
        };

        input: {
            retry: string;
            cancel: string;
            notes: string;
            tradeNotPermitted: string;
            orderNotAllowed: string;
            cannotShort: string;
            invalidQuantity: string;
            strategy: string;
            x1pAlgo: string;
            care: string;
            careDescription: string;
            vwap: string;
            vwapDescription: string;
            twap: string;
            twapDescription: string;
            pov: string;
            povDescription: string;
            sor: string;
            sorDescription: string;
            applyForMargins: string;
            unknownTitle: (isError: boolean) => string;
            unknownMessage: (isError: boolean) => string;

            tradeNow: string;
            unitName: (symbol: string) => string;
            futures: {
                enforceFractional: string;
                enforceTickSize: (tickSize: number) => string;
                numberPerContract: (formattedNumber: string) => string;
                side: string;
            };
            orderType: {
                limit: string;
                loc: string;
                market: string;
                moc: string;
                stop: string;
                stoplimit: string;
            };
            selectAnAccount: string;
            shares: string;
            tradeWholeShares: string;
            tradeDollarAmount: string;
            securityCannotBeTraded: string;
            bid: string;
            ask: string;
            mid: string;
            vol: string;
            held: (symbol: string) => string;
            options: {
                breakEven: string;
                credit: string;
                debit: string;
                even: string;
                last: string;
                netAsk: string;
                netBid: string;
                midpoint: string;
                underlyingShares: string;
                contracts: string;
                sharesPerContract: (n: number) => string;
                actionToOpenClose: (t: Partial<TradeRequest>, openClose: OptionsOpenClose) => string;
                unlimited: string;
                maxGain: string;
                maxLoss: string;
                enableOptionsTrading: string;
                upgradeOptionsLevel: string;
            };
            advisories: {
                notice: string;
                immediateExecution: {
                    orderPriceError: (priceName: 'limit' | 'stop', priceIs: 'above-market' | 'below-market') => string;
                    stopLimitPriceError: (priceIs: 'above-stop' | 'below-stop') => string;
                };
                insufficientValue: {
                    notEnoughBuyingPower: string;
                    notEnoughMarketValue: string;
                    depositFunds: string;
                    dismiss: string;
                };
                invalidStopOrder: {
                    buy: string;
                    sell: string;
                };
                amountIsEstimate: {
                    mainText: string;
                    subText: string;
                };
                notionalValueExceeded: {
                    mainText: string;
                    subText: string;
                };
            };
            dollars: string;
            limitPrice: string;
            stopPrice: string;
            stopLimitPrice: string;
            timeInForce: string;
            timesInForce: {
                day: string;
                gtc: string;
                fok: string;
                ioc: string;
            };
            advancedRouting: {
                notHeld: string;
            };
            dvp: string;
            extendedHours: {
                GTXPre: string;
                GTXPost: string;
                nte: string;
            };
            marketPrice: string;
            expiration: string;
            totalCost: string;
            estimatedSymbolAmount: (symbol: string) => string;
            estimatedTotalCredit: string;
            estimatedTotalCost: string;
            totalGain: string;
            estimatedTotalGain: string;
            buyingPower: string;
            marketValue: string;
            reviewAction: (trade: Partial<TradeRequest>) => string;
            sellAll: (trade: Partial<TradeRequest>, holdingsAmount: number) => string;
            reviewEdit: (trade: Partial<TradeRequest>) => string;
            buySymbol: (symbolName: string) => string;
            sellSymbol: (symbolName: string) => string;
            action: {
                buy: string;
                sell: string;
                tabFontSize?: number;
            };
            reviewTrade: string;
            buyingPowerAdvisory: (trade: Partial<TradeRequest>) => string;
            depositFunds: string;
            addFundsToContinue: string;
            dismiss: string;
            error: string;
            warning: string;
            resubmit: string;
            submitAnyway: string;
            submitShort: string;
            continue: string;
            reviseOrder: string;
        };
        reinvestmentOptions: {
            reinvestmentOptions: string;
            instructions: (trade: Partial<TradeRequest>) => string;
            mutualFundInstructions: (trade: Partial<TradeRequest>) => string;
            offshoreCashInstructions: (accountNumber: string, isin: string) => string;
            offshoreReinvestInstructions: (accountNumber: string, isin: string) => string;
            review: string;
            dividends: string;
            shortTermCapitalGains: string;
            longTermCapitalGains: string;
            editButtonText: string;
            saveButton: string;
            reinvest: string;
            mutualFundsDistribution: string;
            usePreferences: string;
            shortTermsGains: string;
            longTermGains: string;
            shortTerm: string;
            longTerm: string;
            reinvestScreenDesc: (symbol: string, accountNumber: string) => string;
        };

        review: {
            confirmTrade: string;
            gtxDisclaimer: string;
            offshoreDisclaimer: string;
            /** @deprecated Use equitiesV2 instead, will be replaced in the future */
            equities: (
                priceFormatter: (value?: number) => string,
                trade: Partial<TradeRequest>,
                currentMarketPrice: number,
                totalCost: number,
                limitPrice?: number,
                stopPrice?: number
            ) => ShadedTextList;
            tifQualifier?: (trade: Partial<TradeRequest>, formattedSymbol) => string | ShadedTextList;
            actionText?: (
                trade: Partial<TradeRequest>,
                assetClass?: AssetClass,
                secMeta?: SecurityMetadata
            ) => { actionWording: string; actionWordVerb: string; actionWordNoun: string; xUnits: string; unit: string };
            equitiesV2?: (
                assetClass: AssetClass,
                trade: Partial<TradeRequest>,
                secMeta: SecurityMetadata,
                currentMarketPrice: number,
                totalCost: number,
                limitPrice?: number,
                stopPrice?: number
            ) => ShadedTextList;
            sharesV2?: (assetClass: AssetClass, trade: Partial<TradeRequest>, secMeta: SecurityMetadata, purchasePrice: number, sharePrice: number) => ShadedTextList;
            dollarsV2?: (assetClass: AssetClass, trade: Partial<TradeRequest>, secMeta: SecurityMetadata, sharePrice: number) => ShadedTextList;
            futuresV2?: (assetClass: AssetClass, trade: Partial<TradeRequest>, secMeta: SecurityMetadata) => ShadedTextList;
            futuresSpreads?: (assetClass: AssetClass, trade: Partial<TradeRequest>, secMeta: SecurityMetadata) => ShadedTextList;
            cryptos?: (assetClass: AssetClass, trade: Partial<TradeRequest>, secMeta: SecurityMetadata, purchasePrice: number, sharePrice: number) => ShadedTextList;
            reinvestmentOptions: {
                allWillBeReinvested: ShadedTextList;
                allWillBeCashed: ShadedTextList;
                willBeReinvested: (labels: string[]) => ShadedTextList;
                willBeCashed: (labels: string[]) => ShadedTextList;
            };
        };

        receipt: {
            tradeReceipt: string;
            orderSummary: string;
            orderType: {
                orderType: string;
                marketOrder: string;
                mocOrder: string;
                limitOrder: string;
                locOrder: string;
                limitOrderMinMax: (sell: boolean) => string;
                stopOrder: string;
                stopLimitOrder: string;
                marketOrderDesc: string;
                limitOrderDesc: string;
                stopOrderDesc: string;
                stopLimitOrderDesc: string;
                tradeCantBePlaced: string;
                setStop: string;
                setLimit: string;
                setStopLimit: string;
                buyShareMaxPrice: string;
                sellSharesMaxPrice: string;
                triggerMarket: (orderAction: string) => string;
                triggerLimit: (orderAction: string) => string;
            };
            marketPrice: string;
            limitPrice: string;
            stopPrice: string;
            stopLimitPrice: string;
            numberOfUnits: (symbol: string) => string;
            submittingOrder: string;
            pleaseWait: string;
            youMayClickAway: string;
            orderSubmitted: string;
            checkForStatusUpdatesUnderPositions: string;
            thereWasAProblem: string;
            pleaseTryAgain: string;
            submitAnotherOrder: string;
            estimatedTotalCost: string;
            estimatedTotalGain: string;
            estimatedTotalCredit: string;
            estimatedTotalDebit: string;
            leg1: string;
            leg2: string;
        };
        afterHours: {
            disclosure: {
                title: string;
                shortDisclosure: string;
                longDisclosure: ShadedTextList;
            };
        };
        shortSales: {
            acceptance: (shares: number, rate: number) => ShadedTextList;
            thisWillShortYou: (quantityShort: number, rate: number) => ShadedTextList;
            disclosureTitle: string;
            disclosure: (isExpanded: boolean) => string;
            viewMore: string;
            viewLess: string;
        };
        offshore: {
            disclosure: {
                title: string;
                shortDisclosure: string;
                longDisclosure: ShadedTextList;
            };
        };
        crypto: {
            disclosure: {
                title: string;
                shortDisclosure: string;
                longDisclosure: ShadedTextList;
            };
        };
    };

    // Order List
    orders: {
        accountNumber: string;
        account: string;
        acct: string;
        contractSymbol: string;
        dateAndTime: string;
        filledAmount: string;
        fillPrice: string;
        onClose: string;
        order: string;
        openOrders: string;
        tradeHistory: string;
        orderHistory: string;
        tradeConfirmation: string;
        expirationDate: string;
        true: string;
        spreadSymbol: string;
        buySide: string;
        sellSide: string;
        completedDate: string;
        fees: string;
        averageTradePrice: string;

        stratValue: (strat: ApiTradeStrategy) => string;

        numberFilledOfTotal: (o: Order) => string; // "500 of 1,000 filled" (don't include decimals)

        actionSummary: ({
            format,
            limitPrice,
            order,
            quantity,
            stopPrice
        }: {
            format?: 'short' | 'long';
            limitPrice: string;
            order: Order;
            quantity: string;
            stopPrice: string;
        }) => string;

        /** @deprecated Please use actionSummary */ actionSummary2: (
            o: Order,
            options?: Partial<{
                decimalPlaces: number;
                secType?: string;
                format: 'long' | 'short';
                assetClass;
            }>,
            hideMoneySymbol?: boolean,
            formatValue?: (value: number) => string
        ) => string; // Limit Buy QUANTITY @ PRICE
        /** @deprecated Please use quantityPriceSummary */ quantityPriceSummary2: (
            o: Order,
            options?: Partial<{
                decimalPlaces: number;
                secType?: string;
                format: 'long' | 'short';
            }>,
            hideMoneySymbol?: boolean,
            formatValue?: (value: number) => string
        ) => string; // QUANTITY @ PRICE
        action: (action: string, secType?: string) => string; // Buy
        multiLegAction: (o: Order) => string; // Buy 1, Sell 1
        typedAction: (o: Order, secType?: string) => string; // Limit Buy
        fullActionTypeToOpenOrClose: (o: Order) => string; // Buy To Open, Buy To Close, etc.

        orderSetup: {
            orderSetup: string;
            expires: string;
            orderType: string;
            quantityType: string;
            notional: string;
            algorithm: string;
            stratPercent: string;
            sellAll: string;
            done: string;
            discardTitle: string;
            discardChanges: string;
            discardChangesMsg: string;
            stayHere: string;

            action: string;
            actionBottomSheet: {
                buySell: string;
                sellBuy: string;
                buyTheEarlierAndSellTheLater: string;
                sellTheEalierAndBuyTheLater: string;
                buying: string;
                selling: string;
            };

            expiresBottomsheet: {
                expires: string;
                goodThruTitle: string;
                goodThruSubtitle: string;
                goodTillCancelled: string;
                goodTillCancelltedSubtitle: string;
                dayOrdersUnavailable: string;
                gtcOrdersUnavailable: string;
                premarketUnavailable: string;
                postMarketUnavailable: string;

                preMarketExecution: string;
                preMarketActive: string;
                postMarketExcution: string;
                postMarketActive: string;
                goodTilCancelActive: string;
                goodThruDayActive: string;

                fillOrKillExecution: string;
                fillOrKillActive: string;
            };
        };
        securityName: string;
        placedBy: string;

        quantity: string;
        commission: string;
        stopPrice: string;
        limitPrice: string;
        totalCost: string;
        orderTotalImpactLabel: (action: 'Buy' | 'Sell' | string) => string;
        placed: string;
        swipeUpTrade: string;
        by: (who: string) => string;
        timeInForce: string;
        gtxPre: string;
        gtxPost: string;
        isin: string;
        strategy: string;
        averagePrice: string;
        actionLabel: string;

        orderNumber: string;
        orderNumbers: string;
        orderId: string;
        contactRepToChange: string;
        cannotBeCanceled: string;
        lockedForOpen: string;
        cancelOrder: string;
        cancelingOrder: string;
        orderCanceled: string;
        orderModified: string;
        orderFilled: string;
        orderPartiallyFilled: string;
        orderPlaced: string;
        orderRejected: string;
        orderStopped: string;
        orderSuspended: string;
        orderBiddingOpen: string;
        editOrder: string;
        cannotCancelEditOrder: (action: string) => string;
        cannotCancelEditSublabel: (action: string) => string;
        contactLabel: (useSupport: boolean) => string;
        contactSubLabel: (onlyEdit: boolean, useSupport: boolean, editAndCancel?: boolean) => string;
        partialFill: (symbol: string, filled: number, quantity: number) => string;
        fills: {
            sequenceNumber: string;
            price: string;
            quantity: string;
            timestamp: string;
        };
        statuses: {
            open: string;
            working: string;
            pending: string;
            completed: string;
            filled: string;
            cancelled: string;
            cancelPending: string;
            cancelPartialFill: string;
            allocated: string;
            preAllocated: string;
            created: string;
            expired: string;
            route: string;
            routed: string;
            verification: string;
            change: string;
            notProcessed: string;
            processed: string;
            confirmed: string;
            cancel: string;
            canceled: string;
            rejected: string;
            unknown: string;
            deleted: string;
        };
    };

    messages: {
        subjectByPayload: {
            'System:AdminBroadcast': string;
            'System:AdminDirect': string;
            'System:Welcome': string;
            'CustomAlert:Default': string;
            'OrderUpdate:New': string;
            'OrderUpdate:Filled': string;
            'OrderUpdate:Rejected': string;
            'OrderUpdate:Canceled': string;
            defaultOrderUpdate: string;
        };
    };

    tours: {
        stepsBadgePreposition: string;
        lastStepButtonText: string;
        portfolioScreen: {
            chart: FormattedTextList;
            watchlist: FormattedTextList;
            depositFunds: FormattedTextList;
            search: FormattedTextList;
            profile: FormattedTextList;
        };
        stockScreen: {
            candlestick: FormattedTextList;
            advancedChart: FormattedTextList;
            addToWatchlist: FormattedTextList;
            orderType: FormattedTextList;
            optionsTrading: FormattedTextList;
        };
        premiumStockScreen: {
            advancedChart: FormattedTextList;
            alerts: FormattedTextList;
            deepAnalysis: FormattedTextList;
            streamingNews: FormattedTextList;
        };
        tickerBar: {
            tradingViewMarquee: FormattedTextList;
        };
    };

    mobileTours: {
        summaryScreen: {
            watchlist: {
                text: string;
                name: string;
            };
        };
        securityScreen: {
            alerts: {
                text: string;
                name: string;
            };
            chartToggle: {
                text: string;
                name: string;
            };
            advancedChart: {
                text: string;
                name: string;
            };
            trade: {
                text: string;
                name: string;
            };
        };
        navigateToursAlert: {
            title: string;
            message: string;
        };
    };

    newFeatures: {
        advancedOptionTrading: {
            title: string;
            subtitle: string;
            item1Title: string;
            item1Subtitle: string;
            item2Title: string;
            item2Subtitle: string;
            item3Title: string;
            item3Subtitle: string;
        };
        cryptoTrading: {
            title: string;
            subtitle: string;
            item1Title: string;
            item1Subtitle: string;
            item2Title: string;
            item2Subtitle: string;
            item3Title: string;
            item3Subtitle: string;
        };
    };

    miniplayer: {
        loading: (title: string | null) => string;
        noMediaAvailable: string;
        noTranscriptAvailable: string;
        viewTranscript: string;
    };

    modals: {
        done: string;
        addManyToListContent: {
            title: string;
            button: string;
            addSecurity: string;
        };
        addToWatchlist: {
            addSymbolToWatchlist: (symbol: string) => string;
            createAList: string;
            newListName: string;
            create: string;
            creating: string;
        };
        viewWatchlistDetails: string;
        appIdle: {
            modalHeader: string;
            noticedInactivity: string;
            logoutInSeconds: (seconds: number) => string;
            logout: string;
            stay: string;
        };
        deleteWatchlist: {
            deleteThisList: string;
            /** @deprecated Please use areYouSureShaded */ areYouSure: (listName: string) => FormattedTextList;
            areYouSureShaded: (listName: string) => ShadedTextList;
            yesDeleteList: (listName: string) => string;
            delete: string;
            deleting: string;
        };
        editWatchlistDetails: {
            editList: (listName: string) => string;
            name: string;
            description: string;
            save: string;
            saving: string;
        };
        deleteAllMessages: {
            label: string;
            body: string;
            yes: string;
            no: string;
        };
        cancelOrder: {
            orderCancelled: string;
            cancelOrder: string;
            cancelOrderFailed: string;
            retry: string;
            areYouSure: string;
            cancelLabel: string;
            ctrlKeyTip: FormattedTextList;
            cancel: string;
            retryCancel: string;
            canceling: string;
        };
        rename: {
            rename: string;
            save: string;
            saving: string;
        };
        errorModal: {
            unknownError: string;
        };
        existingAccountModal: {
            cannotRemoveAcctTitle: string;
            cannotRemoveAcctDesc: string;
            cannotRemoveAcctConfirm: string;
            removeLinkedAcctTitle: string;
            removeLinkedAcctConfirm: (name: string) => string;
            yesRemoveAcct: string;
            noKeppAcct: string;
            loadingYourAcct: string;
            voidingAcct: string;
            addAnAcct: string;
        };
        linkedAccountsModal: {
            retrivingLinkableAcct: string;
            linkingAcctWait: string;
        };
        plaidModal: {
            plaidCompleted: string;
            overlimitAccounts: string;
            holdTight: string;
            verifyingBankAuth: string;
        };
    };

    toasts: {
        error: string;
        sessionExpired: string;
        logInAgain: string;
        orderSubmitted: string;
        orderSubmittedContent: (trade: Partial<Order>) => string;
        orderUpdate: (update: SnexOrderUpdateMessagePayload, message: SnexMessage) => string;
        orderUpdateTitle: (update: SnexOrderUpdateMessagePayload, message: SnexMessage) => string;
        onboardingSuccess: () => string;
        onboardingSuccessTitle: (accountNumber: string) => string;
    };

    infoIconHelpText: {
        client: InfoIconBundle;
        one: InfoIconBundle;
    };

    errors: {
        imageUploadError: string;
        accessRestricted: string;
        loginDead: string;
        loginDeadWithDetail: (getIntoWhat: string, email: string) => string;
        noDataFound: string;
        trade: {
            generalTitle: string;
            generalSubmit: string;
            generalVerify: string;
            restrictedError: string;
            cantVerify: string;
            dimeProduct: string;
            nickelProduct: string;
            pennyProduct: string;
            securityOutOfSession: string;
            accountRestricted: string;
            securityUnavailable: string;
            fundTradingDenied: string;
            basicTradingDenied: string;
            noBuy: string;
            noOptionPosition: string;
            optionAgreement: string;
            optionLevel: string;
            optionPermission: string;
            offHours: string;
            generalNotAvailable: string;
            notAvailable: (product: string) => string;
            sellStopLessThanMarket: string;
            buyStopGreaterThanMarket: string;
            thisIsAShortOrder: string;
            adjustedCloseOnly: string;
            adjustedCloseOnlyMessage: string;
            insufficientShortQuantity: (qty: number) => string;
            sxDenied: string;
            shortNotAllowed: string;
            mutualFundQrpOnly: string;
            mutualFundNoDomesticOnForeign: string;
            mutualFundNoForeignOnDomestic: string;
        };
        alerts: {
            duplicateRuleTitle: string;
            duplicateRuleMessage: string;
            somethingWentWrongMessage: string;
            unableToVerifyCode: string;
            temporarilyUnavailable: string;
            alertsTemporarilyUnavailableMessage: string;
        };
    };

    warnings: {
        pretradeDisclosures:{
            // equities: string; 
            // futures: string
            cryptos: string;
        }
        trade: {
            cannotAfford: (trade: Partial<TradeRequest> | ApiTradeRequest, ac?: AssetClass) => string;
            shortsNotPermittedWhenLong: (trade: Partial<TradeRequest> | ApiTradeRequest) => string;
            shortNotPermittedAccountValue: (trade: Partial<TradeRequest> | ApiTradeRequest) => string;
            shortsNotPermittedWhenAccountNotMargin: (trade: Partial<TradeRequest> | ApiTradeRequest) => string;
            buyExceedsShortsHeld: (trade: Partial<TradeRequest> | ApiTradeRequest) => string;
            insufficientQuantity: string;
            invalidQuantity: string;
            invalidQuantityOptions: string;
            minimumNotMet: string;
            possibleDuplicate: string;
            orderGreaterThanPosition: string;
            accountChangedToMargin: string;
            accountChangedToCash: string;
            foreignAcctDomesticFunds: string;
            optionLevel: string;
            nextDayOrder: string;
            continue: string;
            optionsLowVolumeWarning: string;
            isShort: string;
            cutoffTime: string;
            acknowledgeTitle: string;
        };
    };

    footer: {
        global: {
            chatWithSupport: string;
            disclosures: FormattedTextList;
            disclosuresByEnvironement: (env: Environment) => FormattedTextList;
            copyrightLine: FormattedTextList;
            importantDisclosures: string;
            marketDataDisclosure: string;
            openAnAccount: string;
            privacy: string;
            systemRequirements: string;
            termsOfUse: string;
        };
        disclosurePdfUrl: string;
    };

    mobileUi: {
        bottomBar: {
            summary: string;
            cash: string;
            browse: string;
            messages: string;
        };
    };

    alerts: {
        addCondition: string;
        saveAlert: string;
        updateAlert: string;
        editExistingAlert: string;
        createAlert: string;
        createAnAlertToSeeItHere: string;
        interpretConditions: (field: 'latestPrice' | 'changePercent' | 'Last', operator: '>' | '>=' | '<' | '<=', valueNumberString: string) => string;
        priceAlert: string;
        youDontHaveAlerts: string;
        mutualFundsUnavailable: string;
        futuresUnavailable: string;
        createAlertSheet: {
            selectCondition: string;
            change: string;
            latestPrice: string;
            priceAlert: string;
            createAlert: string;
            updateAlert: string;
            deleteAlert: string;
            alert: string;
            updateAlertFor: (symbol: string) => string;
            target: string;
            searchForSecurity: string;
            chooseSecurity: string;
            greaterThan: string;
            greaterThanEqual: string;
            lessThan: string;
            lessThanEqual: string;
            alertsTitle: string;
            newAlert: string;
            searchSecurity: string;
            createNewAlertRule: string;
            noAlertsYet: string;
            editSymbol: string;
            repeatDaily: string;
            instructions: {
                createAlertBtn: string;
                lookUpSecurity: string;
                getNotified: string;
            };
        };
    };

    equitiesMarginOnboarding: {
        title: string;
        agreementNotAllowed: string;
        contactSupport: string;
        byChoosing: string;
        understandRisk: string;
        agreementDescription: string;
        close: string;
        agreeAndContinue: string;
        reviewAgreement: string;
        submitted: string;
        failed: string;
    };

    futuresOnboarding: {
        sfi: string;
        openDocumentToEnable: string;
        openAccount: string;
        openAccountNotAllowed: string;
        openAccountComingSoon: string;
        stepLabels: string[];
        welcome: (name: string) => string;
        hasReachedMax: string;
        welcomeSubtitle: string;
        nextButton: string;
        returnHome: string;
        disabledNextText: string;
        submitButton: string;
        goBackButton: string;
        yesButton: string;
        noButton: string;
        missingRequiredData: string;
        submitMessage: string;
        submitMessageTitle: string;
        successMessage: (account: string) => string;
        errorMessage: string;
        tryAgain: string;
        okButton: string;
        closeButton: string;
        accountDelayMessage: string;
        contactInfo: {
            name: string;
            firstName: string;
            lastName: string;
            title: string;
            email: string;
            phone: string;
            street: string;
            streetAddress: string;
            street2: string;
            city: string;
            state: string;
            country: string;
            zip: string;
            error: string;
        };
        personalInfo: {
            title: string;
            citizenQuestion: string;
            citizenYes: string;
            citizenNo: string;
            ssnQuestion: string;
            ssnYes: string;
            ssnNo: string;
            ssn: string;
            country: string;
            dob: string;
            governmentId: string;
        };
        accountInfo: {
            title: string;
            accountType: string;
            accountTypeSubtitle: string;
            sourceOfFunds: string;
            intendedInitialDeposit: string;
            expectedMonthlyTrading: string;
            sourceOfFundsOptions: DropdownOption[];
            intendedInitialDepositOptions: DropdownOption[];
            expectedMonthlyTradingOptions: DropdownOption[];
        };
        employmentInfo: {
            title: string;
            status: string;
            employedTitle: string;
            employedSubtitle: string;
            studentTitle: string;
            studentSubtitle: string;
            unemployedTitle: string;
            unemployedSubtitle: string;
            retiredTitle: string;
            retiredSubtitle: string;
            employerAddress: {
                title: string;
                country: string;
                street: string;
                street1: string;
                city: string;
                state: string;
                zip: string;
            };
            employerDetailsTitle: string;
            occupationTitle: string;
            name: string;
            jobTitle: string;
            industryTitle: string;
            jobFunctionTitle: string;
            years: string;
            primarySourceOfIncomeTitle: string;
            incomeOptions: DropdownOption[];
            occupationOptions: DropdownOption[];
            industryOptions: DropdownOption[];
            jobFunctionOptions: DropdownOption[];
        };
        tradingExperience: {
            title: string;
            experienceYears: DropdownOption[];
            commoditiesTitle: string;
            commoditiesQuestion: string;
            commoditiesYears: string;
            securitiesQuestion: string;
            securitiesYears: string;
            otcTitle: string;
            otcQuestion: string;
            otcYears: string;
            professionalTrader: string;
            professionalTraderUrl: string;
            professionalTraderPdfLabel: string;
            tradingExperienceTitle: string;
            doTradingExperience: string;
            yearsExperience: string;
            professionalTraderTitle: string;
            commodityExchangeAct: string;
            governmentRelations: string;
            bankruptcy: string;
            legalDisputes: string;
            closedCommodityAccounts: string;
        };
        affiliationsInfo: {
            title: string;
            politicalOrGovernment: string;
            employeeOfStoneX: string;
            regulatoryMember: string;
            regulatoryBody: string;
            registrationNumber: string;
            employeeName: string;
            employeeRelationship: string;
            nfaBody: string;
            nfaRegNumber: string;
        };
        regulatoryInfo: {
            title: string;
            registrationExemption: string;
            bankruptcy: string;
            disputeOrArbitration: string;
            unsatisfiedDebitBalance: string;
        };
        financialInfo: {
            title: string;
            error: string;
            page2Title: string;
            annualRevenue: string;
            annualRevenueOptions: DropdownOption[];
            netWorth: string;
            netWorthOptions: DropdownOption[];
            workingCapital: string;
            workingCapitalOptions: DropdownOption[];
            annualRevenueShort: string;
            totalNetWorth: string;
            availableWorkingCapital: string;
        };
        review: {
            title: string;
            subTitle: string;
            personal: string;
            account: string;
            funding: string;
            trustedContact: string;
            addTrustedContact: string;
            tradingInfo: string;
            regulatoryInfo: string;
            stoneXAffiliation: string;
            neitherStoneXAffiliation: string;
            nfaAffiliation: string;
            neitherNfaAffiliation: string;
            jobTitle: string;
            employer: string;
            yearsWithEmployer: string;
            employment: string;
            notice: string;
            noticeDescription: string;
            employedStatus: (employedStatus: string) => ShadedTextList;
            accountOpening: (accountType: string) => ShadedTextList;
            exemptUnderCommodityExchangeAct: (areNot: string) => string;
            relatedToGovernment: (areNot: string) => string;
            hasBankruptcy: (haveNot: string) => string;
            hasLegalDisputeWithCommodities: (haveNot: string) => string;
            hasClosedCommodityAccountWithDebitBalance: (haveNot: string) => string;
            isProffesionalTrader: (areNot: string) => string;
        };
        agreements: {
            arbitration: string;
            accept: string;
            doNotAccept: string;
            acceptArbitration: string;
            arbitrationAgreement: string;
            pageTitle: string;
            titleText: string;
            part1Title: string;
            part2Title: string;
            part3Title: string;
            part4Title: string;
            clientCertTitle: string;
            acceptAndContinueText: string;
            W9title: string;
            perjuryCertTitle: string;
            perjuryCertItem1: string;
            perjuryCertItem2: string;
            perjuryCertItem3: string;
            perjuryCertItem4: string;
            clientAcknowledgementTitle: string;
            clientAcknowledgementItem1: FormattedTextList;
            clientAcknowledgementItem2: FormattedTextList;
            clientAcknowledgementItem3: FormattedTextList;
            clientAcknowledgementItem4: FormattedTextList;
            clientAcknowledgementItem5: FormattedTextList;
            clientAcknowledgementItem6: FormattedTextList;
            clientAcknowledgementItem7: FormattedTextList;
            arbitrationAcceptText: string;
            arbitrationDeclineText: string;
            IRSnote: string;
            acceptAndContinueButton: string;
        };
        documents: {
            privacyPolicy: string;
            antiMoneyLaundering: string;
            commoditiesFuturesRiskDisclosure: string;
            commoditiesOptionsRiskDisclosure: string;
            addlDisclosures: string;
            subText: {
                nfaRiskDisclosure: string;
                addlRiskDisclosure: string;
                materialConflicts: string;
                electronicTrading: string;
                noticeToOmnibus: string;
                underlyingOrSpot: string;
                clearedSwaps: string;
            };
            stoneXCustomerAgreement: string;
            arbitrationAgreement: string;
            platformAgreement: string;
            virtualCurrencyRiskDisclosure: string;
            marketDataSubscriptionTerms: string;
            blobStorageUrls: {
                privacyPolicies: string;
                commodityFuturesTradingComissionRiskDisclosure: string;
                optionsDisclosureStatement: string;
                acknowledgementOfAdditionalDisclosures: string;
                virtualCurrencyRiskStatements: string;
                futuresAndExchangeTradedOptionsCustomerAgreement: string;
                platformUserAgreement: string;
                arbitrationAgreement: string;
                futuresMarketDataSubscriptionTerms: string;
            };
        };
        submit: {
            title: string;
        };
    };
    mediantSSO: {
        buttonText: string;
    };
    equitiesOnboarding: {
        // Look at fieldTranslations
        yes: string;
        no: string;
        firstName: string;
        lastName: string;
        email: string;
        continue: string;
        showIssues: string;
        are: string;
        areNot: string;
        openAccount: string;
        personalInfo1: {
            title: string;
            birthDate: string;
        };
        contactInfo: {
            title: string;
            // email
            validationMessage: string;
        };
        personalInfo2: {
            title: string;
            label: string;
            yesCitizen: string;
            noCitizen: string;
            ssnHeader: string;
            ssnOr: string;
            alert: string;
            desc: string;
        };
        chooseAccount: {
            title: string;
            description: string;
            individual: string;
            individualDescription: string;
            individualForeign: string;
            individualForeignDescription: string;
            rothIRA: string;
            rothIRADescription: string;
            traditionalIRA: string;
            traditionalIRADescription: string;
        };
        addressInfo: {
            title: string;
            secondary: string;
        };
        employmentOptions: {
            title: string;
            employment: string;
            employmentStatus: {
                employed: string;
                youAreEmployed: string;
                unemployed: string;
                youAreUnemployed: string;
                student: string;
                youAreStudent: string;
                retired: string;
                youAreRetired: string;
            };
            employmentInfo: {
                title: string;
                occupation: string;
                employer: string;
                jobTitle: string;
                jobFunction: string;
                industry: string;
                employerPhone: string;
                yearsEmployed: string;
            };
            employmentAddress: string;
            occupations: {
                accountingOrAuditing: string;
                advertisingOrMarketingSVCE: string;
                civilService: string;
                it: string;
                domesticHelp: string;
                education: string;
                trainingEmployment: string;
                engineering: string;
                executiveManagement: string;
                finance: string;
                insurance: string;
                doctor: string;
                dental: string;
                nurse: string;
                socialServices: string;
                homemaker: string;
                hospitality: string;
                tourism: string;
                transport: string;
                humanResources: string;
                manufacturing: string;
                military: string;
                adminSecty: string;
                customerService: string;
                other: string;
                salesOrRetail: string;
                science: string;
                agent: string;
                banker: string;
                business: string;
                policeFireLaw: string;
                attorneyJudgeLegal: string;
                politician: string;
            };
            jobFunction: {
                creditCollections: string;
                financialPlanning: string;
                financialAccounting: string;
                payroll: string;
                procurement: string;
                riskManagement: string;
                tax: string;
                treasury: string;
                businessManagement: string;
                adminManager: string;
                executiveAssistant: string;
                officeClerks: string;
                counseling: string;
                recruiting: string;
                training: string;
                applicationsSystems: string;
                businessSystems: string;
                databaseAdmin: string;
                ITArchitecture: string;
                ITProjectManagement: string;
                networkServices: string;
                softwareDevelopment: string;
                systemsSupport: string;
                technicalServices: string;
                webServices: string;
                generalCounsel: string;
                legalServices: string;
                legalSupport: string;
                audioVisual: string;
                communications: string;
                designServices: string;
                marketing: string;
                mediaRelations: string;
                publications: string;
                writingEditorial: string;
                other: string;
            };
            industries: {
                accounting: string;
                advertisingMarketing: string;
                aerospaceDefense: string;
                architectureDesign: string;
                automotive: string;
                childcare: string;
                communicationsTelecommunications: string;
                constructionCarpentryLandscaping: string;
                education: string;
                energy: string;
                engineering: string;
                financialServices: string;
                gamingCasinoCardClub: string;
                governmentPublicAdministration: string;
                healthcareMedicalServices: string;
                hotelHospitality: string;
                informationTechnology: string;
                insurance: string;
                legalServicesPublicSafety: string;
                manufacturing: string;
                mediaEntertainment: string;
                nonProfitCharity: string;
                printingPublishing: string;
                realEstate: string;
                restaurantFoodService: string;
                transportation: string;
                travel: string;
                other: string;
            };
        };
        funding: {
            title: string;
            sourceIncome: {
                title: string;
                wages: string;
                sale: string;
                inheritance: string;
                gift: string;
                insurance: string;
                legalSettlement: string;
                pension: string;
                retirement: string;
                broker: string;
            };
            estimatedLiquidNet: {
                title: string;
                a: string; // MoneyOptions reference MoneyOptions.ts
                b: string;
                c: string;
                d: string;
                e: string;
                f: string;
                g: string;
            };
        };
        trustedContact: {
            title: string;
            description: string;
            contactInfo: {
                title: string;
                relationship: string;
                phoneNumber: string;
                contactsEmailOpt: string;
                addAddress: string;
            };
            addressTitle: string;
        };
        stoneXAffiliation: {
            title: string;
            titleInfo: string;
            description: string;
            position: string;
            relationToEmployee: string;
            affiliatedEntity: string;
            affiliationName: string;
            affiliationRelationship: string;
            relationship: {
                child: string;
                self: string;
                spouse: string;
                other: string;
            };
            entity: string;
            affiliate: {
                stnx: string;
                infa: string;
                sswm: string;
                ssia: string;
            };
            affiliation: {
                areYou: string;
                you: string;
                areOtherUsBrokers: string;
                areAccreditedMember: string;
                areRegisteredAdvisor: string;
                areOfficerOwnsTenPercent: string;
                areEmployeeOfStonex: string;
                areNaturalPersonOrEntityWithoutTaxId: string;
                otherUsBrokers: string;
                accreditedMember: string;
                registeredAdvisor: string;
                officerOwnsTenPercent: string;
                employeeOfStonex: string;
                naturalPersonOrEntityWithoutTaxId: string;
            };
        };
        industryAffiliation: {
            title: string;
            description: string;
            // yes
            // no
            firmName: string;
            sec144: string;
            companyName: string;
            ticker: string;
        };
        review: {
            title: string;
            personal: string;
            countryOfCitizenship: string; // repeated
            ssnItin: string;
            account: string;
            accountDescription: string; // you are opening an
            employment: string; // Employment
            employmentDescription: string; // you are
            funding: string; // Funding
            relationship: string;
            noStoneXAffiliation: string;
            address: string;
            sec144Name: string; // SEC Rule 144 Company Name
            sec144Ticker: string; // SEC Rule 144 Company Ticker
            notPolicyMaker: string;
            notEmployed: string;
        };
        agreements: {
            title: string;
            description: string;
            label: string;
            optIn: string;
            optOut: string;
        };
        disclosures: {
            title: string;
            reviewAccept: string;
            reviewDisclosures: string;
            disclosuresAccepted: string;
            acceptContinue: string;
            pressFinishToCreate: string;
            finish: string;
            missingData: string;
            finishLater: string;
        };
    };
    equitiesOptionsOnboarding: {
        gotIt: string;
        whatAreOptions: {
            title: string;
            atItsCore: string;
            analogy: string;
            imagine: string;
        };
        financialInformation: {
            title: string;
            annualIncome: string;
            estNw: string;
            estLiquidNw: string;
            selectAnnualIncome: string;
            selectEstNw: string;
            selectLiquidNw: string;
        };
        investmentExperience: {
            title: string;
            0: string;
            1: string;
            2: string;
            3: string;
            stocksAndBonds: string;
            mutualFunds: string;
            options: string;
            annuities: string;
            altInvestments: string;
            selectNumYears: string;
        };
        requestOptionsLevel: {
            title: string;
            description: string;
        };
        riskTolerance: {
            title: string;
            description: string;
            conservative: string;
            conservativeDesc: string;
            moderate: string;
            moderateDesc: string;
            aggressive: string;
            aggressiveDesc: string;
            speculative: string;
            speculativeDesc: string;
        };
        optionsLevel: {
            title: string;
            description: string;
            level1: {
                title: string;
                action: string;
            };
            level2: {
                title: string;
                action1: string;
                action2: string;
            };
            level3: {
                title: string;
                action1: string;
                action2: string;
            };
            level4: {
                title: string;
                action1: string;
                action2: string;
                action3: string;
            };
            level5: {
                title: string;
                action1: string;
                action2: string;
            };
            requiresMargin: string;
            addMargin: string;
            levelRequiresMargin: string;
            level2Margin: string;
        };
        optionsAgreement: {
            title: string;
            description: string;
            review: string;
            close: string;
        };
        review: {
            title: string;
            financialInfo: string;
            estLiquidNw: string;
            netWorth: string;
            income: string;
            experienceInfo: string;
            stocksAndBonds: string;
            options: string;
            mutualFunds: string;
            annuities: string;
            altInvestments: string;
            optionsInfo: string;
            optionsLevel: string;
            investmentObjective: string;
        };
    };
    sharedOnboarding: {
        agreementRequired: (agreementName: string) => string;
        selectAccount: {
            title: (name: string) => string;
            subtitle: string;
            equitiesButtonLabelTitle: string;
            futuresButtonLabelTitle: string;
            equitiesButtonLabelSubtitle: string;
            futuresButtonLabelSubtitle: string;
        };
        true: string;
        false: string;
        signup: {
            leaveSignup: string;
            confirmCancelSignup: string;
            yesCancel: string;
            noStayHere: string;
        };
        headerTranslations: {
            personal: string;
            account: string;
            address: string;
            employment: string;
            funding: string;
            trustedContact: string;
            stonexAffiliation: string;
            industryAffiliation: string;
            whichAccount: string;
        };
        fieldTranslations: {
            personal: {
                firstName: string;
                lastName: string;
                email: string;
                phoneNumber: string;
                usCitizen: string;
                countryOfCitizenship: string;
                ssnOrItin: string;
            };
            account: {
                accountType: string;
            };
            address: {
                address: string;
                city: string;
                state: string;
                zipCode: string;
            };
            employment: {
                employmentStatus: string;
                occupation: string;
                employer: string;
                employerPhoneNumber: string;
                jobTitle: string;
                industry: string;
                jobFunction: string;
                employerCountry: string;
                employerAddress: string;
                employerAddress2: string;
                employerCity: string;
                employerState: string;
                employerZipCode: string;
            };
            funding: {
                sourceOfIncome: string;
                estimatedLiquidNetWorth: string;
            };
            trustedContact: {
                addTrustedContact: string;
                withTrustedContact: string;
                trustedContactFirstName: string;
                trustedContactLastName: string;
                trustedContactEmail: string;
                trustedContactPhoneNumber: string;
                trustedContactRelationship: string;

                withTrustedContactAddress: string;
                trustedContactAddress: string;
                trustedContactCity: string;
                trustedContactState: string;
                trustedContactZipCode: string;
            };
            stonexAffiliation: {
                withStonexAffiliation: string;
                stonexAffilFirstName: string;
                stonexAffilLastName: string;
                stonexAffilPosition: string;
                stonexAffilEntity: string;
                sameAsEmploymentInformation: string;

                stonexAffilOtherUsBrokers: string;
                stonexAffilAccreditedMember: string;
                stonexAffilWithRegisteredAdvisor: string;
                stonexAffilOfficerOwnsTenPercent: string;
                stonexAffilEmployeeOfStonex: string;
                stonexAffilNaturalPersonOrEntityWithoutTaxId: string;
            };
            industryAffiliation: {
                withIndustryAffiliation: string;
                industryAffilFirmName: string;
                withIndustryAffiliationRule144: string;
                industryAffilRule144CompanyName: string;
                industryAffilRule144CompanySymbolOrCusip: string;
            };
            whichAccount: {
                continueStockTradingApplication: string;
                stockTradingAccount: string;
                futuresTradingAccount: string;
            };
        };
        accountOpeningSelection: {
            pageTitle: string;
            pageSubtitle: string;
            margin: string;
            options: string;
            accountNumber: string;
            accountType: string;
            activeApplication: string;
            selectedAccountOptions: {
                addMargin: string;
                marginSubLabel: string;
                addOptions: string;
                optionsSubLabel: string;
                continueApplication: string;
                continueApplicationSubLabel: string;
            };
            selectionBadge: {
                inProgress: string;
                created: string;
                pending: string;
            };
        };
        submissionModal: {
            multiAssetTrading: string;
            loading: string;
            allDone: string;
            tapAnywhereContinue: string;
            notAbleToCreateAccount: string;
            tapAnywhereForSupport: string;
            applicationPending: string;
            applicationQueued: string;
        };
    };
    futuresGlance: {
        labels: {
            cash: string;
            netLiq: string;
            openPnl: string;
            realizedPnl: string;
            buyingPower: string;
        };
        info: {
            netLiq: string;
            openPnl: string;
            realizedPnl: string;
            buyingPower: string;
            lastUpdated: string;
        };
    };
    fplBanner: {
        bannerText: (isSummary?: boolean, theme?: AppliedAppTheme) => ShadedTextList;
        bannerTextMobileSummary: ShadedTextList;
        bannerTextMobileAccount: ShadedTextList;
        applyNow: string;
        learnMore: string;
    };
    fplModal: {
        headerText: ShadedTextList;
        eligibilityText: string;
        aboutText: string;
        revenueStreamText: ShadedTextList;
        maintainOwnershipText: ShadedTextList;
        loansAreSecureText: ShadedTextList;
        howItWorksTitle: string;
        howItWorksText: string;
        bulletPointsText: string[];
        invitedToFpl: string;
        fullyPaidLending: string;
        maximizeProfits: string;
        addFunds: string;
    };
    fplProfile: {
        applyForFPL: string;
        fplProfileLink: string;
        fplProfileBannerText: (theme?: AppliedAppTheme) => ShadedTextList;
        learnMore: string;
        pending: {
            header: string;
            body: string;
        };
        enabled: {
            header: string;
            body: string;
        };
    };
    fplOnboarding: {
        welcome: {
            title: string;
            text: string;
        };
        fplInfo: {
            title: string;
            text: string;
        };
        agreements: {
            requiredClick: string;
            title: string;
            subtitle: () => ShadedTextList;
            clientCertTitle: string;
            loanAgreementText: () => ShadedTextList;
            loanAgreementPDFUrl: string;
            loanAgreementPDFTitle: string;
            fplAccountControlAgreementText: () => ShadedTextList;
            fplAccountControlAgreementPDFUrl: string;
            fplAccountControlAgreementPDFTitle: string;
            axosAcknowledgementText: string;
        };
        disclosures: {
            requiredClick: string;
            title: string;
            subtitle: string;
            scrollTitle: string;
            scrollSubtitle: string;
            importantCharAndRiskAgreement: string;
            importantCharAndRiskAgreementPDFUrl: string;
            importantCharAndRiskAgreementText: () => ShadedTextList;
        };
        submitting: {
            title: string;
            completedTitle: string;
            loadingMessage: () => ShadedTextList;
            successMessage: () => ShadedTextList;
            axosMessage: string;
            errorMessage: string;
        };
    };
    loggedOutScreen: {
        title: string;
        body: string;
    };
    mobileFplOnboarding: {
        apply: {
            title: string;
            account: string;
            selectAccount: string;
            incomeGenerationTitle: string;
            incomeGenerationDesc: string;
            ownershipRetentionTitle: string;
            ownershipRetentionDesc: string;
            riskAdjustedReturnsTitle: string;
            riskAdjustedReturnsDesc: string;
            votingTitle: string;
            votingDesc: string;
            eligibilityTitle: string;
            eligibilityDesc: string;
        };
        disclosures: {
            title: string;
            masterSecuritiesLoan: string;
            lendingAccountControl: string;
            lendingImportantCharacteristicsAndRisks: string;
            reviewAccept: string;
            reviewDisclosures: string;
            disclosuresAccepted: string;
            acceptContinue: string;
            pressFinishToCreate: string;
            finish: string;
            missingData: string;
            finishLater: string;
        };
        flowStage: {
            submittedTitle: string;
            submittedSubtitle: string;
            loadingTitle: string;
            failedTitle: string;
        };
        acknowledgements: {
            title: string;
            message: string;
            accept: string;
        };
    };
    mobileNavBar: {
        home: string;
        cash: string;
        trade: string;
        orders: string;
    };
    oneProNetwork: {
        discoverMore: string;
        insights: string;
        latestPosts: string;
        enableOneProNetwork: string;
    };
}
